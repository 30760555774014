import { cn } from '@/lib/utils'

export function Divider({
  className,
  variant = 'horizontal',
  style,
}: {
  className?: string
  variant?: 'horizontal' | 'vertical'
  style?: React.CSSProperties
}) {
  if (variant === 'vertical') {
    return (
      <div style={style} className={cn('dark:bg-dark-500 h-full w-px border-0 bg-gray-300', className)}>
        &nbsp;
      </div>
    )
  }

  return <hr style={style} className={cn('dark:bg-dark-500 h-px border-0 bg-gray-200', className)} />
}
