import { useLazyQuery } from '@apollo/client'
import { QueryAuthorizedVehicles, QueryVehicles } from 'internal/graphql/query/vehicle'
import { QueryResponse, Vehicle } from 'types'
import { UseVehiclesOptions } from './useVehicles'

export function useLazyVehicles(options: UseVehiclesOptions = { page: 1 }) {
  const [load, { data, loading, refetch }] = useLazyQuery<QueryResponse<'vehicles', Vehicle>>(QueryVehicles, {
    variables: options,
  })

  const vehicles = data?.vehicles?.results || []
  const total = data?.vehicles?.total || 0

  return { load, vehicles, total, loading, refetch }
}

export function useLazyAuthorizedVehicles(options?: UseVehiclesOptions) {
  const [load, { data, loading, refetch }] = useLazyQuery<QueryResponse<'vehicles', Vehicle>>(QueryAuthorizedVehicles, {
    variables: options,
  })

  const vehicles = data?.vehicles?.results || []
  const total = data?.vehicles?.total || 0

  return { load, vehicles, total, loading, refetch }
}
