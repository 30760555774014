import classNames from 'classnames'
import { Fragment, ReactNode } from 'react'

type FormGroupSectionProps = {
  className?: string
  expanded?: boolean
  label: string
  children?: ReactNode
}

export default function FormGroupSection(props: FormGroupSectionProps) {
  return (
    <Fragment>
      <div
        className={classNames(
          'dark:border-dark-400 dark:bg-dark-500 border-b border-t border-gray-200 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 dark:text-gray-200',
        )}
      >
        {props.label}
      </div>
      <div className={classNames(props.className, 'divide-y divide-gray-200 dark:divide-gray-500')}>
        {props.children}
      </div>
    </Fragment>
  )
}
