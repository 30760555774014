import classNames from 'classnames'
import { Button } from 'components/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { SECTIONS } from 'constants/document'
import { format } from 'date-fns'
import { Texture } from 'pixi.js'
import { Fragment, MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { HiXCircle } from 'react-icons/hi'
import { Container, Sprite, Stage } from 'react-pixi-fiber'
import { Document, FieldMetadata, TextractBlock } from 'types/Document'
import { decode, draw } from 'util/decode'
import { Rectangle } from './pixi/Rectangle'

declare var cv

export type Rect = {
  height: number
  width: number
}

export type DocumentPageProps = {
  document: Document
  fieldMetadata: FieldMetadata[]
  page: number
  pages: number
  blocks: TextractBlock[]
  fields: any[]
  url: string
  rotation: number
  width: number
  height: number
  onChange: (field: string, value: string, blocks: TextractBlock[]) => void
  onLoad?: (dataUrl: string) => void
  onData?: (data: any) => void
}

type Point = { x: number; y: number }
type Block = TextractBlock & { points: Point[] }

export const DocumentPage = (props: DocumentPageProps) => {
  const {
    document: doc,
    page,
    pages,
    fields,
    // fieldMetadata,
    blocks: propsBlocks,
    url,
    rotation,
    width,
    height,
    onChange,
    onLoad,
    onData,
  } = props

  const [img, setImg] = useState<HTMLImageElement | null>(null)
  const [activeBlockIds, setActiveBlockIds] = useState<string[]>([])
  const [isMouseDown, setIsMouseDown] = useState(false)
  const clickRef = useRef<number[]>([0, 0])
  const containerRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const shouldFlip = Math.abs(rotation) === 90

  const { imgWidth, imgHeight } = (() => {
    if (!img) {
      return { imgWidth: 0, imgHeight: 0 }
    }
    let w = width
    let h = img.height * (width / img.width)

    if (Math.abs(rotation) === 90) {
      const ratio = img.width / img.height
      h = width * ratio
    } else {
      h = img.height * (width / img.width)
    }

    if (Math.abs(rotation) === 90) {
      return { imgWidth: h, imgHeight: w }
    }

    return { imgWidth: w, imgHeight: h }
  })()
  const blocks: Block[] = propsBlocks.map(block => ({
    ...block,
    points: block.Geometry.Polygon.map(p => ({
      x: p.X * imgWidth,
      y: p.Y * imgHeight,
    })),
  }))
  const activeBlocks = activeBlockIds.map(id => blocks.find(b => b.Id === id))
  const firstActiveBlock: Block | null = activeBlocks[0] || null

  const handleLoaded = useCallback(
    async e => {
      const img: HTMLImageElement = e.currentTarget
      if (onLoad || onData) {
        try {
          // only scan vehicle registrations for barcodes
          if (doc?.documentType !== 'VehicleRegistration') return

          const canvas = document.createElement('canvas')
          canvas.width = img.naturalWidth
          canvas.height = img.naturalHeight
          const rect = canvas.getBoundingClientRect()

          // Copy the image contents to the canvas
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          let dataUrl = canvas.toDataURL('image/png')

          // skip if too large
          if (dataUrl.length > 5e7) return

          // try to detect using original image
          let detected = await decode(dataUrl)
          if (detected) {
            onData?.(detected)
            return
          }

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          let src = cv.matFromImageData(imageData)

          // try to detect using grayscale image
          const gray = cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC3)
          cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0)
          cv.threshold(gray, gray, 0, 255, cv.THRESH_BINARY | cv.THRESH_OTSU)
          const grayUrl = draw(gray, rect)
          detected = await decode(grayUrl)
          if (detected) {
            onData?.(detected)
            return
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    [onLoad, onData, doc?.documentType],
  )

  const load = useCallback(
    url => {
      if (!url) return setImg(null)
      if (img || !isVisible) return

      const i = new Image()
      i.crossOrigin = 'anonymous'
      i.onload = e => {
        handleLoaded(e)
        setImg(i)
      }
      i.src = url

      return () => {
        setImg(null)
      }
    },
    [handleLoaded, isVisible, img],
  )

  const intersectionCb = useCallback(
    entries => {
      const [entry] = entries
      if (entry.isIntersecting && height > 0) {
        setIsVisible(true)
        if (!img) {
          load(url)
        }
      } else {
        setIsVisible(false)
      }
    },
    [url, height, img, load],
  )

  const handleMouseDown = useCallback(() => {
    setActiveBlockIds([])
    // setSelection(null)
    setIsMouseDown(true)
  }, [])

  const handleMouseUp: MouseEventHandler = useCallback(async e => {
    // const dragRect = e.currentTarget.getBoundingClientRect()
    // onSelect(activeBlockIds, e, rect)
    if (!menuRef.current) return
    const rect = menuRef.current.getBoundingClientRect()
    let x = e.clientX
    let y = e.clientY
    if (x + rect.width >= window.innerWidth) {
      x = window.innerWidth - rect.width
    }

    if (y + rect.height >= window.innerHeight) {
      y = window.innerHeight - rect.height
    }
    clickRef.current = [x, y]
    setIsMouseDown(false)
  }, [])

  const handleMouseEnter = useCallback(
    block => {
      if (!isMouseDown) return
      setActiveBlockIds(blocks => blocks.filter(b => b !== block.Id).concat(block.Id))
    },
    [isMouseDown],
  )

  const handleClick = field => () => {
    const el = document.getElementById(field.name) as HTMLInputElement

    let value = activeBlocks.map(b => b.Text).join(' ')
    if (field.type === 'date') {
      try {
        const yearFirst = /^\d{4}/
        if (yearFirst.test(value)) {
          const parts = value.split(/[-\/]/)
          parts.push(...parts.splice(0, 1))
          value = parts.join('/')
        }

        const dateValue = format(new Date(value), 'MM/dd/yyyy')
        value = dateValue
      } catch {}
    }

    el.focus()
    el.value = value
    el.scrollIntoView({ block: 'center' })
    el.blur()
    el.focus()
    setActiveBlockIds([])
    onChange(field.name, value, activeBlocks)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCb, { threshold: 0 })
    if (containerRef.current) observer.observe(containerRef.current)
    const cur = containerRef.current
    return () => {
      if (cur) observer.unobserve(cur)
    }
  }, [containerRef, intersectionCb])

  return (
    <Fragment>
      <div className="relative">
        <div className="flex justify-end">
          <Text className="mb-2" weight="medium">
            Page {page + 1} of {pages}
          </Text>
        </div>
        <div
          className="relative"
          ref={containerRef}
          style={{
            minHeight: '50vh',
          }}
        >
          {img && isVisible && height > 0 ? (
            <Stage
              options={{
                backgroundAlpha: 0,
                width: shouldFlip ? imgHeight : imgWidth,
                height: shouldFlip ? imgWidth : imgHeight,
              }}
              onPointerDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <Container
                x={shouldFlip ? (rotation > 0 ? 0 : imgHeight) : rotation ? imgWidth : 0}
                y={shouldFlip ? (rotation > 0 ? imgWidth : 0) : rotation ? imgHeight : 0}
                rotation={-rotation * (Math.PI / 180)}
              >
                <Sprite texture={Texture.from(img)} width={imgWidth} height={imgHeight} />
                {blocks.map((block, blockIdx) => {
                  return (
                    <Rectangle
                      key={block.Id}
                      index={blockIdx}
                      selected={activeBlockIds.includes(block.Id)}
                      color={0x22c55e}
                      points={block.points}
                      onMouseEnter={handleMouseEnter.bind(null, block)}
                    />
                  )
                })}
              </Container>
            </Stage>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <div
        className={classNames(
          'fixed z-20 max-h-full overflow-auto rounded-md bg-gray-800 p-4 text-sm text-white shadow-lg',
          !isMouseDown && firstActiveBlock ? 'opacity-1' : 'pointer-events-none opacity-0',
        )}
        style={{
          left: clickRef.current[0],
          top: clickRef.current[1],
        }}
        ref={menuRef}
      >
        <Text variant="white" className="mb-4" size="xs">
          {activeBlocks.map(b => b.Text).join(' ')}
        </Text>
        <ul className="flex gap-4">
          {SECTIONS.filter(s => s.selectable !== false).map(section => {
            const f = section.fields
              .filter(f => fields.includes(f.name))
              .map(field => {
                const el = document.getElementById(field.name) as HTMLInputElement
                const disabled = !!el?.value

                return (
                  <li key={`${section.name}.${field.name}`} className="flex w-full items-center gap-1">
                    <Button
                      size="xs"
                      variant="light"
                      className="hover:bg-white/10 disabled:text-white/20"
                      onClick={handleClick(field)}
                      disabled={disabled}
                    >
                      {field.title}
                    </Button>
                    {disabled && (
                      <IconButton
                        ariaLabel="Clear"
                        size="xs"
                        icon={HiXCircle}
                        variant="light"
                        className="text-white/20 hover:text-white"
                        onClick={() => {
                          el.value = ''
                          onChange(field.name, el.value, activeBlocks)
                        }}
                      />
                    )}
                  </li>
                )
              })

            if (f.length < 1) {
              return null
            }

            return (
              <div key={section.name}>
                <Text variant="extralight" className="mb-2" size="xs">
                  {section.name}
                </Text>
                {f}
              </div>
            )
          })}
        </ul>
      </div>
    </Fragment>
  )
}
