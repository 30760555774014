import { gql, useQuery } from '@apollo/client'
import { post } from 'lib/api/req'
import { WorkspaceInvite } from 'types/Workspace'

const QueryWorkspaceInvites = gql`
  query WorkspaceInvites {
    workspaceInvites {
      id
      createdAt

      workspace {
        imageUrl
        name
        type
      }
    }
  }
`

export function useWorkspaceInvites() {
  const { data, loading, error, refetch } = useQuery(QueryWorkspaceInvites)
  const invites: WorkspaceInvite[] = data?.workspaceInvites || []

  const accept = async (id: string) => {
    const res = await post('/v1/workspaces/invites/accept', { id })

    refetch()

    return res
  }

  return {
    invites,
    loading,
    error,
    refetch,
    accept,
  }
}
