import { DocumentExtraction, DocumentLink } from 'types/Document'

export const checkFirstName = (value: string, extractedData: DocumentExtraction, owner: DocumentLink) => {
  if (!value || !owner?.driver?.firstName) return null

  if (clean(value) !== clean(owner?.driver?.firstName)) {
    return `First name mismatch: ${value} vs ${owner.driver.firstName}`
  }
}

export const checkMiddleName = (value: string, extractedData: DocumentExtraction, owner: DocumentLink) => {
  if (!value || !owner?.driver?.middleName) return null

  if (clean(value) !== clean(owner?.driver?.middleName)) {
    return `Middle name mismatch: ${value} vs ${owner.driver.middleName}`
  }
}

export const checkLastName = (value: string, extractedData: DocumentExtraction, owner: DocumentLink) => {
  if (!value || !owner?.driver?.lastName) return null

  if (clean(value) !== clean(owner?.driver?.lastName)) {
    return `Last name mismatch: ${value} vs ${owner.driver.lastName}`
  }
}

const clean = v => v.toLowerCase().trim()
