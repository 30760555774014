import { RequireRole } from 'components/RequireRole/RequireRole'
import { useAuth } from 'hooks/useAuth'

import { useSidebarContext } from 'hooks/useSidebarContext'
import { useToggle } from 'hooks/useToggle'
import { useLayoutContext } from 'layouts/MainLayout'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { HiMoon, HiOutlineFlag, HiOutlineLogout, HiOutlineOfficeBuilding, HiOutlineUserCircle } from 'react-icons/hi'
import { TbLayoutSidebarLeftCollapse } from 'react-icons/tb'
import { IconMenu } from '../IconMenu/IconMenu'
import { ChooseLangaugeModal } from '../Modals/ChooseLangaugeModal'
import { MenuItem } from './MenuItem'

export type AccountMenuProps = {
  position?: 'top' | 'bottom' | 'left' | 'right'
}

export const AccountMenu = (props: AccountMenuProps) => {
  const { account, logout } = useAuth()
  const { t } = useTranslation()
  const { toggleCollapsed } = useSidebarContext()
  const layoutContext = useLayoutContext()
  const [showLanguageModal, toggleShowLanguageModal] = useToggle(false)

  const toggleDarkMode = () => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      localStorage.setItem('theme', 'light')
    } else {
      localStorage.setItem('theme', 'dark')
    }

    window.location.reload()
  }

  if (!account) return null

  return (
    <>
      <IconMenu
        icon={
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-700">
            <span className="font-bold text-white">{account?.firstName?.slice(0, 1)}</span>
          </div>
        }
        position={props.position}
      >
        <Link href="/account/profile">
          <MenuItem icon={HiOutlineUserCircle} title={t('profile')} />
        </Link>
        <RequireRole deny="insurance-producer">
          <RequireRole allow="provider">
            <Link href="/account/team">
              <MenuItem icon={HiOutlineOfficeBuilding} title={t('organization')} />
            </Link>
          </RequireRole>
          <MenuItem icon={HiOutlineFlag} title={t('changeLanguage')} onClick={() => toggleShowLanguageModal()} />
          <RequireRole allow={['superadmin', 'dark-mode']} override={process.env.NEXT_PUBLIC_APP_ENV === 'local'}>
            <MenuItem icon={HiMoon} title="Toggle Dark Mode" onClick={() => toggleDarkMode()} />
          </RequireRole>
          <MenuItem
            icon={TbLayoutSidebarLeftCollapse}
            title={'Toggle Sidebar'}
            onClick={() => toggleCollapsed()}
            disabled={layoutContext.sidebarCollapsed}
          />
        </RequireRole>

        <MenuItem icon={HiOutlineLogout} title={t('logOut')} onClick={() => logout()} />
      </IconMenu>
      <ChooseLangaugeModal isOpen={showLanguageModal} onClose={() => toggleShowLanguageModal(false)} />
    </>
  )
}
