import { useFlagsmith } from 'hooks/useFlagsmith'
import { refreshProfile } from 'lib/api'
import { useDispatch, useSelector } from 'react-redux'
import { Account } from 'types/Account'
import { selectAuth, setAccount } from '../internal/redux'
import { useAnalytics } from './useAnalytics'

export function useAuth() {
  const flagsmith = useFlagsmith()
  const auth = useSelector(selectAuth)
  const analytics = useAnalytics()
  const dispatch = useDispatch()
  const refresh = async () => {
    const r = await refreshProfile()
    if (!r.ok) {
      return dispatch(setAccount(null))
    } else {
      const acc = await r.json()

      analytics?.register({
        accountId: acc?.id,
        roles: acc?.roles.join(', '),
        market: acc?.provider?.market?.name || '',
      })
      flagsmith.identify(acc.id, {
        providerId: acc.providerId,
        market: acc.provider?.market?.name || '',
      })

      return dispatch(setAccount(acc))
    }
  }

  const logout = () => {
    if (!auth.account) return
    fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/auth/logout', {
      method: 'POST',
      credentials: 'include',
    }).finally(() => {
      flagsmith?.logout()
      window.location.href = '/login'
    })
  }

  return {
    ...auth,
    // this can technically be null but Wrapper should prevent
    // any children that require account to render.
    account: auth.account as Account,
    refresh,
    logout,
  }
}
