import { createAsyncThunk } from '@reduxjs/toolkit'
import { Bounds, County, Zip, Zoom } from 'internal/types/map'

import { MODES } from 'components/Map/modes'
import { getFitBounds } from 'components/Map/utilities'
import { post as geoPost } from 'lib/api/geo'
import { post } from 'lib/api/req'
import { ServiceArea } from 'types'
import { Geometry } from 'wkx'
import { AppDispatch, store } from '../../redux/store'
import { setBounds, setMode, setSelectedCounty, setSelectedZip } from '../slices/map'

export function handleMapMove({ bounds, zoom }: { bounds: Bounds; zoom: Zoom }) {
  return function mapMoveThunk() {
    const state = store.getState()
    const mode = MODES[state.map.modeKey]
    if (!mode) return

    mode.forEach(m => {
      if (m.mapMove) m.mapMove({ bounds, zoom })
    })
  }
}

export function handleSelectCounty({ geoid }: { geoid: string }) {
  return function selectCountyThunk(dispatch: AppDispatch) {
    const state = store.getState()
    const countiesSource = state.map.sources.find(s => s.id === 'counties')
    const county = countiesSource?.config?.data?.features?.find(f => f.id === geoid)
    if (!county) return null

    dispatch(setSelectedCounty(county.id))
    dispatch(setMode('providerZipcodes'))
    dispatch(loadZips({ state: county.properties?.state, code: county.properties?.code }))
    const bounds = getFitBounds(county.geometry) || null
    dispatch(setBounds(bounds))

    return county
  }
}

export function handleNetworkOverview({ bounds }: { bounds: number[] }) {
  return async function handleNetworkOverviewThunk(dispatch: AppDispatch) {
    try {
      const zips = await dispatch(loadZips({ bounds })).unwrap()

      await dispatch(loadCounts({ zips: zips.map(z => z['postal_code']) }))
    } catch (e) {
      console.error(e)
    }
  }
}

export const loadZip = createAsyncThunk('map/loadZip', async (options: { zipCode: string }) => {
  const res = await geoPost('/zips', { zips: [options.zipCode] })
  if (!res.ok) return null

  const json = await res.json()
  const zip: Zip = json.zips?.[0]

  if (!zip) return null
  return zip
})

export function handleLoadZip({ zipCode }: { zipCode: string }) {
  return async function handleLoadZipThunk(dispatch: AppDispatch) {
    const zip = await dispatch(loadZip({ zipCode })).unwrap()
    dispatch(setSelectedZip(zip.postal_code))
    dispatch(loadCounts({ zips: [zip.postal_code] }))
  }
}

export const loadServiceArea = createAsyncThunk(
  'map/loadServiceArea',
  async (options: { serviceArea: ServiceArea[] }) => {
    const serviceArea = options.serviceArea
    const state = store.getState()
    const modeKey = state.map.modeKey
    const sources = state.map.sources

    const serviceAreaMode = MODES[modeKey].find(m => m.id === 'serviceArea')
    const serviceAreaSource = sources.find(s => s.id === 'serviceArea')

    if (!serviceAreaMode || !serviceAreaSource || !serviceAreaMode.featureData) return

    const serviceTypes = serviceArea.reduce(
      (obj, acc) => {
        obj[acc.postalCode] = acc.serviceType
        return obj
      },
      {} as Record<string, string>,
    )

    const res = await geoPost('/zips', { zips: serviceArea.map(area => area.postalCode) })
    if (!res.ok) return []

    const json = await res.json()
    const zips: Zip[] = json.zips

    const features = zips.map(zip => ({
      type: 'Feature',
      geometry: Geometry.parse(zip.geom).toGeoJSON(),
      id: zip.postal_code,
      properties: {
        serviceType: serviceTypes[zip.postal_code],
        code: zip.postal_code,
        name: zip.name,
        county: zip.county,
        state: zip.state,
      },
    }))

    return features
  },
)

export const loadCounties = createAsyncThunk('map/loadCounties', async (options: { bounds: Bounds }) => {
  const state = store.getState()
  const context = state.auth.context

  const markets =
    context === 'provider'
      ? [state.auth.account?.provider?.market?.name]
      : state.auth.account?.permissions?.markets?.map(m => m.name)

  const params = new URLSearchParams()

  params.append('markets', markets?.join(',') || '')
  params.set('bounds', options.bounds.map(f => f.toFixed(4)).join(','))

  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_GEO_API_BASE_URL}/counties?${params.toString()}`)
    if (!res.ok) {
      throw new Error('Something went wrong')
    }

    const data: { counties: County[] } = await res.json()
    const uniqueGeoids = new Set<string>()
    const uniqueCounties = []

    data?.counties.forEach(c => {
      if (uniqueGeoids.has(c.geoid)) return
      uniqueGeoids.add(c.geoid)
      uniqueCounties.push(c)
    })

    return uniqueCounties
  } catch {
    throw new Error('Something went wrong')
  }
})

export const loadZips = createAsyncThunk(
  'map/loadZips',
  async (options: { state?: string; code?: string; bounds?: number[] }) => {
    const state = store.getState()
    const context = state.auth.context
    const markets =
      context === 'provider'
        ? [state.auth.account?.provider?.market?.name]
        : state.auth.account?.permissions?.markets?.map(m => m.name)

    const params = new URLSearchParams()

    params.append('markets', markets?.join(',') || '')

    options.state && params.set('state', options.state)
    options.code && params.set('county_code', options.code)
    options.bounds &&
      params.set(
        'bounds',
        options.bounds
          .flat()
          .map(b => b.toFixed(4))
          .join(','),
      )

    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_GEO_API_BASE_URL}/zips?${params.toString()}`)
      if (!res.ok) {
        throw new Error('Something went wrong')
      }

      const data: { zips: Zip[] } = await res.json()
      const uniqueZipIds = new Set<string>()
      const uniqueZips = []

      data?.zips.forEach(z => {
        if (uniqueZipIds.has(z.postal_code)) return

        uniqueZipIds.add(z.postal_code)
        uniqueZips.push(z)
      })

      return uniqueZips
    } catch {
      throw new Error('Something went wrong')
    }
  },
)

export const loadCounts = createAsyncThunk('map/loadCounts', async (options: { zips: string[] }) => {
  const state = store.getState()
  const res = await post('/v1/area-of-service/count', {
    zipCodes: options.zips,
    levelOfService: state.overview.levelOfService,
  })
  if (!res.ok) {
    throw new Error('Something went wrong')
  }

  const json = await res.json()

  return json?.results || {}
})
