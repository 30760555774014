import { DocumentExtraction, DocumentLink } from 'types/Document'

export const checkVINOwner = (value: string, extraction?: DocumentExtraction, owner?: DocumentLink) => {
  if (value?.length != 17) {
    return `VIN is not 17 characters long`
  }

  if (value != owner?.vehicle?.vin) {
    return `VIN mismatch`
  }

  return
}
