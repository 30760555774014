import { gql, useQuery } from '@apollo/client'

const query = gql`
  query ($type: String!) {
    requiredFields(types: [$type]) {
      fields
    }
  }
`

export const useDocumentFields = (documentType: string) => {
  const { data } = useQuery(query, { variables: { type: documentType } })
  const { requiredFields = [{}] } = data || {}
  const { fields = [] } = requiredFields[0] || {}

  return { fields }
}
