import { useSidebarContext } from 'hooks/useSidebarContext'
import { formatNum } from 'util/format'

export function NavStat({ children }) {
  const { theme } = useSidebarContext()
  return (
    <span className={'ml-2 inline-block rounded-md px-1.5 py-0.5 text-xs font-medium ' + theme.navStat}>
      {formatNum(children)}
    </span>
  )
}
