import { Text } from 'components/Text'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { ReactNode } from 'react'

type NavGroupProps = {
  name: string
  expanded?: boolean
  children: ReactNode | ReactNode[]
}

export const NavGroup = (props: NavGroupProps) => {
  const { isCollapsed } = useSidebarContext()

  return (
    <div className="mt-2">
      {!isCollapsed && (
        <Text size="sm" className="px-6 py-2" variant="light">
          {props.name}
        </Text>
      )}
      <div>{props.children}</div>
    </div>
  )
}
