import { gql } from '@apollo/client'

export const QueryVehicles = gql`
  query Vehicles(
    $page: Int!
    $limit: Int
    $query: String
    $ids: [String!]
    $active: Boolean
    $activeBrokerLink: Boolean
    $marketId: String
    $providerIds: [String!]
    $sort: String
  ) {
    vehicles(
      page: $page
      limit: $limit
      query: $query
      ids: $ids
      active: $active
      activeBrokerLink: $activeBrokerLink
      marketId: $marketId
      providerIds: $providerIds
      sort: $sort
    ) {
      total
      results {
        fleetNumber
        id
        providerId
        documentGroupId
        vin
        plate
        year
        make
        model
        color
        isActive
        isAuthorized
        isCompliant
        compliantUntil
        statusReason
        createdAt
        updatedAt

        authorization {
          id
          expiresAt
          createdAt
          completedAt
        }

        provider {
          id
          name
          market {
            id
            name
          }
        }

        documentGroup {
          id
          name
          serviceType
        }

        documentExemptions {
          id
          documentType
          reason
          expiresAt
        }

        requiredDocuments {
          documentType
          requireLevel

          primary {
            id
            expiresAt
            status
            statusReason
          }
          pending {
            id
            expiresAt
            status
            statusReason
          }
        }
      }
    }
  }
`

export const QuerySearchVehicles = gql`
  query SearchVehicles($page: Int! = 1, $limit: Int! = 15, $query: String!) {
    vehicles(page: $page, limit: $limit, query: $query) {
      total
      results {
        id
        vin
        plate
        model
        make
        year
        isActive
      }
    }
  }
`

export const QueryProviderVehicles = gql`
  query ProviderVehicles($id: String!, $page: Int! = 1, $active: Boolean, $query: String) {
    providerVehicles(id: $id, page: $page, active: $active, query: $query) {
      total
      results {
        id
        providerId
        documentGroupId
        fleetNumber
        vin
        plate
        year
        make
        model
        color
        isActive
        isAuthorized
        isCompliant
        compliantUntil
        statusReason
        createdAt
        updatedAt

        provider {
          id
          name
        }

        documentGroup {
          id
          name
          serviceType
        }

        documentExemptions {
          id
          documentType
          reason
          expiresAt
        }

        requiredDocuments {
          documentType
          requireLevel

          primary {
            id
            expiresAt
            status
            statusReason
          }
        }
      }
    }
  }
`

export const QueryVehicleFull = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
      id
      providerId
      documentGroupId
      fleetNumber
      vin
      plate
      year
      make
      model
      color
      isActive
      isAuthorized
      isCompliant
      compliantUntil
      statusReason
      fleetNumber
      createdAt
      updatedAt

      authorization {
        createdAt
        expiresAt
        completedAt
        updatedAt
        decider {
          firstName
          lastName
        }
      }

      checks(latest: true) {
        id
        name
        result
        details
        createdAt
      }

      customAttributes {
        providerCode
        attributes
      }

      documentGroupId
      documentGroup {
        id
        name
        serviceType
      }

      exclusions {
        id
        note
        validUntil
        revokedAt
        value
        providerIds
        createdAt
      }

      documentExemptions {
        id
        documentType
        reason
        expiresAt
      }

      requiredCredentials {
        name
        type

        requiredDocuments {
          vehicleId
          documentType
          name
          allowedMimeTypes
          canReplaceOnExpire
          canExempt
          requireLevel

          dependencies {
            documentType
            name
          }

          documentRequests {
            id
            documentType
            dueAt
            createdAt
            updatedAt
          }

          requiredAuxiliaryDocuments {
            vehicleId
            documentType
            name
            allowedMimeTypes
            canReplaceOnExpire
            canExempt
            requireLevel

            primary {
              id
              documentType
              expiresAt
              status
              statusReason
              createdAt

              decision {
                id
                assignmentId
              }

              extractedData {
                documentId
                id
                issuedAt
                expiresAt
              }

              type {
                name
              }
            }

            pending {
              id
              documentType
              expiresAt
              status
              statusReason
              createdAt

              extractedData {
                documentId
                id
                issuedAt
                expiresAt
              }

              type {
                name
              }
            }
          }

          primary {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            decision {
              id
              assignmentId
              createdAt
            }

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }

          pending {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }
        }
      }

      requiredDocuments {
        name
        documentType
        requireLevel
        canReplaceOnExpire
        canExempt

        documentRequests {
          id
          documentType
          dueAt
          createdAt
          updatedAt
        }

        dependencies {
          documentType
          name
        }

        primary {
          id
          status
          statusReason
          expiresAt
          createdAt
          updatedAt
          isVirtual

          decision {
            id
            createdAt
          }
        }

        pending {
          id
          status
          statusReason
          expiresAt
          createdAt
          updatedAt
        }
      }

      provider {
        id
        name
        market {
          id
          name
        }

        documentGroup {
          id
          name
        }

        customAttributes
      }
    }
  }
`

export const QueryVehicleRequiredDocuments = gql`
  query {
    requiredVehicleDocuments {
      documentType
      name
      allowedMimeTypes
      canReplaceOnExpire
      canExempt
      requireLevel
    }
  }
`

export const QueryVehicleLite = gql`
  query {
    vehicles(page: 1, limit: -1) {
      total
      results {
        id
        documentGroupId
        vin
        plate
      }
    }
  }
`

export const QueryAuthorizedVehicles = gql`
  query AuthorizedVehicles($providerIds: [String!]) {
    vehicles(page: 1, limit: -1, authorized: true, providerIds: $providerIds) {
      total
      results {
        id
        vin
      }
    }
  }
`

export const QueryVehicleEvents = gql`
  query VehicleEvents($id: String!) {
    vehicleEvents(id: $id) {
      id
      type
      createdAt

      details {
        # vehicle {
        #   id
        #   firstName
        #   lastName
        #   email
        #   phone
        # }

        document {
          id
          documentType
          status
          issuedAt
          expiresAt
          isLinked
          createdAt
        }
      }
    }
  }
`
