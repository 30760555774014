import { ColumnDef } from '@tanstack/react-table'
import { Box } from 'components/Box'
import { CheckBox } from 'components/CheckBox'
import { Dot } from 'components/Dot'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { formatDate } from 'internal/util/dates'
import { Vehicle } from 'types'

type Data = Vehicle & { detectedOnPage: number | null }

export const columns: ColumnDef<Data>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <CheckBox
        className="h-4 w-4"
        checked={table.getIsAllPageRowsSelected()}
        onChange={e => table.toggleAllPageRowsSelected(!!e.target.checked)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <CheckBox
        className="h-4 w-4"
        checked={row.getIsSelected()}
        onChange={e => row.toggleSelected(!!e.target.checked)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'vin',
    header: 'VIN',
    cell: ({ row }) => (
      <Box className="flex items-center gap-1">
        <Text size="sm" weight="normal">
          {row.original.vin}
        </Text>
        {row.original.isAuthorized && (
          <Tooltip label={`This vehicle is authorized until ${formatDate(row.original.compliantUntil)}`}>
            <Box className="p-1.5">
              <Dot color="green" />
            </Box>
          </Tooltip>
        )}
      </Box>
    ),
  },
  {
    accessorKey: 'detectedOnPage',
    header: 'Location',
    cell: ({ row }) => row.original.detectedOnPage && `Page ${row.original.detectedOnPage}`,
  },
]
