import classNames from 'classnames'
import { differenceInDays } from 'date-fns'
import { ReactNode } from 'react'
import { IconType } from 'react-icons'
import { HiExclamation } from 'react-icons/hi'

type ExpirationLabelProps = {
  date: number | string | Date | null
  size?: 'sm' | 'md' | 'lg'
  full?: boolean
  className?: string
}

export const ExpirationLabel = (props: ExpirationLabelProps) => {
  const { date, size = 'sm', className } = props
  if (!date) return null

  try {
    const days = differenceInDays(new Date(date), new Date())

    let styleClasses = ''
    let iconClasses = ''
    let content: ReactNode = ''
    let sizeClasses = size === 'sm' ? 'text-xs rounded-md px-2 py-1' : 'text-sm rounded-md px-4 py-2 '
    const Icon: IconType | null = HiExclamation

    if (days > 90) {
      return null
    }

    if (days <= 0) {
      styleClasses =
        'bg-red-50 text-red-600 border-red-400 dark:text-red-300 dark:border-red-400 dark:bg-red-900 dark:bg-opacity-20'
      iconClasses = 'text-red-400 dark:text-red-300'
      content = 'Expired'
    } else {
      styleClasses =
        'bg-yellow-50 text-yellow-600 border-yellow-400 dark:text-yellow-300 dark:border-yellow-400 dark:bg-yellow-900 dark:bg-opacity-20'
      iconClasses = 'text-yellow-400'
      content = (
        <>
          {props.full && 'Expires in '}
          {days} days
        </>
      )
    }

    if (props.size === 'sm') {
      sizeClasses = 'text-xs rounded-md px-2.5 py-1'
    }

    return (
      <div
        className={classNames(['flex items-center justify-center gap-2 border', sizeClasses, styleClasses, className])}
      >
        <Icon className={classNames([iconClasses, '-ml-1 h-5 w-5'])} />
        <span>{content}</span>
      </div>
    )
  } catch {
    return null
  }
}
