import { useQuery } from '@apollo/client'
import { QueryEntities } from 'internal/graphql/query/entities'

type Variables = {
  providerId: string | null
}

export function useEntities(variables: Variables) {
  const { data, loading, refetch } = useQuery(QueryEntities, {
    variables,
    fetchPolicy: 'no-cache',
    skip: !variables.providerId,
  })

  const drivers: number = data?.drivers?.total || 0
  const vehicles: number = data?.vehicles?.total || 0

  return { drivers, vehicles, loading, refetch }
}
