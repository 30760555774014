export function req(url, config) {
  return fetch(`${process.env.NEXT_PUBLIC_GEO_API_BASE_URL}${url}`, config)
}

export function get(url, config = {}) {
  return req(url, config)
}

export function post(url, data?, config = {}) {
  return req(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data && JSON.stringify(data),
    ...config,
  })
}
