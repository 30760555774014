import { Menu, Portal, Transition } from '@headlessui/react'
import { Placement } from '@popperjs/core'
import classNames from 'classnames'
import { Box } from 'components/Box'
import { Icon } from 'components/Icon'
import { Fragment, MouseEventHandler, ReactNode, useRef, useState } from 'react'
import { IconType } from 'react-icons'
import { HiCheck, HiDotsHorizontal } from 'react-icons/hi'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

type DropdownProps = {
  items: {
    onClick?: MouseEventHandler<HTMLButtonElement>
    label: string
    disabled?: boolean
    selected?: boolean
    variant?: 'danger'
    icon?: IconType
  }[]
  size?: 'sm' | 'md' | 'lg'
  children?: ReactNode
  className?: string
  placement?: Placement
  offset?: [number, number]
}

export const Dropdown = ({
  size = 'md',
  children,
  className,
  items,
  placement = 'bottom-end',
  offset = [8, 0],
}: DropdownProps) => {
  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',

        options: {
          offset,
        },
      },
    ],
  })

  const options = items?.filter(Boolean)

  if (!options?.length) {
    return null
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <Fragment>
          <div ref={setTargetElement}>
            {children ? (
              <Menu.Button as="div" className={className}>
                {children}
              </Menu.Button>
            ) : (
              <Menu.Button
                className={twMerge(
                  'dark:focus:ring-offset-dark-500 flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-gray-100 dark:text-gray-200 hover:dark:text-gray-400',
                  size === 'sm' ? 'p-1' : 'p-2',
                  className,
                )}
              >
                <span className="sr-only">Open options</span>
                <HiDotsHorizontal className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            )}
          </div>
          <Portal>
            <div ref={popperElRef} style={styles.popper} {...attributes.popper} className="z-50">
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  static
                  className={classNames(
                    'absolute mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                    'dark:border-dark-500 dark:bg-dark-700 dark:border',
                    placement === 'bottom-end' && 'right-0',
                    placement === 'bottom-start' && 'left-0',
                  )}
                >
                  <div className="flex flex-col py-1">
                    {options.map((item, i) => (
                      <Menu.Item key={i}>
                        {({ active }) => (
                          <button
                            className={twMerge(
                              active
                                ? 'dark:bg-dark-400 bg-gray-100 text-gray-900 dark:text-white'
                                : 'text-gray-700 dark:text-gray-200',
                              'inline-flex flex-1 items-center gap-4 whitespace-nowrap py-2 pl-4 pr-6 text-left text-sm',
                              item.disabled && 'cursor-not-allowed opacity-50',
                              item.selected && 'font-semibold',
                              item.variant === 'danger' && 'text-red-500 dark:text-red-400',
                            )}
                            onClick={item.onClick}
                            disabled={item.disabled}
                          >
                            {item.icon && (
                              <Box className="text-gray-500 dark:text-gray-500">
                                <Icon size="sm" icon={item.icon} color="inherit" />
                              </Box>
                            )}
                            {item.label}
                            {item.selected && <Icon icon={HiCheck} size="sm" className="ml-2" color="info" />}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </Fragment>
      )}
    </Menu>
  )
}
