import classNames from 'classnames'
import { IconType } from 'react-icons'

export type ColorType =
  | 'danger'
  | 'warning'
  | 'info'
  | 'success'
  | 'default'
  | 'purple'
  | 'dark'
  | 'light'
  | 'black'
  | 'inherit'

interface IconProps {
  className?: string
  icon: IconType
  color?: ColorType
  size?: string
  id?: string
}

const sizes: { [key: string]: string } = {
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  base: 'h-5 w-5',
  lg: 'h-6 w-6',
  xl: 'h-8 w-8',
  '2xl': 'h-10 w-10',
}

export const colors: Record<ColorType, string> = {
  danger: 'text-red-500 dark:text-red-400',
  warning: 'text-yellow-500 dark:text-yellow-400',
  info: 'text-blue-600 dark:text-blue-400',
  success: 'text-green-500 dark:text-green-400',
  default: 'text-gray-500 dark:text-gray-300',
  purple: 'text-indigo-500 dark:text-indigo-400',
  dark: 'text-gray-600 dark:text-gray-300',
  black: 'text-black dark:text-white',
  light: 'text-gray-300 dark:text-gray-500',
  inherit: 'text-current',
}

export function Icon({ icon: ReactIcon, className, color = 'default', size = 'md' }: IconProps) {
  return <ReactIcon className={classNames('flex-shrink-0', colors[color], sizes[size], className)} />
}
