import { RequireRole } from 'components/RequireRole/RequireRole'
import { BiChart } from 'react-icons/bi'
import { HiOutlineViewGrid } from 'react-icons/hi'
import { HiOutlineClipboard, HiOutlineCog6Tooth, HiOutlineFolder, HiOutlineUsers } from 'react-icons/hi2'
import { RiTaxiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavLink } from './NavLink'

const urls = [
  {
    href: '/inspector/dashboard',
    label: 'Dashboard',
    icon: HiOutlineViewGrid,
    allow: ['inspection-manager'],
  },
  {
    href: '/inspector/overview',
    label: 'Inspections Overview',
    icon: BiChart,
    allow: ['inspections-overview'],
  },
  {
    href: '/inspector/inspections',
    label: 'Inspections',
    icon: HiOutlineClipboard,
  },
  {
    href: '/inspector/in-person',
    label: 'In-Person Overview',
    icon: RiTaxiLine,
    level: 2,
  },
  {
    href: '/inspector/inspectors',
    label: 'Inspectors',
    icon: HiOutlineUsers,
    allow: ['inspection-manager'],
  },
  {
    href: '/inspector/templates',
    label: 'Templates',
    icon: HiOutlineFolder,
    allow: ['inspection-manager'],
  },
  {
    href: '/inspector/settings',
    label: 'Settings',
    icon: HiOutlineCog6Tooth,
  },
]

export function InspectorNav() {
  return (
    <Nav>
      {urls.map(({ allow, href, label, level, icon: Icon }) => (
        <RequireRole allow={allow} key={href}>
          <NavLink href={href} icon={Icon} level={level}>
            {label}
          </NavLink>
        </RequireRole>
      ))}
    </Nav>
  )
}
