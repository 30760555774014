import { DataTablePagination } from '@/components/DataTablePagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table'
import { Box } from 'components/Box'
import { IconButton } from 'components/Buttons/IconButton'
import { SearchInput } from 'components/SearchInput'
import { Text } from 'components/Text'
import { useLazyVehicles } from 'hooks/useLazyVehicles'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { TextractBlock } from 'types'
import { useDebouncedCallback } from 'use-debounce'

import { columns } from './columns'
import { LoadingState } from './LoadingState'

type Props = {
  selectedVehicles: string[]
  setSelectedVehicles: Dispatch<SetStateAction<string[]>>
  onCancel: () => void
  isResolved: boolean
  providerId: string
  documentVins: TextractBlock[]
  isReadOnly?: boolean
}

export function VehiclesList({
  onCancel,
  setSelectedVehicles,
  selectedVehicles,
  providerId,
  isResolved,
  documentVins: vins,
  isReadOnly = false,
}: Props) {
  const [query, setQuery] = useState<string>('')
  const handleSearch = useDebouncedCallback(setQuery, 250)
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { load, total, vehicles, loading } = useLazyVehicles({
    active: true,
    providerIds: [providerId],
    page: pageIndex + 1,
    limit: pageSize,
    query,
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const rowSelection = selectedVehicles.length
    ? selectedVehicles.reduce((acc, vin) => ({ ...acc, [vin]: true }), {})
    : {}

  const handleRowSelectionChange = (getSelected: any) => {
    setSelectedVehicles(Object.keys(getSelected(rowSelection)))
  }

  const tableData = useMemo(() => {
    return vehicles.map(v => ({
      ...v,
      detectedOnPage: vins.find(vin => vin.Text === v.vin)?.Page || null,
    }))
  }, [vins, vehicles])

  const table = useReactTable({
    data: tableData,
    columns,
    pageCount: Math.ceil(total / pageSize),
    state: {
      rowSelection,
      pagination,
    },
    enableRowSelection: true,
    manualPagination: true,
    onRowSelectionChange: updater => {
      if (!isReadOnly) {
        handleRowSelectionChange(updater)
      }
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => (typeof row === 'string' ? row : row.vin),
  })

  useEffect(() => {
    load()
  }, [load])

  const description = isReadOnly
    ? 'Vehicles this document will be applied to.'
    : 'Select all vehicles this document will be applied to.'

  return (
    <Box className="flex h-full flex-col">
      <Box className="flex flex-col gap-2 border-b pb-2">
        <Box className="px-4">
          <Text size="sm" weight="normal">
            {description}
          </Text>
        </Box>
        <Box className="flex gap-2 px-4">
          {!isResolved && <IconButton ariaLabel="Back to details" onClick={onCancel} icon={HiOutlineArrowLeft} />}
          <SearchInput onChange={e => handleSearch(e.target.value)} placeholder="Search vehicles..." />
        </Box>
      </Box>
      <Box className="flex-1 overflow-auto bg-gray-50">
        <Table className="bg-white">
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead className="h-10" key={header.id}>
                      <Box className="text-xs text-gray-500">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Box>
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          {loading ? (
            <LoadingState />
          ) : (
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map(row => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    onClick={() => row.toggleSelected()}
                    className="cursor-pointer"
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell className="py-2" key={cell.id}>
                        <Box className="text-sm">{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </Box>
      <DataTablePagination table={table} />
    </Box>
  )
}
