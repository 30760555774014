import type { Meta, StoryObj } from '@storybook/react'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import { useState } from 'react'
import { CheckBox } from './CheckBox'

const meta: Meta<typeof CheckBox> = {
  title: 'Actions/CheckBox',
  component: CheckBox,
}

export default meta
type Story = StoryObj<typeof CheckBox>

const CheckboxWithState = () => {
  const [checked, setChecked] = useState(false)

  const handleChange = () => setChecked(!checked)

  return (
    <Box className="flex items-center gap-2">
      <Text>Click me</Text>
      <CheckBox checked={checked} onChange={handleChange} />
    </Box>
  )
}

export const Default: Story = {
  render: () => <CheckboxWithState />,
}
