import classNames from 'classnames'
import { Text } from 'components/Text'
import { useAuth } from 'hooks/useAuth'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { useTranslation } from 'react-i18next'
import { AccountMenu } from './AccountMenu'

export function AccountSection() {
  const { account } = useAuth()
  const { isCollapsed } = useSidebarContext()
  const { t } = useTranslation()

  if (!account) return null

  return (
    <div className={classNames('flex items-center gap-4 px-4 py-6', isCollapsed && 'w-full justify-center ')}>
      <AccountMenu />
      {!isCollapsed && (
        <div className="flex flex-col overflow-hidden">
          <Text size="sm" variant="light" weight="normal">
            {t('loggedInAs')}
          </Text>
          <Text size="sm" variant="white" className="overflow-hidden truncate">
            {account?.email}
          </Text>
        </div>
      )}
    </div>
  )
}
