import { useAuth } from 'hooks/useAuth'
import { Fragment, ReactNode } from 'react'

export type RequireSuperAdminProps = {
  children: ReactNode | ReactNode[]
}

export const RequireSuperAdmin = (props: RequireSuperAdminProps) => {
  const { account } = useAuth()

  if (account?.permissions?.documentTypes?.length > 0) {
    return null
  }

  return <Fragment>{props.children}</Fragment>
}
