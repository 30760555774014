import { FontSize, FontWeight, TextVariant } from './types'

export const fontWeight: { [key in FontWeight]: string } = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  inherit: 'font-inherit',
}

export const fontSize: { [key in FontSize]: string } = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-xl sm:text-2xl',
  '3xl': 'text-2xl sm:text-3xl',
  '4xl': 'text-3xl sm:text-4xl',
}

export const textVariant: { [key in TextVariant]: string } = {
  extralight: 'text-gray-400 dark:text-gray-600',
  light: 'text-gray-500 dark:text-gray-400 text-muted-foreground',
  medium: 'text-gray-600 dark:text-gray-400',
  base: 'text-gray-700 dark:text-gray-300',
  dark: 'text-gray-800 dark:text-gray-200 text-foreground',
  extradark: 'text-gray-900 dark:text-gray-100 text-foreground',
  black: 'text-black',
  white: 'text-white',
  inherit: 'text-inherit',
  danger: 'text-red-500 dark:text-red-400',
  info: 'text-blue-500 dark:text-blue-400',
}
