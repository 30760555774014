import { CheckBox } from 'components/CheckBox'
import { DescriptionDetails } from 'components/DescriptionDetails'
import { DescriptionTerm } from 'components/DescriptionTerm'
import { SECTIONS } from 'constants/document'
import { useDocumentFields } from 'hooks/useDocumentFields'
import { formatDate } from 'internal/util/dates'
import { DocumentExtraction } from 'types/Document'

export type DocumentModalFieldsProps = {
  documentType: string
  extractedData: DocumentExtraction
}

export const DocumentModalFields = ({ documentType, extractedData }: DocumentModalFieldsProps) => {
  const { fields: requiredFields } = useDocumentFields(documentType)

  if (!documentType || !extractedData) {
    return null
  }

  return (
    <>
      {SECTIONS.map(section => {
        const fields = section.fields
          .map(field => {
            if (!requiredFields || !requiredFields.includes(field.name)) {
              return null
            }

            return field
          })
          .filter(Boolean)

        if (!fields.length) {
          return null
        }

        return fields.map(field => {
          let value = extractedData[field.name]
          if (field.type === 'date') {
            value = formatDate(value)
          } else if (field.type === 'boolean') {
            value = <CheckBox readOnly checked={!!value} />
          }

          return (
            <div
              className="overflow-hidden sm:col-span-1"
              key={field.name}
              data-testid={`DocumentModal-field-${field.name}`}
            >
              <DescriptionTerm test-id="DocumentModal-field-value">{field.title}</DescriptionTerm>
              <DescriptionDetails className="mt-2">{value}</DescriptionDetails>
            </div>
          )
        })
      })}
    </>
  )
}
