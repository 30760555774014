import { createContext, ReactNode } from 'react'
import { useAsync } from 'react-use'

type RemoteConfigProviderProps = {
  children: ReactNode | ReactNode[]
}

export const RemoteConfigContext = createContext({})

export function RemoteConfigProvider({ children }: RemoteConfigProviderProps) {
  const { value = {} } = useAsync(() => {
    return fetch('/api/config/latest')
      .then(r => r.json())
      .catch(() => ({}))
  })

  return <RemoteConfigContext.Provider value={value}>{children}</RemoteConfigContext.Provider>
}
