import classNames from 'classnames'
import { ReactNode } from 'react'

type PillTabsProps = {
  activeIndex: number
  onChange: (idx: number) => void
  children?: ReactNode | ReactNode[]
}

export const PillTabs = ({ activeIndex, children, onChange }: PillTabsProps) => {
  const tabs = [].concat(children)
  return (
    <div className="flex items-center gap-2 rounded-md bg-gray-50 px-4 py-2">
      {tabs.map((tab, i) => (
        <div
          key={tab.key}
          className={classNames([
            'cursor-pointer rounded px-3 py-1 text-sm font-medium',
            activeIndex === i ? 'bg-gray-600 text-white shadow-md' : ' text-gray-600 hover:bg-gray-100',
          ])}
          onClick={onChange.bind(null, i)}
        >
          {tab}
        </div>
      ))}
    </div>
  )
}
