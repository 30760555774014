import { cn } from '@/lib/utils'
import { fontSize, fontWeight, textVariant } from 'components/constants'
import { FontSize, FontWeight, TextVariant } from 'components/constants/types'
import { forwardRef, MouseEvent, ReactNode } from 'react'

export interface TextProps {
  className?: string
  size?: FontSize
  variant?: TextVariant
  weight?: FontWeight
  as?: 'p' | 'span'
  children: string | string[] | ReactNode | ReactNode[]
  style?: React.CSSProperties
  onClick?: (e: MouseEvent<HTMLParagraphElement>) => void
}

export const Text = forwardRef<any, TextProps>(
  ({ children, className, size, variant, weight, as: TextElement = 'p', style, onClick }, ref) => {
    const sizeClass = size ? fontSize[size] : fontSize.base
    const weightClass = weight ? fontWeight[weight] : fontWeight.normal
    const variantClasses = variant ? textVariant[variant] : textVariant.base

    return (
      <TextElement
        className={cn(sizeClass, weightClass, variantClasses, className)}
        onClick={onClick}
        ref={ref}
        style={style}
      >
        {children}
      </TextElement>
    )
  },
)

Text.displayName = 'Text'
