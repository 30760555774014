import classNames from 'classnames'
import { Box } from 'components/Box'
import { ReactNode } from 'react'
import { HiOutlineDocument } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'

type ModalHeaderProps = {
  className?: string
  title: string | ReactNode
  description?: string | ReactNode
  padding?: boolean
  icon?: ReactNode | false
  children?: ReactNode
  variant?: 'warning' | 'danger' | 'success' | 'info' | 'muted'
  onClose?: () => void
}

const variants = {
  info: 'bg-blue-100 dark:bg-gray-800 dark:border dark:border-dark-400',
  warning: 'bg-yellow-100 dark:bg-gray-800 dark:border dark:border-dark-400',
  danger: 'bg-red-100 dark:bg-gray-800 dark:border dark:border-dark-400',
  success: 'bg-green-100 dark:bg-gray-800 dark:border dark:border-dark-400',
  muted: 'bg-gray-100 dark:bg-gray-800 dark:border dark:border-dark-400',
}

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <Box
      className={twMerge(
        'dark:bg-dark-700 flex items-center gap-3 rounded-t-lg bg-white',
        'dark:border-dark-500 border-b border-gray-200 dark:border',
        'px-4 py-3',
        // 'flex items-center border-b border-gray-200 dark:border dark:border-dark-500 gap-4 rounded-t-lg bg-white dark:bg-dark-700 ',
        // props.padding !== false && 'px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ',
        props.className,
      )}
    >
      {props.icon !== false && (
        <Box
          className={classNames(
            'mx-auto mr-4 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
            variants[props?.variant || 'info'],
          )}
        >
          {props.icon || <HiOutlineDocument className="h-6 w-6 text-blue-600 dark:text-blue-400" />}
        </Box>
      )}
      <Box className="my-3 flex flex-1 flex-row justify-between text-center sm:my-0 sm:mt-0 sm:text-left">
        <Box className="flex justify-between">
          <Box className="flex flex-col justify-center">
            <h3 className="text-md font-medium leading-6 text-gray-900 dark:text-white" id="modal-headline">
              {props.title}
            </h3>
            {props.description && <p className="text-sm text-gray-500">{props.description}</p>}
          </Box>
        </Box>
        {props.children}
      </Box>
    </Box>
  )
}
