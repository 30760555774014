import { Loader } from 'components/Loader'
import { Sidebar } from 'components/sidebar/Sidebar'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth } from 'hooks/useAuth'
import { useSidebarContext } from 'hooks/useSidebarContext'

import Head from 'next/head'
import { createContext, ReactNode, useContext, useEffect } from 'react'

type MainLayoutProps = {
  loading?: boolean
  title?: string
  sidebarCollapsed?: boolean
  children?: ReactNode | ReactNode[]
}

const LayoutContext = createContext({
  sidebarCollapsed: false,
})

export const useLayoutContext = () => useContext(LayoutContext)

export const MainLayout = (props: MainLayoutProps) => {
  const { account } = useAuth()
  const { title, loading, children, sidebarCollapsed } = props
  const { isCollapsed, toggleCollapsed } = useSidebarContext()

  useEffect(() => {
    if (sidebarCollapsed !== undefined) {
      toggleCollapsed(sidebarCollapsed, false)
    } else if (toggleCollapsed) {
      const currentCollapsed = isCollapsed ? '1' : '0'
      const storedCollapse = localStorage?.getItem('sidebarCollapsed')

      if (storedCollapse !== currentCollapsed) {
        toggleCollapsed(storedCollapse === '1')
      }
    }
  }, [isCollapsed, sidebarCollapsed, toggleCollapsed])

  return (
    <LayoutContext.Provider value={{ sidebarCollapsed }}>
      <Head>{title ? <title>{title} • Complicore</title> : <title>Complicore</title>}</Head>
      <div
        data-testid="main-layout"
        className="dark:bg-dark-900 relative flex h-screen flex-row overflow-hidden bg-white pt-16 lg:pt-0"
      >
        {account && <Sidebar />}
        <div className="relative h-full w-full overflow-hidden">
          <AnimatePresence>
            {loading ? (
              <motion.div
                key="loading"
                className="h-full items-center justify-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Loader />
              </motion.div>
            ) : (
              <motion.div key="content" initial={{ opacity: 1 }} className="h-full flex-1 overflow-hidden">
                {children}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </LayoutContext.Provider>
  )
}
