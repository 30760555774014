import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type FieldContainerProps = {
  id?: string
  title: string
  required?: boolean
  children?: ReactNode | ReactNode[]
  width?: string
}

export const FieldContainer = (props: FieldContainerProps) => {
  return (
    <div className="flex flex-1 divide-x divide-gray-200 dark:divide-gray-500">
      <label
        htmlFor={props.id}
        className={twMerge(
          'flex min-h-[2.5rem] w-1/3 items-center overflow-hidden p-2 text-xs font-medium text-gray-700 dark:text-gray-300',
          props.width,
        )}
      >
        {props.title}
        {props.required && <span className="ml-1 text-red-500">*</span>}
      </label>
      {props.children}
    </div>
  )
}
