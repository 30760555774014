import cx from 'classnames'
import { Badge } from 'components/Badge'
import { Text } from 'components/Text'
import { STATUS_OPTIONS, StatusOption, getStatusExempt } from 'constants/document'
import { HiBadgeCheck } from 'react-icons/hi'
import { DocumentExemption } from 'types/Document'

const cache = {}
export const getOptions = status => {
  if (cache[status]) return cache[status]

  cache[status] = STATUS_OPTIONS.find(s => s.value === status)

  return cache[status]
}

type DocumentStatusBadgeProps = {
  className?: string
  exemption: DocumentExemption | null
  isPrimary?: boolean
  showBadge?: boolean
  status: string
  onClick?: (s: string) => void
}

export const DocumentStatusBadge = (props: DocumentStatusBadgeProps) => {
  const { className, exemption, isPrimary, showBadge, status, onClick } = props
  let options: StatusOption = getOptions(status)

  if (!options && exemption) {
    options = getStatusExempt(exemption)
  } else if (!options && status === 'done') {
    return (
      <div className="inline-flex items-center whitespace-nowrap">
        <Badge color="white" size="xs" dot>
          <Text size="xs">Uploaded</Text>
        </Badge>
      </div>
    )
  }

  if (!options) {
    return (
      <div className="inline-flex items-center whitespace-nowrap" onClick={() => onClick?.(status)}>
        <Badge color="red" size="xs">
          Missing
        </Badge>
      </div>
    )
  }

  return (
    <div className="inline-flex items-center whitespace-nowrap" onClick={() => onClick?.(status)}>
      <span
        className={cx(
          'inline-flex items-center rounded-md border px-3 py-1.5 text-xs font-medium',
          options.classNames.button,
          className,
        )}
      >
        <span className={cx('-ml-1 mr-1.5 h-1.5 w-1.5 rounded-full', options.classNames.indicator)} />
        {options.label}
      </span>
      {isPrimary && showBadge && <HiBadgeCheck className="ml-2 h-5 w-6 text-indigo-600 dark:text-indigo-400" />}
    </div>
  )
}
