import flagsmithPrimitive from 'flagsmith'
import {
  useFlags as useFlagsPrimitive,
  useFlagsmith as useFlagsmithPrimitive,
  useFlagsmithLoading as useFlagsmithLoadingPrimitive,
} from 'flagsmith/react'
import { noop } from 'lodash'

const localFlags = {
  /* eslint-disable */
  allow_duplicate_driver_phone: { enabled: true },
  coi: { enabled: true },
  enable_certifications: { enabled: true },
  driver_ssn_confirmation: { enabled: true },
  is_local: { enabled: true },
  new_teams_page_markets: { enabled: false, value: JSON.stringify(['NY']) },
  require_insurance_documents: { enabled: false },
  require_team_info_markets: { enabled: true, value: JSON.stringify(['NY']) },
  teams: { enabled: true },
  vehicle_inspection_remote_markets: { enabled: true, value: JSON.stringify(['NY']) },
  vehicle_inspection_types: { enabled: true, value: JSON.stringify(['VehicleInspection']) },
  vehicle_inspection_provisional: { enabled: true },
  vehicle_inspection_spot: { enabled: true },
  workspaces: { enabled: false },
  /* eslint-enable */
}
const fakeFlagsmith = { identify: noop, init: noop, logout: noop }

export const useFlagsmith = () => {
  const hook = getFlagsmith()
  const flagsmith = hook()

  return flagsmith
}

function getFlagsmith() {
  if (process.env.NEXT_PUBLIC_IS_LOCAL === '1') {
    return () => fakeFlagsmith
  }

  return useFlagsmithPrimitive
}

export const useFlags = (flags: string[]) => {
  const func = getUseFlags()
  const value = func(flags)

  return value
}

export const useFlagArrayValue = (flag: string, value: string) => {
  const flags = useFlags([flag])

  if (!flags[flag]?.enabled) {
    return false
  }

  try {
    const parsedValue = JSON.parse(flags[flag].value)

    return parsedValue.includes(value)
  } catch (e) {
    console.log(e)
  }

  return false
}

function getUseFlags() {
  if (process.env.NEXT_PUBLIC_IS_LOCAL === '1') {
    return () => localFlags
  }

  return useFlagsPrimitive
}

export const useFlagsmithLoading = () => {
  const func = getUseLoading()
  const value = func()

  return value
}

function getUseLoading() {
  if (process.env.NEXT_PUBLIC_IS_LOCAL === '1') {
    return () => ({ isLoading: false })
  }

  return useFlagsmithLoadingPrimitive
}

export const flagsmith = process.env.NEXT_PUBLIC_IS_LOCAL === '1' ? fakeFlagsmith : flagsmithPrimitive
