import { Banner } from 'components/Banner'
import { Box } from 'components/Box'
import { useRequirementsProviderContext } from 'contexts/providers/RequirementsProvider'
import { HiExclamationCircle } from 'react-icons/hi'

export function MissingRequirementsBanner() {
  const { hasMissingRequirements, missingRequirements } = useRequirementsProviderContext()

  if (!hasMissingRequirements) return null

  return (
    <Box className="px-4 sm:px-0">
      <Banner
        icon={HiExclamationCircle}
        variant={missingRequirements.insuranceDocuments ? 'error' : 'warning'}
        truncate={false}
        title="Missing required information"
        msg="Please provide the missing required information before continuing."
      />
    </Box>
  )
}
