import { useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { QueryProviders } from 'internal/graphql/query/provider'
import { useEffect } from 'react'
import { TransportationProvider } from 'types/Provider'
import { QueryResponse } from 'types/Query'

type ProviderOptions = {
  page?: number
  ids?: string[]
  limit?: number
  sort?: string
  query?: string
  marketId?: string
  suspended?: boolean
}

export const useProviders = (
  query = QueryProviders,
  variables: ProviderOptions = { page: 1, limit: 50, sort: 'createdAt', query: '' },
  fetchPolicy: WatchQueryFetchPolicy = 'no-cache',
) => {
  const [load, { data, loading, refetch }] = useLazyQuery<QueryResponse<'providers', TransportationProvider>>(query, {
    variables,
    fetchPolicy,
  })
  const { page = 1, limit, sort, query: searchQuery } = variables

  const total = data?.providers?.total || 0
  const providers = data?.providers?.results || []

  useEffect(() => {
    load({
      variables: {
        page,
        limit,
        sort,
        query: searchQuery,
      },
    })
  }, [page, limit, sort, searchQuery, load])

  return { providers, total, loading, refetch }
}
