import { useCallback, useEffect, useRef } from 'react'

type ViewerProps = {
  url: string
  rotation: number
  width: number
  maxHeight: number
  pageNumber?: number
  pdf?: any
}

export function PDFViewer({ rotation, pageNumber, pdf, width }: ViewerProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const locked = useRef(false)
  const loadPage = useCallback(
    async (pageNumber: number) => {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')

      const page = await pdf.getPage(pageNumber)
      const scale = width / page.getViewport({ scale: 1 }).width
      const viewport = page.getViewport({ scale, rotation: -rotation })

      canvas.width = viewport.width
      canvas.height = viewport.height

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise
    },
    [pdf, rotation, width],
  )

  useEffect(() => {
    if (!pdf) return
    if (locked.current) {
      return
    }

    locked.current = true

    loadPage(pageNumber).then(() => {
      locked.current = false
    })
  }, [pdf, pageNumber, loadPage])

  return <canvas className="w-full" ref={canvasRef} />
}
