import * as PDFJS from 'pdfjs-dist'
import workerSrc from 'workers/pdf-worker'

PDFJS.GlobalWorkerOptions.workerSrc = workerSrc

type GetDataUrlOptions = {
  imageData?: boolean
}

export const getDataUrl = async (file: File, options?: GetDataUrlOptions) =>
  new Promise<{ dataUrl: string; data?: { imageData: ImageData } }>((resolve, reject) => {
    const isImage = file.type.startsWith('image')

    const reader = new FileReader()

    reader.onload = async e => {
      const dataUrl = e.target.result.toString()
      if (!isImage) {
        try {
          const MARKER = ';base64,'
          const data = dataUrl.substring(dataUrl.indexOf(MARKER) + MARKER.length)
          const doc = await PDFJS.getDocument({ data: atob(data) }).promise
          const page = await doc.getPage(1)
          const canvas = document.createElement('canvas')
          let scale = 1
          let viewport = page.getViewport({ scale: 1 })
          if (viewport.width < 1500 || viewport.height < 1500) {
            const smallest = viewport.width > viewport.height ? 'height' : 'width'
            scale /= viewport[smallest] / 1500
            viewport = page.getViewport({ scale })
          }

          canvas.width = viewport.width
          canvas.height = viewport.height

          const ctx = canvas.getContext('2d')

          await page.render({ canvasContext: ctx, viewport }).promise

          const pageDataUrl = canvas.toDataURL('image/jpeg')
          const img = document.createElement('img')
          img.src = pageDataUrl

          if (options?.imageData) {
            resolve({
              dataUrl: pageDataUrl,
              data: { imageData: ctx.getImageData(0, 0, canvas.width, canvas.height) },
            })
            return
          }

          resolve({ dataUrl: pageDataUrl })
          return
        } catch (e) {
          reject(e)
        }
      } else {
        const img = document.createElement('img')

        img.src = dataUrl
        img.onload = () => {
          const MIN_SIDE = 0
          let width = img.naturalWidth
          let height = img.naturalHeight

          if (width < MIN_SIDE || height < MIN_SIDE) {
            if (width < height) {
              height /= width / MIN_SIDE
              width = MIN_SIDE
            } else {
              width /= height / MIN_SIDE
              height = MIN_SIDE
            }
          }

          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          const canvasDataUrl = canvas.toDataURL('image/jpeg')

          if (options?.imageData) {
            resolve({
              dataUrl: canvasDataUrl,
              data: { imageData: ctx.getImageData(0, 0, canvas.width, canvas.height) },
            })
            return
          }

          resolve({ dataUrl: canvasDataUrl })
        }
      }
    }

    reader.readAsDataURL(file)
  })
