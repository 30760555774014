import classNames from 'classnames'
import { formatDate } from 'internal/util/dates'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'
import { CheckResult } from 'types/Check'

type ChecksListProps = {
  checks: CheckResult[]
  showLine?: boolean
}

export const ChecksList = ({ checks, showLine = true }: ChecksListProps) => {
  return (
    <ul role="list" className="-mb-8">
      {checks?.map((item, itemIdx) => (
        <li key={item.id}>
          <div className="relative pb-8">
            {showLine && itemIdx !== checks.length - 1 && (
              <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            )}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    item.result === 'pass' ? 'bg-green-50' : 'bg-red-50',
                    'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                  )}
                >
                  {item.result === 'pass' ? (
                    <HiCheckCircle className="h-5 w-5 text-green-500" aria-hidden="true" />
                  ) : (
                    <HiXCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
                  )}
                </span>
              </div>
              <div className="flex min-w-0 flex-1 items-center justify-between space-x-4 truncate">
                <div>
                  <a href="#" className="font-medium text-gray-900">
                    {item.name}
                  </a>
                  {/* <p className="text-sm text-gray-500">{item.details}</p> */}
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  <time dateTime={formatDate(item.createdAt)}>{formatDate(item.createdAt, 'MMM dd')}</time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
