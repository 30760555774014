import { Text } from 'components/Text'

type FullnameProps = {
  fullName: string
}

type ProviderNameProps = {
  providerName: string
}

export const Introduction = ({ fullName, providerName }: FullnameProps & ProviderNameProps) => (
  <Text as="span" size="xs" weight="normal">
    I,{' '}
    <Text as="span" size="xs" weight="normal" className="underline">
      {fullName}
    </Text>
    , as a representative of{' '}
    <Text as="span" size="xs" weight="normal" className="underline">
      {providerName}
    </Text>
    , hereby acknowledge and agree to authorize Complicore and Modivcare Solutions, LLC (&quot;Modivcare&quot;), to
    contact our insurance carrier (&quot;Insurance Carrier&quot;) for the sole purpose of verifying the validity of our
    insurance policy (&quot;Policy&quot;). By submitting our insurance document, I affirm that I have read and
    understood the terms and conditions outlined below, and I voluntarily agree to its contents on behalf of{' '}
    <Text as="span" size="xs" weight="normal" className="underline">
      {providerName}
    </Text>
    .
  </Text>
)

export const Authorization = ({ providerName }: ProviderNameProps) => (
  <Text size="xs" weight="normal">
    Authorization: I grant Complicore and Modivcare the authority to contact our Insurance Carrier, on behalf of{' '}
    <Text as="span" size="xs" weight="normal" className="underline">
      {providerName}
    </Text>
    , and request verification of the Policy. This verification may include, but is not limited to, confirming the
    existence, validity, coverage limits, effective dates, and any other relevant details pertaining to our insurance
    coverage.
  </Text>
)

export const InsuranceAccuracy = () => (
  <Text size="xs" weight="normal">
    Insurance Accuracy Confirmation: I hereby certify that the insurance documents uploaded are true, accurate,
    complete, and valid to the best of my knowledge and belief. I understand that any misrepresentation or falsification
    of insurance information may result in the termination of any agreements or contracts between Modivcare and any
    other relevant parties.
  </Text>
)

export const LiabilityRelease = () => (
  <Text size="xs" weight="normal">
    Release of Liability: I understand that the information obtained from the Insurance Carrier may be used to evaluate
    eligibility requirements with Modivcare and ensure compliance with contractual obligations. This assessment may
    extend to the potential termination of contracts or agreements involving Modivcare and other relevant parties.
    Furthermore, I acknowledge and agree that: Complicore and Modivcare, including their officers, directors, employees,
    agents, and representatives, shall not be held liable for any errors, inaccuracies, or omissions in the information
    obtained from the Insurance Carrier, regardless of whether they were aware of such errors, inaccuracies, or
    omissions. I irrevocably release and discharge the Insurance Carrier from any and all claims, demands, damages,
    liabilities, or expenses, whether known or unknown, arising out of or in connection with the verification process or
    any subsequent actions taken by Complicore, Modivcare, or the Insurance Carrier, including any errors, inaccuracies,
    or omissions in the information provided by the Insurance Carrier. This release of liability extends to all claims,
    including those arising from negligence, gross negligence, or any other legal theory, to the fullest extent
    permitted by law.
  </Text>
)

export const Confidentiality = () => (
  <Text size="xs" weight="normal">
    Confidentiality: I understand that any information obtained from Insurance Carrier in the course of the verification
    process is strictly confidential and shall only be used for the purpose specified herein. Complicore and Modivcare
    agree to handle such information in accordance with applicable privacy laws and regulations.
  </Text>
)

export const Indemnification = () => (
  <Text size="xs" weight="normal">
    Indemnification: I agree to indemnify and hold harmless Complicore, Modivcare, Insurance Carrier, their officers,
    directors, employees, agents, and representatives from any claims, damages, liabilities, or expenses, including
    reasonable attorney fees, arising out of or in connection with the verification process or any actions taken by
    Complicore, Modivcare, or Insurance Carrier based on the information received.
  </Text>
)
