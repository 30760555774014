/* eslint-disable camelcase */
import { motion } from 'framer-motion'
import { boot, shutdown } from 'helpers/intercom'
import { useAnalytics } from 'hooks/useAnalytics'
import { useAuth } from 'hooks/useAuth'
import { flagsmith, useFlags } from 'hooks/useFlagsmith'
import { useRoles } from 'hooks/useRoles'
import { selectGlobalError, selectGlobalLoading, setAccount, setGlobalError } from 'internal/redux'
import { useAppDispatch } from 'internal/redux/hooks'
import { loadBrokers, loadProviderEntity, loadWorkspaces, setActiveWorkspace } from 'internal/redux/slices/workspace'
import { MainLayout } from 'layouts/MainLayout'
import { getProfile } from 'lib/api'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from '../Loader'

export const allowlistedPaths = [
  '/_error',
  '/login',
  '/logout',
  '/setup/a/[inviteToken]',
  '/setup/d/[driverInviteToken]',
  '/setup/p/[providerId]',
  '/i/[inspectionId]',
  '/trip-requests/[requestId]',
  '/u/[tokenId]',
  '/u/upload/[tokenId]',
  '/404',
]

type WrapperProps = {
  children: React.ReactNode
  bootSupport?: boolean
}

const ErrorWrapper = dynamic<{}>(() => import('../ErrorWrapper/ErrorWrapper').then(r => r.ErrorWrapper))

export function Wrapper({ bootSupport = true, children }: WrapperProps) {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const analytics = useAnalytics()
  const { account, isLoaded } = useAuth()
  const roles = useRoles()
  const { provider } = account || {}
  const globalLoading = useSelector(selectGlobalLoading)
  const globalError = useSelector(selectGlobalError)
  const flags = useFlags(['workspaces'])

  useEffect(() => {
    if (isLoaded) return

    getProfile()
      .then(r => {
        if (r.status === 502) {
          throw new Error()
        } else if (!r.ok) {
          return null
        }
        return r.json()
      })
      .then(async acc => {
        if (acc) {
          analytics?.register({
            accountId: acc.id,
            roles: acc.roles.join(', '),
            market: acc.provider?.market.name || '',
          })

          await flagsmith.init({
            environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_KEY,
            api: 'https://flags.complicore.co/api/v1/',
            identity: acc.id,
            traits: {
              providerId: acc.providerId,
              market: acc.provider?.market?.name || '',
              roles: acc.roles.toSorted().join(' '),
            },
          })
        }

        dispatch(setAccount(acc))
        if (acc?.roles?.includes('broker') && acc.workspace) {
          dispatch(setActiveWorkspace(acc.workspace))
        }
      })
      .catch(e => {
        console.error(e)
        dispatch(setAccount(null))
        dispatch(setGlobalError())
      })
  }, [isLoaded, dispatch, analytics])

  useEffect(() => {
    if (!account || !bootSupport) return

    const company = provider || {
      id: roles.isSuperAdmin ? 'complicore' : 'modivcare',
      name: roles.isSuperAdmin ? 'Complicore' : 'Modivcare',
    }

    boot({
      user_id: account.id,
      email: account.email || '',
      name: `${account.firstName} ${account.lastName}`,
      customAttributes: {
        role: account.roles.join(' '),
      },
      company: {
        company_id: company.id,
        name: company.name,
        industry: provider?.market.name,
        customAttributes: {
          role: account.roles.join(' '),
          medicaidProviderId: provider?.medicaidProviderId,
          npi: provider?.npi,
          market: provider?.market?.name,
          attributes: provider?.attributes?.join(' '),
          groupType: provider?.documentGroup?.name,
        },
      },
    })

    return () => {
      shutdown()
    }
  }, [account, bootSupport, provider, roles, router])

  useEffect(() => {
    if (!account?.id || !flags['workspaces']?.enabled) return

    dispatch(loadWorkspaces())
    dispatch(loadBrokers())

    if (account.roles.includes('provider') && account.workspaceId) {
      dispatch(loadProviderEntity(account.workspaceId))
    }
  }, [dispatch, account, flags])

  if (!isLoaded) {
    return (
      <motion.div
        className="fixed inset-0 flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Loader />
      </motion.div>
    )
  } else if (globalError) {
    return <ErrorWrapper />
  }

  if (globalLoading) return <MainLayout loading />

  return <Fragment>{children}</Fragment>
}
