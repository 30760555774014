import classNames from 'classnames'
import { Box, BoxProps } from 'components/Box'
import { forwardRef } from 'react'

type FlexProps = BoxProps

export const Flex = forwardRef<HTMLDivElement, FlexProps>(function Flex(props, ref) {
  return (
    <Box {...props} ref={ref} className={classNames('flex flex-1', props.className)}>
      {props.children}
    </Box>
  )
})
