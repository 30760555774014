import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, MouseEventHandler, ReactNode } from 'react'
import { HiDotsVertical } from 'react-icons/hi'

type IconMenuProps = {
  id?: string
  icon?: ReactNode
  position?: 'left' | 'right' | 'top' | 'bottom'
  children?: ReactNode | ReactNode[]
  short?: boolean
  rounded?: boolean
}
const positions = {
  top: 'origin-top-right absolute z-20 -left-2 bottom-0 mt-10',
  bottom: 'origin-top-right absolute z-20 -right-2 top-0 mt-10',
}

export const IconMenu = (props: IconMenuProps) => {
  const { position = 'top', short = false, rounded = true } = props
  const positionClassnames = positions[position]
  const handleMenuClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
  }

  return (
    <Menu>
      {({ open }) => (
        <div className="relative">
          <div className="flex justify-end" id="accoutMenuBtnContainer">
            <Menu.Button
              onClick={handleMenuClick}
              className={classNames([
                rounded ? 'rounded-full' : 'rounded-sm',
                'dark:text-dark-500 dark:hover:text-dark-400 inline-flex h-8 w-8 items-center justify-center border-0 text-gray-500 outline-0 hover:text-gray-600 focus:ring-1 focus:ring-blue-500',
              ])}
            >
              {props.icon || <HiDotsVertical className="h-5 w-5 dark:text-gray-400" />}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames([
                positionClassnames,
                short ? 'w-40' : 'w-60',
                'dark:border-dark-500 dark:bg-dark-800 rounded bg-white py-1 text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border',
              ])}
            >
              {(Array.isArray(props.children) ? props.children : [props.children]).filter(Boolean).map((child, i) => (
                <Menu.Item key={i}>{child}</Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  )
}
