import { cn } from '@/lib/utils'
import { forwardRef } from 'react'

export const ContentWrapper = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div
      {...props}
      className={cn('dark:bg-dark-900 flex h-full flex-1 flex-col overflow-hidden bg-gray-50', props.className)}
      ref={ref}
    />
  )
})

ContentWrapper.displayName = 'ContentWrapper'
