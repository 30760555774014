import { useSidebarContext } from 'hooks/useSidebarContext'
import { useTranslation } from 'react-i18next'
import { HiOutlineDocumentReport, HiOutlineOfficeBuilding, HiOutlineSupport, HiOutlineUsers } from 'react-icons/hi'
import { RiTaxiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function DmasNav() {
  const { stats } = useSidebarContext()
  const { t } = useTranslation()

  return (
    <Nav>
      <NavLink href="/network/reports" icon={HiOutlineDocumentReport}>
        Reports
      </NavLink>
      <NavGroup name={t('network')}>
        <NavLink href="/network/providers" icon={HiOutlineOfficeBuilding} stat={stats?.providers}>
          {t('providers')}
        </NavLink>
        <NavLink href="/network/drivers" icon={HiOutlineUsers} stat={stats?.drivers}>
          {t('drivers')}
        </NavLink>
        <NavLink href="/network/vehicles" icon={RiTaxiLine} stat={stats?.vehicles}>
          {t('vehicles')}
        </NavLink>
      </NavGroup>

      <NavGroup name={t('Other')}>
        <NavLink href="https://help.complicore.co" icon={HiOutlineSupport}>
          {t('help')}
        </NavLink>
      </NavGroup>
    </Nav>
  )
}
