import { cn } from '@/lib/utils'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Box } from './Box'
import { ColorType, Icon } from './Icon'

export type TabItem<T = string> = {
  key: T
  label: string
  icon?: any
  iconColor?: ColorType
  iconClassName?: string
  disabled?: boolean
  stat?: string | number
}

export type TabsProps = {
  activeIdx?: number
  children?: ReactNode | ReactNode[]
  className?: string
  itemClassName?: string
  navClassName?: string
  items?: TabItem[]
  onChange?: (i: number) => void
  responsive?: boolean
  tabs?: string[]
}

export function Tabs(props: TabsProps) {
  const {
    className,
    navClassName,
    tabs,
    activeIdx = 0,
    items: propItems,
    itemClassName,
    onChange,
    children,
    responsive = true,
  } = props

  const items: TabItem[] =
    propItems ||
    tabs?.map(tab => ({
      key: tab,
      label: tab,
    })) ||
    []

  const variant = 'tab'
  const variantStyles = {
    tab: {
      base: 'border-b-2 border-transparent',
      active: 'border-blue-700 text-blue-700 font-normal dark:border-blue-400 dark:text-blue-400',
    },
    pill: {
      base: 'rounded-lg py-2 hover:text-gray-600 dark:text-gray-300 dark:hover:bg-transparent dark:hover:text-gray-200',
      active:
        'bg-blue-500 hover:bg-blue-600 text-white hover:text-white dark:bg-blue-600 dark:hover:bg-blue-500 dark:text-white dark:hover:text-white',
    },
  }

  return (
    <>
      <div className={twMerge('w-full sm:hidden', responsive === false && 'hidden', className)}>
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="dark:border-dark-500 dark:bg-dark-700 block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 dark:text-gray-200"
          defaultValue={activeIdx}
          onChange={e => onChange?.(parseInt(e.target.value))}
        >
          {tabs?.map((tab, i) => (
            <option key={i} value={i}>
              {tab}
            </option>
          ))}
          {items?.map((item, i) => (
            <option key={i} value={i} disabled={item.disabled}>
              {item.label}
              {item.stat !== undefined && ` (${item.stat})`}
            </option>
          ))}
        </select>
      </div>
      <div
        className={classNames([
          'dark:border-dark-400 flex items-center justify-between border-gray-200 sm:block',
          responsive && 'hidden',
          className,
        ])}
      >
        <nav className={cn('-mb-px flex flex-1', navClassName)} aria-label="Tabs">
          {items?.map((item, i) => {
            const isActive = i === props.activeIdx
            return (
              <button
                key={i}
                type="button"
                className={cn(
                  'relative p-2 text-sm',
                  variantStyles[variant].base,
                  isActive && variantStyles[variant].active,
                  itemClassName,
                )}
                onClick={() => onChange?.(i)}
                disabled={item.disabled}
              >
                <Box
                  className={cn(
                    'before:contents[ ] dark:hover:bg-dark-500 flex items-center gap-2 rounded px-2 py-1 transition-all before:absolute before:left-0 before:top-0 before:h-full before:w-full hover:bg-gray-100',
                  )}
                >
                  {item.icon && (
                    <Icon
                      icon={item.icon}
                      color={item.iconColor || 'default'}
                      className={classNames('h-5 w-5', item.iconClassName)}
                    />
                  )}
                  <span
                    className={cn(
                      'whitespace-nowrap text-gray-500 dark:text-gray-400',
                      isActive && 'text-gray-800 dark:text-gray-100',
                    )}
                  >
                    {item.label}
                  </span>
                  {item.stat !== undefined && <span className={classNames()}>{item.stat}</span>}
                </Box>
              </button>
            )
          })}
        </nav>
      </div>
      {children}
    </>
  )
}
