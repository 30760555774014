import { gql } from '@apollo/client'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Label } from 'components/Label'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { client } from 'internal/graphql/client'
import * as pdfjsLib from 'pdfjs-dist'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { PDFViewer } from './PDFViewer'
import { Viewer } from './Viewer'

type DocumentViewerProps = {
  activeTab?: 'Document' | 'Attestation'
  isWorkspace?: boolean
  documentId: string | null
  mimeType: string | null
  rotation?: number
}

const QueryDocumentUrl = gql`
  query DocumentUrl($id: String!) {
    document(id: $id) {
      id
      url
    }
  }
`
const QueryWorkspaceDocumentUrl = gql`
  query WorkspaceDocumentUrl($id: String!) {
    workspaceDocument(id: $id) {
      id
      url
    }
  }
`

const QueryDocumentAttestationUrl = gql`
  query DocumentUrl($id: String!) {
    document(id: $id) {
      id

      attestation {
        id
        url
      }
    }
  }
`

export const DocumentViewer = ({
  activeTab = 'Document',
  isWorkspace = false,
  documentId,
  mimeType,
  rotation,
}: DocumentViewerProps) => {
  const [loading, setLoading] = useState(false)
  const [docUrl, setDocUrl] = useState<string | null>(null)
  const [docError, setDocError] = useState<null | Error>(null)
  const [canvasElements, setCanvasElements] = useState([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState([0, 0])
  const [pdf, setPdf] = useState(null)
  const { width: windowWidth } = useWindowSize()
  const isPdf = mimeType === 'application/pdf'

  const handleLoad = useCallback(() => {
    setLoading(true)

    client
      .query({
        query:
          activeTab === 'Document'
            ? isWorkspace
              ? QueryWorkspaceDocumentUrl
              : QueryDocumentUrl
            : QueryDocumentAttestationUrl,
        variables: { id: documentId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (isWorkspace) {
          setDocUrl(data.workspaceDocument.url)
        } else {
          setDocUrl(data.document.attestation?.url || data.document.url)
        }
        setDocError(null)
      })

    setTimeout(() => {
      setLoading(false)
    }, 250)
  }, [documentId, activeTab, isWorkspace])

  useEffect(() => {
    if (!documentId) {
      setLoading(false)
      setDocUrl(null)
      return
    }

    handleLoad()
  }, [documentId, handleLoad])

  useEffect(() => {
    if (!containerRef.current) return
    const rect = containerRef.current.getBoundingClientRect()
    setSize([rect.width, rect.height])
  }, [docUrl, rotation, windowWidth])

  useEffect(() => {
    const abortController = new AbortController()

    if (!isPdf || !docUrl) return

    fetch(docUrl, { signal: abortController.signal })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        return response.arrayBuffer()
      })
      .then(data => pdfjsLib.getDocument(data).promise)
      .then(pdf => {
        setPdf(pdf)
      })
      .catch(e => {
        setDocError(e)
        setDocUrl(null)
      })

    return () => {
      abortController.abort()
    }
  }, [docUrl, isPdf])

  useEffect(() => {
    if (!isPdf || !pdf) return
    const els = new Array(pdf?.numPages || 0).fill(0).map((_, i) => (
      <div key={i} className="flex w-full flex-col items-end p-4">
        <Label className="mb-1 text-xs font-semibold">
          Page {i + 1} of {pdf?.numPages || 0}
        </Label>
        <PDFViewer url={docUrl} rotation={rotation} width={size[0]} maxHeight={size[1]} pdf={pdf} pageNumber={i + 1} />
      </div>
    ))
    setCanvasElements(els)
  }, [isPdf, rotation, size, pdf, docUrl])

  return (
    <div className="relative flex flex-1 flex-col justify-center overflow-auto pt-8">
      {loading && (
        <div className="absolute inset-0 h-full w-full">
          <div className="absolute inset-0 h-full w-full">
            <Loader />
          </div>
        </div>
      )}
      <div className="flex h-full w-full flex-1 flex-col" ref={containerRef}>
        {docError && (
          <Box className="flex flex-1 flex-col items-center justify-center gap-6">
            <Text>Could not load document. Please try again</Text>
            <Button onClick={handleLoad} aria-label="Reload Document">
              Reload
            </Button>
          </Box>
        )}
        {!loading &&
          (isPdf ? (
            canvasElements
          ) : (
            <Box className="p-4">
              <Viewer url={docUrl} rotation={rotation} width={size[0]} maxHeight={size[1]} />
            </Box>
          ))}
      </div>
    </div>
  )
}
