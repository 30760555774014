import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PinnedTransportationProvider } from 'components/AreaOfService/types'
import { saveConfiguration } from 'components/AreaOfService/utilities'
import { graphqlFetcher } from 'internal/graphql/fetcher'
import { QueryProvidersAreaOfService, QueryProvidersMeta } from 'internal/graphql/query/provider'
import { loadCounts } from 'internal/redux/map/actions'
import { AppDispatch, store } from 'internal/redux/store'
import { post } from 'lib/api/assignment'
import { Qualification, QualifiedTransportationProvider, TransportationProvider } from 'types'
import { ServiceLevel } from 'types/ServiceLevel'
import { setMode, setServiceArea } from '../../map'

type OverviewState = {
  selectedProviderId: string | null
  levelOfService: number
  serviceLevels: ServiceLevel[]
  serviceLevel: ServiceLevel | null
  providers: QualifiedTransportationProvider[]
  pinned: PinnedTransportationProvider[]
  loading: boolean
}

type ProvidersMetaResponse = { providers: { results: TransportationProvider[] } }
type AssignmentProvider = { providerId: string } & Qualification
type AssignmentResponse = { providers: AssignmentProvider[] }
type ProvidersAreaOfServiceResponse = {
  providersAreaOfService: {
    levelOfService: number
    order: number
    provider: Partial<TransportationProvider>
    providerId: string
    zipCode: string
  }[]
}
type QualificationProvider = TransportationProvider & Qualification

const initialState: OverviewState = {
  selectedProviderId: null,
  levelOfService: 0,
  serviceLevels: [],
  serviceLevel: null,
  providers: [],
  pinned: [],
  loading: false,
}

export const clearProvider = () => {
  return function clearProviderThunk(dispatch: AppDispatch) {
    dispatch(setSelectedProviderId(null))
    dispatch(setMode('networkOverview'))
    dispatch(setServiceArea([]))
  }
}

export const loadProviders = createAsyncThunk(
  'network/map/overview/loadProviders',
  async (options: { zipCode: string }) => {
    const state = store.getState()

    try {
      const [providersRes, configuration, assignmentRes]: [
        ProvidersMetaResponse,
        ProvidersAreaOfServiceResponse,
        AssignmentResponse,
      ] = await Promise.all([
        graphqlFetcher(QueryProvidersMeta, { zipCodes: [options.zipCode], page: 1, limit: -1 }),
        graphqlFetcher(QueryProvidersAreaOfService, {
          zipCode: options.zipCode,
          levelOfService: state.overview.levelOfService,
        }),
        post('/find/providers', { zipCode: options.zipCode }).then(r => r.json()),
      ])

      return { providersRes, configuration, assignmentRes }
    } catch {
      return { providersRes: null, configuration: null, assignmentRes: null }
    }
  },
)

export function handlePin({ providerId, pin }: { providerId: string; pin: boolean }) {
  return async function handlePinThunk(dispatch: AppDispatch) {
    const state = store.getState()
    const { pinned, providers, levelOfService } = state.overview

    const pinnedIds = pinned.map(p => p.id).filter(p => p !== providerId)

    if (pin) {
      pinnedIds.push(providerId)
      dispatch(setPinned(pinned.concat({ ...providers.find(p => p.id === providerId), isServingZip: true })))
    } else {
      dispatch(setPinned(pinned.filter(p => p.id !== providerId)))
    }

    const zipCode = state.map.selectedZip.properties?.code as string
    const zipCodeConfiguration = {
      zipCode,
      levelOfService,
      configuration: pinnedIds.map((providerId, i) => ({
        providerId,
        order: i,
        pinned: true,
      })),
    }

    await saveConfiguration(zipCodeConfiguration)
    dispatch(loadCounts({ zips: [zipCode] }))

    return zipCodeConfiguration
  }
}

export function handlePins({ zipCodes }: { zipCodes: string[] }) {
  return async function handlePinsThunk(dispatch: AppDispatch) {
    const state = store.getState()
    for (const zipCode of zipCodes) {
      await saveConfiguration({
        zipCode,
        levelOfService: state.overview.levelOfService,
        configuration: state.overview.pinned.map((p, i) => ({ providerId: p.id, order: i, pinned: true })),
      })
    }

    dispatch(loadCounts({ zips: zipCodes }))
  }
}

export function handleLevelOfService({ levelOfService }: { levelOfService: number }) {
  return async function handleLevelOfServiceThunk(dispatch: AppDispatch) {
    const state = store.getState()

    dispatch(setLevelOfService(levelOfService))
    const zipsSource = state.map.sources.find(s => s.id === 'zipcodes')
    if (zipsSource) {
      const zips = zipsSource.config.data?.features?.map(f => f.properties?.code) || []
      dispatch(loadCounts({ zips }))
    }
  }
}

export const overviewSlice = createSlice({
  name: 'network/map/overview',
  initialState,
  reducers: {
    setSelectedProviderId: (state, action: PayloadAction<string | null>) => {
      state.selectedProviderId = action.payload
    },
    setServiceLevels: (state, action: PayloadAction<ServiceLevel[]>) => {
      state.serviceLevels = action.payload
      state.serviceLevel = action.payload.find(sl => sl.code === state.levelOfService)
    },
    setLevelOfService: (state, action: PayloadAction<number>) => {
      state.levelOfService = action.payload
      state.serviceLevel = state.serviceLevels.find(sl => sl.code === state.levelOfService)
    },
    setProviders: (state, action: PayloadAction<QualifiedTransportationProvider[]>) => {
      state.providers = action.payload
    },
    setPinned: (state, action: PayloadAction<PinnedTransportationProvider[]>) => {
      state.pinned = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadProviders.pending, state => {
        state.loading = true
      })
      .addCase(loadProviders.fulfilled, (state, action) => {
        state.loading = false

        const { providersRes, configuration, assignmentRes } = action.payload
        const loadedProviders: QualificationProvider[] =
          (providersRes?.providers?.results as QualificationProvider[]) || []
        const providerMap = new Map<string, QualificationProvider>(loadedProviders.map(p => [p.id, p]))
        const newProviders = []

        if (configuration?.providersAreaOfService?.length > 0) {
          for (const { providerId } of configuration.providersAreaOfService) {
            const provider = providerMap.get(providerId)
            if (provider) {
              newProviders.push(provider)
            }
          }
        }

        for (const loadedProvider of loadedProviders) {
          if (!newProviders.find(p => p.id === loadedProvider.id)) {
            newProviders.push(loadedProvider)
          }
        }

        for (const assignmentProvider of assignmentRes?.providers) {
          const provider = providerMap.get(assignmentProvider.providerId)
          if (provider) {
            provider.qualifiers = assignmentProvider.qualifiers
            provider.score = assignmentProvider.score
          }
        }

        state.providers = loadedProviders
        state.pinned =
          configuration.providersAreaOfService.map(aos => {
            const provider = providerMap.get(aos.provider.id)
            return {
              ...aos.provider,
              isServingZip: !!provider,
              qualifiers: provider?.qualifiers || null,
            }
          }) || []
      })
      .addCase(loadProviders.rejected, state => {
        state.loading = false
      })
  },
})

export const { setSelectedProviderId, setServiceLevels, setLevelOfService, setProviders, setPinned } =
  overviewSlice.actions
