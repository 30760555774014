import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { Box } from 'components/Box'
import { CheckBox } from 'components/CheckBox'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { RejectionReason } from 'types/RejectionReason'
import { RejectionReasonsObject } from './DocumentRejectModal'

type Props = {
  rejectionReasonsObject: RejectionReasonsObject
  values: RejectionReason[]
  input: string
  onChange: (reason: RejectionReason) => void
  handleClick: (key: string) => void
}

export function RejectionReasonsDisclosure({ rejectionReasonsObject, values, input, onChange, handleClick }: Props) {
  if (Object.keys(rejectionReasonsObject).length === 0) {
    return (
      <div className="flex justify-center">
        <Text>No reasons found</Text>
      </div>
    )
  }

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-600">
      {Object.keys(rejectionReasonsObject).map((key, i) => {
        let category = key
        if (input.length > 0) {
          category = category.replace(
            new RegExp(input, 'gi'),
            match => `<span class="underline decoration-blue-500 bg-yellow-200 dark:text-gray-800">${match}</span>`,
          )
        }

        return (
          <Disclosure key={i}>
            {() => (
              <>
                <Disclosure.Button
                  onClick={() => handleClick(key)}
                  className="flex w-full justify-between py-2 text-left"
                >
                  <p className="text-sm font-medium dark:text-white" dangerouslySetInnerHTML={{ __html: category }}></p>
                  {rejectionReasonsObject[key].isOpen ? <Icon icon={HiChevronUp} /> : <Icon icon={HiChevronDown} />}
                </Disclosure.Button>
                {rejectionReasonsObject[key].isOpen && (
                  <Disclosure.Panel className="dark:bg-dark-800 bg-gray-100 px-4 py-2" static>
                    {rejectionReasonsObject[key].reasons.map((reason, i) => {
                      let description = reason.description
                      if (input.length > 0) {
                        description = description.replace(
                          new RegExp(input, 'gi'),
                          match =>
                            `<span class="underline decoration-blue-500 bg-yellow-200 dark:text-gray-900">${match}</span>`,
                        )
                      }

                      const isChecked = !!values.find(
                        v => v.category === reason.category && v.description === reason.description,
                      )

                      return (
                        <Box
                          role="button"
                          key={i}
                          onClick={() => onChange(reason)}
                          className={classNames(
                            'dark:bg-dark-600 dark:hover:bg-dark-500 relative my-2 flex cursor-pointer items-center rounded-lg border bg-white p-4 hover:bg-gray-100 focus:outline-none',
                            isChecked
                              ? 'border-blue-600 bg-blue-50 dark:border-blue-400'
                              : 'border-gray-300 dark:border-gray-500',
                          )}
                        >
                          <CheckBox
                            checked={isChecked}
                            onChange={() => {
                              return
                            }}
                            className="cursor-pointer"
                          />
                          <div className="ml-4">
                            <p
                              dangerouslySetInnerHTML={{ __html: description }}
                              className={classNames({
                                'text-sm dark:text-gray-200': true,
                                'dark:text-white': isChecked,
                              })}
                            />
                          </div>
                        </Box>
                      )
                    })}
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        )
      })}
    </div>
  )
}
