import { post } from 'lib/api/req'
import { TransportationProvider } from 'types'
import { Geometry } from 'wkx'
import { LoadGeoOptions, ZipCode, ZipCodeConfiguration } from './types'

export const loadGeo = async (options: LoadGeoOptions): Promise<ZipCode[]> => {
  const query = new URLSearchParams({ sparse: '1' })
  if (options.bounds) {
    query.set('bounds', options.bounds.join(','))
  } else if (options.zipCodes) {
    query.set('zipCodes', options.zipCodes.join(','))
  }

  const zipData = await fetch('https://zip-data-service.onrender.com/load?' + query.toString())
    .then(r => r.json())
    .catch(() => [])

  return Promise.all(
    (zipData?.results || [])?.map(async r => {
      try {
        const { result } = await fetch(`https://zip-data-service.onrender.com/load/${r.postalCode}`).then(r => r.json())
        r.geometry = Geometry.parse(result.geometry).toGeoJSON()
        return r
      } catch (e) {
        console.error(e)
      }
    }),
  )
}

export const loadZipsByCounty = async (county: string): Promise<ZipCode[]> => {
  const query = new URLSearchParams({ county })

  const zipData: string[] = await fetch('https://zip-data-service.onrender.com/zips?' + query.toString())
    .then(r => r.json())
    .catch(() => [])

  return Promise.all(
    (zipData || [])?.map(async zipCode => {
      try {
        const { result } = await fetch(`https://zip-data-service.onrender.com/load/${zipCode}`).then(r => r.json())
        result.geometry = Geometry.parse(result.geometry).toGeoJSON()
        return result
      } catch (e) {
        console.error(e)
      }
    }),
  )
}

export const saveConfiguration = async (configuration: ZipCodeConfiguration) => {
  const res = await post('/v1/area-of-service', configuration)
  return res.json()
}

export const getCapacity = (provider: TransportationProvider, tripType: number) => {
  return provider.tripCapacity?.find(tc => tc.levelOfService === tripType)?.capacity || 0
}

// a little function to help us with reordering the result
export const reorder = <TList extends unknown[]>(list: TList, startIndex: number, endIndex: number): TList => {
  const result = Array.from(list) as TList
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
