import { SubmissionStatus } from 'types/Workspace'
import { patch } from './req'

export type UpdateSubmissionPayload = {
  id: string
  status: SubmissionStatus
  statusReason?: string
  expiresAt?: number
}

export function updateSubmission(payload: UpdateSubmissionPayload) {
  return patch('/v1/workspaces/submissions', payload)
}
