import { Box } from 'components/Box'
import { DocumentRejectModal } from 'components/DocumentPanel/DocumentRejectModal'
import { DocumentStatusFilter } from 'components/Documents/DocumentStatusFilter'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { STATUS_OPTIONS_SETTABLE_MANAGER, StatusOption } from 'constants/document'
import { useRoles } from 'hooks/useRoles'
import { decideDocument } from 'lib/api/manager'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Document } from 'types/Document'
import { RejectionReason } from 'types/RejectionReason'
import { sleep } from 'util/sleep'
import { Button } from '../Button'
import { InputGroup } from '../InputGroup/InputGroup'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from '../Modal'
import { TextArea } from '../Textarea'

type DecisionOverrideModalProps = ModalProps & {
  document: Document | undefined
}

export const DecisionOverrideModal = (props: DecisionOverrideModalProps) => {
  const { document: doc, isOpen, onClose } = props
  const { isSuperAdmin, isManager } = useRoles()
  const [loading, setLoading] = useState(false)
  const [documentRejectModalOpen, setDocumentRejectModalOpen] = useState(false)
  const [additionalNotes, setAdditionalNotes] = useState<RejectionReason>({
    category: 'Other',
    description: '',
    canOverride: true,
    shouldOverwrite: false,
  })
  const [rejectionReasons, setRejectionReasons] = useState<RejectionReason[]>([])
  const filteredRejectionReasons = [...rejectionReasons, additionalNotes]?.filter(r => r.description)

  const handleRejectionReasons = (reasons: RejectionReason[]) => {
    const additionalReason = reasons.find(r => r.category === 'Other')
    const rejectionReasons = reasons.filter(r => r.category !== 'Other')

    setRejectionReasons(rejectionReasons)
    setAdditionalNotes(
      additionalReason?.description
        ? additionalReason
        : {
            category: 'Other',
            description: '',
            canOverride: true,
            shouldOverwrite: false,
          },
    )

    setDocumentRejectModalOpen(false)
  }

  const { watch, handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      status: '',
      overrideReason: '',
    },
  })
  const values = watch()

  const onSubmit = async (data: any) => {
    if (loading) {
      return
    }

    setLoading(true)
    if (doc?.status === data.status && !isSuperAdmin) {
      toast.error(`Document is already ${data.status}`)
      setLoading(false)
      return
    }

    const reason =
      data.status === 'approved' ? '' : filteredRejectionReasons.map(r => `${r.category}:${r.description}`).join('\n\n')

    const params = {
      documentId: doc?.id,
      isApproved: data.status === 'approved',
      overrideId: doc?.decision.id,
      canOverride: rejectionReasons.every(r => r.canOverride),
      overrideReason: data.overrideReason || reason,
      reason,
    }

    await decideDocument(params)
    await sleep(500)
    setLoading(false)
    onClose()
  }

  useEffect(() => {
    reset({
      status: doc?.status,
      overrideReason: '',
    })

    if (doc?.status === 'rejected' && doc?.statusReason) {
      const reasons = doc?.statusReason.split('\n\n').map(r => {
        const colonIndex = r?.indexOf(':')

        let category = ''
        let description = ''

        if (colonIndex !== -1) {
          category = r.slice(0, colonIndex)
          description = r.slice(colonIndex + 1)
        } else {
          category = 'Rejected'
          description = r
        }

        return {
          category,
          description: description.trim(),
          canOverride: doc?.decision?.canOverride,
          shouldOverwrite: false,
        }
      })

      setRejectionReasons(reasons.filter(f => f.category !== 'Other'))
      setAdditionalNotes(
        reasons.find(f => f.category === 'Other') || {
          canOverride: true,
          category: 'Other',
          description: '',
          shouldOverwrite: false,
        },
      )
    } else {
      setRejectionReasons([])
    }
  }, [isOpen, doc, reset])

  useEffect(() => {
    if (isOpen) return

    setRejectionReasons([])
  }, [isOpen])

  const onDocumentStatusFilterChange = (o: StatusOption) => {
    setValue('status', o.value)

    if (o.value === 'rejected' && doc?.status !== 'rejected') {
      setDocumentRejectModalOpen(true)
    } else if (doc?.status !== 'rejected') {
      setRejectionReasons([])
    }
  }

  const canOverrideDocument = doc?.decision?.canOverride
  const canOverrideAsManager = isManager && doc?.decision?.deciderType !== 'system'

  if (!canOverrideDocument && !canOverrideAsManager && !isSuperAdmin) {
    return (
      <Modal key="decisionOverrideModal" isOpen={isOpen} onClose={onClose}>
        <ModalHeader key="decisionOverrideModalHeader" title="Override Decision" />
        <ModalBody key="decisionOverrideModalBody" className="w-screen max-w-md justify-center text-center">
          <Text weight="normal">This decision is not eligible to be overridden.</Text>
        </ModalBody>
        <ModalFooter onClose={onClose} isOnlyClose />
      </Modal>
    )
  }

  if (!doc) {
    return (
      <Modal key="decisionOverrideModal" isOpen={isOpen} onClose={onClose}>
        <ModalHeader key="decisionOverrideModalHeader" title="Override Decision" />
        <ModalBody key="decisionOverrideModalBody" className="w-screen max-w-md justify-center text-center">
          <Loader />
        </ModalBody>
        <ModalFooter onClose={onClose} isOnlyClose />
      </Modal>
    )
  }

  return (
    <Modal key="decisionOverrideModal" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-screen max-w-lg">
        <ModalHeader key="decisionOverrideModalHeader" title="Override Decision" />
        <ModalBody key="decisionOverrideModalBody" className="min-h-40 space-y-4">
          <DocumentStatusFilter
            value={STATUS_OPTIONS_SETTABLE_MANAGER.find(o => o.value === values.status)}
            options={STATUS_OPTIONS_SETTABLE_MANAGER}
            onChange={onDocumentStatusFilterChange}
          />
          {values.status === 'rejected' || (doc?.status === 'rejected' && values.status === 'rejected') ? (
            <Fragment>
              <Box className="flex flex-col gap-2">
                {filteredRejectionReasons.map((r, i) => (
                  <Text as="span" size="sm" weight="normal" key={i}>
                    <Text as="span" size="sm" weight="semibold">
                      {r.category}:
                    </Text>{' '}
                    {r.description}
                  </Text>
                ))}
              </Box>
              {doc?.status !== 'rejected' && (
                <Button
                  size="sm"
                  className="w-full items-center justify-center"
                  type="button"
                  onClick={() => setDocumentRejectModalOpen(true)}
                  disabled={doc?.status === 'rejected'}
                >
                  {rejectionReasons?.length > 0 || additionalNotes?.description ? 'Change' : 'Choose rejection reason'}
                </Button>
              )}
            </Fragment>
          ) : (
            <InputGroup label="Override Reason" id="overrideReason" required {...register('overrideReason')}>
              <TextArea className="bg-gray-50" id="overrideReason" rows={3} {...register('overrideReason')} required />
            </InputGroup>
          )}
        </ModalBody>
        <ModalFooter
          isLoading={loading}
          onClose={onClose}
          isDisabled={
            (values.status === 'rejected' && !filteredRejectionReasons.length) || values.status === doc.status
          }
        />
      </form>
      <DocumentRejectModal
        documentType={doc?.documentType}
        isOpen={documentRejectModalOpen}
        onClose={() => setDocumentRejectModalOpen(false)}
        onSubmit={handleRejectionReasons}
      />
    </Modal>
  )
}
