import classNames from 'classnames'
import { AccountSection } from 'components/account/AccountSection'
import { Box } from 'components/Box'
import { IconButton } from 'components/Buttons/IconButton'
import { motion } from 'framer-motion'
import { useAuth } from 'hooks/useAuth'
import { useRoles } from 'hooks/useRoles'

import { useSidebarContext } from 'hooks/useSidebarContext'
import { Fragment } from 'react'
import { HiMenu } from 'react-icons/hi'
import { BrokerNav } from './BrokerNav'
import { DiversityNav } from './DiversityNav'
import { DmasNav } from './DmasNav'
import { InspectorNav } from './InspectorNav'
import { ManagerNav } from './ManagerNav'
import { ProviderNav } from './ProviderNav'
import { ProviderSwitcher } from './ProviderSwitcher'
import { SidebarLogo } from './SidebarLogo'
import { SuperAdminNav } from './SuperAdminNav'

export function Sidebar() {
  const { account } = useAuth()
  const { theme, isCollapsed, toggleExpanded, expanded } = useSidebarContext()

  return (
    <div
      className={classNames([
        'lg:max-w-2xs md:dark:border-dark-500 absolute top-0 flex h-screen w-full flex-1 flex-shrink-0 flex-col md:dark:border-r lg:relative lg:w-screen',
        theme.background,
        !isCollapsed && 'min-w-2xs',
        expanded && 'z-40',
      ])}
    >
      <div
        className={classNames([
          'flex w-full flex-row items-center',
          !isCollapsed ? 'justify-between pr-4' : 'justify-center',
        ])}
      >
        <Box className="px-6 py-4">
          <SidebarLogo />
        </Box>
        <IconButton
          ariaLabel="Menu"
          className="block lg:hidden"
          icon={HiMenu}
          variant="light"
          onClick={() => toggleExpanded()}
        />
      </div>
      {account && <SidebarContent expanded={expanded} />}
    </div>
  )
}

function SidebarContent({ expanded }: { expanded: boolean }) {
  const { account } = useAuth()
  const { isProvider } = useRoles()

  return (
    <Fragment>
      {isProvider && account?.providerIds?.length > 1 && (
        <div className="px-4 pt-4">
          <ProviderSwitcher />
        </div>
      )}

      <motion.div
        className={classNames([
          'relative flex w-full flex-1 flex-col items-center overflow-y-auto pt-4',
          expanded && 'right-0 w-full rounded-b-lg bg-black dark:bg-black',
        ])}
      >
        <Box className="w-full flex-1 overflow-auto py-1">
          {account?.roles.includes('manager') && <ManagerNav />}
          {account?.roles.includes('dmas') && <DmasNav />}
          {account?.roles.includes('operations') && <ManagerNav />}
          {account?.roles.includes('provider') && <ProviderNav />}
          {account?.roles.includes('inspector') && !account?.roles.includes('manager') && <InspectorNav />}
          {account?.roles.includes('superadmin') && <SuperAdminNav />}
          {account?.roles.includes('broker') && <BrokerNav />}
          {account?.roles.includes('diversity-manager') && <DiversityNav />}
          {account?.roles.includes('risk-manager') && <ManagerNav />}
        </Box>
      </motion.div>
      <AccountSection />
    </Fragment>
  )
}
