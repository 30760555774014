import classNames from 'classnames'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { IconType } from 'react-icons'
import { Icon } from '../Icon'

type BadgeProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  size?: 'xs' | 'sm' | 'md'
  padding?: 'xs' | 'sm' | 'md'
  color?: keyof typeof colors
  weight?: number
  icon?: IconType
  dot?: boolean
  border?: boolean
  rounded?: 'sm' | 'md' | 'full'
}

export const colors = {
  white: 'text-gray-700 bg-gray-50 border border-gray-300 dark:text-gray-200 dark:border-gray-600 dark:bg-dark-800',
  green:
    'text-green-800 bg-green-100 border border-green-400 dark:text-green-300 dark:border-green-400 dark:bg-green-900 dark:bg-opacity-40',
  blue: 'text-blue-800 bg-blue-100 border border-blue-400 dark:text-blue-300 dark:border-blue-400 dark:bg-blue-900 dark:bg-opacity-40',
  red: 'text-red-800 bg-red-100 border border-red-400 dark:text-red-300 dark:border-red-400 dark:bg-red-900 dark:bg-opacity-40',
  yellow:
    'text-yellow-800 bg-yellow-100 border border-yellow-400 dark:text-yellow-300 dark:border-yellow-400 dark:bg-yellow-900 dark:bg-opacity-40',
  purple:
    'text-purple-800 bg-purple-100 border border-purple-400 dark:text-purple-300 dark:border-purple-400 dark:bg-purple-900 dark:bg-opacity-40',
}

const badgeColors = {
  white: 'bg-gray-400 dark:bg-gray-200',
  green: 'bg-green-400 dark:bg-green-300',
  blue: 'bg-blue-400 dark:bg-blue-300',
  red: 'bg-red-400 dark:bg-red-300',
  yellow: 'bg-yellow-400 dark:bg-yellow-300',
  purple: 'bg-purple-400 dark:bg-purple-300',
}
const iconColors = {
  white: 'text-gray-600 dark:text-gray-200',
  green: 'text-green-600 dark:text-green-300',
  blue: 'text-blue-700 dark:text-blue-300',
  red: 'text-red-600 dark:text-red-300',
  yellow: 'text-yellow-600 dark:text-yellow-300',
  purple: 'text-purple-600 dark:text-purple-300',
}

const roundedClassnames = {
  sm: 'rounded-sm',
  md: 'rounded-md',
  full: 'rounded-full',
}

const getPaddingClasses = (padding: string) => {
  switch (padding) {
    case 'xs':
      return 'px-1.5 py-0.5'
    case 'sm':
      return 'px-3 py-1'
  }

  return 'px-3 py-1.5'
}

export const Badge = ({
  className,
  size = 'md',
  padding = 'md',
  rounded = 'md',
  weight = 500,
  border,
  color = 'white',
  dot,
  icon,
  children,
  ...rest
}: BadgeProps) => {
  const textSize = size === 'xs' ? 'text-xs' : 'text-sm'
  const paddingClasses = getPaddingClasses(padding)
  const fontWeight = weight === 500 ? 'font-normal' : 'font-medium'

  return (
    <div
      {...rest}
      className={classNames(
        'flex flex-shrink-0 items-center gap-2',
        border === false ? 'border-none' : 'dark:border-dark-500 border',
        roundedClassnames[rounded],
        paddingClasses,
        textSize,
        fontWeight,
        colors[color],

        className,
      )}
    >
      {icon && <Icon icon={icon} size={size} className={iconColors[color]} />}
      {dot && <div className={classNames(['h-1.5 w-1.5 rounded-full', badgeColors[color]])} />}
      {children}
    </div>
  )
}
