import { gql, useLazyQuery } from '@apollo/client'

type UseExtractionFieldOptions = {}

const QUERY_EXTRACTION_FIELD_OPTIONS = gql`
  query QueryExtractionFieldOptions($field: String!, $documentType: String!) {
    extractionFieldOptions(field: $field, documentType: $documentType)
  }
`

export const useExtractionFieldOptions = () => {
  const [load, { data, loading }] = useLazyQuery(QUERY_EXTRACTION_FIELD_OPTIONS)
  const handleLoad = (options?: UseExtractionFieldOptions) => {
    if (!options) return
    load({ variables: options })
  }

  return { data: data?.extractionFieldOptions || [], loading, load: handleLoad }
}
