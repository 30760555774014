export async function fetcher(url) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + url, {
    credentials: 'include',
  })
    .then(async r => {
      const res = await r.json()
      if (!r.ok) {
        throw new Error(res.error)
      }

      return res
    })
    .catch(() => null)
}
export async function baseFetcher(url) {
  return fetch(url, {
    credentials: 'include',
  })
    .then(async r => {
      const res = await r.json()
      if (!r.ok) {
        throw new Error(res.error)
      }

      return res
    })
    .catch(() => null)
}

export function postFetcher(data) {
  return async url =>
    fetch(process.env.NEXT_PUBLIC_API_BASE_URL + url, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then(async r => {
        const res = await r.json()
        if (!r.ok) {
          throw new Error(res.error)
        }

        return res
      })
      .catch(() => null)
}
