import { handleLoadZip, handleSelectCounty } from 'internal/redux/map/actions'
import { selectZip, setSelectedZip } from 'internal/redux/slices/map'
import { clearProvider } from 'internal/redux/slices/network/map/overview'
import { ServiceType } from 'types'
import { store } from '../../internal/redux/store'
import { SERVICE_TYPE_LABELS } from './constants'

export const LAYERS = {
  countiesFill: {
    id: 'counties',
    popup: (properties: any) => {
      if (!properties) return null
      const { name, state } = properties
      if (!name || !state) return null

      return `<div class="p-2 rounded-lg bg-white dark:bg-dark-600 dark:border-dark-400"><p class="text-sm font-semibold leading-1 p-0 m-0 text-gray-900 dark:text-gray-100">${name}, ${state}</p></div>`
    },
    click: (properties: any) => {
      if (!properties?.geoid) return

      store.dispatch(handleSelectCounty({ geoid: properties?.geoid }))
    },
    highlight: true,
    config: {
      id: 'counties',
      type: 'fill',
      source: 'counties',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#9ca3af',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'highlighted'], false], 0.3, 0],
      },
    },
  },
  zipcodesFill: {
    id: 'zipcodes',
    highlight: true,
    popup: (properties: any) => {
      if (!properties) return null
      const { code, county, state, serviceType } = properties
      const serviceTypeLabel = SERVICE_TYPE_LABELS[serviceType as ServiceType]

      return `<div class="p-2 rounded-lg bg-white dark:bg-dark-600 dark:border-dark-400">
        <p class="text-sm font-semibold leading-1 p-0 m-0 text-gray-900 dark:text-gray-100">${code}</p>
        <p class="text-xs p-0 m-0 text-gray-500 dark:text-gray-400 leading-1">${county}, ${state}</p>
        <div class="flex items-center gap-1 mt-1 visibility: ${serviceType ? 'visible' : 'hidden'}">
        <span class="service-type-indicator service-type-indicator--${serviceType}"></span>
        <p class="flex-1 text-xs font-medium p-0 m-0 text-gray-500 dark:text-gray-400">${serviceTypeLabel}</p>
        </div>
      </div>`
    },
    click: (properties: any) => {
      if (!properties?.code) return

      store.dispatch(selectZip(properties.code))
    },
    config: {
      id: 'zipcodes',
      type: 'fill',
      source: 'zipcodes',
      paint: {
        'fill-color': [
          'match',
          ['feature-state', 'serviceType'],
          'both',
          '#16a34a',
          'pickup',
          '#eab308',
          'dropoff',
          '#0284c7',
          /* other */ '#9ca3af',
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'highlighted'], false],
          0.7,
          ['boolean', ['feature-state', 'selected'], false],
          0.5,
          0.1,
        ],
      },
    },
  },
  zipcodesOutline: {
    id: 'zipcodes_outline',
    config: {
      id: 'zipcodes_outline',
      type: 'line',
      source: 'zipcodes',
      paint: {
        'line-color': [
          'match',
          ['feature-state', 'serviceType'],
          'both',
          '#16a34a',
          'pickup',
          '#eab308',
          'dropoff',
          '#0284c7',
          /* other */ '#9ca3af',
        ],
      },
    },
  },
  networkZipcodesFill: {
    id: 'zipcodes',
    highlight: true,
    popup: (properties: any) => {
      if (!properties) return null
      const { code, county, state } = properties

      return `<div class="p-2 rounded-lg bg-white dark:bg-dark-600 dark:border-dark-400">
        <p class="text-sm font-semibold leading-1 p-0 m-0 text-gray-900 dark:text-gray-100">${code}</p>
        <p class="text-xs p-0 m-0 text-gray-500 dark:text-gray-400 leading-1">${county}, ${state}</p>
      </div>`
    },
    click: (properties: any) => {
      if (!properties?.code) return

      const state = store.getState()
      if (state.map.selectedZip?.properties?.code === properties.code) return

      store.dispatch(setSelectedZip(properties.code))
    },
    config: {
      id: 'zipcodes',
      type: 'fill',
      source: 'zipcodes',
      layout: {},
      paint: {
        'fill-color': ['case', ['>', ['get', 'pinned'], 0], '#7c3aed', '#9ca3af'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'highlighted'], false],
          0.7,
          ['boolean', ['feature-state', 'selected'], false],
          0.5,
          0.1,
        ],
      },
    },
  },
  networkZipcodesOutline: {
    id: 'zipcodes_outline',
    config: {
      id: 'zipcodes_outline',
      type: 'line',
      source: 'zipcodes',
      layout: {},
      paint: {
        'line-color': ['case', ['>', ['get', 'pinned'], 0], '#7c3aed', '#9ca3af'],
      },
    },
  },
  zipcodesLabel: {
    id: 'zipcodes_labels',
    config: {
      id: 'zipcodes_labels',
      type: 'symbol',
      source: 'zipcodes',
      layout: {
        'text-field': ['get', 'label'],
        'text-size': 13,
      },
      paint: {
        'text-color': '#0f172a',
      },
    },
  },
  serviceAreaFill: {
    id: 'serviceArea',
    config: {
      id: 'serviceArea',
      type: 'fill',
      source: 'serviceArea',
      paint: {
        'fill-color': [
          'match',
          ['feature-state', 'serviceType'],
          'both',
          '#16a34a',
          'pickup',
          '#eab308',
          'dropoff',
          '#0284c7',
          /* other */ '#9ca3af',
        ],
        'fill-opacity': ['case', ['boolean', ['feature-state', 'highlighted'], false], 0.7, 0.1],
      },
    },
  },
  networkServiceAreaFill: {
    id: 'serviceArea',
    highlight: true,
    popup: (properties: any) => {
      if (!properties) return null
      const { code, county, state, serviceType } = properties
      const serviceTypeLabel = SERVICE_TYPE_LABELS[serviceType as ServiceType]

      return `<div class="p-2 rounded-lg bg-white dark:bg-dark-600 dark:border-dark-400">
        <p class="text-sm font-semibold leading-1 p-0 m-0 text-gray-900 dark:text-gray-100">${code}</p>
        <p class="text-xs p-0 m-0 text-gray-500 dark:text-gray-400 leading-1">${county}, ${state}</p>
        <div class="flex items-center gap-1 mt-1 visibility: ${serviceType ? 'visible' : 'hidden'}">
        <span class="service-type-indicator service-type-indicator--${serviceType}"></span>
        <p class="flex-1 text-xs font-medium p-0 m-0 text-gray-500 dark:text-gray-400">${serviceTypeLabel}</p>
        </div>
      </div>`
    },
    click: (properties: any) => {
      const zipCode = properties?.code
      if (!zipCode) return

      store.dispatch(clearProvider())
      store.dispatch(handleLoadZip({ zipCode }))
    },
    config: {
      id: 'serviceArea',
      type: 'fill',
      source: 'serviceArea',
      paint: {
        'fill-color': [
          'match',
          ['feature-state', 'serviceType'],
          'both',
          '#16a34a',
          'pickup',
          '#eab308',
          'dropoff',
          '#0284c7',
          /* other */ '#9ca3af',
        ],
        'fill-opacity': ['case', ['boolean', ['feature-state', 'highlighted'], false], 0.7, 0.1],
      },
    },
  },
  serviceAreaOutline: {
    id: 'serviceArea_outline',
    config: {
      id: 'serviceArea_outline',
      type: 'line',
      source: 'serviceArea',
      paint: {
        'line-color': [
          'match',
          ['feature-state', 'serviceType'],
          'both',
          '#16a34a',
          'pickup',
          '#eab308',
          'dropoff',
          '#0284c7',
          /* other */ '#9ca3af',
        ],
        'line-opacity': 0.5,
      },
    },
  },
}
