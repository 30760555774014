import { Box } from 'components/Box'
import { Button } from 'components/Button/Button'
import { ReactNode } from 'react'
import { IconType } from 'react-icons'

type CardFooterProps = {
  isOnlyClose?: boolean
  submitText?: string
  isLoading?: boolean
  isDisabled?: boolean
  children?: ReactNode | ReactNode[]
  submitIcon?: IconType
  submitIconRight?: IconType
  onClose?: () => void
}

export const CardFooter = (props: CardFooterProps) => {
  const {
    isDisabled,
    isLoading,
    onClose,
    children,
    submitText,
    submitIcon,
    submitIconRight,
    isOnlyClose = false,
  } = props

  return (
    <div className="dark:border-dark-500 items-center justify-between gap-4 px-4 py-3 sm:flex sm:flex-row sm:px-6">
      {children}
      <Box>
        <div className="flex flex-col gap-2 sm:flex-row">
          {onClose && (
            <Button type="button" onClick={onClose}>
              {isOnlyClose ? 'Close' : 'Cancel'}
            </Button>
          )}
          {!isOnlyClose && (
            <Button
              type="submit"
              variant="primary"
              disabled={isLoading || isDisabled}
              loading={isLoading}
              icon={submitIcon}
              rightIcon={submitIconRight}
            >
              {submitText || 'Submit'}
            </Button>
          )}
        </div>
      </Box>
    </div>
  )
}
