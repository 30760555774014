// import { post } from 'cypress/types/jquery'
import { graphqlFetcher } from 'internal/graphql/fetcher'
import { QueryProvider } from 'internal/graphql/query/provider'
import { ContactMethod, OpeningHours, TripCapacity } from 'types'
import { del, patch, post, put } from './req'

export type UpdateProviderPayload = {
  id: string
  npi?: string
  medicaidProviderId?: string
  tlcBaseNumber?: string
  dba?: string[]
  addressStreet?: string
  addressStreet2?: string
  city?: string
  state?: string
  zip?: string
  hoursOfOperation?: OpeningHours[]
  tripCapacity?: TripCapacity
  isAcceptingSameDayTrips?: boolean
  primaryContactId?: string
}

export function getProvider(id: string) {
  return graphqlFetcher(QueryProvider, { id })
}

export function updateProvider(payload: UpdateProviderPayload) {
  return patch('/v1/providers', payload)
}

export type UpdateTraitsPayload = {
  providerId?: string
  traits: Record<string, any>
}

export function updateTraits(payload: UpdateTraitsPayload) {
  return patch('/v1/providers/traits', payload)
}

export type UpsertTripCapacityPayload = {
  providerId?: string
  capacity: Record<string, number>
  hourMax: Record<string, number>
  hourMaxWeekend: Record<string, number>
}

export function upsertTripCapacity(payload: UpsertTripCapacityPayload) {
  return patch('/v1/providers/trip-capacity', payload)
}

type CreateTripCapacityOverridePayload = {
  providerId: string
  serviceLevelCode: number | null
  days: number[]
  start: string
  end: string
  hourMax: number
}

export function createTripCapacityOverride(payload: CreateTripCapacityOverridePayload) {
  return post('/v1/providers/trip-capacity-overrides', payload)
}

export function removeTripCapacityOverride(id: string) {
  return del('/v1/providers/trip-capacity-overrides', { id })
}

export type UpsertOperatingHoursPayload = {
  providerId?: string
  open: Record<string, string>
  close: Record<string, string>
}

export function upsertOperatingHours(payload: UpsertOperatingHoursPayload) {
  return patch('/v1/providers/operating-hours', payload)
}

export type SuspendProviderPayload = {
  id: string
  reason?: string
}

export function suspendProvider(payload: SuspendProviderPayload) {
  return post('/v1/providers/suspend', payload)
}
export type ReinstateProviderPayload = {
  id: string
}

export function reinstateProvider(payload: ReinstateProviderPayload) {
  return post('/v1/providers/reinstate', payload)
}

type OperatingHoursOverride = {
  opens: string
  closes: string
  note: string
  validFrom: string
  validThrough: string
}

export type OperatingHoursOverridesPayload = {
  providerId?: string
  overrides: OperatingHoursOverride[]
}

export function createOperatingHoursOverrides(payload: OperatingHoursOverridesPayload) {
  return put('/v1/providers/operating-hours-overrides', payload)
}

export type ProviderServiceLevel = {
  postalCode: string
  serviceType: string
}

export function updateServiceArea(payload: { providerId?: string; zips: ProviderServiceLevel[] }) {
  return patch('/v1/providers/service-area', payload)
}

export type CreateContactPayload = {
  providerId: string
  firstName: string
  middleName?: string
  lastName?: string
  email: string
  phone: string
  title: string
  methods: ContactMethod[]
}

export function createContact(payload: CreateContactPayload) {
  return post('/v1/providers/contacts', payload)
}

export type UpdateContactPayload = {
  id: string
  providerId: string
  firstName: string
  middleName?: string
  lastName?: string
  email: string
  phone: string
  title: string
  methods: ContactMethod[]
}

export function updateContact(payload: UpdateContactPayload) {
  return patch('/v1/providers/contacts', payload)
}

type DeleteContactPayload = {
  id: string
  providerId: string
}

export function deleteContact(payload: DeleteContactPayload) {
  return del('/v1/providers/contacts', payload)
}
