import { useToggle } from 'hooks/useToggle'
import { linkDocument } from 'lib/api'
import { useCallback, useState } from 'react'
import { HiOutlineLink } from 'react-icons/hi'
import { Driver } from 'types/Driver'
import { Vehicle } from 'types/Vehicle'
import { sleep } from 'util/sleep'
import { Button } from '../Button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal'
import { RosterSelect } from '../providers/RosterSelect'

export type ConnectOwnerButtonProps = {
  documentId: string
}

export const ConnectOwnerButton = ({ documentId }: ConnectOwnerButtonProps) => {
  const [isOpen, toggleOpen] = useToggle(false)
  const [loading, toggleLoading] = useToggle(false)
  const [selected, setSelected] = useState<{ type: 'driver' | 'vehicle'; value: Vehicle | Driver | null }>({
    type: null,
    value: null,
  })
  const handleChange = useCallback((type, value) => setSelected({ type, value }), [setSelected])
  const handleSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (loading) {
      return
    }

    toggleLoading()
    await linkDocument({
      documentId,
      [`${selected.type}Id`]: selected.value.id,
    })

    await sleep(250)

    toggleLoading()
  }

  return (
    <div>
      <Button size="xs" onClick={() => toggleOpen()}>
        Connect
      </Button>
      <Modal target="linkModal" isOpen={isOpen} onClose={() => toggleOpen()}>
        <ModalHeader title="Connect Owner" icon={<HiOutlineLink className="h-6 w-6 text-blue-600" />} />
        <ModalBody padding={false}>
          <form onSubmit={handleSubmit}>
            <div className="h-80 w-screen max-w-xl overflow-auto">
              <RosterSelect onChange={handleChange} />
            </div>
            <ModalFooter isLoading={loading} isDisabled={!selected.value} onClose={() => toggleOpen(false)} />
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
