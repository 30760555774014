import { Fragment } from 'react'
import { TransportationProvider } from 'types'
import { MetadataSettings } from '../MetadataSettings'

import { Entities } from './Entities'
import { InsuranceDocuments } from './InsuranceDocuments'
import { Organization } from './Organization'

type Props = {
  provider: TransportationProvider
  refresh: () => void
}

export function OnboardingSection({ provider, refresh }: Props) {
  return (
    <Fragment>
      <Organization provider={provider} />
      <MetadataSettings setting="enableInsurance">
        <InsuranceDocuments provider={provider} refresh={refresh} />
      </MetadataSettings>
      <Entities provider={provider} />
    </Fragment>
  )
}
