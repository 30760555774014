import { Box } from 'components/Box'
import { Icon } from 'components/Icon'
import { InputGroup } from 'components/InputGroup'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'components/Modal'
import { ModalForm } from 'components/Modals/ModalForm'
import { Text } from 'components/Text'
import { TextArea } from 'components/Textarea'
import { rejectUpload } from 'lib/api'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineDocument } from 'react-icons/hi'
import { toast } from 'react-toastify'
import { Document } from 'types'

type Props = ModalProps & {
  onDocumentModalClose: () => void
  document: Document | null
  reload?: () => void
}
type FormValues = {
  reason: string
}

export function RejectUploadedDocumentModal({ isOpen, onClose, document, reload, onDocumentModalClose }: Props) {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      reason: '',
    },
  })

  const { reason } = watch()

  if (!document) {
    return null
  }

  const onSubmit: SubmitHandler<FormValues> = async ({ reason }) => {
    if (!reason.length) {
      return
    }

    setLoading(true)
    try {
      const { document: rejectedDocument } = await rejectUpload({
        documentId: document.id,
        documentName: document.type?.name,
        uploadLinkToken: document.uploadLinkToken,
        reason,
      })

      if (!rejectedDocument) {
        toast.error('There was a problem rejecting the document')
        return
      }

      toast.info('Document was rejected. A notification has been sent to the driver.')
      reload?.()
      onClose()
      onDocumentModalClose()
    } catch {
      toast.error('There was a problem rejecting the document')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalForm onSubmit={handleSubmit(onSubmit)} className="max-w-lg">
        <ModalHeader icon={<Icon icon={HiOutlineDocument} color="danger" />} title="Reject Document" variant="danger" />
        <ModalBody>
          <Box className="-mx-2 space-y-4 overflow-auto px-2 pb-2">
            <Text weight="normal">
              Please provide a clear reason for rejection. Once submitted, the driver will be notified and receive a new
              link to upload a revised document.
            </Text>
            <InputGroup>
              <TextArea
                rows={4}
                maxLength={200}
                {...register('reason')}
                placeholder="Enter a rejection reason (max 255 chars.)"
              />
            </InputGroup>
          </Box>
        </ModalBody>
        <ModalFooter isLoading={loading} isDisabled={!reason} submitText="Reject" onClose={onClose} variant="danger" />
      </ModalForm>
    </Modal>
  )
}
