export const DATA_ELEMENTS = [
  {
    code: 'TAC',
    name: 'Titling jurisdiction',
    description: 'The code for the jurisdiction (U.S., Canadian, or Mexican) that titled the vehicle.',
  },

  {
    code: 'TAA',
    name: 'Title number',
    description:
      'A unique set of alphanumeric characters assigned by the titling jurisdiction to the certificate of title of a vehicle.',
  },

  {
    code: 'TAV',
    name: 'Title issue date',
    description:
      "The date the jurisdiction's titling authority issued a title to the owner of the vehicle. The format is CCYYMMDD.",
  },

  {
    code: 'VAL',
    name: 'Vehicle model year',
    description: 'The year that is assigned to a vehicle by the manufacturer. The format is CCYY.',
  },

  {
    code: 'VAK',
    name: 'Vehicle make',
    description: 'The distinctive (coded) name applied to a group of vehicles by a manufacturer.',
  },
  {
    code: 'VAD',
    name: 'Vehicle identification number (VIN)',
    description:
      'A unique combination of alphanumeric characters that identifies a specific vehicle or component. The VIN is affixed to the vehicle in  specific locations and formulated by the manufacturer. State agencies under some controlled instances may assign a VIN to a vehicle.',
  },

  {
    code: 'TAF',
    name: 'Odometer reading—mileage',
    description:
      'This is the odometer reading registered with the DMV either at the time of titling or registration renewal.',
  },

  {
    code: 'TAU',
    name: 'Vehicle purchase date',
    description: 'The date a vehicle was purchased by the current owner. The format is CCYYMMDD.',
  },

  {
    code: 'NAA',
    name: 'Family name',
    description: 'Family name (commonly called surname or last name) of the owner of the vehicle.',
  },

  {
    code: 'NAE',
    name: 'Given name',
    description:
      'Given name or names (includes all of what are commonly referred to as first and middle names) of the owner of the vehicle.',
  },

  { code: 'NAR', name: 'Address-street', description: "Street portion of the owner's address." },

  { code: 'NAT', name: 'Address-city', description: "City portion of the owner's address." },

  { code: 'NAU', name: 'Address-jurisdiction code', description: "Jurisdiction portion of the owner's address." },

  { code: 'NAV', name: 'Address-zip code', description: "The ZIP code or Postal code portion of the owner's address." },

  {
    code: 'TAG',
    name: 'Odometer disclosure',
    description:
      'This is the federal odometer mileage disclosure. The mandatory information is: (1) Actual vehicle mileage; (2) Mileage exceeds mechanical limitations; (3) Not actual mileage; (4) Mileage disclosure not required.',
  },

  {
    code: 'TPJ',
    name: 'Previous titling jurisdiction',
    description:
      'The code for the jurisdiction (U.S., Canadian, or Mexican) that titled the vehicle immediately prior to the current titling jurisdiction.',
  },

  {
    code: 'TAZ',
    name: 'Previous title number',
    description: 'The title number assigned to the vehicle by the previous titling jurisdiction.',
  },

  { code: 'TAY', name: 'Title brand', description: 'Code providing information about the brand applied to the title.' },

  {
    code: 'VAO',
    name: 'Vehicle body style',
    description:
      'The general configuration or shape of a vehicle distinguished by characteristics such as number of doors, seats, windows, roofline, and type of top. The vehicle body type is 2-character alphanumeric.',
  },

  {
    code: 'TAH',
    name: 'Odometer date',
    description: 'The date the odometer reading was recorded by the jurisdiction.',
  },

  {
    code: 'TAW',
    name: 'New / used indicator',
    description:
      "This code represents whether the vehicle/vessel is new or used. Note: jurisdictions' definitions of these classifications may vary a little due to state regulations on demo vehicles, slates between dealers, application of state taxes, etc. N = New, U = Used.",
  },

  { code: 'LAA', name: 'First lien holder name', description: 'Name of the first lien holder of the vehicle.' },

  {
    code: 'LAF',
    name: 'First lien holder ID',
    description: 'A code that uniquely identifies the first holder of a lien.',
  },

  {
    code: 'VAM',
    name: 'Vehicle model',
    description:
      'A code denoting a family of vehicles (within a make), which has a degree of similarity in construction, such as body, chassis, etc. The field does not necessarily contain a standard code; it may contain a value provided by the originator of the field.',
  },

  {
    code: 'TAI',
    name: 'Odometer reading—kilometers',
    description:
      'This is the odometer reading registered with the DMV either at the time of titling or registration renewal in kilometers.',
  },

  { code: 'BBC', name: 'Business Name', description: 'The name of business that owns the vehicle.' },

  {
    code: 'VBD',
    name: 'Vehicle color',
    description:
      'Where the vehicle/vessel is one color, this is the appropriate code describing that color. When the vehicle is two colors, this is the code for the top-most or front-most color.',
  },

  {
    code: 'RBB',
    name: 'Registration issue',

    description: 'The date in which the registration was issued. Format is CCYYMMDD.',
  },

  {
    code: 'RAG',
    name: 'Registration expiry',

    description: 'The date in which the registration expired. Format is CCYYMMDD.',
  },

  {
    code: 'RAM',
    name: 'Registration plate number',
    description:
      'The characters assigned to a registration plate or tag affixed to the vehicle, assigned by the jurisdiction.',
  },

  {
    code: 'RBD',
    name: 'Registrant family name',
    description: 'Family name (commonly called surname or last name) of the registered owner of a vehicle.',
  },

  {
    code: 'RBE',
    name: 'Registrant given name',
    description:
      'Given name or names (includes all of what are commonly referred to as first and middle names) of the registered owner of a vehicle.',
  },

  { code: 'RBI', name: 'Address-street', description: "Street portion of the owner's address." },

  { code: 'RBK', name: 'Address-city', description: "City portion of the owner's address." },

  { code: 'RBL', name: 'Address-jurisdiction code', description: "Jurisdiction portion of the owner's address." },

  {
    code: 'RBM',
    name: 'Address-zip code',
    description: "The Zip code or Postal code of the vehicle owner's residence address.",
  },

  {
    code: 'VAD',
    name: 'Vehicle identification number (VIN)',
    description:
      'A unique combination of alphanumeric characters that identifies a specific vehicle or component. The VIN is affixed to the vehicle in specific locations and formulated by the manufacturer. State agencies under some controlled instances my assign a VIN to a vehicle.',
  },

  {
    code: 'VAK',
    name: 'Vehicle make',
    description: 'The distinctive (coded) name applied to a group of vehicles by a manufacturer.',
  },

  {
    code: 'VAL',
    name: 'Vehicle model year',
    description: 'The year which is assigned to a vehicle by the manufacturer. The format is CCYY.',
  },

  {
    code: 'VAO',
    name: 'Vehicle body style',
    description:
      'The general configuration or shape of a vehicle distinguished by characteristics such as number of doors, seats, windows, roofline, and type of top. The vehicle body type is 2-character alphanumeric.',
  },

  { code: 'RBT', name: 'Registration year', description: 'The year of registration. Format is CCYYMMDD.' },

  {
    code: 'RBU',
    name: 'Registration window sticker decal',
    description: 'A unique number printed on the tab/decal and stored as part of the registration record.',
  },

  { code: 'VPC', name: 'Vehicle use', description: 'Indicates the use of the vehicle.' },

  {
    code: 'FUL',
    name: 'Fuel',
    description: 'The type of fuel used by the vehicle. In most cases, the fuel type would be diesel.',
  },

  {
    code: 'VBC',
    name: 'Axles',
    description: 'The number of common axles of rotation of one or more wheels of a vehicle,',
  },

  {
    code: 'VAT',
    name: 'Gross vehicle weight',
    description:
      'The unladen weight of the vehicle (e.g., the single-unit truck, truck combination) plus the weight of the load being carried at a specific point in time.',
  },

  {
    code: 'VAM',
    name: 'Vehicle model',
    description:
      'A code denoting a family of vehicles (within a make), which has a degree of similarity in construction, such as body, chassis, etc. The field does not necessarily contain a standard code; it may contain a value provided by the originator of the field.',
  },

  { code: 'BBC', name: 'Business Name', description: 'The business name of the first registrant of a vehicle.' },

  {
    code: 'VBD',
    name: 'Vehicle color',
    description:
      'Where the vehicle is one color, this is the appropriate code describing that color. When the vehicle is two colors, this is the code for the top-most or front-most color.',
  },

  {
    code: 'MAN',
    name: 'USDOT number',
    description:
      'A unique identifier assigned to the carrier responsible for safety issued by the U.S. Department of Transportation – Federal Motor Carrier Safety Administration.',
  },

  {
    code: 'MAA',
    name: 'Carrier name',
    description:
      'The name of the carrier responsible for safety. This can be an individual, partnership or corporation responsible for the transportation of persons or property. This is the name that is recognized by law.',
  },

  {
    code: 'MAK',
    name: 'Street address',
    description:
      'This is the mailing address of the individual carrier. This information is utilized by the base jurisdiction to send information to the carrier that purchased the IRP credentials.',
  },

  {
    code: 'MAL',
    name: 'City',
    description:
      'This is the city for the mailing address of the individual carrier. This information is utilized by the base jurisdiction to send information to the carrier that purchased the IRP credentials.',
  },

  {
    code: 'MAI',
    name: 'Jurisdiction',
    description:
      'This is the jurisdiction of the residential address of the individual carrier. This information is utilized by the base jurisdiction to send information to the carrier that purchased the IRP credentials.',
  },

  { code: 'MAO', name: 'Zip', description: 'The ZIP or Postal code of the resident address of the vehicle owner.' },

  {
    code: 'RBC',
    name: 'Carrier name-registrant',
    description:
      "The name of the first registrant of a vehicle. Registrant's name may be a combined individual name or the name of a business",
  },

  { code: 'RBI', name: 'Address', description: "The first line of the registrant's residence address." },

  { code: 'RBK', name: 'City', description: "The registrant's residence city name." },

  { code: 'RBL', name: 'Jurisdiction', description: "The state or province of the registrant's residence address." },

  { code: 'RBM', name: 'Zip code', description: 'The ZIP or Postal code of the resident address of the registrant.' },

  {
    code: 'IEG',
    name: 'Unit number',
    description:
      "A number, assigned by the registrant of the commercial vehicle or trailer, to identify the vehicle or trailer in the fleet. No two units in a fleet can have the same number. A.K.A vehicle unit number or owner's equipment number.",
  },

  {
    code: 'VAD',
    name: 'Vehicle identification number (VIN)',
    description:
      'A unique combination of alphanumeric characters that identifies a specific vehicle or component. The VIN is affixed to the vehicle in specific locations and formulated by the manufacturer. State agencies under some controlled instances may assign a VIN to a vehicle.',
  },

  {
    code: 'VAL',
    name: 'Model year',
    description: 'The year which is assigned to a vehicle by the manufacturer. The format is YY.',
  },

  {
    code: 'VAK',
    name: 'Vehicle make',
    description: 'The distinctive (coded) name applied to a group of vehicles by a manufacturer.',
  },

  {
    code: 'VBB',
    name: 'Type of vehicle',
    description:
      'The type of vehicle operated for the transportation of persons or property in the furtherance of any commercial or industrial enterprise, for hire or not for hire. Not all states will use all values.',
  },

  {
    code: 'RAP/VBC',
    name: 'Number of seats/axles',
    description:
      'The seat capacity of a commercial vehicle designed for transportation of more than then passengers. The number of common axles of rotation of one or more wheels of a vehicle, whether power design or freely rotating.',
  },

  {
    code: 'RBT',
    name: 'Registration year',
    description: 'This field is the registration year assigned by the jurisdiction. The format is CCYY.',
  },

  {
    code: 'IFJ',
    name: 'Registration issue date',
    description: 'The date in which the registration was issued. CCYYMMDD format.',
  },

  {
    code: 'RAM',
    name: 'Registration plate number',
    description:
      'The characters assigned to a registration plate or tag affixed to the vehicle, assigned by the jurisdiction.',
  },

  {
    code: 'RAD',
    name: 'Registration decal number',
    description: 'The number assigned to the registration decal in those jurisdictions that issue decals.',
  },

  {
    code: 'RAF',
    name: 'Registration enforcement date',
    description:
      'The registration enforcement date is the date that the current registration was enforced. This may or may not be the original registration date. The date format is CCYYMMDD.',
  },

  {
    code: 'RAG',
    name: 'Registration expiration date',
    description: 'The date in which the registration expired. The date format is CCYYMMDD.',
  },

  {
    code: 'VAT',
    name: 'Gross vehicle weight',
    description:
      'The unladen weight of the vehicle (e.g., single-unit truck, truck combination) plus the weight of the maximum load for which vehicle registration fees have been paid within a particular jurisdiction.',
  },

  {
    code: 'RAU',
    name: 'Base jurisdiction registered weight',
    description: 'The declared base jurisdiction registration weight.',
  },

  {
    code: 'IIN',
    name: 'Inspector identification number',
    description: 'A unique number assigned to each licensed vehicle inspector.',
  },

  {
    code: 'VAK',
    name: 'Vehicle make',
    description: 'The distinctive (coded) name applied to a group of vehicles by a manufacturer.',
  },

  {
    code: 'VAL',
    name: 'Vehicle model year',
    description: 'The year which is assigned to a vehicle by the manufacturer. The format is CCYY.',
  },

  {
    code: 'VAO',
    name: 'Vehicle body type',
    description:
      'The general configuration or shape of a vehicle distinguished by characteristics such as number of doors, seats, windows, roofline, and type of top. The vehicle body type is 2-character alphanumeric.',
  },

  {
    code: 'ORI',
    name: 'Odometer reading at inspection',
    description: "The vehicle's odometer reading (to the nearest mile or kilometer) at the time of inspection.",
  },

  {
    code: 'IAN',
    name: 'Inspection address',
    description: 'The street name and number, city, state and zip code of the inspection facility.',
  },

  {
    code: 'IPD',
    name: 'Inspection air pollution device conditions',
    description: 'Identifies whether the pollution control devices meet the minimum inspection criteria.',
  },

  {
    code: 'IFI',
    name: 'Inspection facility identifier',
    description: 'The unique number assigned to an inspection facility.',
  },

  {
    code: 'INC',
    name: 'Inspection form number, current',
    description: 'A unique number assigned to a current vehicle inspection form for identification purposes.',
  },

  {
    code: 'INP',
    name: 'Inspection form number, previous',
    description: 'The number of the last inspection form excluding the current inspection.',
  },

  {
    code: 'ISC',
    name: 'Inspection smog certificate indicator',
    description: 'An indicator that specifies whether or not the vehicle has a current smog certificate.',
  },

  {
    code: 'INC',
    name: 'Inspection sticker number, current',
    description:
      'Preprinted unique number on the motor vehicle inspection sticker currently issued to a motor vehicle which has passed inspection.',
  },

  {
    code: 'INP',
    name: 'Inspection sticker number, previous',
    description: 'The certification number of the last inspection sticker, excluding the current inspection.',
  },

  {
    code: 'NAA',
    name: 'First owner total name',
    description:
      'Name of the (or one of the) individual(s) who owns the Vehicle as defined in the ANSI D-20 Data Element Dictionary. (Lastname@Firstname@MI@Suffix if any.)',
  },

  {
    code: 'NAB',
    name: 'First owner last name',
    description:
      'Last Name or Surname of the Owner. Hyphenated names acceptable, spaces between names acceptable, but no other use of special symbols.',
  },

  {
    code: 'NAC',
    name: 'First owner name',
    description:
      'First Name or Given Name of the Owner. Hyphenated names acceptable, but no other use of special symbols.',
  },

  {
    code: 'NAD',
    name: 'First owner middle name',
    description:
      'Middle Name(s) or Initial(s) of the Owner. Hyphenated names acceptable, spaces, between names acceptable, but no other use of special symbols.',
  },

  {
    code: 'NAE',
    name: 'Second owner total name',
    description:
      'Name of the (or one of the) individual(s) who owns the Vehicle as defined in the ANSI D-20 Data Element Dictionary. (Lastname@Firstname@MI@Suffix if any.)',
  },

  {
    code: 'NAF',
    name: 'Second owner last name',
    description:
      'Last Name or Surname of the Owner. Hyphenated names acceptable, spaces between names acceptable, but no other use of special symbols.',
  },

  {
    code: 'NAG',
    name: 'Second owner name',
    description:
      'First Name or Given Name of the Owner. Hyphenated names acceptable, but no other use of special symbols.',
  },

  {
    code: 'NAH',
    name: 'Second owner middle name',
    description:
      'Middle Name(s) or Initial(s) of the Owner. Hyphenated names acceptable, spaces between names acceptable, but no other use of special symbols.',
  },

  { code: 'NAR', name: 'Mailing address 1', description: 'Street address line 1. (Mailing)' },

  { code: 'NAS', name: 'Mailing address 2', description: 'Street address line 2. (Mailing)' },

  { code: 'NAT', name: 'Mailing city', description: 'Name of city for mailing address.' },

  {
    code: 'NAU',
    name: 'Mailing jurisdiction code',
    description:
      'Jurisdiction code for mailing address. Conforms to Canadian, Mexican and US jurisdictions as appropriate. Codes for provinces (Canada) and states (US and Mexico).',
  },

  {
    code: 'NAV',
    name: 'Mailing zip code',
    description: 'The ZIP code or Postal code used for mailing. (As used by Canadian, Mexican and US jurisdictions.)',
  },

  { code: 'NAM', name: 'Residence address 1', description: 'Street address line 1. (Mailing)' },

  { code: 'NAN', name: 'Residence address 2', description: 'Street address line 2. (Mailing)' },

  { code: 'NAO', name: 'Residence city', description: 'Name of city for mailing address.' },

  {
    code: 'NAP',
    name: 'Residence jurisdiction code',
    description:
      'Jurisdiction code for mailing address. Conforms to Canadian, Mexican and US jurisdictions as appropriate. Codes for pro',
  },

  {
    code: 'NAQ',
    name: 'Residence zip code',
    description: 'The ZIP code or Postal code used for mailing. (As used by Canadian, Mexican and US jurisdictions).',
  },

  {
    code: 'NAX',
    name: 'First owner ID number',
    description: 'The unique customer number/ID of the first vehicle owner.',
  },

  {
    code: 'NAY',
    name: 'Second owner ID number',
    description: 'The unique customer number/ID of the second vehicle owner.',
  },

  {
    code: 'NBA',
    name: 'First owner legal status',
    description:
      'The legal status of the first vehicle owner. This is only used when a vehicle has multiple owners. A legal status may be (“AND”, “OR”).',
  },

  {
    code: 'NBB',
    name: 'Second owner legal status',
    description:
      'The legal status of the second vehicle owner. This is only used when a vehicle has multiple owners. A legal status may be (“AND”, “OR”).',
  },

  { code: 'VAA', name: 'Major code', description: 'State to provide definition.' },

  { code: 'VAB', name: 'Minor code', description: 'State to provide definition.' },

  { code: 'VAC', name: 'Transmission code', description: 'Type of transmission the vehicle carries.' },

  {
    code: 'VAD',
    name: 'Vehicle identification number',
    description:
      'A unique combination of alphanumeric characters that identifies a specific vehicle or component. The VIN is affixed to the vehicle in specific locations and formulated by the manufacturer. State agencies under some controlled instances may assign a VIN to a vehicle.',
  },

  {
    code: 'VAE',
    name: 'MSRP/FLP',
    description: "Manufacturer's Suggested Retail Price. No decimal places. Right Justified Zero or space fill.",
  },

  { code: 'VAF', name: 'Junked indicator', description: 'Vehicle has been junked.' },

  { code: 'VAG', name: 'Date junked', description: 'CCYYMMDD; Date vehicle reported junked.' },

  { code: 'VAH', name: 'Stolen indicator', description: 'Indicates stolen vehicle.' },

  { code: 'VAI', name: 'Date stolen', description: 'CCYYMMDD; Date vehicle reported stolen.' },

  { code: 'VAJ', name: 'Date recovered', description: 'CCYYMMDD; Date vehicle reported recovered.' },

  {
    code: 'VAK',
    name: 'Vehicle make',
    description: 'The distinctive (coded) name applied to a group of vehicles by a manufacturer.',
  },

  { code: 'VAL', name: 'Make Year', description: 'Vehicle manufacture year.' },

  { code: 'VAM', name: 'Vehicle model', description: 'Vehicle manufacture model.' },

  { code: 'VAN', name: 'Fuel type', description: 'Type of fuel the vehicle utilizes.' },

  { code: 'VAO', name: 'Body style', description: 'Vehicle manufacture body style.' },

  { code: 'VAP', name: 'Number of doors', description: 'Number of doors on the vehicle.' },

  { code: 'VAQ', name: 'Number of cylinders', description: 'Number of cylinders the vehicle has.' },

  { code: 'VAR', name: 'Engine size', description: "The size of a vehicle's engine." },

  {
    code: 'VAS',
    name: 'Vehicle status code',
    description: 'This is the status of the vehicle (e.g., active, suspend, etc.)',
  },

  { code: 'VAT', name: 'Manufacture gross weight', description: "Manufacturer's gross vehicle weight rating." },

  { code: 'VAU', name: 'Horsepower', description: "Manufacturer's rated horsepower." },

  { code: 'VAV', name: 'Unladen weight', description: 'Gross weight of the vehicle unloaded.' },

  { code: 'VAW', name: 'Engine displacement', description: "Manufacturer's rated engine displacement." },

  { code: 'VAX', name: 'IRP indicator', description: 'International registration plan indicator.' },

  { code: 'VAY', name: 'IFTA indicator', description: 'International fuel tax indicator' },

  { code: 'VAZ', name: 'VLT clac from date', description: 'Vehicle license tax calculation from date of purchase.' },

  { code: 'VBA', name: 'Vehicle ID number', description: 'Unique number to identify the vehicle record.' },

  { code: 'VBB', name: 'Vehicle type code', description: 'EPA vehicle classification.' },

  { code: 'VBC', name: 'Number of Axles', description: 'Number of axles the vehicle has.' },
].reduce((obj, c) => {
  obj[c.code] = c
  return obj
}, {})
