import { Redirect } from 'components/Redirect'
import { useAuth } from 'hooks/useAuth'
import { Fragment, ReactNode } from 'react'

export type RequireRoleProps = {
  allow?: string | string[]
  deny?: string | string[]
  authRedirect?: string
  override?: boolean
  children: ReactNode | ReactNode[]
}

export const RequireRole = (props: RequireRoleProps) => {
  const { account } = useAuth()

  if (!account) {
    return null
  }

  const { allow = [], deny = [], override = false, authRedirect, children } = props

  if (override) {
    return <Fragment>{children}</Fragment>
  }

  const allowedRoles = ([] as string[]).concat(allow)
  const deniedRoles = ([] as string[]).concat(deny)

  const isExplicitlyAllowed = account.roles.some(role => allowedRoles.includes(role))
  const isExplicitlyDenied = account.roles.some(role => deniedRoles.includes(role))

  if (isExplicitlyDenied || (allowedRoles.length > 0 && !isExplicitlyAllowed)) {
    if (authRedirect) {
      return <Redirect href={authRedirect} />
    }

    return null
  }

  return <Fragment>{children}</Fragment>
}
