/* eslint-disable camelcase */
type WindowWithIntercom = Window &
  typeof globalThis & {
    Intercom: (...args: any[]) => void
    intercomSettings: any
    attachEvent: (event: string, listener: EventListener) => void
  }

const INTERCOM_APP_ID = 'qkmm1ssb'
const INTERCOM_API_BASE = 'https://api-iam.intercom.io'

// Code snippet from intercom's setup guide
export const load = () => {
  ;(function () {
    var w = window as WindowWithIntercom
    var ic = w.Intercom

    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i: any = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}

export const boot = (options = {}) => {
  window &&
    (window as WindowWithIntercom).Intercom &&
    (window as WindowWithIntercom).Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      api_base: INTERCOM_API_BASE,
      ...options,
    })
}

export const shutdown = () => {
  window && (window as WindowWithIntercom).Intercom && (window as WindowWithIntercom).Intercom('shutdown')
}
