import { useDrivers } from 'hooks/useDrivers'
import { useTranslation } from 'react-i18next'
import { HiOutlineUser } from 'react-icons/hi'
import { NavLink } from '../NavLink'

export function VolunteerDriverNavLink() {
  const { t } = useTranslation()
  const { drivers = [] } = useDrivers({ page: 1, limit: 1 })
  const driver = drivers?.[0]
  if (!driver) {
    return null
  }

  return (
    <NavLink href={`/providers/drivers/${driver.id}`} icon={HiOutlineUser}>
      {t('driver')}
    </NavLink>
  )
}
