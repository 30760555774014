import classNames from 'classnames'
import { fontSize, fontWeight, textVariant } from 'components/constants'
import { FontSize, FontWeight, TextVariant } from 'components/constants/types'
import { DetailedHTMLProps, DetailsHTMLAttributes } from 'react'

type Props = DetailedHTMLProps<DetailsHTMLAttributes<HTMLDetailsElement>, HTMLDetailsElement> & {
  size?: FontSize
  variant?: TextVariant
  weight?: FontWeight
}

export function DescriptionDetails({ children, className, size, variant, weight }: Props) {
  const sizeClass = size ? fontSize[size] : fontSize.sm
  const weightClass = weight ? fontWeight[weight] : fontWeight.normal
  const variantClasses = variant ? textVariant[variant] : textVariant.extradark

  return <dd className={classNames(sizeClass, weightClass, variantClasses, className)}>{children}</dd>
}
