import { AnalyticsContext } from 'contexts/AnalyticsProvider'
import { useContext } from 'react'

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
