import { useLazyQuery } from '@apollo/client'
import { QueryDocument, QueryDocumentWithBlocks } from 'internal/graphql/query/document'
import { useEffect } from 'react'
import { Document } from 'types/Document'

export const useDocument = (id: string | null, blocks = false) => {
  const [fetchDocument, { data, loading, error, refetch }] = useLazyQuery<{
    document: Document
  }>(blocks ? QueryDocumentWithBlocks : QueryDocument, {
    variables: { id },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!id) return

    fetchDocument({ variables: { id } })
  }, [id, fetchDocument])

  return {
    document: id && data?.document,
    loading,
    error,
    refetch,
  }
}
