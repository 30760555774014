import { handleNetworkOverview, loadCounties } from 'internal/redux/map/actions'
import { store } from 'internal/redux/store'
import { County, Zip } from 'internal/types/map'
import { ServiceArea } from 'types'
import { MIN_COUNTY_ZOOM, MIN_ZIPCODES_ZOOM } from './constants'
import { LAYERS } from './layers'
import { Mode, ModeKey } from './types'
import { getCountyFeatures, getZipsFeatures } from './utilities'

export const MODES: Record<ModeKey, Mode> = {
  providerCounties: [
    {
      id: 'serviceArea',
      featureData: () => {
        return null
      },
      featureState: {
        id: 'code',
        key: 'serviceType',
      },
      source: {
        id: 'serviceArea',
        highlight: false,
        config: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
      layers: [LAYERS.serviceAreaFill, LAYERS.serviceAreaOutline],
    },
    {
      id: 'counties',
      mapMove: ({ bounds, zoom }) => {
        if (zoom >= MIN_COUNTY_ZOOM) {
          store.dispatch(loadCounties({ bounds }))
        }
      },
      featureData: ({ counties }: { counties: County[] }) => {
        return {
          type: 'FeatureCollection',
          features: getCountyFeatures(counties),
        }
      },
      source: {
        id: 'counties',
        highlight: true,
        config: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
      layers: [LAYERS.countiesFill],
    },
  ],
  providerZipcodes: [
    {
      id: 'zipcodes',
      featureData: ({ zips, serviceArea }: { zips: Zip[]; serviceArea?: ServiceArea[] }) => {
        const features = getZipsFeatures(zips, serviceArea) || []

        return {
          type: 'FeatureCollection',
          features,
        }
      },
      featureState: {
        id: 'code',
        key: 'serviceType',
      },
      source: {
        id: 'zipcodes',
        highlight: true,
        config: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
      layers: [LAYERS.zipcodesFill, LAYERS.zipcodesOutline],
    },
  ],
  networkOverview: [
    {
      id: 'zipcodes',
      mapMove: ({ bounds, zoom }) => {
        const state = store.getState()
        if (zoom >= MIN_ZIPCODES_ZOOM && !state.map.selectedZip) {
          store.dispatch(handleNetworkOverview({ bounds }))
        }
      },
      featureState: {
        id: 'code',
        key: 'serviceType',
      },
      featureData: ({
        prevZips = [],
        zips,
        serviceArea,
        selectedZipCode,
      }: {
        prevZips: any
        zips: Zip[]
        serviceArea?: ServiceArea[]
        selectedZipCode?: string | null
      }) => {
        const prevZipsSet = new Set(prevZips.map(z => z.properties?.code))
        const zipsSet = new Set(zips.map(obj => obj.postal_code))
        const removedZipCodes = prevZips.reduce((set, z) => {
          if (!zipsSet.has(z.properties?.code)) {
            set.add(z.properties?.code)
          }
          return set
        }, new Set())

        const filteredCurrentZips = prevZips.filter(z => !removedZipCodes.has(z.properties?.code))
        const newZips = zips.filter(obj => !prevZipsSet.has(obj.postal_code))

        if (zipsSet.has(selectedZipCode)) {
          const zipIndex = newZips.findIndex(z => z.postal_code === selectedZipCode)
          if (zipIndex > -1) {
            newZips[zipIndex].selected = true
          }
        }

        const newFeatures = getZipsFeatures(newZips, serviceArea) || []

        return {
          type: 'FeatureCollection',
          features: [...filteredCurrentZips, ...newFeatures],
        }
      },
      source: {
        id: 'zipcodes',
        highlight: true,
        config: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
      layers: [LAYERS.networkZipcodesFill, LAYERS.networkZipcodesOutline, LAYERS.zipcodesLabel],
    },
  ],
  networkProviderOverview: [
    {
      id: 'serviceArea',
      featureData: () => {
        return null
      },
      featureState: {
        id: 'code',
        key: 'serviceType',
      },
      source: {
        id: 'serviceArea',
        highlight: true,
        config: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
      layers: [LAYERS.networkServiceAreaFill, LAYERS.serviceAreaOutline],
    },
  ],
}
