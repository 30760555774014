import { FilePreview } from 'components/File/FilePreview'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'components/Modal'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAuth } from 'hooks/useAuth'
import { WorkspaceUploadLink } from 'internal/graphql/query/workspaceUploadLink'
import { selectActiveWorkspace, selectEntity } from 'internal/redux'
import { uploadFile } from 'lib/api'
import { StartDocumentUpload, completeDocumentUpload, startDocumentUpload, submitDocument } from 'lib/api/workspace'
import { useSelector } from 'react-redux'
import { Requirement } from 'types/Requirement'
import { WorkspaceDocument } from 'types/Workspace'
import { InputGroup } from '../InputGroup/InputGroup'
import { UploadSection } from '../UploadSection/UploadSection'

type Props = ModalProps & {
  requirement: Requirement
  workspaceUploadLink?: WorkspaceUploadLink
  afterUpload?: (requirementId: string) => void
}

export function NewWorkspaceDocumentModal({ isOpen, onClose, requirement, workspaceUploadLink, afterUpload }: Props) {
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const workspaceEntity = useSelector(selectEntity)

  const { account } = useAuth()
  const [isUploading, setIsUploading] = useState(false)
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])

  useEffect(() => {
    if (!isOpen) {
      setFilesToUpload([])
      setIsUploading(false)
    }
  }, [isOpen])

  if (!requirement) return null

  const canUpload = !isUploading && filesToUpload.length > 0

  const handleError = (_error: any, file: File) => {
    toast.error(`${file.name} file is corrupt. Please check the file extension or retrieve another copy.`)
    setFilesToUpload([])
  }

  const handleCancel = () => {
    setFilesToUpload([])
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!canUpload) return

    setIsUploading(true)

    for (const file of filesToUpload) {
      const submitToWorkspaceId = requirement.workspaceId || activeWorkspace.id
      const startUploadPayload: StartDocumentUpload = {
        mimeType: file.type,
        name: file.name,
        ownerId: workspaceUploadLink ? workspaceUploadLink?.entityId : workspaceEntity?.id,
        uploaderId: workspaceUploadLink ? workspaceUploadLink?.creatorId : account?.id,
        uploadLinkToken: workspaceUploadLink?.token ?? undefined,
        workspaceId: submitToWorkspaceId,
      }

      const { uploadUrl, uploadToken } = await startDocumentUpload(startUploadPayload).then(r => r.json())

      if (!uploadUrl || !uploadToken) {
        toast.error(`Failed to upload ${file.name}`)
        return
      }

      const uploadRes = await uploadFile(uploadUrl, file)
      if (!uploadRes.ok) {
        toast.error(`Failed to upload ${file.name}`)
        return
      }

      const { workspaceDocument }: { workspaceDocument: WorkspaceDocument } = await completeDocumentUpload({
        uploadToken,
      }).then(r => r.json())

      if (!workspaceDocument) {
        toast.error(`Failed to upload ${file.name}`)
        return
      }

      const res = await submitDocument({
        workspaceDocumentId: workspaceDocument.id,
        submissions: {
          [submitToWorkspaceId]: requirement.id,
        },
      })

      if (!res.ok) {
        toast.error(`Something went wrong, please try again later`)
        return
      }

      afterUpload && afterUpload(requirement.id)

      toast.success(`Uploaded ${file.name}`)
    }

    setIsUploading(false)
    onClose()
  }

  const handleFiles = (files: File[]) => setFilesToUpload(files)

  const entityType = workspaceUploadLink
    ? workspaceUploadLink?.entity?.attributes?.type
    : workspaceEntity?.attributes?.type
  const entityName = workspaceUploadLink ? workspaceUploadLink?.entity?.name : workspaceEntity?.name

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form className="flex h-full w-screen max-w-3xl flex-col overflow-hidden" onSubmit={handleSubmit}>
        <ModalHeader title="Upload a Document" />
        <ModalBody>
          <div className="-mx-4 space-y-6 overflow-auto px-4 capitalize sm:px-6">
            <InputGroup id="ownerName" label={entityType} value={entityName} disabled readOnly />
            <div>
              <label htmlFor="documentType" className="block text-sm font-medium text-gray-700">
                {requirement.name}
              </label>
              <div className="mt-1">
                {filesToUpload.length ? (
                  <FilePreview file={filesToUpload[0]} onError={handleError} onCancel={handleCancel} />
                ) : (
                  <UploadSection
                    id="file"
                    onFiles={handleFiles}
                    // accept={convertToAccept(requiredDocument?.allowedMimeTypes)}
                    full
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter isDisabled={!canUpload} isLoading={isUploading} onClose={onClose} />
      </form>
    </Modal>
  )
}
