import { useQuery } from '@apollo/client'
import { QueryRejectionReasons } from 'internal/graphql/query/rejectionReasons'
import { RejectionReason } from 'types/RejectionReason'

export const useRejectionReasons = (documentType: string) => {
  const { data, loading, refetch } = useQuery(QueryRejectionReasons, { variables: { documentType } })
  const { rejectionReasons = [] }: { rejectionReasons: RejectionReason[] } = data || {}

  return { rejectionReasons, loading, refetch }
}
