import { gql } from '@apollo/client'

export const QueryProviders = gql`
  query Providers(
    $page: Int!
    $limit: Int = 50
    $marketId: String
    $sort: String
    $query: String
    $suspended: Boolean
  ) {
    providers(page: $page, limit: $limit, marketId: $marketId, sort: $sort, query: $query, suspended: $suspended) {
      total
      results {
        id
        documentGroupId
        workspaceId
        npi
        medicaidProviderId
        tlcBaseNumber
        name
        dba
        addressStreet
        addressStreet2
        city
        state
        zip
        primaryContactId
        primaryContactFirstName
        primaryContactLastName
        primaryContactEmail
        primaryContactPhone
        suspendedAt
        suspendedReason

        checks(latest: true) {
          id
          name
          result
          createdAt
        }

        market {
          id
          name
        }

        documentGroup {
          name
        }

        medicaidProviderListing {
          medicaidProviderId
          npi
          providerOrFacilityName
          medicaidType
          professionOrService
          providerSpecialty
          serviceAddress
          city
          state
          zipCode
          county
          telephone
          latitude
          longitude
          enrollmentBeginDate
          nextAnticipatedRevalidationDate
          fileDate
          medicallyFragileChildrenDirectoryInd
          providerEmail
        }

        primaryContact {
          id
          firstName
          middleName
          lastName
          email
          phone
          lastLoginAt
        }
      }
    }
  }
`

export const QueryProvidersList = gql`
  query ProvidersList($ids: [String!], $query: String, $limit: Int = 25) {
    providers(ids: $ids, query: $query, limit: $limit) {
      total
      results {
        id
        name

        market {
          name
        }

        documentGroup {
          name
        }
      }
    }
  }
`

export const QueryProvidersMeta = gql`
  query ProvidersMeta($page: Int!, $limit: Int = 50, $marketId: String, $zipCodes: [String!]) {
    providers(page: $page, limit: $limit, marketId: $marketId, zipCodes: $zipCodes) {
      total
      results {
        id
        documentGroupId
        marketId
        npi
        medicaidProviderId
        tlcBaseNumber
        name
        dba
        addressStreet
        addressStreet2
        city
        state
        zip
        primaryContactFirstName
        primaryContactLastName
        primaryContactEmail
        primaryContactPhone

        market {
          name
        }

        documentGroup {
          name
        }

        customAttributes

        tripCapacity {
          id
          levelOfService
          capacity
          hourMax
          hourMaxWeekend

          serviceLevel {
            code
            name
          }
        }
      }
    }
  }
`

export const QueryProviderZipCodes = gql`
  query ProviderZipCodes($providerId: String!, $levelOfService: Int!, $zipCodes: [String!]!) {
    providerZipCodes(providerId: $providerId, levelOfService: $levelOfService, zipCodes: $zipCodes) {
      providerId
      zipCode
      order
      pinned
    }
  }
`

export const QueryProvidersAreaOfService = gql`
  query ProvidersAreaOfService($zipCode: String!, $levelOfService: Int!) {
    providersAreaOfService(zipCode: $zipCode, levelOfService: $levelOfService) {
      zipCode
      levelOfService
      providerId
      order

      provider {
        id
        name
        documentGroup {
          name
        }

        customAttributes
        tripCapacity {
          id
          levelOfService
          capacity
          hourMax
          hourMaxWeekend
        }
      }
    }
  }
`

export const QueryProvider = gql`
  query Provider($id: String!) {
    provider(id: $id) {
      id
      documentGroupId
      npi
      medicaidProviderId
      tlcBaseNumber
      name
      dba
      addressStreet
      addressStreet2
      city
      state
      zip
      primaryContactId
      primaryContactFirstName
      primaryContactLastName
      primaryContactEmail
      primaryContactPhone
      suspendedAt
      suspendedReason
      isAcceptingSameDayTrips

      traits(keys: ["checkupSkippedAt", "lastCheckupAt"])

      documentGroup {
        name
      }

      serviceArea {
        postalCode
        serviceType

        zipData {
          postalCode
          countyName
        }
      }

      operatingHours {
        day
        open
        close
      }

      operatingHoursOverrides {
        id
        providerId
        opens
        closes
        validFrom
        validThrough
        note
        dayOfWeek
        createdAt
        updatedAt
      }

      tripCapacity {
        id
        levelOfService
        capacity
        hourMax
        hourMaxWeekend

        serviceLevel {
          code
          name
        }
      }

      customAttributes

      attributes
      metadata {
        name
        enableInsurance
        enableDrivers
        enableVehicles
        enableTeams
        createDefaultDriver
        createDefaultVehicle
        limitDrivers
        limitVehicles
      }

      market {
        id
        name
      }

      medicaidProviderListing {
        medicaidProviderId
        npi
        providerOrFacilityName
        medicaidType
        professionOrService
        providerSpecialty
        serviceAddress
        city
        state
        zipCode
        county
        telephone
        latitude
        longitude
        enrollmentBeginDate
        nextAnticipatedRevalidationDate
        fileDate
        medicallyFragileChildrenDirectoryInd
        providerEmail
      }

      primaryContact {
        id
        firstName
        middleName
        lastName
        email
        phone
        lastLoginAt
      }

      requiredDocuments {
        name
        primary {
          id
          status
        }
        pending {
          id
          status
        }
      }
    }
  }
`

export const QueryProviderAccounts = gql`
  query Provider($id: String!) {
    provider(id: $id) {
      id
      npi
      medicaidProviderId
      tlcBaseNumber
      name
      dba
      addressStreet
      addressStreet2
      city
      state
      zip
      primaryContactId
      primaryContactFirstName
      primaryContactLastName
      primaryContactEmail
      primaryContactPhone
      isAcceptingSameDayTrips

      traits

      documentGroup {
        name
      }

      serviceArea {
        postalCode
        serviceType

        zipData {
          postalCode
          countyName
        }
      }

      operatingHours {
        day
        open
        close
      }

      operatingHoursOverrides {
        id
        providerId
        opens
        closes
        validFrom
        validThrough
        note
        dayOfWeek
        createdAt
        updatedAt
      }

      tripCapacity {
        id
        levelOfService
        capacity
        hourMax
        hourMaxWeekend

        serviceLevel {
          code
          name
        }
      }

      tripCapacityOverrides {
        id
        providerId
        serviceLevelCode
        days
        start
        end
        hourMax

        serviceLevel {
          name
        }
      }

      customAttributes

      accounts {
        id
        firstName
        lastName
        email
        createdAt
        lastLoginAt
      }

      contacts {
        id
        firstName
        middleName
        lastName
        phone
        email
        title

        methods {
          type
          phone
          extension
          email
        }
      }

      invites {
        id
        firstName
        lastName
        email
        createdAt
      }

      market {
        id
        name
      }
    }
  }
`

export const QueryProviderDetails = gql`
  query ProviderDetails($id: String!) {
    provider(id: $id) {
      id
      documentGroupId
      npi
      medicaidProviderId
      tlcBaseNumber
      name
      dba
      addressStreet
      addressStreet2
      city
      state
      zip
      primaryContactId
      primaryContactFirstName
      primaryContactLastName
      primaryContactEmail
      primaryContactPhone

      accounts {
        id
        firstName
        lastName
        email
        createdAt
        lastLoginAt
      }

      invites {
        id
        firstName
        lastName
        email
        createdAt
      }

      contacts {
        id
        firstName
        middleName
        lastName
        phone
        email
        title

        methods {
          type
          phone
          extension
          email
        }
      }

      documentGroup {
        name
      }

      serviceArea {
        postalCode
        serviceType

        zipData {
          postalCode
          countyName
          stateCode
        }
      }

      operatingHours {
        day
        open
        close
      }

      operatingHoursOverrides {
        id
        providerId
        opens
        closes
        validFrom
        validThrough
        note
        dayOfWeek
        createdAt
        updatedAt
      }

      tripCapacity {
        id
        levelOfService
        capacity
        hourMax
        hourMaxWeekend

        serviceLevel {
          code
          name
        }
      }

      tripCapacityOverrides {
        id
        providerId
        serviceLevelCode
        days
        start
        end
        hourMax

        serviceLevel {
          name
        }
      }

      attributes
      customAttributes

      metadata {
        enableDrivers
        enableVehicles
        enableInsurance
        createDefaultDriver
      }

      market {
        id
        name
      }

      medicaidProviderListing {
        medicaidProviderId
        npi
        providerOrFacilityName
        medicaidType
        professionOrService
        providerSpecialty
        serviceAddress
        city
        state
        zipCode
        county
        telephone
        latitude
        longitude
        enrollmentBeginDate
        nextAnticipatedRevalidationDate
        fileDate
        medicallyFragileChildrenDirectoryInd
        providerEmail
      }

      primaryContact {
        id
        firstName
        middleName
        lastName
        email
        phone
        lastLoginAt
      }

      checks(latest: true) {
        id
        name
        result
        details
        createdAt
      }

      suspendedAt
      suspendedReason

      requiredDocuments {
        name
        documentType
        requireLevel
        canReplaceOnExpire
        canAttest
        canExempt
        allowedMimeTypes

        documentRequests {
          id
          documentType
          dueAt
          createdAt
          updatedAt
        }

        primary {
          id
          documentType
          originalFilename
          status
          statusReason
          expiresAt
          createdAt

          type {
            name
            canAttest
          }

          decision {
            createdAt
          }

          attestation {
            documentId
          }
        }

        pending {
          id
          documentType
          originalFilename
          status
          statusReason
          expiresAt
          createdAt

          type {
            name
          }
        }
      }
    }
  }
`

export const QueryProviderRoster = gql`
  query ProviderRoster($id: String!) {
    provider(id: $id) {
      id

      drivers {
        id
        firstName
        middleName
        lastName
        phone
        email
        streetAddress
        streetAddress2
        city
        state
        zip
        hackNumber
        hackExpires
        driversLicenseNumber
        driversLicenseExpires
        isActive
        createdAt
        updatedAt

        requiredDocuments {
          documentType
          primary {
            id
            expiresAt
            status
            statusReason
          }
          pending {
            id
            expiresAt
            status
            statusReason
          }
        }
      }

      vehicles {
        id
        vin
        year
        make
        plate
        isActive
        statusReason
        createdAt
        updatedAt

        requiredDocuments {
          documentType
          primary {
            id
            expiresAt
            status
            statusReason
          }
        }
      }
    }
  }
`

export const QueryMedicaidProviders = gql`
  query MedicaidProviders($ids: [String!]!) {
    medicaidProviders(ids: $ids) {
      medicaidProviderId
      npi
      providerOrFacilityName
      medicaidType
      professionOrService
      providerSpecialty
      serviceAddress
      city
      state
      zipCode
      county
      telephone
      latitude
      longitude
      enrollmentBeginDate
      nextAnticipatedRevalidationDate
      fileDate
      medicallyFragileChildrenDirectoryInd
      providerEmail
    }
  }
`

export const QueryProviderAttributeGroups = gql`
  query {
    providerAttributeGroups {
      id
      name
      enableInsurance
      enableDrivers
      enableVehicles
      enableTeams
      createDefaultDriver
      createDefaultVehicle
      limitDrivers
      limitVehicles
    }
  }
`

export const QueryProviderServiceArea = gql`
  query ProviderServiceArea($id: String!) {
    provider(id: $id) {
      id
      zip
      serviceArea {
        id
        postalCode
        serviceType
        createdAt
        updatedAt

        zipData {
          countyName
          countyCode
          placeName
          stateCode
        }
      }
    }
  }
`

export const QueryAvailableProviders = gql`
  query AvailableProviders($pickupZip: String!, $dropoffZip: String!, $levelOfService: Int!) {
    availableProviders(pickupZip: $pickupZip, dropoffZip: $dropoffZip, levelOfService: $levelOfService) {
      total
      results {
        id
        name
        market {
          name
        }
        isAcceptingSameDayTrips
        primaryContactEmail
        primaryContactPhone
        customAttributes
        tripCapacity {
          id
          levelOfService
          capacity
          hourMax
          hourMaxWeekend
        }
      }
    }
  }
`
