import { IconButton } from 'components/Buttons/IconButton'
import { ReactNode } from 'react'
import { HiX } from 'react-icons/hi'

type PanelHeaderProps = {
  title: ReactNode
  actions?: ReactNode
  onClose: () => void
}

export const PanelHeader = ({ actions, title, onClose }: PanelHeaderProps) => {
  return (
    <div className="dark:border-dark-500 dark:bg-dark-700 relative border-b border-gray-200 bg-white">
      <div className={'relative z-10 mx-auto flex h-16 items-center justify-between overflow-visible px-6'}>
        <div className="mr-3 font-medium dark:text-white">{title}</div>
        <div className="ml-4 flex items-center">
          {actions}
          <IconButton
            className="rounded-full px-1.5 py-1.5"
            variant="ghost"
            ariaLabel="Close"
            icon={HiX}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}
