import { Listbox } from '@headlessui/react'
import { default as cx } from 'classnames'
import { Badge } from 'components/Badge'
import { Button } from 'components/Button/Button'
import { ListBox } from 'components/ListBox'
import { Text } from 'components/Text'
import { STATUS_OPTIONS, StatusOption } from 'constants/document'
import { HiCheck, HiOutlineSelector } from 'react-icons/hi'

type DocumentStatusFilterProps = {
  label?: string
  variant?: string
  className?: string
  options?: any[]
  disabled?: boolean
  value: StatusOption
  onChange: (o: StatusOption) => void
}

export function DocumentStatusFilter(props: DocumentStatusFilterProps) {
  return (
    <ListBox
      disabled={props.disabled}
      label={props.label}
      onChange={props.onChange}
      options={props.options || STATUS_OPTIONS}
      optionsClassName="w-60"
      value={props.value}
      variant={props.variant}
      button={
        <Listbox.Button as={Button} rightIcon={HiOutlineSelector} className="flex w-full justify-between">
          <div className="flex items-center">
            {props.label !== null && (
              <Badge color="white" size="xs" padding="sm" className="mr-2" border={false}>
                Status
              </Badge>
            )}
            <span
              className={cx(props.value?.classNames.indicator, 'inline-block h-2 w-2 flex-shrink-0 rounded-full')}
            />
            <span className="ml-3 block truncate">{props.value?.label}</span>
          </div>
        </Listbox.Button>
      }
      renderOption={option => (
        <Listbox.Option
          key={option.id}
          className={({ active }) =>
            cx(active && 'bg-blue-600', 'relative cursor-default select-none border border-transparent py-2 pl-4 pr-9')
          }
          value={option}
        >
          {({ selected, active }) => (
            <>
              <div className="flex items-center">
                <span
                  className={cx(option.classNames.indicator, 'inline-block h-2 w-2 flex-shrink-0 rounded-full')}
                  aria-hidden="true"
                />
                <Text
                  size="sm"
                  className="ml-3 truncate"
                  weight={selected ? 'semibold' : 'normal'}
                  variant={active ? 'white' : 'dark'}
                >
                  {option.label}
                </Text>
              </div>

              {selected ? (
                <span
                  className={cx(
                    active ? 'text-white' : 'text-blue-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  )}
                >
                  <HiCheck className="h-5 w-5" aria-hidden="true" />
                </span>
              ) : null}
            </>
          )}
        </Listbox.Option>
      )}
    />
  )
}
