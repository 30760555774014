import { useContext } from 'react'
import { RemoteConfigContext } from '../contexts/RemoteConfigProvider'

export type RemoteConfig = {
  ShowSameDayTripsOptIn?: boolean
  ShowMarkAsCorrectButton?: boolean
  ExcludedTripRequestTPs?: string[]
  EnableOrganizationRemoveMember?: boolean
  EnableResyncAuthorization?: boolean
  ShowInviteDriverButton?: boolean
  EnableHolidayOperatingHours?: boolean
  EnableIgnoreFailedWebhooksButton?: boolean
  EnableNewDocumentsTable?: boolean
  EnableChat?: boolean
  EnableProviderPerformance?: boolean
  ShowProviderComplianceChecks?: boolean
  ProviderAreaOfService?: boolean
  ExclusionChecksInAuthorization?: boolean
  EnableIssuesExperience?: boolean
}

export const useRemoteConfig = (): RemoteConfig => useContext(RemoteConfigContext)
