export const decodeVins = (vins: string[]) => {
  return fetch('/api/vehicles/decode-vins', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ vins }),
  }).then(r => {
    if (!r.ok) {
      return []
    }

    return r.json().then(r => r?.results || [])
  })
}
