import { useIsDarkMode } from 'hooks/useIsDarkMode'
import { SpinnerCircular } from 'spinners-react'
import { blue, slate } from 'tailwindcss/colors'

type LoaderProps = {
  size?: number
}

export const Loader = ({ size }: LoaderProps) => {
  const dimensions = size ? `${size}rem` : '5rem'
  const isDarkMode = useIsDarkMode()

  return (
    <div className="flex h-full w-full items-center justify-center">
      <SpinnerCircular
        style={{ width: dimensions, height: dimensions }}
        color={isDarkMode ? blue['400'] : blue['900']}
        secondaryColor={isDarkMode ? slate['600'] : slate['100']}
      />
    </div>
  )
}
