export const ExampleChecks = [
  {
    createdAt: 1656129911737,
    details: '{"lastName": "DRISCOLL", "firstName": "ALBERT"}',
    id: '7c365ba2-d6dd-4a2f-99f6-1112f71f0520',
    name: 'CheckDriverOIG',
    result: 'pass',
  },

  {
    createdAt: 1656129910042,
    details: '{"lastName": "DRISCOLL", "firstName": "ALBERT"}',
    id: '907a080d-7317-4926-be99-b91711d13d15',
    name: 'CheckDriverOMIG',
    result: 'pass',
  },

  {
    createdAt: 1656129911261,
    details: '{"lastName": "DRISCOLL", "firstName": "ALBERT"}',
    id: 'e26b1411-278b-4cf2-9735-0b7fc1eb599b',
    name: 'CheckDriverSAM',
    result: 'pass',
  },
]
