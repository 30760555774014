import { useLazyQuery } from '@apollo/client'
import { QueryAllDocumentGroups, QueryAllRequiredDocuments, QueryRequiredFields } from 'internal/graphql/query/document'
import { useEffect, useMemo } from 'react'
import {
  RequiredDocumentGroupWithType,
  RequiredDriverDocument,
  RequiredProviderDocument,
  RequiredVehicleDocument,
} from 'types/Document'
import { Market } from 'types/Market'
import { useAuth } from './useAuth'

type WithOwner<T> = T & {
  owner: string
}
export type RequiredDriverDocumentWithOwner = WithOwner<RequiredDriverDocument>
export type RequiredProviderDocumentWithOwner = WithOwner<RequiredProviderDocument>
export type RequiredVehicleDocumentWithOwner = WithOwner<RequiredVehicleDocument>
export type RequiredDocumentWithOwner =
  | RequiredDriverDocumentWithOwner
  | RequiredProviderDocumentWithOwner
  | RequiredVehicleDocumentWithOwner
export type RequiredDocuments = RequiredDocumentWithOwner[]

type RequiredDocumentsQueryResult = {
  requiredDriverDocuments: RequiredDriverDocument[]
  requiredProviderDocuments: RequiredProviderDocument[]
  requiredVehicleDocuments: RequiredVehicleDocument[]
}

export const useRequiredDocuments = (market?: Market) => {
  const { account } = useAuth()

  const [loadAll, { data, loading, refetch }] = useLazyQuery<RequiredDocumentsQueryResult>(QueryAllRequiredDocuments, {
    fetchPolicy: 'no-cache',
  })
  const [loadAllGroups, { data: groupData, refetch: groupRefetch, loading: groupsLoading }] = useLazyQuery(
    QueryAllDocumentGroups,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [loadRequiredFields, { data: fieldsData, loading: requiredFieldsLoading, refetch: fieldsRefetch }] =
    useLazyQuery(QueryRequiredFields, { fetchPolicy: 'no-cache', variables: { marketId: market?.id } })

  const { driverGroups = [], vehicleGroups = [], providerGroups = [] } = groupData || {}
  const { requiredDriverDocuments = [], requiredProviderDocuments = [], requiredVehicleDocuments = [] } = data || {}

  // Turns [ { documentType, fields } ] into { [documentType]: { [field]: true } }
  const requiredFields = fieldsData
    ? fieldsData.requiredFields.reduce(
        (sections, section) =>
          Object.assign(sections, {
            [section.documentType]: section.fields.reduce(
              (fields, field) => Object.assign(fields, { [field.split(':')[0]]: field.split(':')[1] || '1' }),
              {},
            ),
          }),
        {},
      )
    : []

  const allRequiredDocuments: RequiredDocuments = data
    ? [].concat(
        requiredDriverDocuments.map(d => ({ ...d, owner: 'driver' })) as WithOwner<RequiredDriverDocument>[],
        requiredProviderDocuments.map(d => ({ ...d, owner: 'provider' })) as WithOwner<RequiredProviderDocument>[],
        requiredVehicleDocuments.map(d => ({ ...d, owner: 'vehicle' })) as WithOwner<RequiredVehicleDocument>[],
      )
    : []

  const filteredDriverGroups = useMemo(() => {
    return driverGroups
      .filter(g => !market || g.marketId === market.id)
      .map(g => ({ ...g, type: 'driver' })) as RequiredDocumentGroupWithType[]
  }, [driverGroups, market])

  const filteredVehicleGroups = useMemo(() => {
    return vehicleGroups
      .filter(g => !market || g.marketId === market.id)
      .map(g => ({ ...g, type: 'vehicle' })) as RequiredDocumentGroupWithType[]
  }, [vehicleGroups, market])

  const filteredProviderGroups = useMemo(() => {
    return providerGroups
      .filter(g => !market || g.marketId === market.id)
      .map(g => ({ ...g, type: 'provider' })) as RequiredDocumentGroupWithType[]
  }, [providerGroups, market])

  const allRequiredDriverDocuments = allRequiredDocuments.filter(d => d.owner === 'driver')
  const allRequiredProviderDocuments = allRequiredDocuments.filter(d => d.owner === 'provider')
  const allRequiredVehicleDocuments = allRequiredDocuments.filter(d => d.owner === 'vehicle')

  useEffect(() => {
    if (!account) {
      return
    }
    loadAll()
    loadAllGroups()
    loadRequiredFields()
  }, [account, loadAllGroups, loadRequiredFields, loadAll])

  return {
    allRequiredDocuments,
    loading: loading || groupsLoading || requiredFieldsLoading,
    requiredDriverDocuments: allRequiredDriverDocuments,
    requiredProviderDocuments: allRequiredProviderDocuments,
    requiredVehicleDocuments: allRequiredVehicleDocuments,
    driverGroups: filteredDriverGroups,
    vehicleGroups: filteredVehicleGroups,
    providerGroups: filteredProviderGroups,
    requiredFields,
    requiredFieldsLoading,
    reload: refetch,
    reloadFields: fieldsRefetch,
    reloadGroups: groupRefetch,
  }
}
