import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client } from 'internal/graphql/client'
import { QueryPacket } from 'internal/graphql/query/packet'
import { Packet } from 'types/Workspace'

type IniitalState = {
  packet: Packet | null
  loading: boolean
}

const initialState: IniitalState = {
  packet: null,
  loading: true,
}

export const packetSlice = createSlice({
  name: 'packet',
  initialState,
  reducers: {
    setPacket: (state, action: PayloadAction<Packet | null>) => {
      state.packet = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPacket.pending, state => {
      state.loading = true
    })
    builder.addCase(loadPacket.fulfilled, (state, action) => {
      state.packet = action.payload
      state.loading = false
    })
    builder.addCase(loadPacket.rejected, state => {
      state.loading = false
    })
  },
})

export const loadPacket = createAsyncThunk('packet/load', async (id: string) => {
  const res = await client.query<{ packet: Packet }>({ query: QueryPacket, variables: { id } })

  const packet = res.data?.packet
  return packet
})

export const { setPacket } = packetSlice.actions
