import { cn } from '@/lib/utils'
import { Portal, Transition } from '@headlessui/react'
import { Placement } from '@popperjs/core'
import { Fragment, ReactNode, useRef, useState } from 'react'
import { Modifier, usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'
import { Box } from './Box'
import { Text } from './Text'

interface Props {
  className?: string
  labelClassName?: string
  placement?: Placement
  modifiers?: Modifier<any>[]
  children: ReactNode
  label?: any
}

export function Tooltip({ className, labelClassName, placement, modifiers, children, label }: Props) {
  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placement || 'bottom',
    modifiers,
  })

  if (!label) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <Fragment>
      <Box
        className={twMerge('min-w-fit cursor-pointer', className)}
        onMouseEnter={() => setPopperElement(popperElRef.current)}
        onMouseLeave={() => setPopperElement(null)}
        ref={setTargetElement}
      >
        {children}
      </Box>

      <Portal>
        <div ref={popperElRef} style={{ ...styles.popper, pointerEvents: 'none', zIndex: 9999 }} {...attributes.popper}>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={!!popperElement}
          >
            <Box className="dark:border-dark-600 z-10 origin-top rounded-md bg-black/80 px-2 py-1.5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:shadow-white/10">
              <Box className={cn('w-full', labelClassName || 'max-w-40')}>
                <Text size="xs" variant="white">
                  {label}
                </Text>
              </Box>
            </Box>
          </Transition>
        </div>
      </Portal>
    </Fragment>
  )
}
