import classNames from 'classnames'
import { Badge } from 'components/Badge'
import { Dot } from 'components/Dot'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useAnalytics } from 'hooks/useAnalytics'
import { useSidebarContext } from 'hooks/useSidebarContext'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { formatShortNumber } from '../../util/format'
import { NavStat } from './NavStat'

type NavLinkProps = {
  href: string
  icon?: any
  group?: boolean
  stat?: number
  dot?: boolean
  external?: boolean
  badge?: { title: string; color?: string }
  statVariant?: string
  children: any
  level?: number
}

export const NavLink = ({
  href,
  icon: PropsIcon,
  external,
  group,
  stat,
  badge,
  dot,
  children,
  level,
}: NavLinkProps) => {
  const analytics = useAnalytics()
  const router = useRouter()
  const { theme, isCollapsed, expanded, toggleExpanded } = useSidebarContext()
  const isActive = router.pathname === href

  const onLinkClick = () => {
    if (expanded) {
      toggleExpanded()
    }
    analytics.track('NavLink - Click', { href })
  }

  if (isCollapsed && level) return null

  return (
    <Tooltip placement="right" label={isCollapsed ? getChildText(children) : ''}>
      <Link
        href={href}
        className={classNames(
          'group relative mx-4 mb-1 flex flex-1 items-center justify-center whitespace-nowrap rounded-md text-sm outline-0 focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-700',
          theme.navLinkPadding,
          isActive ? (group ? theme.navLinkActive : theme.navGroupActive) : group ? theme.navLink : theme.navGroup,
          level && 'h-8 bg-transparent py-0 pl-11 font-normal',
        )}
        target={href.startsWith('http') ? '_blank' : undefined}
        rel={href.startsWith('http') ? '_blank' : 'noreferrer'}
        onClick={onLinkClick}
        data-place="right"
      >
        {/* {level && <div className="absolute left-0 h-1/2 w-px border-l border-white">&nbsp;</div>} */}
        {!level && PropsIcon && (
          <PropsIcon
            className={classNames(
              isActive ? theme.navIconActive : theme.navIcon,
              group && `mr-4`,
              isCollapsed ? 'h-6 w-6' : 'mr-3 h-5 w-5',
            )}
          />
        )}
        {!isCollapsed && (
          <div className="flex flex-1 items-center justify-between">
            <Text size="sm" weight="inherit" variant="inherit">
              {children}
            </Text>
            {stat > 0 && <NavStat>{formatShortNumber(stat)}</NavStat>}
            {badge && (
              <Badge size="xs" padding="xs" color={(badge.color || 'blue') as any}>
                {badge.title}
              </Badge>
            )}
            {dot && <Dot />}
            {external && <Icon icon={HiOutlineExternalLink} />}
          </div>
        )}
      </Link>
    </Tooltip>
  )
}

function getChildText(children: any) {
  return Array.isArray(children) && children.length > 1
    ? children[0].props?.children
    : children.props?.children || children
}
