import { useQuery } from '@apollo/client'
import { QueryWorkspaceDocument } from 'internal/graphql/query/workspaceDocument'
import { WorkspaceDocument } from 'types/Workspace'

export const useWorkspaceDocument = (id: string | null) => {
  const { data, loading, error, refetch } = useQuery<{ workspaceDocument: WorkspaceDocument }>(QueryWorkspaceDocument, {
    variables: { id },
    fetchPolicy: 'no-cache',
    skip: !id,
  })

  const document = data?.workspaceDocument || null

  return {
    document,
    loading,
    error,
    refetch,
  }
}
