import { Submission } from 'types/Workspace'

import { Badge } from 'components/Badge'
import { Box } from 'components/Box'
import { SUBMISSION_STATUS } from './constants'

type Props = {
  submission: Submission
}

export function SubmissionStatusBadge({ submission }: Props) {
  if (!submission) {
    return (
      <Box className="flex">
        <Badge color="white" rounded="md" size="xs" padding="xs">
          Missing
        </Badge>
      </Box>
    )
  }

  if (submission.documentExemption) {
    return (
      <Box className="flex">
        <Badge border={false} dot color="purple" rounded="md" size="xs" padding="xs">
          Exempt
        </Badge>
      </Box>
    )
  }

  const submissionStatus = SUBMISSION_STATUS[submission.status]

  return (
    <Box className="flex">
      <Badge border={false} dot color={submissionStatus.color} rounded="md" size="xs" padding="xs">
        {submissionStatus.label}
      </Badge>
    </Box>
  )
}
