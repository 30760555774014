import { createSlice } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

type Dialog = {
  id: string
  content: ReactNode
}

type DialogState = {
  dialogs: Dialog[]
}

const initialState: DialogState = {
  dialogs: [],
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    addDialog(state, action) {
      const dialog = action.payload
      state.dialogs.push(dialog)
    },

    removeDialog(state, action) {
      state.dialogs = state.dialogs.filter(d => d.id !== action.payload)
    },
  },
})

export const { addDialog, removeDialog } = dialogSlice.actions
