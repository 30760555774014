import { post } from './req'

type AssignDocumentPayload = {
  documentId: string
  assigneeId: string
}

type UnassignDocumentPayload = {
  assignmentId: string
}

type DecideDocumentPayload = {
  assignmentId?: string
  documentId: string
  isApproved: boolean
  canOverride?: boolean
  overrideId?: string
  overrideReason?: string
  reason: string | null
}

type AssignAuthorizationPayload = {
  assigneeId: string
  driverId?: string
  vehicleId?: string
}

type AssignPacketPayload = {
  assigneeId: string
  driverId?: string
  vehicleId?: string
  providerId?: string
  documentIds?: string[]
}

type DecideAuthorizationPayload = {
  authorizationId: string
  comment?: string
  expiresAt?: number
  destinationCodes?: string[]
}

type UnassignAuthorizationPayload = {
  authorizationId: string
}

type UnassignAuthorizationWithReasonPayload = {
  authorizationId: string
  reason: string
  shouldRevalidate?: boolean
}

type DeauthorizePayload = {
  authorizationId: string
  reason: string
  destinationCodes?: string[]
  driverId?: string | null
  vehicleId?: string | null
  isPreviouslyCompleted?: boolean
}

type ResyncAuthorizationPayload = {
  driverId: string | null
  vehicleId: string | null
  requestId?: string
}

export const assignDocument = (data: AssignDocumentPayload) => {
  return post('/v1/documents/assign', data)
}
export const unassignDocument = (data: UnassignDocumentPayload) => {
  return post('/v1/documents/unassign', data)
}

export const decideDocument = (data: DecideDocumentPayload) => {
  return post('/v1/documents/decide', data)
}

export const assignAuthorization = (data: AssignAuthorizationPayload) => {
  return post('/v1/authorizations/assign', data)
}

export const assignPacket = (data: AssignPacketPayload) => {
  return post('/v1/authorizations/assign-packet', data)
}

export const decideAuthorization = (data: DecideAuthorizationPayload) => {
  return post('/v1/authorizations/authorize', data)
}
export const unassignAuthorization = (data: UnassignAuthorizationPayload) => {
  return post('/v1/authorizations/unassign', data)
}
export const unassignAuthorizationWithReason = (data: UnassignAuthorizationWithReasonPayload) => {
  return post('/v1/authorizations/unassign', data)
}

export const deauthorize = (data: DeauthorizePayload) => {
  return post('/v1/authorizations/deauthorize', data)
}

export const resendAuthorization = (data: ResyncAuthorizationPayload) => {
  return post('/v1/authorizations/replay', data)
}
