import { OnboardingPage } from 'components/providers/OnboardingPage'
import { useAuth } from 'hooks/useAuth'
import { useProvider } from 'hooks/useProvider'
import { QueryProviderDetails } from 'internal/graphql/query/provider'

export default function Onboarding() {
  const auth = useAuth()
  const { provider, loading, refresh } = useProvider(auth?.account?.providerId, QueryProviderDetails)

  return <OnboardingPage provider={provider} loading={loading} refresh={refresh} />
}
