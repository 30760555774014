import { RequiredDocument } from 'types'

export const hasSubmittedDocument = (rd: RequiredDocument) => Boolean(rd.pending && rd.pending?.status !== 'failed')
export const hasCompletedDocument = (rd: RequiredDocument) =>
  rd.primary?.status === 'approved' && (!rd.primary.expiresAt || rd.primary.expiresAt > Date.now())
export const parseJsonArray = (value: string | undefined): string[] => {
  try {
    return typeof value === 'string' ? JSON.parse(value) : []
  } catch (error) {
    return []
  }
}
