import { DocumentPanel } from 'components/DocumentPanel'
import { useRoles } from 'hooks/useRoles'
import { useRouter } from 'next/router'
import { DocumentModal } from './DocumentModal'

export function GlobalDocumentModal() {
  const { isSuperAdmin, isOperations } = useRoles()
  const router = useRouter()

  if (isOperations) {
    return null
  }

  if (isSuperAdmin && router.pathname.includes('admin')) {
    return <DocumentPanel />
  }

  return <DocumentModal />
}
