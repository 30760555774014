import { useCallback, useEffect, useRef, useState } from 'react'

type ViewerProps = {
  url: string
  rotation: number
  width: number
  maxHeight: number
}

export function Viewer({ url, rotation, width, maxHeight }: ViewerProps) {
  const [img, setImg] = useState<HTMLImageElement>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const load = useCallback(
    url => {
      if (!url) return setImg(null)
      if (img || !isVisible) return

      const i = new Image()
      i.crossOrigin = 'anonymous'
      i.onload = () => {
        setImg(i)
      }
      i.src = url

      return () => {
        setImg(null)
      }
    },
    [isVisible, img],
  )

  const intersectionCb = useCallback(
    entries => {
      const [entry] = entries
      if (entry.isIntersecting && maxHeight > 0) {
        setIsVisible(true)
        if (!img) load(url)
      } else {
        setIsVisible(false)
      }
    },
    [url, maxHeight, img, load],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCb, { threshold: 0 })
    if (canvasRef.current) observer.observe(canvasRef.current)
    const cur = canvasRef.current
    return () => {
      if (cur) observer.unobserve(cur)
    }
  }, [canvasRef, intersectionCb])

  useEffect(() => {
    if (!img) return

    const canvas = canvasRef.current
    if (!canvas) return
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = img.height * (canvas.width / img.width)

    ctx.fillRect(0, 0, canvas.width, canvas.height)

    if (Math.abs(rotation) === 180) {
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(-rotation * (Math.PI / 180))
      ctx.translate(-canvas.width / 2, -canvas.height / 2)
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    } else if (Math.abs(rotation) === 90) {
      canvas.width = img.height
      canvas.height = img.width
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(-rotation * (Math.PI / 180))
      ctx.translate(-canvas.height / 2, -canvas.width / 2)
      ctx.drawImage(img, 0, 0, img.width, img.height)
    } else {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    }
  }, [img, width, rotation])

  return <canvas ref={canvasRef} className="mx-auto w-full max-w-7xl" />
}
