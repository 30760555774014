import classNames from 'classnames'
import { Badge } from 'components/Badge'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { DecisionOverrideModal } from 'components/Modals/DecisionOverrideModal'
import { useAuth } from 'hooks/useAuth'
import { useShowDialog } from 'hooks/useDialog'
import { useDocument } from 'hooks/useDocument'
import { useRequiredDocuments } from 'hooks/useRequiredDocuments'
import { useToggle } from 'hooks/useToggle'
import { selectGlobalActiveDocumentId, setGlobalActiveDocumentId } from 'internal/redux'
import * as dates from 'internal/util/dates'
import { admin } from 'lib/api'
import { analyzeDocument, resetDocument } from 'lib/api/admin'
import { archiveDocument } from 'lib/api/document'
import { decideDocument } from 'lib/api/manager'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { HiChevronDown } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useKeyPressEvent } from 'react-use'
import { FieldMetadata } from 'types/Document'
import { sleep } from 'util/sleep'
import { DocumentFields } from './DocumentFields'
import { DocumentPagesProps } from './DocumentPages'
import { PanelActions } from './PanelActions'
import { PanelHeader } from './PanelHeader'

const DocumentPages = dynamic<DocumentPagesProps>(() => import('./DocumentPages').then(r => r.DocumentPages), {
  ssr: false,
})

export type DocumentPanelProps = {
  onClose?: () => void
}

export const DocumentPanel = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [metadata, setMetadata] = useState<Record<string, FieldMetadata>>({})
  const [submitting, toggleSubmitting] = useToggle(false)
  const [isOverriding, setIsOverriding] = useState(false)
  const [detected, setDetected] = useState<any>(null)

  const viewRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const auth = useAuth()
  const id = useSelector(selectGlobalActiveDocumentId)

  const { document: doc, loading, refetch } = useDocument(id, true)
  const { requiredFields } = useRequiredDocuments(doc?.provider?.market)
  const blocks = doc?.extractedData?.blocks?.filter(b => b.Text && b.BlockType === 'WORD')
  const isOpen = !!id
  const isReadOnly = !auth.account.roles.includes('superadmin')
  const isAnalyzing = !doc?.extractedData?.createdAt

  const { show: confirmReset } = useShowDialog({
    title: 'Confirm Reset',
    msg: 'This will reset the document. Are you sure?',
  })

  const onClose = () => {
    dispatch(setGlobalActiveDocumentId(null))
    setDetected(null)

    if (router.pathname.startsWith('/admin/documents/')) {
      let route = window.sessionStorage.getItem('prevRoute') || '/admin/documents'
      router.push(route)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const updates: any = {
      documentType: doc.documentType,
      extractedData: cloneDeep(doc.extractedData || {}),
      expiresAt: doc.documentType === 'SocialSecurityCard' ? '' : undefined,
    }

    if (submitting) return

    toggleSubmitting(true)

    const formFields = Array.from(formRef.current.elements).filter(e =>
      ['INPUT', 'TEXTAREA', 'SELECT'].includes(e.tagName),
    )
    formFields.forEach((e: any) =>
      set(updates.extractedData, e.name || e.id, e.type === 'checkbox' ? (e.checked ? '1' : '') : e.value),
    )

    updates.id = doc.id

    const status = updates.extractedData.status
    delete updates.extractedData.status

    const statusReason = updates.extractedData.statusReason
    delete updates.extractedData.statusReason

    if (updates.extractedData.quality) {
      updates.extractedData.quality = parseInt(updates.extractedData.quality, 10)
    }

    // Data has been parsed, status and statusReason are independent variables since document status
    // changes are a separate action from update the document extraction data.

    if (['approved', 'analyzed', 'waiting'].includes(status) && !formRef.current.checkValidity()) {
      formRef.current.reportValidity()
      toggleSubmitting(false)
      return
    }

    if (status === 'rejected' && !statusReason) {
      toast.error('Rejected documents must have a reason')
      toggleSubmitting(false)
      return
    }

    const acceptableStatus = ['approved', 'analyzed', 'waiting'].includes(status)
    if (acceptableStatus && !updates.extractedData.quality) {
      toast.error('Please select the quality of the document')
      toggleSubmitting(false)
      return
    }

    delete updates.extractedData.blocks

    if (updates.extractedData.issuedAt) {
      if (!dates.validateFormat(updates.extractedData.issuedAt)) {
        toast.error('Issued must be in mm/dd/yyyy format')
        toggleSubmitting(false)
        return
      }
      updates.extractedData.issuedAt = dates.formatToNY(updates.extractedData.issuedAt)
    }

    if (updates.extractedData.expiresAt) {
      if (!dates.validateFormat(updates.extractedData.expiresAt)) {
        toast.error('Expires must be in mm/dd/yyyy format')
        toggleSubmitting(false)
        return
      }

      if (updates.extractedData.expiresAt < Date.now() && status === 'approved' && doc.status !== 'approved') {
        toast.error('cannot approve expired document')
        toggleSubmitting(false)
        return
      }

      updates.extractedData.expiresAt = dates.formatToNY(updates.extractedData.expiresAt)
    }

    const updatedMetadata = { ...metadata }
    for (const key in updates.extractedData) {
      if (updatedMetadata[key]) {
        updatedMetadata[key].text = updates.extractedData[key]?.toString()
      }
    }

    updates.extractedData.fieldMetadata = Object.values(updatedMetadata)

    admin
      .updateDocument(updates)
      .then(async r => {
        if (!r.ok) {
          toast.error(await r.json().then(e => e.error))
          return
        }

        if (doc.status === status) {
          toast.success('Changes saved')
          return
        }

        if (['approved', 'rejected'].includes(status) && doc.status !== status) {
          const decideRes = await decideDocument({
            documentId: doc.id,
            isApproved: status === 'approved',
            reason: statusReason,
            canOverride: updates.extractedData?.rejectionReasonCanOverride === '1',
          })

          if (!decideRes.ok) {
            const err = await decideRes.json().then(e => e.error)
            toast.error(`Error: ${err}`)
            return
          }
        } else if (status === 'archived' && doc.status !== status) {
          const archiveRes = await archiveDocument(doc.id)

          if (!archiveRes.ok) {
            const err = await archiveRes.json().then(e => e.error)
            toast.error(`Error: ${err}`)
            return
          }
        } else {
          const updateRes = await admin.updateDocumentStatus({
            id: doc.id,
            status,
            statusReason,
          })
          if (!updateRes.ok) {
            const err = await updateRes.json().then(e => e.error)
            toast.error(`Error: ${err}`)
            return
          }
        }

        await sleep(1000)

        toast.success('Changes saved')
      })
      .catch(() => {
        toast.error('There was an error')
      })
      .finally(async () => {
        await sleep(500)
        toggleSubmitting(false)
        refetch()
      })
  }

  useEffect(() => {
    if (doc) {
      setMetadata(
        doc.extractedData?.fieldMetadata?.reduce((obj, cur) => {
          obj[cur.key] = cur
          return obj
        }, {}) || {},
      )
    }
    toggleSubmitting(false)
  }, [doc, toggleSubmitting])

  useKeyPressEvent('Escape', () => {
    onClose()
  })

  return (
    <div>
      {isOpen && !loading && doc && (
        <div
          className={classNames([
            'dark:border-dark-500 dark:bg-dark-700 fixed inset-0 z-50 flex h-screen w-screen flex-col border-l border-gray-200 bg-white',
            !isOpen && 'pointer-events-none',
          ])}
        >
          <PanelHeader
            title={
              <div className="inline-flex items-center space-x-2">
                <h1 className="mr-2">Document</h1>
                {isAnalyzing && (
                  <Badge color="yellow" rounded="full" size="sm">
                    <AiOutlineLoading className="mr-2 h-4 w-4 animate-spin" />
                    Analyzing
                  </Badge>
                )}
              </div>
            }
            actions={
              <Box className="w-full flex-1 justify-start">
                <Dropdown
                  items={[
                    {
                      label: 'Override Decision',
                      onClick: async () => {
                        setIsOverriding(true)
                      },
                    },
                    {
                      label: 'Re-analyze',
                      onClick: async () => {
                        await analyzeDocument(doc?.id)
                      },
                    },
                    {
                      label: 'Reset',
                      onClick: async () => {
                        if (!(await confirmReset())) {
                          return
                        }
                        await resetDocument(doc.id)
                        refetch()
                      },
                    },
                  ]}
                >
                  <Button variant="ghost" rightIcon={HiChevronDown}>
                    Actions
                  </Button>
                </Dropdown>
              </Box>
            }
            onClose={onClose}
          />
          <form className="flex flex-1 flex-col overflow-hidden" onSubmit={handleSubmit} ref={formRef}>
            <div className="flex flex-1 flex-row overflow-hidden">
              <DocumentFields
                className="dark:border-dark-500 w-screen max-w-md overflow-hidden border-r border-gray-200"
                requiredFields={requiredFields}
                detected={detected}
                document={doc}
                onRefresh={async () => refetch() as any}
              />
              <div className="dark:bg-dark-900 relative h-full w-full overflow-auto bg-gray-200 py-6" ref={viewRef}>
                <DocumentPages
                  blocks={blocks || []}
                  document={doc}
                  fieldMetadata={Object.values(metadata)}
                  onChange={(fieldName, value, blocks) => {
                    setMetadata(fields => ({
                      ...fields,
                      [fieldName]: { key: fieldName, text: value, rawText: value, blocks },
                    }))
                  }}
                  onData={data => {
                    setDetected(data)
                  }}
                />
              </div>
            </div>
            <PanelActions
              isLoading={submitting}
              isSubmittable={!isReadOnly}
              onSubmit={e => {
                e.preventDefault()
                formRef.current?.dispatchEvent(new Event('submit', { bubbles: true }))
              }}
              actions={
                <div className="relative flex flex-row items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <a
                      className="btn dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200"
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents/${doc.id}/view`}
                    >
                      Open in New Tab
                    </a>
                  </div>
                </div>
              }
            />
          </form>
          <DecisionOverrideModal
            document={doc}
            isOpen={!!isOverriding}
            onClose={async () => {
              await refetch()
              setIsOverriding(false)
            }}
          />
        </div>
      )}
    </div>
  )
}
