import { Accept } from 'react-dropzone'
import { MIME_TYPES_MAP } from './constants'

export function convertToAccept(mimeTypes?: string[]): Accept {
  if (!mimeTypes?.length) return {}

  return mimeTypes?.reduce((acc, mimeType) => {
    if (!MIME_TYPES_MAP[mimeType]) return acc

    acc[mimeType] = MIME_TYPES_MAP[mimeType]

    return acc
  }, {} as Accept)
}
