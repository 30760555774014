import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'

const qualities = [
  { label: '0', value: 0, activeClassName: 'bg-gray-100 dark:bg-gray-500 text-gray-600' },
  { label: '1', value: 1, activeClassName: 'bg-red-100 dark:bg-red-500 text-red-600' },
  { label: '2', value: 2, activeClassName: 'bg-yellow-100 dark:bg-yellow-500 text-yellow-600' },
  { label: '3', value: 3, activeClassName: 'bg-green-100 dark:bg-green-500 text-green-600' },
]

export type DocumentQualityRadioProps = {
  value: number | null
  onChange: (value: number) => void
}

export const DocumentQualityRadio = ({ value, onChange }: DocumentQualityRadioProps) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className="flex items-center">
        <div className="relative flex divide-x divide-gray-200 rounded-md border border-gray-300 dark:divide-gray-500 dark:border-gray-600">
          {qualities.map(quality => (
            <RadioGroup.Option
              key={quality.value}
              value={quality.value}
              className={({ checked }) =>
                classNames(
                  checked ? quality.activeClassName : 'hover:bg-gray-100 dark:hover:bg-gray-700',
                  'relative flex cursor-pointer items-center justify-center px-4 py-1.5 font-medium focus:outline-none',
                  'first:rounded-l-md last:rounded-r-md',
                )
              }
            >
              <RadioGroup.Label as="span" className="text-center dark:text-gray-100">
                {quality.label}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </div>
    </RadioGroup>
  )
}
