import { Folders, LucideLayoutGrid } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { HiOutlineOfficeBuilding, HiOutlineUsers } from 'react-icons/hi'
import { HiOutlineClipboardDocumentList, HiOutlineCog6Tooth } from 'react-icons/hi2'
import { RiTaxiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function BrokerNav() {
  const { t } = useTranslation()

  return (
    <Nav>
      <NavLink href="/manage/dashboard" icon={LucideLayoutGrid}>
        Dashboard
      </NavLink>
      <NavGroup name={t('network')}>
        <NavLink href="/manage/providers" icon={HiOutlineOfficeBuilding}>
          {t('providers')}
        </NavLink>
        <NavLink href="/manage/drivers" icon={HiOutlineUsers}>
          {t('drivers')}
        </NavLink>
        <NavLink href="/manage/vehicles" icon={RiTaxiLine}>
          {t('vehicles')}
        </NavLink>
      </NavGroup>
      <NavGroup name="Configuration">
        <NavLink href="/requirements" icon={Folders}>
          Requirements
        </NavLink>
        <NavLink href="/manage/packets" icon={HiOutlineClipboardDocumentList}>
          Credentialing Packets
        </NavLink>
        <NavLink href="/settings" icon={HiOutlineCog6Tooth}>
          Settings
        </NavLink>
      </NavGroup>
    </Nav>
  )
}
