import { cn } from '@/lib/utils'
import { ReactNode } from 'react'

export type ModalBodyProps = {
  className?: string
  padding?: boolean
  children?: ReactNode | ReactNode[]
}

export const ModalBody = ({ children, className, padding = true }: ModalBodyProps) => {
  return (
    <div
      className={cn([
        'dark:border-dark-500 dark:bg-dark-700 flex flex-1 flex-col bg-white dark:border-x',
        padding && 'space-y-6 px-4 py-6 sm:px-6',
        'overflow-hidden',
        className,
      ])}
    >
      {children}
    </div>
  )
}
