import { useRouter } from 'next/router'
import { useEffect } from 'react'

type RedirectProps = {
  href: string
}
export function Redirect(props: RedirectProps) {
  const { href } = props
  const router = useRouter()

  useEffect(() => {
    router.replace(href)
  }, [router, href])

  return null
}
