import { gql } from '@apollo/client'

const AllExtractedFields = `
      documentId
      documentType
      issuedAt
      expiresAt

      firstName
      middleName
      lastName
      suffix

      addressStreet
      addressStreet2
      addressCity
      addressState
      addressZIP

      issuingState

      dob
      ssn
      screeningSsn

      id
      restrictions
      classification

      vin
      plate
      make
      model
      year
      color
      capacity
      odometer

      naic
      producer
      registeredParty
      additionalInsured
      
      agentName
      agentEmail
      agentPhone
      
      certificateNumber
      certificateHolder
      certificateHolderAddressStreet
      certificateHolderAddressStreet2
      certificateHolderAddressCity
      certificateHolderAddressState
      certificateHolderAddressZIP
      
      boxAdditionalInsured
      boxCommercialGeneralLiability
      boxGeneralClaimsMade
      boxGeneralOccurrence
      boxGeneralAggregateAppliesPerPolicy
      boxGeneralAggregateAppliesPerProject
      boxGeneralAggregateAppliesPerLocation
      boxAutoAnyAuto
      boxAutoAllOwnedAutos
      boxAutoHiredAutos
      boxAutoScheduledAutos
      boxAutoNonOwnedAutos
      boxAutoUmbrellaLiab
      boxAutoExcess
      boxAutoOccurrence
      boxAutoClaimsMade
      boxAutoDed
      boxAutoRetention
      boxCompensationWorkersAndEmployersLiabilityExcluded
      boxSubrogationWaived
      boxSignature
    
      policyProvider
      policyNumber
      policyLimitAmount
      policyLimitAggregate
      policyLimitSexualAbuseAndMolestation
      policyLimitProfessionalLiability
      policyLimitMalpractice
      policyLimitBodilyInjuryPerPerson
      policyLimitBodilyInjuryPerAccident
      policyLimitPropertyDamagePerAccident
      policyLimitSexualAbuseAndMolestationAggregate
      policyLimitCombinedSingleLimit
      policyLimitPerAccident
      policyLimitPerDisease
      policyLimitPerEmployee
      policyLimitPerOccurrence

      inspectionOutcome
      descriptionsBox
  
      vendorName
      inspectorName

      createdAt
      `

const AllDocumentFieldsFragment = gql`
  fragment AllDocumentFields on Document {
    createdAt
    documentType
    originalFilename
    inputDocumentType
    issuedAt
    expiresAt
    id
    isLinked
    isVirtual
    providerId
    parentId
    status
    statusReason

    type {
      name
      documentType
    }

    extractedData {
      ${AllExtractedFields}
    }

    owner {
      type
      driverId
      providerId
      vehicleId
      isPrimary

      driver {
        id
        driverNumber
        firstName
        middleName
        lastName
      }

      vehicle {
        id
        vin
      }

      provider {
        id
        name
      }
    }

    provider {
      id
      name

      market {
        id
        name
      }

      primaryContact {
        firstName
        middleName
        lastName
        email
        phone
      }
    }

    decision {
      id
      deciderType
      createdAt

      assignee {
        id
        firstName
        middleName
        lastName
        email
      }

      assigner {
        id
        firstName
        middleName
        lastName
        email
      }
    }
  }
`

export const QueryDocuments = gql`
  ${AllDocumentFieldsFragment}
  query Documents(
    $page: Int! = 1
    $status: String
    $type: String
    $types: [String!]
    $owner: String
    $virtual: Boolean
    $primary: Boolean
    $providerIds: [String!]
    $marketId: String
    $marketIds: [String!]
    $expiring: Boolean
    $tag: String
    $sort: String
  ) {
    documents(
      page: $page
      status: $status
      type: $type
      types: $types
      owner: $owner
      virtual: $virtual
      primary: $primary
      providerIds: $providerIds
      marketId: $marketId
      marketIds: $marketIds
      expiring: $expiring
      tag: $tag
      sort: $sort
    ) {
      total
      results {
        ...AllDocumentFields
      }
    }
  }
`

export const QueryDocument = gql`
  query Document($id: String!) {
    document(id: $id) {
      id
      providerId
      parentId
      uploaderId
      status
      statusReason
      mimeType
      originalFilename
      documentType
      inputDocumentType
      rotation
      issuedAt
      expiresAt
      isLinked
      uploadToken
      uploadLinkToken
      createdAt

      isExtracting

      type {
        name
        documentType
        canAttest
      }

      attestation {
        id
        generatedAt
        verifiedAt
        isVerified
        verificationDecision
        createdAt
      }

      extractedData {
        ${AllExtractedFields}
      }

      owner {
        type
        driverId
        providerId
        vehicleId
        isPrimary

        driver {
          id
          driverNumber
          firstName
          middleName
          lastName

          documentGroup {
            name
          }
        }

        vehicle {
          id
          documentGroupId
          vin

          provider {
            id
            market {
              name
            }
          }

          documentGroup {
            id
            name
          }
        }

        provider {
          id
          name

          documentGroup {
            name
          }

          attestationRequests {
            documentId
            documentAttestationId
          }
        }
      }

      successor {
        id
        status
        statusReason
        createdAt
      }

      tags {
        id
        color
        tag
      }

      provider {
        id
        name

        documentGroup {
          id
          name
        }

        primaryContact {
          firstName
          middleName
          lastName
          email
          phone
        }
      }

      decision {
        id
        assignmentId
        deciderType
        canOverride
        createdAt

        assignee {
          id
          firstName
          middleName
          lastName
          email
        }

        assigner {
          id
          firstName
          middleName
          lastName
          email
        }

        decider {
          id
          firstName
          middleName
          lastName
          email
        }
      }

      checks(latest: true) {
        id
        name
        result
        details
        createdAt
      }

      uploadLink {
        id
        driver {
          firstName
          lastName
        }
      }
    }
  }
`

export const QueryDocumentWithBlocks = gql`
  query Document($id: String!) {
    document(id: $id) {
      id
      providerId
      parentId
      uploaderId
      status
      statusReason
      mimeType
      originalFilename
      documentType
      inputDocumentType
      rotation
      issuedAt
      expiresAt
      isLinked
      createdAt

      isExtracting

      type {
        name
        documentType
        canAttest
      }

      extractedData {
        ${AllExtractedFields}

        quality
        fieldMetadata {
          key
          text
          rawText
          blocks {
            Id
            Page
            Text
            BlockType
            Geometry {
              Polygon {
                X
                Y
              }
            }
          }
        }

        blocks {
          Id
          Page
          Text
          BlockType
          Geometry {
            Polygon {
              X
              Y
            }
          }
        }
      }

      owner {
        type
        driverId
        providerId
        vehicleId
        isPrimary

        driver {
          id
          driverNumber
          firstName
          middleName
          lastName

          documentGroup {
            name
          }
        }

        vehicle {
          id
          vin

          documentGroup {
            name
          }
        }

        provider {
          id
          name

          documentGroup {
            name
          }
        }
      }

      successor {
        id
        status
        statusReason
        createdAt
      }

      tags {
        id
        color
        tag
      }

      provider {
        id
        name
        marketId

        market {
          id
          name
        }

        primaryContact {
          firstName
          middleName
          lastName
          email
          phone
        }

        documentGroup {
          id
          name
        }
      }

      assignments {
        id

        assignee {
          id
          firstName
          middleName
          lastName
          email
        }

        assigner {
          id
          firstName
          middleName
          lastName
          email
        }
      }

      decision {
        id
        canOverride

        assignee {
          id
          firstName
          middleName
          lastName
          email
        }

        assigner {
          id
          firstName
          middleName
          lastName
          email
        }
      }

      checks(latest: true) {
        id
        name
        result
        details
        createdAt
      }
    }
  }
`

export const QueryExpiringDocuments = gql`
  query {
    expiringDocuments {
      id
      providerId
      uploaderId
      status
      statusReason
      mimeType
      originalFilename
      documentType
      inputDocumentType
      issuedAt
      expiresAt
      isLinked
      isExtracting
      createdAt

      type {
        name
      }

      provider {
        id
        name
      }

      extractedData {
        ${AllExtractedFields}
      }

      owner {
        type
        driverId
        providerId
        vehicleId
        isPrimary

        driver {
          id
          firstName
          middleName
          lastName
        }

        vehicle {
          id
          vin
          plate
          make
          year
        }

        provider {
          id
          name
        }
      }
    }
  }
`

export const QueryAllRequiredDocuments = gql`
  query QueryAllRequiredDocuments($marketId: String) {
    requiredDriverDocuments(marketId: $marketId) {
      type
      documentType
      name
      allowedMimeTypes
      canReplaceOnExpire
      canAttest
      canExempt
      requireLevel
      requiredAuxiliary

      requiredAuxiliaryDocuments {
        documentType
        name
        allowedMimeTypes
        canReplaceOnExpire
        canExempt
        requireLevel
      }
    }

    requiredProviderDocuments(marketId: $marketId) {
      type
      documentType
      name
      allowedMimeTypes
      canReplaceOnExpire
      canAttest
      canExempt
      requireLevel
      requiredAuxiliary
      requiredAuxiliaryDocuments {
        documentType
        name
        allowedMimeTypes
        canReplaceOnExpire
        canExempt
        requireLevel
      }
    }

    requiredVehicleDocuments(marketId: $marketId) {
      type
      documentType
      name
      allowedMimeTypes
      canReplaceOnExpire
      canAttest
      canExempt
      requireLevel
      requiredAuxiliary
      requiredAuxiliaryDocuments {
        documentType
        name
        allowedMimeTypes
        canReplaceOnExpire
        canExempt
        requireLevel
      }
    }
  }
`

export const QueryDocumentGroups = gql`
  query DocumentGroups($type: String!, $marketId: String, $providerId: String) {
    documentGroups(type: $type, marketId: $marketId, providerId: $providerId) {
      id
      marketId
      serviceType
      documentTypes
      documentTypeOverrides
      documentTypeDependencies
      allowedDriverGroupIds
      allowedVehicleGroupIds
      name
      isDefault
      isHidden

      market {
        id
        name
      }

      typeDescriptions {
        id
        documentGroupId
        documentType
        description
        createdAt
        updatedAt
      }

      requiredDocuments {
        documentType
        name
        requireLevel
      }

      documentCategories {
        name
        documentTypes
      }
    }
  }
`

export const QueryDocumentGroupsByIds = gql`
  query DocumentGroupsByIds($type: String!, $ids: [String!]) {
    documentGroups(type: $type, ids: $ids) {
      id
      name
      market {
        id
        name
      }
    }
  }
`

export const QueryAllDocumentGroups = gql`
  query AllDocumentGroups($marketId: String) {
    driverGroups: documentGroups(type: "driver", marketId: $marketId) {
      id
      marketId
      name
      serviceType
      attributes
      documentTypes
      documentTypeOverrides
      documentTypeDependencies
      documentCategories {
        name
        documentTypes
      }
      isDefault
      isHidden

      market {
        id
        name
      }

      typeDescriptions {
        id
        documentGroupId
        documentType
        description
        createdAt
        updatedAt
      }
    }

    vehicleGroups: documentGroups(type: "vehicle", marketId: $marketId) {
      id
      marketId
      name
      serviceType
      attributes
      documentTypes
      documentTypeOverrides
      documentTypeDependencies
      documentCategories {
        name
        documentTypes
      }
      isDefault
      isHidden

      typeDescriptions {
        id
        documentGroupId
        documentType
        description
        createdAt
        updatedAt
      }

      market {
        id
        name
      }
    }

    providerGroups: documentGroups(type: "provider", marketId: $marketId) {
      id
      marketId
      name
      serviceType
      attributes
      documentTypes
      documentTypeOverrides
      documentTypeDependencies
      documentCategories {
        name
        documentTypes
      }
      isDefault

      allowedDriverGroupIds
      allowedVehicleGroupIds

      typeDescriptions {
        id
        documentGroupId
        documentType
        description
        createdAt
        updatedAt
      }

      market {
        id
        name
      }
    }
  }
`

export const QueryDocumentAssignments = gql`
  query DocumentAssignments(
    $assigneeId: String
    $status: String!
    $page: Int = 1
    $query: String
    $marketId: String
    $providerIds: [String!]
  ) {
    documentAssignments(
      assigneeId: $assigneeId
      status: $status
      page: $page
      query: $query
      marketId: $marketId
      providerIds: $providerIds
    ) {
      total
      results {
        id
        reason
        status
        statusReason
        completedAt
        createdAt
        updatedAt

        assignee {
          id
          firstName
          middleName
          lastName
          email
        }

        document {
          id
          documentType
          status
          statusReason
          expiresAt
          issuedAt
          createdAt
          updatedAt

          type {
            name
          }

          provider {
            id
            name

            market {
              name
            }
          }

          owner {
            type
            providerId
            driverId
            vehicleId

            provider {
              id
              name
            }

            driver {
              id
              firstName
              middleName
              lastName
            }

            vehicle {
              id
              vin
            }
          }
        }
      }
    }
  }
`

export const QueryUnassignedDocuments = gql`
  query UnassignedDocuments($page: Int! = 1, $query: String, $marketId: String, $providerIds: [String!]) {
    unassignedDocuments(page: $page, query: $query, marketId: $marketId, providerIds: $providerIds) {
      total
      results {
        id
        documentType
        status
        expiresAt
        issuedAt
        createdAt

        type {
          name
        }

        owner {
          type
          driverId
          providerId
          vehicleId

          driver {
            id
            driverNumber
            firstName
            lastName
          }

          vehicle {
            id
            vin
          }

          provider {
            id
            name
          }
        }

        provider {
          id
          name

          market {
            name
          }
        }
      }
    }
  }
`

export const QueryDocumentAssignment = gql`
  query DocumentAssignment($id: String! = "") {
    documentAssignment(id: $id) {
      id
      reason
      status
      statusReason
      createdAt
      completedAt

      assignee {
        id
        firstName
        middleName
        lastName
        email
      }

      document {
        id
        documentType
        status
        statusReason
        expiresAt
        issuedAt
        createdAt
        mimeType
        rotation

        type {
          name
        }

        extractedData {
          ${AllExtractedFields}
        }

        decision {
          id
          canOverride
          createdAt
        }

        owner {
          type
          driverId
          providerId
          vehicleId
          isPrimary

          driver {
            id
            driverNumber
            firstName
            middleName
            lastName

            requiredDocuments {
              documentType
              name
              
              primary {
                id
                status
                statusReason
                expiresAt
                createdAt

                assignments {
                  id
                }
              }
            }
          }

          vehicle {
            id
            vin

            requiredDocuments {
              documentType
              
              primary {
                id
                status
                statusReason
                createdAt
              }
            }
          }

          provider {
            id
            name

            requiredDocuments {
              documentType
              primary {
                id
                status
                statusReason
                createdAt
              }
            }
          }
        }

        provider {
          id
          name
        }
      }
    }
  }
`

export const QueryDocumentAssignmentStats = gql`
  query DocumentAssignmentStats($marketId: String) {
    documentAssignmentStats(marketId: $marketId) {
      assigned
      completed
      inProgress
      unassigned
    }
  }
`

export const QueryRequiredFields = gql`
  query RequiredFields($marketId: String) {
    requiredFields(marketId: $marketId) {
      documentType
      fields
    }
  }
`
