import { get, post } from './req'

export const archiveDocument = (id: string) => {
  return post(`/v1/documents/archive`, { id })
}

export const setSuccessor = (data: { documentId: string; successorId: string }) => {
  return post(`/v1/documents/successor`, data)
}

export const getViewURL = (id: string) => {
  return get(`/v1/documents/${id}/view`)
}

export const getDocument = (id: string) => {
  return get(`/v1/documents/${id}`)
}

export type RequestDocumentPayload = {
  driverId?: string
  vehicleId?: string
  documentType: string
  dueAt?: number
}

export const requestDocument = (payload: RequestDocumentPayload) => {
  return post('/v1/documents/request', payload)
}

export type RequestAttestationPayload = {
  documentId: string
  providerId: string
}

export const requestAttestation = (payload: RequestAttestationPayload) => {
  return post('/v1/documents/attestations/request', payload)
}

export type CancelDocumentRequestPayload = {
  id: string
  cancelReason: string
}

export const cancelDocumentRequest = (payload: CancelDocumentRequestPayload) => {
  return post('/v1/documents/request/cancel', payload)
}

type CreateAttestationPayload = {
  documentId: string
  type: string
  details: Record<string, any>
}

export const createAttestation = (payload: CreateAttestationPayload) => {
  return post('/v1/documents/attest', payload)
}
