import { gql } from '@apollo/client'

export const DOCUMENT_FRAGMENTS = gql`
  fragment DocumentExemptionDetails on DocumentExemption {
    id
    documentType
    reason
    expiresAt
  }

  fragment RequiredDocumentDetails on RequiredDocument {
    providerId
    driverId
    vehicleId
    documentType
    requireLevel
    name

    primary {
      id
      expiresAt
      status
      statusReason

      decision {
        id
        assignmentId
      }
    }

    pending {
      id
      expiresAt
      status
      statusReason
    }
  }
`
