import { Table } from '@tanstack/react-table'
import { Box } from 'components/Box'
import { IconButton } from 'components/Buttons/IconButton'
import { Text } from 'components/Text'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'

interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({ table }: DataTablePaginationProps<TData>) {
  return (
    <Box className="flex items-center justify-between border-t p-2">
      <Box className="pl-2">
        <Text size="sm" weight="normal" variant="light">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </Text>
      </Box>
      <Box className="flex items-center gap-2">
        <IconButton
          size="sm"
          icon={HiOutlineChevronLeft}
          ariaLabel="Go to previous page"
          variant="outline"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        />
        <IconButton
          size="sm"
          icon={HiOutlineChevronRight}
          ariaLabel="Go to next page"
          variant="outline"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        />
      </Box>
    </Box>
  )
}
