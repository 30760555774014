import classNames from 'classnames'
import {
  HiBadgeCheck,
  HiOutlineAnnotation,
  HiOutlineBell,
  HiOutlineCursorClick,
  HiOutlineUpload,
  HiOutlineX,
} from 'react-icons/hi'
import { Document } from 'types/Document'
import { Modal, ModalProps } from '../Modal'

const eventTypes = {
  uploaded: { icon: HiOutlineUpload, bgColorClass: 'bg-gray-500' },
  opened: { icon: HiOutlineCursorClick, bgColorClass: 'bg-gray-500' },
  updated: { icon: HiOutlineAnnotation, bgColorClass: 'bg-blue-500' },
  notified: { icon: HiOutlineBell, bgColorClass: 'bg-gray-500' },
  primaried: { icon: HiBadgeCheck, bgColorClass: 'bg-indigo-600' },
  expired: { icon: HiOutlineX, bgColorClass: 'bg-red-600' },
}

const timeline = [
  {
    id: 1,
    type: eventTypes.uploaded,
    content: 'Uploaded by',
    target: 'Lisa Smith',
    date: 'Sep 20',
    datetime: '2020-09-20',
  },
  {
    id: 2,
    type: eventTypes.opened,
    content: 'Viewed by',
    target: 'Bethany Blake',
    date: 'Sep 22',
    datetime: '2020-09-22',
  },
  {
    id: 3,
    type: eventTypes.updated,
    content: 'Updated by',
    target: 'Martha Gardner',
    date: 'Sep 28',
    datetime: '2020-09-28',
  },
  {
    id: 4,
    type: eventTypes.primaried,
    content: 'Set as primary document',
    target: '',
    date: 'Sep 30',
    datetime: '2020-09-30',
  },
  {
    id: 5,
    type: eventTypes.notified,
    content: 'Owner notified of expiration - ',
    target: '30 day warning',
    date: 'Oct 4',
    datetime: '2020-10-04',
  },
  {
    id: 2,
    type: eventTypes.opened,
    content: 'Viewed by',
    target: 'Steve Johnson',
    date: 'Oct 4',
    datetime: '2020-10-04',
  },
  {
    id: 5,
    type: eventTypes.expired,
    content: 'Document Expired',
    target: '',
    date: 'Nov 3',
    datetime: '2020-11-03',
  },
]

type DocumentHistoryModalProps = ModalProps & {
  document: Document
}

export const DocumentHistoryModal = ({ isOpen, onClose }: DocumentHistoryModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
            Timeline
          </h2>

          {/* Activity Feed */}
          <div className="mt-6 flow-root">
            <ul role="list" className="-mb-8">
              {timeline.map((item, itemIdx) => (
                <li key={item.id}>
                  <div className="relative pb-8">
                    {itemIdx < timeline.length - 1 ? (
                      <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            item.type.bgColorClass,
                            'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                          )}
                        >
                          <item.type.icon className="h-5 w-5 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div>
                          <p className="text-sm text-gray-500">
                            {item.content}{' '}
                            <a href="#" className="font-medium text-gray-900">
                              {item.target}
                            </a>
                          </p>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                          <time dateTime={item.datetime}>{item.date}</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-6 flex flex-col justify-stretch">
            <button
              type="button"
              className="focus:outline-noneF inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:ring-1 focus:ring-blue-500"
            >
              View full history
            </button>
          </div>
        </div>
      </section>
    </Modal>
  )
}
