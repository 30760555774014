import Link from 'next/link'
import { Account } from 'types/Account'

type AccountLabelProps = {
  account: Account | null
  href?: string
}

export function AccountLabel(props: AccountLabelProps) {
  const { account, href } = props
  const content = (
    <>
      {account?.firstName} {account?.lastName}
    </>
  )

  if (href) {
    return (
      <Link href={href} className="font-medium text-gray-900 hover:underline dark:text-gray-100">
        {content}
      </Link>
    )
  }
  return <span>{content}</span>
}
