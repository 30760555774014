import { ReactNode } from 'react'

type LanguageItemProps = {
  onClick: () => void
  children?: ReactNode | ReactNode[]
}

export const LanguageItem = ({ onClick, children }: LanguageItemProps) => {
  return (
    <div className="dark:hover:bg-dark-500 flex flex-1 gap-4 rounded px-6 py-3 hover:bg-gray-50" onClick={onClick}>
      {children}
    </div>
  )
}
