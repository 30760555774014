import * as PIXI from 'pixi.js'
import { useState } from 'react'
import { CustomPIXIComponent } from 'react-pixi-fiber'
import { useDebouncedCallback } from 'use-debounce'

const TYPE = 'Rectangle'
export const behavior = {
  customDisplayObject: () => new PIXI.Graphics(),
  customApplyProps: function (g, oldProps, newProps) {
    const { selected, color, points, style = 'fill', isHovering, ...newPropsRest } = newProps
    const {
      // eslint-disable-next-line no-unused-vars
      selected: oldSelected,
      // eslint-disable-next-line no-unused-vars
      color: oldColor,
      // eslint-disable-next-line no-unused-vars
      points: oldPoints,
      // eslint-disable-next-line no-unused-vars
      isHovering: oldIsHovering,
      ...oldPropsRest
    } = oldProps || {}
    if (typeof oldProps !== 'undefined') {
      g.clear()
    }

    if (style === 'fill') {
      g.beginFill(selected || isHovering ? color : 0xffffff, selected ? 0.5 : 0.15)
    } else {
      g.lineStyle(2, selected || isHovering ? color : 0xffffff, selected ? 0.5 : 0.15)
    }
    g.moveTo(points[0].x, points[0].y)
    points.slice(1).forEach(point => g.lineTo(point.x, point.y))
    if (style === 'fill') {
      g.endFill()
    } else {
      g.closePath()
    }

    this.applyDisplayObjectProps(oldPropsRest, newPropsRest)
  },
}

const CustomRectangle = CustomPIXIComponent(behavior, TYPE)

type RectangleProps = {
  points: { x: number; y: number }[]
  onMouseEnter?: (e) => void
  color: number
  index: number
  style?: 'fill' | 'stroke'
  selected?: boolean
}

export function Rectangle(props: RectangleProps) {
  const [mouseOver, setMouseOver] = useState(false)
  const handleMove = useDebouncedCallback(e => {
    setMouseOver(true)
    props.onMouseEnter?.(e)
  }, 8)

  return (
    <CustomRectangle
      color={props.color}
      cursor="text"
      isHovering={mouseOver}
      mousedown={handleMove}
      mouseout={() => setMouseOver(false)}
      mouseover={handleMove}
      points={props.points}
      selected={props.selected}
      style={props.style}
      interactive
    />
  )
}
