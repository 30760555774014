import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

type SupportedLanguagesType = 'en' | 'es' | 'ru'
type Strings = {
  onboard: string
  dashboard: string
  performance: string
  notifications: string
  network: string
  insurance: string
  credentials: string
  credentialing: string
  authorizations: string
  inspections: string
  accounts: string
  providers: string
  driver: string
  drivers: string
  vehicles: string
  issues: string
  upload: string
  email: string
  accountTeam: string
  organization: string
  changeLanguage: string
  profile: string
  signIn: string
  signInMsg: string
  token: string
  tokenMsg: string
  next: string
  emailFormatErr: string
  loginTokenMsg: string
  resendMsg: string
  returnMsg: string
  useAnotherEmailMsg: string
  pilotMsg: string
  forbiddenMsg: string
  feedbackMsg: string
  team: string
  active: string
  inactive: string
  activeCredentials: string
  activeDrivers: string
  activeVehicles: string
  activeProviders: string
  viewAll: string

  loggedInAs: string
  logOut: string
  help: string

  confirmAccountTitle: string
  confirmAccountDescription: string

  notificationSettings: string

  emailNotifications: string
  emailNotificationsDescription: string
}

const resources: { [key in SupportedLanguagesType]: { translation: Strings } } = {
  en: {
    translation: {
      onboard: 'Onboard',
      dashboard: 'Dashboard',
      performance: 'Performance',
      notifications: 'Notifications',
      network: 'Network',
      insurance: 'Insurance',
      credentials: 'Credentials',
      credentialing: 'Credentialing',
      authorizations: 'Authorizations',
      inspections: 'Inspections',
      accounts: 'Accounts',
      providers: 'Providers',
      driver: 'Driver',
      drivers: 'Drivers',
      vehicles: 'Vehicles',
      issues: 'Issues',
      upload: 'Upload',
      email: 'Email',
      accountTeam: 'Team',
      organization: 'Organization',
      changeLanguage: 'Language',
      profile: 'Profile',
      signIn: 'Sign in',
      signInMsg: 'Sign in to your account',
      token: 'Code',
      tokenMsg: 'Enter your login code',
      next: 'Next',
      emailFormatErr: 'Please check your email and try again.',
      loginTokenMsg: 'A login code was sent to your email.',
      resendMsg: 'Resend code',
      returnMsg: 'Return to login',
      useAnotherEmailMsg: 'Use another email',
      pilotMsg: 'Thank you for participating in the Complicore pilot!',
      forbiddenMsg:
        'We are working with the ModivCare team on next steps. In the mean time, please continue to upload\ncredentials to Salesforce.\nIf you have any questions regarding your compliance status, you can reach out to your ModivCare\nspecialist.',
      feedbackMsg: 'Your feedback and requests are also welcomed at hello@complicore.co.',
      team: 'The Complicore Team',
      active: 'Active',
      inactive: 'Inactive',
      activeCredentials: 'Active Credentials',
      activeDrivers: 'Active Drivers',
      activeVehicles: 'Active Vehicles',
      activeProviders: 'Active Providers',
      viewAll: 'View all',

      loggedInAs: 'Logged in as',
      logOut: 'Log out',
      help: 'Help',
      confirmAccountTitle: 'Verify Your Account Details',
      confirmAccountDescription:
        'Please take a moment to double-check the information you provided during registration',

      notificationSettings: 'Notification Settings',

      emailNotifications: 'Email Notifications',
      emailNotificationsDescription:
        'Opt-in to email notifications about document expirations and other important events.',
    },
  },
  es: {
    translation: {
      onboard: 'Empezar',
      dashboard: 'Tablero',
      performance: 'Rendimiento',
      notifications: 'Notificaciones',
      network: 'La Red',
      insurance: 'Seguro',
      credentials: 'Credenciales',
      credentialing: 'Acreditación',
      authorizations: 'Autorizaciones',
      inspections: 'Inspecciones',
      accounts: 'Cuentas',
      providers: 'Proveedores',
      driver: 'Conductor',
      drivers: 'Conductores',
      vehicles: 'Vehículos',
      issues: 'Asuntos',
      upload: 'Subir',
      email: 'Email',
      accountTeam: 'Equipo',
      organization: 'Organización',
      changeLanguage: 'Lenguaje',
      profile: 'Perfil',
      signIn: 'Registrarse',
      signInMsg: 'Iniciar sesión en su cuenta',
      token: 'Código',
      tokenMsg: 'Introduzca su código de inicio de sesión',
      next: 'Próximo',
      emailFormatErr: 'Por favor revise su correo electrónico y vuelva a intentarlo.',
      loginTokenMsg: 'Se envió un código a su correo electrónico.',
      resendMsg: 'Reenviar codigo',
      returnMsg: 'Volver a iniciar sesión',
      useAnotherEmailMsg: 'Usa otro correo electrónico',
      pilotMsg: '¡Gracias por participar en el piloto de Complicore!',
      forbiddenMsg:
        'Estamos trabajando con el equipo de ModivCare para sus próximos pasos. Mientras tanto, sigue subiendo\nsus credenciales al Salesforce.\nSi, tiene alguna pregunta sobre su estado de cumplimiento, puede comunicarse con su ModivCare\nespecialista.',
      feedbackMsg: 'Sus opiniones y comentarios son bienvenidos en hello@complicore.co.',
      team: 'El equipo de Complicore',
      active: 'Activa',
      inactive: 'Inactiva',
      activeCredentials: 'Credenciales Activas',
      activeDrivers: 'Conductores Activos',
      activeVehicles: 'Vehículos Activos',
      activeProviders: 'Proveedores Activos',
      viewAll: 'Ver todos',
      loggedInAs: 'Conectado como',
      logOut: 'Cerrar sesión',
      help: 'Ayuda',

      confirmAccountTitle: 'Verifique los detalles de su cuenta',
      confirmAccountDescription: 'Tómese un momento para verificar la información que proporcionó durante el registro',

      notificationSettings: 'Configuración de notificaciones',

      emailNotifications: 'Notificaciones por correo electrónico',
      emailNotificationsDescription:
        'Suscríbase a las notificaciones por correo electrónico sobre vencimientos de documentos y otros eventos importantes.',
    },
  },
  ru: {
    translation: {
      onboard: 'начать',
      dashboard: 'Домой',
      performance: 'Производительность',
      notifications: 'Уведомления',
      network: 'Сеть',
      insurance: 'Страхование',
      credentials: 'Полномочия',
      credentialing: 'Удостоверение',
      authorizations: 'Авторизации',
      inspections: 'Инспекции',
      accounts: 'Счета',
      providers: 'Провайдеры',
      driver: 'Водитель',
      drivers: 'Драйверы',
      vehicles: 'Транспорт',
      issues: 'Проблемы',
      upload: 'Загрузить',
      email: 'Eмейл',
      accountTeam: 'Команда',
      organization: 'Организация',
      changeLanguage: 'Язык',
      profile: 'Профиль',
      signIn: 'Войти',
      signInMsg: 'Войдите в свой аккаунт',
      token: 'Код',
      tokenMsg: 'Введите ваш код входа',
      next: 'Следующий',
      emailFormatErr: 'Пожалуйста, проверьте свою электронную почту и повторите попытку.',
      loginTokenMsg: 'Код для входа был отправлен на вашу электронную почту.',
      resendMsg: 'Отправить код еще раз',
      returnMsg: 'Вернуться к входу',
      useAnotherEmailMsg: 'сменить имейл',
      pilotMsg: 'Благодарим вас за участие в пилотной программе Complicore!',
      forbiddenMsg:
        'Мы работаем с командой ModivCare над следующими шагами. Тем временем продолжайте загружать\nучетные данные в Salesforce.\nЕсли у вас есть какие-либо вопросы относительно вашего статуса соответствия, вы можете обратиться к своему\nспециалисту ModivCare.',
      feedbackMsg: 'Ваши отзывы и запросы также приветствуются по адресу hello@complicore.co.',
      team: 'Команда Компликор',
      active: 'Активный',
      inactive: 'Неактивный',
      activeCredentials: 'Активные учетные данные',
      activeDrivers: 'Активные драйверы',
      activeVehicles: 'Активные автомобили',
      activeProviders: 'Активные Провайдеры',
      viewAll: 'Посмотреть все',

      loggedInAs: 'Вы вошли как',
      logOut: 'Выйти',
      help: 'Помощь',

      confirmAccountTitle: 'Подтвердите данные своей учетной записи',
      confirmAccountDescription: 'Пожалуйста, проверьте информацию, которую вы предоставили при регистрации',

      notificationSettings: 'Настройки уведомлений',

      emailNotifications: 'Уведомления по электронной почте',
      emailNotificationsDescription:
        'Подпишитесь на уведомления по электронной почте о сроках действия документов и других важных событиях.',
    },
  },
}

export const setupi18n = () =>
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  })
