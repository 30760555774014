import classNames from 'classnames'
import { Button, ButtonSize } from 'components/Button/Button'
import { Tooltip } from 'components/Tooltip'
import Link from 'next/link'
import { HiBadgeCheck, HiOutlineBadgeCheck, HiOutlineOfficeBuilding, HiOutlineUser } from 'react-icons/hi'
import { RiTaxiLine } from 'react-icons/ri'
import { Document, DocumentLink } from 'types/Document'
import { formatName } from 'util/driver'
import { IconButton } from '../Buttons/IconButton'
import { SplitButton } from '../Buttons/SplitButton'

type DocumentOwnerButtonProps = {
  className?: string
  baseUrl: string
  document: Document
  showLabel?: boolean
  onSet?: any
  size?: ButtonSize
}

export const DocumentOwnerButton = (props: DocumentOwnerButtonProps) => {
  const { document: doc, baseUrl, showLabel = true, onSet, className } = props
  const { owner } = doc
  const icon = getIcon(owner)
  const label = getLabel(owner)
  const Container = !!onSet ? SplitButton : 'div'

  return (
    <Container className={classNames('w-full', className)}>
      <Link href={`${baseUrl}/${owner.type}s/${owner[owner.type].id}`}>
        <IconButton ariaLabel={`View ${owner.type}`} icon={icon} className="flex-1 overflow-hidden" size={props.size}>
          {showLabel && <p className="truncate">{label}</p>}
        </IconButton>
      </Link>
      {onSet && (
        <Tooltip label="Set as primary">
          <Button
            aria-label="Set as primary"
            data-place="right"
            disabled={doc.status !== 'approved'}
            onClick={() => {
              if (doc.owner?.isPrimary) return
              onSet({
                documentId: doc.id,
                [owner.type + 'Id']: owner[owner.type + 'Id'],
              })
            }}
          >
            {doc.owner?.isPrimary ? (
              <HiBadgeCheck className="h-5 w-5 text-indigo-600" />
            ) : (
              <HiOutlineBadgeCheck className="h-5 w-5" />
            )}
          </Button>
        </Tooltip>
      )}
    </Container>
  )
}

export function getLabel(owner: DocumentLink) {
  switch (owner.type) {
    case 'driver':
      return formatName(owner.driver)
    case 'vehicle':
      return `${owner.vehicle?.vin}`
    case 'provider':
      return `${owner.provider?.name}`
  }

  return ''
}

function getIcon(owner: DocumentLink) {
  switch (owner.type) {
    case 'driver':
      return HiOutlineUser
    case 'vehicle':
      return RiTaxiLine
    case 'provider':
      return HiOutlineOfficeBuilding
  }

  return null
}
