import { Text } from 'components/Text'
import { useTranslation } from 'react-i18next'
import { HiOutlineFlag } from 'react-icons/hi'
import { Modal, ModalBody, ModalHeader, ModalProps } from '../Modal'
import { LanguageItem } from './LangaugeItem'

export const ChooseLangaugeModal = ({ isOpen, onClose }: ModalProps) => {
  const { t, i18n } = useTranslation()

  const selectLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={t('changeLanguage')} icon={<HiOutlineFlag className="h-5 w-5 text-blue-500" />} />
      <ModalBody>
        <div className="w-screen max-w-md">
          <LanguageItem onClick={selectLanguage.bind(null, 'en')}>
            <span>🇺🇸</span>
            <Text weight="normal">English (United States)</Text>
          </LanguageItem>
          <LanguageItem onClick={selectLanguage.bind(null, 'ru')}>
            <span>🇷🇺</span>
            <Text weight="normal">Russian - русский</Text>
          </LanguageItem>
          <LanguageItem onClick={selectLanguage.bind(null, 'es')}>
            <span>🇪🇸</span>
            <Text weight="normal">Spanish - español</Text>
          </LanguageItem>
        </div>
      </ModalBody>
    </Modal>
  )
}
