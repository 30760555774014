import cx from 'classnames'
import { HiBadgeCheck, HiOutlineIdentification, HiOutlineQuestionMarkCircle } from 'react-icons/hi'
import { RiTaxiLine } from 'react-icons/ri'
import { Icon } from '../Icon'

type DocumentTypeLabelProps = {
  className?: string
  name?: string
  isPrimary?: boolean
  virtual?: boolean
  onClick?: () => void
}

export const DocumentTypeLabel = (props: DocumentTypeLabelProps) => {
  const { className, name, isPrimary, onClick } = props

  return (
    <label
      className={cx([
        'inline-flex w-full items-center justify-between overflow-hidden text-gray-700 dark:text-gray-200',
        props.virtual ? 'border border-dashed border-purple-600 dark:border-purple-400' : '',
        onClick && 'cursor-pointer hover:underline',
        className,
      ])}
      onClick={onClick}
    >
      <span className="inline-flex">
        {name ? (
          name.startsWith('Vehicle') ? (
            <RiTaxiLine className="h-5 w-5" />
          ) : (
            <HiOutlineIdentification className="h-5 w-5" />
          )
        ) : (
          <HiOutlineQuestionMarkCircle className="h-5 w-5" />
        )}
        <span className="mx-2 font-medium">{name || 'Not Detected'}</span>
      </span>
      {isPrimary && <Icon icon={HiBadgeCheck} color="purple" />}
    </label>
  )
}
