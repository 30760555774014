import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { MdContentCopy } from 'react-icons/md'
import { toast } from 'react-toastify'
import { Text, TextProps } from './Text'

export function CopyableText({ children, ...props }: TextProps) {
  if (!children) return null

  return (
    <Button
      variant="unstyled"
      aria-label="Copy to Clipboard"
      className="cursor-pointer hover:underline"
      onClick={e => {
        navigator.clipboard.writeText(e.currentTarget.innerText)
        toast.info('Copied to clipboard')
      }}
    >
      <Text {...props} className="inline-flex items-center gap-1">
        {children}
        <Icon icon={MdContentCopy} size={props.size || 'sm'} />
      </Text>
    </Button>
  )
}
