import { gql } from '@apollo/client'

export const QueryDrivers = gql`
  query Drivers(
    $page: Int!
    $limit: Int
    $query: String
    $ids: [String!]
    $providerIds: [String!]
    $documentGroupId: String
    $active: Boolean
    $activeBrokerLink: Boolean
    $sort: String
    $marketId: String
  ) {
    drivers(
      page: $page
      limit: $limit
      query: $query
      ids: $ids
      marketId: $marketId
      providerIds: $providerIds
      documentGroupId: $documentGroupId
      active: $active
      activeBrokerLink: $activeBrokerLink
      sort: $sort
    ) {
      total
      results {
        id
        providerId
        driverNumber
        documentGroup {
          id
          name
        }
        firstName
        middleName
        lastName
        suffix
        dob
        phone
        email
        streetAddress
        streetAddress2
        city
        state
        zip
        hackNumber
        hackExpires
        driversLicenseNumber
        driversLicenseExpires
        isActive
        isAuthorized
        isCompliant
        compliantUntil
        createdAt
        updatedAt

        vehicles {
          id
        }

        documentExemptions {
          id
          documentType
          reason
          expiresAt
        }

        requiredDocuments {
          driverId
          documentType
          requireLevel
          name

          primary {
            id
            expiresAt
            status
            statusReason

            decision {
              id
              assignmentId
            }
          }

          pending {
            id
            expiresAt
            status
            statusReason
          }

          requiredAuxiliaryDocuments {
            driverId
            documentType
            name

            primary {
              id
              expiresAt
              status
              statusReason
            }

            pending {
              id
              expiresAt
              status
              statusReason
            }
          }
        }

        provider {
          id
          name
          market {
            id
            name
          }
        }
      }
    }
  }
`

export const QueryProviderDrivers = gql`
  query ProviderDrivers($id: String!, $page: Int! = 1, $active: Boolean) {
    providerDrivers(id: $id, page: $page, active: $active) {
      total
      results {
        id
        providerId
        documentGroup {
          id
          name
        }
        driverNumber
        firstName
        middleName
        lastName
        suffix
        dob
        phone
        email
        streetAddress
        streetAddress2
        city
        state
        zip
        hackNumber
        hackExpires
        driversLicenseNumber
        driversLicenseExpires
        isActive
        isAuthorized
        isCompliant
        compliantUntil
        createdAt
        updatedAt

        documentExemptions {
          id
          documentType
          reason
          expiresAt
        }

        requiredDocuments {
          driverId
          documentType
          requireLevel

          primary {
            id
            status
            statusReason
            expiresAt
            createdAt
            updatedAt

            decision {
              id
              assignmentId
            }
          }

          pending {
            id
            status
            statusReason
            expiresAt
            createdAt
            updatedAt
          }
        }

        provider {
          id
          name
        }
      }
    }
  }
`

export const QueryDriverRequiredDocuments = gql`
  query DriverRequiredDocuments($marketId: String!) {
    requiredDriverDocuments(marketId: $marketId) {
      driverId
      documentType
      name
      allowedMimeTypes
      canReplaceOnExpire
      canExempt
      requireLevel

      requiredAuxiliaryDocuments {
        driverId
        documentType
        name
        allowedMimeTypes
        canReplaceOnExpire
        canExempt
        requireLevel
      }
    }
  }
`

export const QueryDriverEvents = gql`
  query DriverEvents($id: String!) {
    driverEvents(id: $id) {
      id
      type
      createdAt

      details {
        # driver {
        #   id
        #   firstName
        #   middleName
        #   lastName
        #   email
        #   phone
        # }

        document {
          id
          documentType
          issuedAt
          expiresAt
          isLinked
          createdAt
          status
          statusReason
        }
      }
    }
  }
`

export const QueryDriverFull = gql`
  query Driver($id: String!) {
    driver(id: $id) {
      id
      documentGroupId
      driverNumber
      documentGroup {
        id
        name
      }
      firstName
      middleName
      lastName
      suffix
      dob
      ssn
      phone
      email
      streetAddress
      streetAddress2
      city
      state
      zip
      hackNumber
      hackExpires
      driversLicenseNumber
      driversLicenseExpires
      isActive
      isAuthorized
      isCompliant
      compliantUntil
      hasAccount
      createdAt
      updatedAt

      exclusions {
        id
        note
        validUntil
        revokedAt
        value
        providerIds
        createdAt
      }

      vehicles {
        id
        vin
        plate
        make
        color
        model
        year
        isActive

        requiredDocuments {
          documentType
          requireLevel
          name
          allowedMimeTypes
          dependencies {
            documentType
            name
          }
          primary {
            id
            status
            expiresAt
          }
          pending {
            id
            status
          }
        }
      }

      authorization {
        id
        expiresAt
        completedAt
        createdAt
        updatedAt
        decider {
          firstName
          lastName
        }
      }

      checks(latest: true) {
        id
        name
        result
        details
        createdAt
      }

      customAttributes {
        providerCode
        attributes
      }

      documentExemptions {
        id
        documentType
        reason
        expiresAt
      }

      requiredCredentials {
        name
        type

        requiredDocuments {
          driverId
          documentType
          name
          allowedMimeTypes
          canReplaceOnExpire
          canExempt
          requireLevel

          dependencies {
            documentType
            name
          }

          documentRequests {
            id
            documentType
            dueAt
            createdAt
            updatedAt
          }

          requiredAuxiliaryDocuments {
            driverId
            documentType
            name
            allowedMimeTypes
            canReplaceOnExpire
            canExempt
            requireLevel

            primary {
              id
              documentType
              expiresAt
              status
              statusReason
              createdAt

              decision {
                id
                assignmentId
              }

              extractedData {
                documentId
                id
                issuedAt
                expiresAt
              }

              type {
                name
              }
            }

            pending {
              id
              documentType
              expiresAt
              status
              statusReason
              createdAt

              extractedData {
                documentId
                id
                issuedAt
                expiresAt
              }

              type {
                name
              }
            }
          }

          primary {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            decision {
              id
              assignmentId
              createdAt
            }

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }

          pending {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }
        }
      }

      requiredDocuments {
        driverId
        documentType
        name
        allowedMimeTypes
        canReplaceOnExpire
        canExempt
        requireLevel

        dependencies {
          documentType
          name
        }

        documentRequests {
          id
          documentType
          dueAt
          createdAt
          updatedAt
        }

        requiredAuxiliaryDocuments {
          driverId
          documentType
          name
          allowedMimeTypes
          canReplaceOnExpire
          canExempt
          requireLevel

          primary {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            decision {
              id
              assignmentId
            }

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }

          pending {
            id
            documentType
            expiresAt
            status
            statusReason
            createdAt

            extractedData {
              documentId
              id
              issuedAt
              expiresAt
            }

            type {
              name
            }
          }
        }

        primary {
          id
          documentType
          expiresAt
          status
          statusReason
          createdAt

          decision {
            id
            assignmentId
            createdAt
          }

          extractedData {
            documentId
            id
            issuingState
            issuedAt
            expiresAt
          }

          type {
            name
          }
        }

        pending {
          id
          documentType
          expiresAt
          status
          statusReason
          createdAt

          extractedData {
            documentId
            id
            issuedAt
            expiresAt
          }

          type {
            name
          }
        }
      }

      provider {
        id
        marketId
        name
        market {
          id
          name
        }

        documentGroup {
          id
          name
        }

        customAttributes
      }
    }
  }
`
