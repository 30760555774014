import { gql } from '@apollo/client'

export const QueryWorkspaceDocument = gql`
  query WorkspaceDocument($id: String!) {
    workspaceDocument(id: $id) {
      id
      attributes
      folderId
      mimeType
      name
      ownerId
      uploadLinkToken
      tagIds
      createdAt
    }
  }
`
