import { gql } from '@apollo/client'

export const QueryPacket = gql`
  query Packet($id: String!) {
    packet(id: $id) {
      id
      ownerId
      workspaceId
      status
      isPrimary
      requirementGroupId
      marketId
      validAt
      expiresAt
      createdAt
      submittedAt
      reviewerId
      reviewedAt

      history {
        id
        event
        submittedAt
        createdAt
        status
        submissionStatus
        submissionId
        submissionIds
        reviewerId
        reviewedAt
      }

      requirementGroup {
        id
        name
        config
        requirements {
          id
          name
        }
      }

      submissions {
        id
        status
        statusReason
        expiresAt
        createdAt
        updatedAt

        requirement {
          id
          name
        }

        workspaceDocument {
          id
          name
        }

        documentExemption {
          id
          reason
          expiresAt
        }
      }

      owner {
        id
        name
        type
        attributes
      }
    }
  }
`
