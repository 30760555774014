import { MetadataSettings } from 'components/providers/MetadataSettings'
import { NavLink } from 'components/sidebar/NavLink'
import { VolunteerDriverNavLink } from 'components/sidebar/navLinks/VolunteerDriverNavLink'
import { useProviderMetadata } from 'hooks/useProviderMetadata'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { useTranslation } from 'react-i18next'
import { HiOutlineUsers } from 'react-icons/hi'

export const DriversNavLink = () => {
  const metadata = useProviderMetadata()
  const { stats } = useSidebarContext()
  const { t } = useTranslation()

  if (metadata?.createDefaultDriver === true) {
    return <VolunteerDriverNavLink />
  }

  return (
    <MetadataSettings setting="enableDrivers">
      <NavLink href="/providers/drivers" icon={HiOutlineUsers} stat={stats?.drivers}>
        {t('drivers')}
      </NavLink>
    </MetadataSettings>
  )
}
