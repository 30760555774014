import { format, intervalToDuration } from 'date-fns'
import { Account } from 'types'

export const formatShortNumber = (n: number) => n
export const friendlyDate = (date: number) => (date ? `${format(date, 'MMM')} '${format(date, 'yy')}` : '')
export const friendlyDateLong = (date: number) => (date ? `${format(date, 'MMM d, yyyy')}` : '')
export const formatAge = (date: string) => {
  try {
    const interval = intervalToDuration({ start: new Date(date), end: new Date() })

    return `${interval.years} years ${interval.months} months`
  } catch {
    return null
  }
}

export const formatNum = num => parseInt(num).toLocaleString()
export const formatName = (...args: string[]) => args.filter(Boolean).join(' ')
export const formatAccount = (acc: Account) => formatName(acc.firstName, acc.lastName)
