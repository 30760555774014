import styled from '@emotion/styled'
import classNames from 'classnames'

const SplitButtonContainer = styled.div`
  button {
    border-radius: 0;
    margin-right: -1px;
  }
  button:first-of-type {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  button:last-of-type {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    margin-left: -1px;
  }
`

type SplitButtonProps = {
  className?: string
  children?: any
}

export const SplitButton = (props: SplitButtonProps) => {
  const { className, children } = props
  if (!Array.isArray(children) || children?.filter(Boolean).length < 2) {
    return children
  }

  return (
    <SplitButtonContainer className={classNames('relative z-0 inline-flex rounded-md shadow-sm', className)}>
      {children}
    </SplitButtonContainer>
  )
}
