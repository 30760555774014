import { post } from './req'

type CreateExemptionPayload = {
  documentType: string
  driverId?: string
  vehicleId?: string
  providerId?: string
  reason: string
  expiresAt?: number
}

export function createDocumentExemption(payload: CreateExemptionPayload) {
  return post('/v1/documents/exemptions', payload)
}

type RevokeExemptionPayload = {
  id: string
  reason: string
}

export function revokeDocumentExemption(payload: RevokeExemptionPayload) {
  return post(`/v1/documents/exemptions/revoke`, payload)
}
