import { gql } from '@apollo/client'

export const QueryWorkspaces = gql`
  query Workspaces($options: WorkspacesInput) {
    workspaces(options: $options) {
      total
      results {
        id
        type
        name
      }
    }
  }
`
