import { cn } from '@/lib/utils'
import { Box } from 'components/Box'
import { ColorType, Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useState } from 'react'
import { IconType } from 'react-icons'

export type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  as?: any
  mask?: string
  maskChar?: string
  containerClassName?: string
  icon?: IconType
  iconColor?: ColorType
  append?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function InputComponent(props, ref) {
  const [isFocused, setIsFocused] = useState(false)
  const { as: component, icon, containerClassName, append, iconColor, ...rest } = props
  const hasBgOverride = props.className?.includes('bg-')
  const Component = component || 'input'

  let bgClassNames = hasBgOverride ? '' : 'bg-white dark:bg-dark-800'

  return (
    <Box className={cn('relative z-0 flex flex-1', isFocused && 'z-10', containerClassName)}>
      <Component
        autoComplete="off"
        type="text"
        {...rest}
        className={cn(
          `relative block w-full focus:z-10`,
          'focus:ring-light-blue-500 rounded-md text-sm dark:text-gray-100',
          'focus:border-light-blue-500 dark:border-dark-500 border-gray-300',
          bgClassNames,
          icon && 'pl-10',
          rest.className,
        )}
        ref={ref}
        onFocus={e => {
          setIsFocused(true)
          rest.onFocus?.(e)
        }}
        onBlur={e => {
          setIsFocused(false)
          rest.onBlur?.(e)
        }}
      />
      {icon && (
        <Box className="pointer-events-none absolute z-10 flex h-full items-center justify-center px-3">
          <Icon icon={icon} color={iconColor || 'default'} />
        </Box>
      )}
      {append && (
        <Box className="pointer-events-none absolute right-0 z-10 flex h-full items-center justify-center pl-2 pr-8">
          <Text size="xs" weight="normal" variant="light">
            {append}
          </Text>
        </Box>
      )}
    </Box>
  )
})
