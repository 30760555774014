import { CheckBox } from 'components/CheckBox'
import { PillTabs } from 'components/PillTabs/PillTabs'
import { useAuth } from 'hooks/useAuth'
import { useProviderRoster } from 'hooks/useProviderRoster'
import { ReactNode, useEffect, useState } from 'react'
import { Driver } from 'types/Driver'
import { Vehicle } from 'types/Vehicle'
import { formatName } from 'util/driver'

type RosterSelectProps = {
  onChange: (type: 'vehicle' | 'driver', value: Driver | Vehicle) => void
}

export const RosterSelect = (props: RosterSelectProps) => {
  const { onChange } = props
  const { account } = useAuth()
  const roster = useProviderRoster(account.providerId)
  const [activeIndex, setActiveIndex] = useState(0)
  const [selected, setSelected] = useState<Vehicle | Driver | null>(null)
  useEffect(() => {
    onChange(activeIndex === 0 ? 'driver' : 'vehicle', selected)
  }, [selected, activeIndex, onChange])

  return (
    <div>
      <div className="border-y border-gray-200">
        <PillTabs activeIndex={activeIndex} onChange={setActiveIndex}>
          <span>Drivers</span>
          <span>Vehicles</span>
        </PillTabs>
      </div>
      <div className="my-1">
        <ul className="divide-y divide-gray-200">
          {activeIndex === 0
            ? roster.drivers.map(driver => (
                <RosterItem
                  key={driver.id}
                  selected={selected?.id === driver.id && activeIndex === 0}
                  onChange={checked => setSelected(checked ? driver : null)}
                >
                  {formatName(driver)}
                </RosterItem>
              ))
            : roster.vehicles.map(vehicle => (
                <RosterItem
                  key={vehicle.id}
                  selected={selected?.id === vehicle.id && activeIndex === 1}
                  onChange={checked => setSelected(checked ? vehicle : null)}
                >
                  {vehicle.vin}
                </RosterItem>
              ))}
        </ul>
      </div>
    </div>
  )
}

type RosterItemProps = {
  selected: boolean
  onChange: (selected: boolean) => void
  children?: ReactNode | ReactNode[]
}
const RosterItem = ({ children, selected, onChange }: RosterItemProps) => {
  return (
    <label className="inline-flex w-full cursor-pointer items-center px-6 py-4 text-sm hover:bg-gray-100">
      <CheckBox checked={selected} onChange={e => onChange(e.target.checked)} />
      <span className="ml-3">{children}</span>
    </label>
  )
}
