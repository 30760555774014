import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client } from 'internal/graphql/client'
import { QueryEntityDetails } from 'internal/graphql/query/entityDetails'
import { EntityRequirements, Packet, Submission, WorkspaceEntity } from 'types/Workspace'

type EntityState = {
  entity: WorkspaceEntity
  entityRequirements: EntityRequirements | undefined
  submissions: Submission[]
  packets: Packet[]
  loading: boolean
  refreshing: boolean
}

type EntityDetails = {
  entity: WorkspaceEntity
  entityRequirements: EntityRequirements
  submissions: Submission[]
  packets: Packet[]
}

const initialState: EntityState = {
  entity: null,
  entityRequirements: undefined,
  submissions: [],
  packets: [],
  loading: true,
  refreshing: false,
}

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntity: (state, action: PayloadAction<WorkspaceEntity>) => {
      state.entity = action.payload
    },
    setEntityDetails: (state, action: PayloadAction<EntityDetails>) => {
      state.entityRequirements = action.payload.entityRequirements
      state.submissions = action.payload.submissions
      state.packets = action.payload.packets
    },
    resetEntity: state => {
      state.entity = initialState.entity
      state.entityRequirements = initialState.entityRequirements
      state.submissions = initialState.submissions
      state.packets = initialState.packets
      state.loading = initialState.loading
    },
  },

  extraReducers(builder) {
    builder
      .addCase(loadEntityDetails.pending, state => {
        state.loading = true
      })
      .addCase(loadEntityDetails.fulfilled, (state, action) => {
        state.entity = action.payload.entity
        state.entityRequirements = action.payload.entityRequirements
        state.submissions = action.payload.submissions
        state.packets = action.payload.packets
        state.loading = false
      })
      .addCase(loadEntityDetails.rejected, state => {
        state.loading = false
      })
      .addCase(refreshDetails.pending, state => {
        state.refreshing = true
      })
      .addCase(refreshDetails.fulfilled, (state, action) => {
        state.entity = action.payload.entity
        state.entityRequirements = action.payload.entityRequirements
        state.submissions = action.payload.submissions
        state.packets = action.payload.packets
        state.refreshing = false
      })
      .addCase(refreshDetails.rejected, state => {
        state.refreshing = false
      })
  },
})

export const loadEntityDetails = createAsyncThunk('entity/loadEntityDetails', async (id: string) => {
  const res = await client.query<{
    workspaceEntity: WorkspaceEntity
    entityRequirements: EntityRequirements
    submissions: { total: number; results: Submission[] }
    packets: { total: number; results: Packet[] }
  }>({
    query: QueryEntityDetails,
    fetchPolicy: 'no-cache',
    variables: { id, packetsInput: { ownerId: id }, submissionsInput: { ownerId: id } },
  })

  const entity = res.data?.workspaceEntity
  const entityRequirements = res.data?.entityRequirements
  const submissions = res.data?.submissions?.results || []
  const packets = res.data?.packets?.results || []

  return { entity, entityRequirements, submissions, packets }
})

export const refreshDetails = createAsyncThunk('entity/refreshDetails', async (id: string) => {
  const res = await client.query<{
    workspaceEntity: WorkspaceEntity
    entityRequirements: EntityRequirements
    submissions: { total: number; results: Submission[] }
    packets: { total: number; results: Packet[] }
  }>({
    query: QueryEntityDetails,
    fetchPolicy: 'no-cache',
    variables: { id, packetsInput: { ownerId: id }, submissionsInput: { ownerId: id } },
  })

  const entity = res.data?.workspaceEntity
  const entityRequirements = res.data?.entityRequirements
  const submissions = res.data?.submissions?.results || []
  const packets = res.data?.packets?.results || []

  return { entity, entityRequirements, submissions, packets }
})

export const { setEntity, setEntityDetails, resetEntity } = entitySlice.actions
