import { Listbox, Transition } from '@headlessui/react'
import { default as classNames, default as cx } from 'classnames'
import { Fragment, ReactNode } from 'react'

export type ListBoxProps<Option = any> = {
  button?: ReactNode
  children?: ReactNode
  disabled?: boolean
  label: string
  onChange: (option: Option) => void
  options?: Option[]
  optionsClassName?: string
  renderOption?: (option: Option, index: number) => any
  value: Option
  variant?: string
}

export function ListBox(props: ListBoxProps) {
  return (
    <Listbox value={props.value} onChange={props.onChange} disabled={props.disabled}>
      {({ open }) => (
        <div className={cx(props.variant === 'split' && 'flex flex-1 items-center')}>
          {props.label && (
            <Listbox.Label className="block w-1/3 text-sm font-medium text-gray-700 dark:text-gray-300">
              {props.label}
            </Listbox.Label>
          )}
          <div className={cx('relative', props.variant === 'split' ? 'flex-1' : '')}>
            {props.button}
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options
                static
                className={classNames(
                  'dark:border-dark-500 dark:bg-dark-700 absolute z-20 mt-1 max-h-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border sm:text-sm',
                  props.optionsClassName,
                  props.variant === 'split' && 'w-full',
                )}
              >
                {props.children || props.options.map(props.renderOption)}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}

export const Option = Listbox.Option
