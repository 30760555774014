import { formatDistance } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import _format from 'date-fns/format'

export function formatDate(date: any, format: string = 'MM/dd/yy') {
  const origDate = date
  try {
    if (!date) {
      return ''
    }

    // if already MM/dd/yy
    if (typeof date === 'string' && date.length === format.length) {
      return date
    }

    if (date instanceof Date === false && typeof date !== 'number') {
      date = new Date(date)
    }

    if (Number.isInteger(date)) {
      date = new Date(date)
    }

    // Date, not timestamp so we have to account for timezone.
    if (date.getTime().toString().endsWith('00000')) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000
      date = new Date(date.getTime() + offset)
    }

    return _format(date, format)
  } catch {
    console.error('Invalid date: ' + origDate)
    return ''
  }
}

export const formatShortDate = date => formatDate(date, 'MMM d')
export const formatShortTime = date => formatDate(date, 'h:mm a')

export function formatTime(date: any) {
  let now = new Date()

  if (date) {
    try {
      now = new Date(date)
    } catch {}
  }

  return _format(now.getHours() < 5 ? now.getTime() - 1000 * 60 * 60 * 6 : now, 'MMM d, yyyy h:mm aa') + ''
}

export function formatTimestamp(date: any) {
  if (!date) return ''

  try {
    const d = new Date(date)
    return _format(d, 'M/d/yyyy h:mm a')
  } catch {
    console.error('Invalid Date: ' + date)
    return ''
  }
}

export function formatShortTimestamp(date: any) {
  try {
    const d = new Date(date)
    return _format(d, 'MM/dd h:mm a')
  } catch {
    console.error('Invalid Date: ' + date)
    return ''
  }
}

export function formatRelativeDate(date: any) {
  date = new Date(date)
  if (Date.now() - date.getTime() < 1000 * 60 * 60) {
    return formatDistance(date, new Date(), { addSuffix: true })
  }

  return formatTimestamp(date)
}

export function validateFormat(str: string): boolean {
  return /^\d{2}\/\d{2}\/\d{4}$/.test(str) || /^\d{4}-\d{2}-\d{2}$/.test(str)
}

/**
 * Returns ISO formatted date in NY timezone
 *
 * @param date date string
 */
export function formatToNY(date: string): number {
  if (!validateFormat(date)) {
    throw new TypeError('date is not in mm/dd/yyy format')
  }

  return zonedTimeToUtc(new Date(date), 'America/New_York').getTime()
}

export function formatInNY(date: string | number): string {
  return _format(utcToZonedTime(date, 'America/New_York'), 'MM/dd/yyyy')
}

export function formatTimeDist(start: any, end: any): string {
  const startDateSeconds = Math.floor(new Date(start).getTime() / 1000)
  const endDateSeconds = Math.floor(new Date(end).getTime() / 1000)
  const distSeconds = endDateSeconds - startDateSeconds
  const minutes = Math.floor(distSeconds / 60)
  const seconds = distSeconds - minutes * 60
  const mins = distSeconds >= 60 ? `${minutes}m` : ''

  return `${mins}${seconds}s`
}
