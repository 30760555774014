import { Badge } from 'components/Badge'
import { Box } from 'components/Box'
import { DescriptionDetails } from 'components/DescriptionDetails'
import { DescriptionTerm } from 'components/DescriptionTerm'
import RejectionReasons from 'components/RejectionReasons'
import { formatTime } from 'internal/util/dates'
import { IconType } from 'react-icons'
import { HiOutlineCheckCircle, HiOutlineClock, HiOutlineXCircle } from 'react-icons/hi'
import { DocumentAttestation } from 'types'

type Props = {
  attestation: DocumentAttestation | null
}

export function DocumentAttestationDetails({ attestation }: Props) {
  if (!attestation) return null

  const { verifiedAt, isVerified, generatedAt, createdAt, verificationDecision, details } = attestation

  let badgeColor: 'blue' | 'red' | 'yellow'
  let badgeText: string
  let icon: IconType
  const generatedAtTime = generatedAt ? formatTime(generatedAt) : formatTime(createdAt)
  const verifiedAtTime = verifiedAt ? formatTime(verifiedAt) : null

  if (verifiedAt) {
    if (isVerified) {
      badgeText = 'Verified'
      badgeColor = 'blue'
      icon = HiOutlineCheckCircle
    } else {
      badgeText = 'Denied'
      badgeColor = 'red'
      icon = HiOutlineXCircle
    }
  } else {
    badgeText = 'Pending'
    badgeColor = 'yellow'
    icon = HiOutlineClock
  }

  const attestationDetails = details ? JSON.parse(details) : null

  return (
    <Box className="h-full bg-gray-50 p-4">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <Box className="col-span-1">
          <DescriptionTerm>Created on</DescriptionTerm>
          <DescriptionDetails className="mt-2">{generatedAtTime}</DescriptionDetails>
        </Box>
        <Box className="col-span-1">
          <DescriptionTerm>Signed by</DescriptionTerm>
          <DescriptionDetails className="mt-2">{attestationDetails?.['name'] ?? null}</DescriptionDetails>
        </Box>
        <Box className="col-span-1">
          <DescriptionTerm>Verification status</DescriptionTerm>
          <DescriptionDetails className="mt-2 text-sm text-gray-900">
            <Box className="flex">
              <Badge icon={icon} color={badgeColor} size="xs">
                {badgeText}
              </Badge>
            </Box>
          </DescriptionDetails>
        </Box>
        <Box className="col-span-1">
          <DescriptionTerm>Verified at</DescriptionTerm>
          <DescriptionDetails className="mt-2 text-sm text-gray-900">{verifiedAtTime}</DescriptionDetails>
        </Box>
        {verificationDecision ? (
          <Box className="col-span-full">
            <DescriptionTerm>Verification details</DescriptionTerm>
            <DescriptionDetails className="mt-2">
              <RejectionReasons statusReason={verificationDecision} />
            </DescriptionDetails>
          </Box>
        ) : null}
      </dl>
    </Box>
  )
}
