import classNames from 'classnames'
import { forwardRef, HTMLProps } from 'react'

type TextareaProps = HTMLProps<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(function TextArea(props, ref) {
  return (
    <textarea
      autoComplete="off"
      {...props}
      className={classNames(
        'dark:border-dark-500 dark:bg-dark-800 relative block w-full rounded-md border-gray-300 shadow-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:text-gray-100 sm:text-sm',
        props.className,
      )}
      ref={ref}
    />
  )
})
