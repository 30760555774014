import { gql } from '@apollo/client'

export const QueryRejectionReasons = gql`
  query RejectionReasons($documentType: String!) {
    rejectionReasons(documentType: $documentType) {
      category
      description
      canOverride
      shouldOverwrite
    }
  }
`
