import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { CheckBox } from 'components/CheckBox'
import { Divider } from 'components/Divider'
import { Icon } from 'components/Icon'
import { Loader } from 'components/Loader'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'components/Modal'
import { ModalForm } from 'components/Modals/ModalForm'
import { SearchInput } from 'components/SearchInput'
import { Text } from 'components/Text'
import { TextArea } from 'components/Textarea'
import { useShowDialog } from 'hooks/useDialog'
import { useRejectionReasons } from 'hooks/useRejectionReasons'
import { useRequiredDocuments } from 'hooks/useRequiredDocuments'
import { useSearch } from 'hooks/useSearch'
import pluralize from 'pluralize'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiOutlineDocumentSearch } from 'react-icons/hi'
import { RejectionReason } from 'types/RejectionReason'
import { RejectionReasonsDisclosure } from './RejectionReasonsDisclosure'

interface Props extends ModalProps {
  values?: RejectionReason[]
  onSubmit?: (reasons: RejectionReason[]) => void
  documentType: string
}

export type RejectionReasonsObject = {
  [category: string]: {
    reasons: RejectionReason[]
    isOpen: boolean
  }
}

export function DocumentRejectModal(props: Props) {
  const { allRequiredDocuments } = useRequiredDocuments()
  const isMultiReject = allRequiredDocuments?.find(d => d.documentType === props.documentType)?.owner === 'provider'
  const [input, setInput] = useState('')
  const { isOpen, onClose, onSubmit: propsOnSubmit, values: propsValues, documentType } = props
  const { rejectionReasons, loading } = useRejectionReasons(documentType)
  const { search } = useSearch(setInput, 150)
  const searchRef = useRef<HTMLInputElement>(null)
  const [openKey, setOpenKey] = useState('')
  const onModalOpen = () => searchRef.current?.focus()
  const confirmOverwrite = useShowDialog({
    title: 'Confirm overwrite',
    msg: 'This rejection reason will overwrite any existing reasons. Are you sure you want to continue?',
    variant: 'primary',
  })

  const confirmReplaceOverwrite = useShowDialog({
    title: 'Confirm selection',
    msg: 'The existing rejection reason cannot be combined with other reasons and will be replaced. Are you sure you want to continue?',
    variant: 'primary',
  })

  const { watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      reasons: [] as RejectionReason[],
      additionalNotes: '',
    },
  })
  const values = watch()

  const filteredReasons = rejectionReasons.filter(
    r =>
      r.category.toLowerCase().includes(input.toLowerCase()) ||
      r.description.toLowerCase().includes(input.toLowerCase()),
  )

  const rejectionReasonsObject: RejectionReasonsObject = useMemo(() => {
    return filteredReasons.reduce((r, a) => {
      r[a.category] = r[a.category] || { reasons: [], isOpen: input.length > 0 }
      r[a.category].reasons.push(a)
      r[a.category].isOpen = openKey === a.category || input.length > 0
      return r
    }, {})
  }, [filteredReasons, openKey, input])

  const toggleReason = async (reason: RejectionReason) => {
    const existingIndex = values.reasons.findIndex(
      r => r.category === reason.category && r.description === reason.description,
    )

    if (!isMultiReject) {
      setOpenKey('')
      if (existingIndex > -1) {
        setValue('reasons', [])
        return
      } else {
        setValue('reasons', [reason])
        return
      }
    }

    if (existingIndex > -1) {
      values.reasons.splice(existingIndex, 1)
    } else {
      // A reason with shouldOverwrite: true should replace any existing reasons
      if (reason.shouldOverwrite && values.reasons.length > 0) {
        if (!(await confirmOverwrite.show())) return
        setValue('reasons', [reason])
        return
      }

      // If there is an existing reason with shouldOverwrite: true, it should be replaced
      if (values.reasons.find(f => f.shouldOverwrite)) {
        if (!(await confirmReplaceOverwrite.show())) return
        setValue('reasons', [reason])
        return
      }

      values.reasons.push(reason)
    }

    setValue('reasons', values.reasons)
  }

  const onSubmit = data => {
    const reasons: RejectionReason[] = data.reasons
    if (data.additionalNotes) {
      reasons.push({
        category: 'Other',
        description: data.additionalNotes,
        canOverride: true,
        shouldOverwrite: false,
      })
    }

    propsOnSubmit(reasons)
  }

  useEffect(() => {
    reset({
      reasons: propsValues?.filter(v => v.category !== 'Other') || [],
      additionalNotes: propsValues?.find(v => v.category === 'Other')?.description || '',
    })
  }, [propsValues, reset])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onOpen={onModalOpen}>
      <ModalHeader
        title="Choose rejection reasons"
        icon={<Icon icon={HiOutlineDocumentSearch} size="lg" color="info" />}
        variant="info"
      />
      <ModalForm
        className="w-screen max-w-2xl"
        onSubmit={e => {
          e.stopPropagation()
          e.preventDefault()
          handleSubmit(onSubmit)(e)
        }}
      >
        <ModalBody>
          <Box className="flex flex-col gap-4">
            <Text size="sm" weight="normal">
              Select or search for rejection reasons below to reject this credential. Add extra notes to provide
              additional clarity.
            </Text>
            <Divider />
            <SearchInput
              ref={searchRef}
              placeholder="Search rejection reasons"
              defaultValue={input}
              className="dark:bg-dark-800"
              onChange={e => search(e.target.value)}
            />
            {loading ? (
              <Loader />
            ) : (
              <RejectionReasonsDisclosure
                rejectionReasonsObject={rejectionReasonsObject}
                values={values.reasons}
                input={input}
                onChange={toggleReason}
                handleClick={k => setOpenKey(c => (c === k ? '' : k))}
              />
            )}
            <Divider />
            {values.reasons?.length > 0 ? (
              <Box className="flex flex-col gap-1">
                <Box className="flex items-center justify-between">
                  <Text size="xs">Selected {pluralize('reasons', values.reasons.length)}:</Text>
                  <Button size="xs" variant="link" onClick={() => setValue('reasons', [])}>
                    Clear all
                  </Button>
                </Box>
                <Box className="flex flex-col gap-2">
                  {values.reasons.map((reason, i) => (
                    <Box
                      role="button"
                      key={i}
                      onClick={toggleReason.bind(null, reason)}
                      className="dark:bg-dark-600 dark:hover:bg-dark-500 relative flex cursor-pointer items-center rounded-lg border border-blue-600 bg-white p-4 hover:bg-gray-100 focus:outline-none dark:border-blue-400"
                    >
                      <CheckBox
                        checked={true}
                        onChange={() => {
                          return
                        }}
                        className="cursor-pointer"
                      />
                      <div className="ml-4">
                        <Text size="sm" weight="normal">
                          {reason.description}
                        </Text>
                      </div>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}
            <Box>
              <Text size="xs">Additional notes:</Text>
              <TextArea
                className="mt-2"
                rows={4}
                defaultValue={values.additionalNotes}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue('additionalNotes', e.target?.value)}
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter onClose={onClose} />
      </ModalForm>
    </Modal>
  )
}
