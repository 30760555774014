import { Badge } from '@/components/ui/badge'
import { twMerge } from 'tailwind-merge'
import { Box } from './Box'
import { Text } from './Text'
import { FontSize, TextVariant } from './constants/types'

type RejectionReasonsProps = {
  className?: string
  statusReason: string
  size?: FontSize
  textVariant?: TextVariant
  variant?: 'badge'
}
export default function RejectionReasons({
  className,
  statusReason,
  size = 'sm',
  textVariant = 'base',
  variant,
}: RejectionReasonsProps) {
  const reasons = statusReason
    ?.split('\n\n')
    .filter(Boolean)
    .map((reason, i) => {
      const colonIndex = reason.indexOf(':')
      let category = ''
      let description = ''

      if (colonIndex !== -1) {
        category = reason.slice(0, colonIndex)
        description = reason.slice(colonIndex + 1).trim()
      } else {
        category = 'Rejected'
        description = reason
      }

      if (variant === 'badge') {
        return (
          <Badge key={i} variant="secondary">
            {category}
          </Badge>
        )
      }

      return (
        <Text as="span" size={size} weight="normal" key={i} variant={textVariant}>
          <Text as="span" size={size} weight="semibold" variant={textVariant}>
            {category}:
          </Text>{' '}
          {description}
        </Text>
      )
    })
  return <Box className={twMerge('flex gap-2', variant === 'badge' ? '' : 'flex-col', className)}>{reasons}</Box>
}
