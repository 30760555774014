import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequiredDriverDocument, RequiredVehicleDocument } from 'types'
import { Submission } from 'types/Workspace'

type InitialState = {
  globalActiveDocumentId: string | null
  workspaceDocumentId: string | null
  submission: Submission | null
  isReplacing: boolean
  isChoosing: boolean
  resolvingDocument: RequiredDriverDocument | RequiredVehicleDocument | null
}

const initialState: InitialState = {
  globalActiveDocumentId: null,
  workspaceDocumentId: null,
  submission: null,
  isReplacing: false,
  isChoosing: false,
  resolvingDocument: null,
}

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setGlobalActiveDocumentId: (state, action: PayloadAction<string | null>) => {
      state.globalActiveDocumentId = action.payload
    },
    setWorkspaceDocumentId: (state, action: PayloadAction<string | null>) => {
      state.workspaceDocumentId = action.payload
    },
    setSubmission: (state, action: PayloadAction<Submission | null>) => {
      state.submission = action.payload
    },
    setResolvingDocument: (state, action: PayloadAction<RequiredDriverDocument | RequiredVehicleDocument | null>) => {
      state.resolvingDocument = action.payload
    },
    setIsReplacing: (state, action: PayloadAction<boolean>) => {
      state.isReplacing = action.payload
    },
    setIsChoosing: (state, action: PayloadAction<boolean>) => {
      state.isChoosing = action.payload
    },
  },
})

export const {
  setGlobalActiveDocumentId,
  setWorkspaceDocumentId,
  setSubmission,
  setIsReplacing,
  setIsChoosing,
  setResolvingDocument,
} = documentSlice.actions
