import classNames from 'classnames'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Card } from 'components/Card'
import { CheckBox } from 'components/CheckBox'
import { Text } from 'components/Text'
import { useExtractionFieldOptions } from 'hooks/useExtractionFieldOptions'
import { InputHTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { HiOutlineCursorClick } from 'react-icons/hi'
import { Document } from 'types/Document'
import { FieldContainer } from './FieldContainer'

type FieldProps = {
  children?: ReactNode
  defaultValue?: string
  document?: Document
  id?: string
  inputProps?: React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  name?: string
  required?: boolean
  subtitle?: string | Function
  title: string
  onSetRef?: (ref: HTMLInputElement) => void
  className?: string
  type?: 'text' | 'textarea' | 'boolean'
  width?: string
}

export const Field = (props: FieldProps) => {
  const { defaultValue, subtitle: propsSubtitle, id, title, required, document: doc, className, type = 'text' } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [subtitle, setSubtitle] = useState('')
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const outsideRef = useOnclickOutside(() => setIsOptionsOpen(false))
  const InputComponent: any = type === 'textarea' ? 'textarea' : 'input'

  const { load, data } = useExtractionFieldOptions()

  const updateSubtitle = useCallback(() => {
    if (typeof propsSubtitle === 'function') {
      setSubtitle(propsSubtitle(inputRef?.current.value, doc?.extractedData, doc?.owner))
    } else {
      setSubtitle(propsSubtitle)
    }
  }, [propsSubtitle, doc])

  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.value = defaultValue || ''
  }, [defaultValue])

  useEffect(() => {
    updateSubtitle()
  }, [updateSubtitle, propsSubtitle])

  return (
    <FieldContainer id={id} title={title} required={required} width={props.width}>
      <div className="relative flex flex-1 flex-col overflow-visible" ref={outsideRef}>
        {props.children ? (
          <div className="flex flex-1 items-center justify-start p-2">{props.children}</div>
        ) : props.type === 'boolean' ? (
          <Box className="flex flex-1 items-center justify-center">
            <CheckBox
              name={props.name || props.id}
              id={props.id}
              defaultChecked={!!defaultValue}
              readOnly
              required={props.required}
            />
          </Box>
        ) : (
          <div className="relative flex overflow-visible">
            <InputComponent
              type="text"
              {...props.inputProps}
              name={props.name || props.id}
              id={props.id}
              autoComplete="off"
              className={classNames(
                'dark:bg-dark-900 dark:hover:bg-dark-800 mb-px mr-px block w-full border-0 hover:bg-gray-50 focus:z-10 dark:text-gray-200',
                className,
              )}
              onBlur={() => {
                updateSubtitle()
              }}
              onChange={() => updateSubtitle()}
              onFocus={async () => {
                await load({ documentType: doc?.documentType, field: id })
                setIsOptionsOpen(true)
              }}
              defaultValue={defaultValue}
              ref={inputRef}
              required={props.required}
            />
            {props.onSetRef && (
              <button
                type="button"
                className="dark:border-dark-600 dark:hover:bg-dark-500 -ml-px rounded-r-md border border-gray-300 px-3 py-2 shadow-sm hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onClick={() => props.onSetRef(inputRef.current)}
              >
                <HiOutlineCursorClick className="h-5 w-5 text-gray-700 dark:text-gray-200" />
              </button>
            )}
            {isOptionsOpen && data.length > 0 && (
              <div className="absolute top-full z-10 w-full overflow-visible shadow-lg">
                <Card>
                  <ul>
                    {data.map((option, i) => (
                      <li key={i}>
                        <Button
                          variant="fill"
                          className="px-4 py-2 text-sm font-normal"
                          onClick={() => {
                            setIsOptionsOpen(false)
                            inputRef.current.value = option
                          }}
                        >
                          {option}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </Card>
              </div>
            )}
          </div>
        )}
        {subtitle && (
          <Text
            className="dark:bg-dark-700 border-t border-gray-200 bg-red-50 p-2 text-red-600 dark:border-t-2 dark:border-red-500 dark:text-red-200"
            size="xs"
            variant="light"
            weight="normal"
          >
            {subtitle}
          </Text>
        )}
      </div>
    </FieldContainer>
  )
}
