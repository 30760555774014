import { cn } from '@/lib/utils'
import { forwardRef, ReactNode } from 'react'
import { IconType } from 'react-icons'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { FontSize } from '../constants/types'
import { ColorType, Icon } from '../Icon'
import { Text } from '../Text'

type Props = {
  actions?: ReactNode[] | ReactNode
  border?: boolean
  children: ReactNode
  className?: string
  actionClassName?: string
  titleClassName?: string
  id?: string
  icon?: IconType | null
  iconColor?: ColorType
  iconSize?: FontSize
  subtitle?: ReactNode | string
  title?: string
  titleSize?: FontSize
  subdued?: boolean
  wrapActions?: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

export const Card = forwardRef<HTMLDivElement, Props>(function Card(
  {
    actions,
    border = false,
    children,
    className,
    actionClassName,
    titleClassName,
    icon = HiOutlineInformationCircle,
    iconColor = 'default',
    iconSize = 'lg',
    id,
    onClick,
    style,
    subdued = false,
    subtitle,
    title,
    titleSize = 'base',
    wrapActions = true,
  }: Props,
  ref,
) {
  return (
    <div
      className={cn(
        'flex flex-col rounded-lg shadow',
        border && 'dark:border-dark-500 border border-gray-200',
        subdued ? 'bg-inherit' : 'dark:border-dark-500 dark:bg-dark-800 bg-white dark:border',
        onClick && 'dark:hover:bg-dark-700 dark:active:bg-dark-800 cursor-pointer hover:bg-gray-50 active:bg-white',
        className,
      )}
      id={id}
      onClick={onClick}
      ref={ref}
      style={style}
    >
      {(title || actions) && (
        <div
          className={cn([
            'dark:border-dark-500 relative flex items-center justify-between gap-3 rounded-t-lg border-b border-gray-200 px-6 font-medium md:flex-row',
            titleSize == 'base' ? 'py-4' : 'py-5',
            wrapActions ? 'flex-col' : 'flex-row',
            titleClassName,
          ])}
        >
          {title && (
            <div className={cn(['inline-flex', subtitle ? 'items-start' : 'items-center'])}>
              {icon && <Icon icon={icon} size={iconSize} className={cn(['mr-3 flex-shrink-0'])} color={iconColor} />}
              <div>
                <Text size={titleSize}>{title}</Text>
                {subtitle && (
                  <Text size="sm" variant="light" weight="normal">
                    {subtitle}
                  </Text>
                )}
              </div>
            </div>
          )}
          {actions && <div className={cn('relative ml-0 md:ml-6 lg:ml-9', actionClassName)}>{actions}</div>}
        </div>
      )}
      {children}
    </div>
  )
})
