import { useLazyQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { QueryProvider } from 'internal/graphql/query/provider'
import { useEffect } from 'react'
import { TransportationProvider } from 'types/Provider'

type UseProvider = (
  id: string,
  type?: DocumentNode,
) => { provider: TransportationProvider; loading: boolean; refresh: () => void }

export const useProvider: UseProvider = (id: string | undefined, query = QueryProvider) => {
  const [load, { data, called, loading, refetch }] = useLazyQuery(query, { fetchPolicy: 'no-cache' })
  const { provider }: { provider: TransportationProvider } = data || {}

  useEffect(() => {
    if (!id) {
      return
    }

    load({ variables: { id } })
  }, [id, load])

  return {
    provider,
    loading: !called || loading,
    refresh: refetch,
  }
}
