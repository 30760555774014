import classNames from 'classnames'
import { Button, ButtonVariant } from 'components/Button/Button'
import { MouseEvent, ReactNode } from 'react'

type ModalFooterProps = {
  isOnlyClose?: boolean
  submitText?: string
  isLoading?: boolean
  isDisabled?: boolean
  onClose?: () => void
  children?: ReactNode | ReactNode[]
  className?: string
  variant?: ButtonVariant
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void
  hideOverflow?: boolean
}

export const ModalFooter = (props: ModalFooterProps) => {
  const {
    isDisabled,
    isLoading,
    onClose,
    children,
    className,
    submitText,
    isOnlyClose = false,
    variant = 'primary',
    hideOverflow = false,
    onSubmit,
  } = props

  return (
    <div
      className={classNames(
        'dark:border-dark-500 dark:bg-dark-700 justify-between rounded-b-lg border-t border-gray-200 bg-gray-50 px-4 py-3 dark:border sm:flex sm:flex-row-reverse sm:px-6',
        hideOverflow && 'sticky bottom-0',
      )}
    >
      <div className={classNames('inline-flex space-x-2', className)}>
        {onClose && (
          <Button type="button" onClick={onClose}>
            {isOnlyClose ? 'Close' : 'Cancel'}
          </Button>
        )}
        {!isOnlyClose && (
          <Button
            type="submit"
            variant={variant}
            disabled={isLoading || isDisabled}
            loading={isLoading}
            onClick={onSubmit}
          >
            {submitText || 'Submit'}
          </Button>
        )}
        {children}
      </div>
    </div>
  )
}
