import { gql, useLazyQuery } from '@apollo/client'
import { TextractBlock } from 'types'

const query = gql`
  query DocumentVINs($id: String!) {
    document(id: $id) {
      id
      vins {
        Id
        Text
        Page
        Geometry {
          Polygon {
            X
            Y
          }
        }
      }
    }
  }
`

export function useDocumentVINs(documentId: string) {
  const [load, { data }] = useLazyQuery(query, {
    variables: { id: documentId },
    fetchPolicy: 'no-cache',
  })
  const vins: TextractBlock[] = data?.document?.vins || []

  return {
    load,
    vins,
  }
}
