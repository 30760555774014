import { Box } from 'components/Box'
import { DriversNavLink } from 'components/drivers/DriversNavLink'
import { MetadataSettings } from 'components/providers/MetadataSettings'
import { RequireFlag } from 'components/RequireFlag'
import { RequireRole } from 'components/RequireRole/RequireRole'
import { useAuth } from 'hooks/useAuth'
import { useRemoteConfig } from 'hooks/useRemoteConfig'

import { useFlagArrayValue } from 'hooks/useFlagsmith'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { useWorkspaceInvites } from 'hooks/useWorkspaceInvites'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiOutlineBell,
  HiOutlineChartBar,
  HiOutlineChat,
  HiOutlineClipboardCheck,
  HiOutlineClipboardList,
  HiOutlineCollection,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlineExclamation,
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
  HiOutlineSupport,
  HiOutlineViewGrid,
} from 'react-icons/hi'
import { HiOutlineClipboardDocumentList, HiOutlinePuzzlePiece } from 'react-icons/hi2'
import { RiTaxiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavLink } from './NavLink'

export function ProviderNav() {
  const { stats, hasUnreadAnnouncements } = useSidebarContext()
  const { account } = useAuth()
  const { t } = useTranslation()
  const remoteConfig = useRemoteConfig()
  const isFlagEnabled = useFlagArrayValue('vehicle_inspection_remote_markets', account?.provider?.market?.name)

  return (
    <Nav>
      <NavLink href="/providers/onboarding" icon={HiOutlineCollection}>
        Setup
      </NavLink>

      <NavLink href="/providers/dashboard" icon={HiOutlineViewGrid}>
        {t('dashboard')}
      </NavLink>

      <RequireRole allow="provider">
        <NavLink href="/account/team" icon={HiOutlineOfficeBuilding}>
          {t('organization')}
        </NavLink>
      </RequireRole>

      <RequireFlag flag="workspaces">
        <InvitesNavLink />
      </RequireFlag>

      <Divider />

      <NavLink href="/providers/performance" icon={HiOutlineChartBar}>
        {t('performance')}
      </NavLink>

      <Divider />

      <MetadataSettings setting="enableInsurance">
        <NavLink href="/providers/insurance" icon={HiOutlineDocumentText}>
          {t('insurance')}
        </NavLink>
      </MetadataSettings>

      <DriversNavLink />

      <MetadataSettings setting="enableVehicles">
        <NavLink href="/providers/vehicles" icon={RiTaxiLine} stat={stats?.vehicles}>
          {t('vehicles')}
        </NavLink>
      </MetadataSettings>

      {isFlagEnabled && (
        <NavLink href="/providers/inspections" icon={HiOutlineClipboardDocumentList}>
          Inspections
        </NavLink>
      )}

      <MetadataSettings setting={['enableDrivers', 'enableVehicles']}>
        <NavLink href="/providers/documents" icon={HiOutlineDocumentDuplicate} stat={stats?.documents}>
          {t('credentials')}
        </NavLink>

        <NavLink href="/providers/authorizations" icon={HiOutlineClipboardCheck}>
          Authorizations
        </NavLink>
        <NavLink href="/providers/issues" icon={HiOutlineExclamation} stat={stats?.issues} statVariant="danger">
          {t('issues')}
        </NavLink>
      </MetadataSettings>

      {remoteConfig?.ShowSameDayTripsOptIn &&
        account.provider?.isAcceptingSameDayTrips &&
        !remoteConfig.ExcludedTripRequestTPs?.includes(account.provider?.id) && (
          <Fragment>
            <NavLink href="/providers/trip-requests" icon={HiOutlineLocationMarker} badge={{ title: 'New' }}>
              Trip Requests
            </NavLink>
            <NavLink href="/providers/open-trips" icon={HiOutlineClipboardList} badge={{ title: 'New' }}>
              Open Trips
            </NavLink>
          </Fragment>
        )}

      {remoteConfig?.EnableChat && (
        <NavLink href="/providers/chat" icon={HiOutlineChat}>
          Chat
        </NavLink>
      )}
      <RequireFlag flag="addons">
        <Divider />
        <NavLink href="/addons" icon={HiOutlinePuzzlePiece} external>
          Add-ons
        </NavLink>
        <Divider />
      </RequireFlag>
      <Box>
        <RequireFlag flag="platform_announcements">
          <NavLink href="/announcements" icon={HiOutlineBell} dot={hasUnreadAnnouncements}>
            Announcements
          </NavLink>
        </RequireFlag>

        <NavLink href="https://help.complicore.co" icon={HiOutlineSupport}>
          {t('help')}
        </NavLink>
      </Box>
    </Nav>
  )
}

function Divider() {
  return <div className="dark:border-dark-500 mx-6 my-2 border-t border-white/10" />
}

function InvitesNavLink() {
  const { invites } = useWorkspaceInvites()

  return (
    <NavLink href="/invites" icon={HiOutlineMail} stat={invites.length > 0 ? invites.length : undefined}>
      Invites
    </NavLink>
  )
}
