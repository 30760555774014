import { County, Zip } from 'internal/types/map'
import { LngLatBoundsLike } from 'maplibre-gl'
import { ServiceArea } from 'types'
import { Geometry } from 'wkx'

export const getCountyFeatures = (counties: County[]) => {
  return counties.map(county => ({
    type: 'Feature',
    geometry: Geometry.parse(county.geom).toGeoJSON(),
    id: county.geoid,
    properties: {
      geoid: county.geoid,
      name: county.name,
      state: county.state,
      code: county.code,
    },
  }))
}

export const getZipsFeatures = (zips: Zip[], serviceArea?: ServiceArea[]) => {
  if (!serviceArea) {
    return zips.map(zip => ({
      type: 'Feature',
      geometry: Geometry.parse(zip.geom).toGeoJSON(),
      id: zip.postal_code,
      properties: {
        code: zip.postal_code,
        name: zip.name,
        county: zip.county,
        state: zip.state,
        label: zip.label,
        count: zip.count || 0,
        pinned: zip.pinned || 0,
        selected: zip.selected || false,
      },
    }))
  }

  const serviceAreaMap: Record<string, string | null> = {}
  serviceArea.forEach(area => {
    if (area?.postalCode) {
      serviceAreaMap[area?.postalCode] = area?.serviceType
    }
  })

  return zips.map(zip => {
    const serviceType = serviceAreaMap?.[zip?.postal_code] || null

    return {
      type: 'Feature',
      geometry: Geometry.parse(zip.geom).toGeoJSON(),
      id: zip.postal_code,
      properties: {
        serviceType,
        code: zip.postal_code,
        name: zip.name,
        county: zip.county,
        state: zip.state,
        label: zip.label,
        count: zip.count || 0,
        pinned: zip.pinned || 0,
        selected: zip.selected || false,
      },
    }
  })
}

export const getFitBounds = (geometry: { coordinates: any; type: 'Polygon' | 'MultiPolygon' }): LngLatBoundsLike => {
  let coords: number[][]

  if (geometry.type === 'Polygon') {
    coords = geometry.coordinates.flat()
  } else {
    coords = geometry.coordinates.flat(2)
  }

  const areaBounds = {
    min: { lat: Number(coords[0][1]), lng: Number(coords[0][0]) },
    max: { lat: Number(coords[0][1]), lng: Number(coords[0][0]) },
  }

  coords.forEach((coord: number[]) => {
    const [lng, lat] = coord
    areaBounds.min.lng = Math.min(lng, areaBounds.min.lng)
    areaBounds.max.lng = Math.max(lng, areaBounds.max.lng)
    areaBounds.min.lat = Math.min(lat, areaBounds.min.lat)
    areaBounds.max.lat = Math.max(lat, areaBounds.max.lat)
  })

  return [
    [areaBounds.min.lng, areaBounds.min.lat],
    [areaBounds.max.lng, areaBounds.max.lat],
  ]
}

export const getFeatureBounds = (features: { geometry: { coordinates: any; type: 'Polygon' | 'MultiPolygon' } }[]) => {
  const bounds = features.map(f => getFitBounds(f.geometry)).flat()

  const areaBounds = {
    min: { lat: Number(bounds[0][1]), lng: Number(bounds[0][0]) },
    max: { lat: Number(bounds[0][1]), lng: Number(bounds[0][0]) },
  }

  bounds.forEach(coord => {
    const [lng, lat] = coord as number[]
    areaBounds.min.lng = Math.min(lng, areaBounds.min.lng)
    areaBounds.max.lng = Math.max(lng, areaBounds.max.lng)
    areaBounds.min.lat = Math.min(lat, areaBounds.min.lat)
    areaBounds.max.lat = Math.max(lat, areaBounds.max.lat)
  })

  return [
    [areaBounds.min.lng, areaBounds.min.lat],
    [areaBounds.max.lng, areaBounds.max.lat],
  ]
}
