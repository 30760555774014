import { gql } from '@apollo/client'

export const FRAGMENTS = gql`
  fragment CheckDetails on CheckResult {
    id
    name
    result
    details
    createdAt
  }
`
