import { gql } from '@apollo/client'

export const VEHICLE_FRAGMENTS = gql`
  fragment VehicleDetails on Vehicle {
    id
    providerId
    documentGroupId
    fleetNumber
    vin
    plate
    year
    make
    model
    color
    isActive
    isAuthorized
    isCompliant
    compliantUntil
    statusReason
    fleetNumber
    documentGroupId
    createdAt
    updatedAt
  }
`
