import { Loader } from 'components/Loader'
import { useRequirementsProviderContext } from 'contexts/providers/RequirementsProvider'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import Onboarding from 'pages/providers/onboarding'

const allowListPaths = ['providers/onboarding', '/providers/insurance', /^\/account/, /^\/setup/]

export function Redirector({ children }: { children: any }) {
  const { account } = useAuth()
  const router = useRouter()
  const { loading, hasMissingRequirements } = useRequirementsProviderContext()

  if (!account) return children
  if (loading) return <Loader />

  if (hasMissingRequirements) {
    if (
      allowListPaths.some(path => {
        if (typeof path !== 'string') {
          return path.test(router.pathname)
        } else {
          return router.pathname === path
        }
      })
    ) {
      return children
    }

    return <Onboarding />
  }

  return children
}
