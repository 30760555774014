import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { QueryResponse } from 'types'
import { WorkspaceEntity } from 'types/Workspace'

type WorkspaceEntitiesInput = {
  workspaceId?: string
  type?: string
}

export const QueryWorkspaceEntities = gql`
  query WorkspaceEntities($options: WorkspaceEntitiesInput!) {
    workspaceEntities(options: $options) {
      total
      results {
        id
        name
        type
        createdAt
        workspaceId

        links {
          requirementGroupId
          legacyId
          brokerWorkspaceId
        }
      }
    }
  }
`

export function useWorkspaceEntities(options?: WorkspaceEntitiesInput, skip?: boolean) {
  const { data, loading, error, refetch } = useQuery<QueryResponse<'workspaceEntities', WorkspaceEntity>>(
    QueryWorkspaceEntities,
    {
      skip,
      fetchPolicy: 'no-cache',
      variables: {
        options,
      },
    },
  )

  const total = data?.workspaceEntities?.total || 0
  const entities = useMemo(() => data?.workspaceEntities?.results || [], [data?.workspaceEntities?.results])

  return {
    total,
    entities,
    loading,
    error,
    refetch,
  }
}
