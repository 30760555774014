import { cn } from '@/lib/utils'
import { forwardRef } from 'react'
import ReactInputMask from 'react-input-mask'
import { Input, InputProps } from './Input'

export const PhoneInput = forwardRef<HTMLInputElement, InputProps>(function PhoneInput(props: InputProps, ref) {
  return (
    <Input
      as={ReactInputMask}
      containerClassName="flex-1"
      placeholder="(xxx) xxx-xxxx"
      mask="(999) 999-9999"
      maskChar="x"
      ref={ref as any}
      {...props}
      className={cn(props.className)}
    />
  )
})
