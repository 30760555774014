import { Box } from 'components/Box'
import { Card } from 'components/Card'
import { Text } from 'components/Text'
import { HiCheckCircle, HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineDocumentText } from 'react-icons/hi'
import { RequiredDocument, RequiredProviderDocument, TransportationProvider } from 'types'

import classNames from 'classnames'
import { Button } from 'components/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { DocumentStatusBadge } from 'components/Documents/DocumentStatusBadge'
import { NewDocumentModal } from 'components/Modals/NewDocumentModal'
import { REQUIRE_LEVEL } from 'constants/document'
import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import { useShowDialog } from 'hooks/useDialog'
import { useFlags } from 'hooks/useFlagsmith'
import { useModalConfirmation } from 'hooks/useModalConfirmation'
import { createAttestation } from 'lib/api/document'
import { useEffect, useMemo, useState } from 'react'
import { AttestationModal } from '../AttestationModal'
import { InsuranceDocuments as Asset } from './assets/InsuranceDocuments'
import { OnboardingStep } from './OnboardingStep'

type Props = {
  provider: TransportationProvider
  refresh: () => void
}

const hasSubmittedDocument = (rd: RequiredDocument) => Boolean(rd.pending && rd.pending?.status !== 'failed')
const hasCompletedDocument = (rd: RequiredDocument) =>
  rd.primary?.status === 'approved' && (!rd.primary.expiresAt || rd.primary.expiresAt > Date.now())

export function InsuranceDocuments({ provider, refresh }: Props) {
  const { account } = useAuth()
  const flags = useFlags(['require_insurance_documents', 'coi'])
  const { component: attestationComponent, confirm: confirmAttestation } = useModalConfirmation(AttestationModal)
  const confirmUpload = useShowDialog({
    title: 'Attestation Required',
    variant: 'primary',
    confirm:
      'The Provider acknowledges and agrees that by typing "ACCEPT" in the input below, you have the authority to make the attestation on behalf of the Provider; that the documents being uploaded contain information that is true, accurate, and complete. \nTo continue, please type ACCEPT into the box below.',
    confirmPhrase: 'ACCEPT',
    confirmCaseSensitive: false,
    onCancel: () => {},
    onConfirm: () => {},
  })
  const { requiredDocuments } = provider || {}
  const steps = useMemo(() => {
    return requiredDocuments?.map(rd => hasSubmittedDocument(rd) || hasCompletedDocument(rd))
  }, [requiredDocuments])
  const activeStepIndex = useMemo(() => {
    const index = steps?.findIndex(step => !step)
    return index !== -1 ? index : null
  }, [steps])

  const shouldRequireInsuranceDocuments = flags?.require_insurance_documents?.enabled
  const [expanded, setExpanded] = useState(true)
  const [uploadingDocument, setUploadingDocument] = useState<RequiredProviderDocument | null>(null)
  const completedAllSteps = steps?.every(Boolean) && requiredDocuments.every(rd => hasCompletedDocument(rd))
  const isCoiEnabled = flags?.coi?.enabled

  useEffect(() => {
    if (completedAllSteps) {
      setExpanded(false)
    }
  }, [completedAllSteps])

  const onBeforeSubmit = async () => {
    if (isCoiEnabled) {
      if (!(await confirmAttestation())) return
    } else {
      if (!(await confirmUpload.show())) return
    }

    return Promise.resolve()
  }

  const onAfterSubmit = async (data: { documentType: string; documentId: string }) => {
    if (isCoiEnabled) {
      const name = `${account?.firstName?.toUpperCase()} ${account?.lastName?.toUpperCase()}`
      const providerName = `${account?.provider?.name?.toUpperCase()}`

      const res = await createAttestation({
        documentId: data.documentId,
        type: data.documentType,
        details: {
          date: format(new Date(), 'MM/dd/yyyy h:mm a'),
          name,
          providerName,
        },
      })

      if (!res.ok) {
        throw new Error('Failed to create attestation')
      }
    }
  }

  if (!requiredDocuments || requiredDocuments?.length === 0) {
    return null
  }

  return (
    <Card
      wrapActions={false}
      icon={HiOutlineDocumentText}
      title="Insurance Documents"
      subtitle="Upload an original copy of your provider insurance documents."
      actions={
        <IconButton
          variant="ghost"
          icon={completedAllSteps ? HiCheckCircle : expanded ? HiOutlineChevronUp : HiOutlineChevronDown}
          className={classNames('-mr-4', completedAllSteps && 'text-blue-600')}
          onClick={() => setExpanded(!expanded)}
          ariaLabel={expanded ? 'Collapse organization card' : 'Expand organization card'}
        />
      }
    >
      {expanded && (
        <Box className="flex flex-col-reverse p-4 sm:flex-row">
          <Box className="flex-1 space-y-2">
            {requiredDocuments?.map((rd, index) => {
              const document = rd.pending || rd.primary
              const completed = hasCompletedDocument(rd)
              const submitted = hasSubmittedDocument(rd)

              return (
                <OnboardingStep
                  key={rd.documentType}
                  defaultOpen={activeStepIndex === index}
                  missingRequirement={
                    shouldRequireInsuranceDocuments &&
                    rd.requireLevel === REQUIRE_LEVEL.REQUIRED &&
                    !(completed || submitted)
                  }
                  severity="danger"
                  label={
                    <Box
                      key={rd.documentType}
                      className="flex flex-1 flex-wrap items-center justify-between gap-2 sm:gap-4"
                    >
                      <Text
                        size="sm"
                        weight={completed || submitted ? 'normal' : 'medium'}
                        variant={completed || submitted ? 'light' : 'base'}
                        className="text-left"
                      >
                        {rd.name}
                      </Text>
                      {submitted && <DocumentStatusBadge exemption={null} status={document?.status} />}
                    </Box>
                  }
                  description="A valid and approved submission of this document is required by Modivcare's Credentialing Services Team. Upload it now by clicking the “Upload Document” button below."
                  actions={
                    <Button variant="primary" onClick={() => setUploadingDocument(rd)}>
                      Upload Document
                    </Button>
                  }
                  completed={completed}
                  expandable={!submitted}
                />
              )
            })}
          </Box>
          <Box className="hidden h-60 opacity-70 sm:block">
            <Asset />
          </Box>
        </Box>
      )}
      <NewDocumentModal
        ownerId={provider?.id}
        ownerName={provider?.name}
        ownerType="provider"
        provider={provider}
        requiredDocument={uploadingDocument}
        isOpen={Boolean(uploadingDocument)}
        onBeforeSubmit={onBeforeSubmit}
        onAfterSubmit={onAfterSubmit}
        onClose={() => {
          setUploadingDocument(null)
          refresh()
        }}
        refresh={refresh}
      />
      {attestationComponent}
    </Card>
  )
}
