import { CheckResult } from 'types/Check'
import { ChecksList } from '../ChecksList'

type ChecksFeedProps = {
  checks?: CheckResult[]
}

export const ChecksFeed = ({ checks }: ChecksFeedProps) => {
  return (
    <div className="mt-6 flow-root">
      {(!checks || !checks.length) && (
        <div className="mb-4 px-6 py-4 text-gray-600 dark:text-gray-300">No checks have been run</div>
      )}
      <ChecksList checks={checks} showLine={false} />
    </div>
  )
}
