import { SidebarContext } from 'components/sidebar/SidebarContext'
import { useAuth } from 'hooks/useAuth'
import { useNotifications } from 'hooks/useNotifications'

import { fetcher } from 'lib/fetcher'
import { useCallback, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import useSWR from 'swr'

export type SidebarTheme = {
  background: string
  navGroup: string
  navGroupActive: string
  navLink: string
  navLinkPadding: string
  navLinkActive: string
  navIcon: string
  navIconActive: string
  navStat: string
}

export type SidebarContextType = {
  stats: any
  theme: SidebarTheme
  isCollapsed: boolean
  toggleCollapsed: (collapsed?: boolean, store?: boolean) => void
  expanded: boolean
  toggleExpanded: () => void
  hasUnreadAnnouncements: boolean
}

const theme: SidebarTheme = {
  background: 'bg-gray-900 dark:bg-dark-800',
  navGroup: 'text-gray-400 hover:bg-gray-700 dark:hover:bg-dark-400 hover:text-white hover:shadow',
  navGroupActive: 'bg-gray-800 dark:bg-dark-500 text-white font-medium',
  navLink: 'py-3 text-md text-gray-700 hover:bg-gray-100 hover:shadow',
  navLinkPadding: 'py-2.5 px-3',
  navLinkActive: 'py-3 text-md bg-gray-100 font-medium',
  navIcon: 'text-gray-300 h-5 w-5',
  navIconActive: 'text-gray-300 h-5 w-5',
  navStat: 'bg-gray-700 dark:bg-dark-500 group-hover:bg-gray-800 dark:group-hover:bg-dark-400',
}

const defaultIsCollapsed = typeof window === 'undefined' ? false : localStorage?.getItem('sidebarCollapsed') === '1'

export function SidebarProvider({ children }: { children: React.ReactNode }) {
  const isWide = useMedia('(min-width: 64rem)', true)
  const { account } = useAuth()
  const { data, mutate } = useSWR(`/v1/stats`, fetcher)
  const [expanded, setIsExpanded] = useState(false)
  const toggleExpanded = v => setIsExpanded(e => v ?? !e)
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed)
  const toggleCollapsed = useCallback(
    (v, store = true) => {
      setIsCollapsed(c => {
        const value = v ?? !c
        if (store) {
          localStorage?.setItem('sidebarCollapsed', value ? '1' : '0')
        }

        return value
      })
    },
    [setIsCollapsed],
  )
  const { stats } = data || {}
  const collapsed = !isWide ? false : isCollapsed
  const { all: announcements } = useNotifications()

  useEffect(() => {
    if (account && !account.workspace?.id) mutate()
  }, [account, mutate])

  return (
    <SidebarContext.Provider
      value={{
        stats,
        theme,
        isCollapsed: collapsed,
        hasUnreadAnnouncements: announcements?.some(a => !a.seenAt),
        toggleCollapsed,
        expanded,
        toggleExpanded,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
