import { DocumentExtraction, DocumentLink } from 'types/Document'

export const checkExpiration: (
  value: string,
  extractedData: DocumentExtraction,
  owner: DocumentLink,
) => string | null = value => {
  if (!value) {
    return null
  }

  const expiration = new Date(value)
  if (!expiration.getTime()) {
    return 'Invalid date'
  }

  const now = new Date()
  const diff = expiration.getTime() - now.getTime()
  const days = Math.round(diff / (1000 * 60 * 60 * 24))
  if (days < 0) {
    return `Expiration date is in the past`
  }

  return null
}
