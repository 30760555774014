import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Document } from 'types/Document'

type AdminState = {
  activeDocument: Document | null
}

const initialState: AdminState = {
  activeDocument: null,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setActiveDocument: (state, action: PayloadAction<Document>) => {
      state.activeDocument = action.payload
    },
  },
})

export const { setActiveDocument } = adminSlice.actions
