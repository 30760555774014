import { DriverAttributes } from 'components/Entities/driver/types'
import { Account } from 'types/Account'
import { Driver } from 'types/Driver'

export const formatName = (person: Driver | Account) => {
  return [person.firstName, person.middleName, person.lastName, (person as Driver).suffix].filter(n => !!n).join(' ')
}

export const extractAttributes = (driver: Driver): DriverAttributes => {
  return {
    firstName: driver?.firstName || '',
    lastName: driver?.lastName || '',
    middleName: driver?.middleName || '',
    suffix: driver?.suffix || '',
    email: driver?.email || '',
    phone: driver?.phone || '',
    streetAddress: driver?.streetAddress || '',
    streetAddress2: driver?.streetAddress2 || '',
    city: driver?.city || '',
    state: driver?.state || '',
    zip: driver?.zip || '',
    dob: driver?.dob || '',
    ssn: driver?.ssn || '',
    driverNumber: driver?.driverNumber || '',
  }
}
