/* eslint-disable camelcase */
import { gql, useQuery } from '@apollo/client'
import { hasCompletedDocument, hasSubmittedDocument, parseJsonArray } from 'components/providers/onboarding/utilities'
import { REQUIRE_LEVEL } from 'constants/document'
import { useFlags, useFlagsmithLoading } from 'hooks/useFlagsmith'
import { TransportationProvider } from 'types'
import { useProviderMetadata } from './useProviderMetadata'

export const ProviderMissingRequirementsQuery = gql`
  query Provider($id: String!) {
    provider(id: $id) {
      id
      market {
        name
      }
      serviceArea {
        id
      }
      tripCapacity {
        id
      }
      requiredDocuments {
        requireLevel
        primary {
          id
          status
          expiresAt
        }
        pending {
          id
          status
          expiresAt
        }
      }
    }
  }
`

export type MissingRequirements = {
  loading: boolean
  missingRequirements: {
    serviceArea: boolean
    tripCapacity: boolean
    insuranceDocuments: boolean
  }
  refetch: () => void
}

export function useHasMissingRequirements(id?: string): MissingRequirements {
  const flags = useFlags(['require_insurance_documents', 'new_teams_page_markets', 'require_team_info_markets'])
  const metadata = useProviderMetadata()
  const flagsmithLoading = useFlagsmithLoading()
  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery<{ provider: TransportationProvider }>(ProviderMissingRequirementsQuery, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'no-cache',
  })

  const { provider } = data || {}
  const marketName = provider?.market?.name

  const newTeamsPageMarkets: string[] = parseJsonArray(flags['new_teams_page_markets']?.value as string)
  const shouldShowNewTeamsPage = flags['new_teams_page_markets']?.enabled && newTeamsPageMarkets?.includes(marketName)

  const teamInfoMarkets: string[] = parseJsonArray(flags['require_team_info_markets']?.value as string)
  const shouldRequireTeamInfo = flags['require_team_info_markets']?.enabled && teamInfoMarkets.includes(marketName)

  const requiresOrgInfo = shouldShowNewTeamsPage && shouldRequireTeamInfo
  const requiresInsuranceDocuments = metadata?.enableInsurance && flags['require_insurance_documents']?.enabled

  const shouldCheckRequirements = requiresOrgInfo || requiresInsuranceDocuments

  if (!shouldCheckRequirements) {
    return {
      loading: false,
      missingRequirements: {
        serviceArea: false,
        tripCapacity: false,
        insuranceDocuments: false,
      },
      refetch,
    }
  }

  const missingRequirements = {
    serviceArea: requiresOrgInfo ? !provider?.serviceArea?.length : false,
    tripCapacity: requiresOrgInfo ? !provider?.tripCapacity?.length : false,
    insuranceDocuments: requiresInsuranceDocuments
      ? !!provider?.requiredDocuments?.some(
          rd => rd.requireLevel === REQUIRE_LEVEL.REQUIRED && !(hasSubmittedDocument(rd) || hasCompletedDocument(rd)),
        )
      : false,
  }

  return {
    loading: (!data && queryLoading) || flagsmithLoading?.isLoading,
    missingRequirements,
    refetch,
  }
}
