import { combineReducers, configureStore, createSlice, PayloadAction, PreloadedState } from '@reduxjs/toolkit'
import { WorkspaceLink } from 'types/Workspace'
import { authSlice } from './slices/auth'
import { dialogSlice } from './slices/dialog'
import { documentSlice } from './slices/document'
import { entitySlice } from './slices/entity'
import { mapSlice } from './slices/map'
import { overviewSlice } from './slices/network/map/overview'
import { packetSlice } from './slices/packet'
import { workspaceSlice } from './slices/workspace'

const globalSlice = createSlice({
  name: 'global',
  initialState: { error: false, loading: false },
  reducers: {
    setGlobalError(state) {
      state.error = true
    },
    setGlobalLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

const rootReducer = combineReducers({
  global: globalSlice.reducer,
  auth: authSlice.reducer,
  dialog: dialogSlice.reducer,
  document: documentSlice.reducer,
  map: mapSlice.reducer,
  overview: overviewSlice.reducer,
  workspace: workspaceSlice.reducer,
  entity: entitySlice.reducer,
  packet: packetSlice.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

export const store = setupStore()

export const selectAccount = (state: RootState) => state.auth.account
export const selectAuth = (state: RootState) => state.auth
export const selectDialogs = (state: RootState) => state.dialog
export const selectGlobalError = (state: RootState) => state.global.error
export const selectGlobalLoading = (state: RootState) => state.global.loading
export const selectWorkspaceDocumentId = (state: RootState) => state.document.workspaceDocumentId
export const selectSubmission = (state: RootState) => state.document.submission
export const selectGlobalActiveDocumentId = (state: RootState) => state.document.globalActiveDocumentId
export const selectResolvingDocument = (state: RootState) => state.document.resolvingDocument
export const selectIsReplacing = (state: RootState) => state.document.isReplacing
export const selectIsChoosing = (state: RootState) => state.document.isChoosing

export const selectPacket = (state: RootState) => state.packet.packet

export const selectBrokers = (state: RootState): WorkspaceLink[] => state.workspace.brokers
export const selectWorkspaces = (state: RootState) => state.workspace.workspaces
export const selectActiveWorkspace = (state: RootState) => state.workspace.activeWorkspace
export const selectProviderEntity = (state: RootState) => state.workspace.providerEntity

export const selectEntity = (state: RootState) => state.entity.entity
export const selectEntityLoading = (state: RootState) => state.entity.loading
export const selectEntityRequirements = (state: RootState) => state.entity.entityRequirements
export const selectEntitySubmissions = (state: RootState) => state.entity.submissions
export const selectEntityPackets = (state: RootState) => state.entity.packets

export const selectSelectedCounty = (state: RootState) => state.map.selectedCounty
export const selectSelectedZip = (state: RootState) => state.map.selectedZip
export const selectHighlightedId = (state: RootState) => state.map.highlightedId
export const selectMapLoading = (state: RootState) => state.map.loading
export const selectMapModeKey = (state: RootState) => state.map.modeKey
export const selectMapSources = (state: RootState) => state.map.sources
export const selectServiceArea = (state: RootState) => state.map.serviceArea
export const selectBounds = (state: RootState) => state.map.bounds
export const selectZoom = (state: RootState) => state.map.zoom

export const selectSelectedProviderId = (state: RootState) => state.overview.selectedProviderId
export const selectLevelOfService = (state: RootState) => state.overview.levelOfService
export const selectServiceLevels = (state: RootState) => state.overview.serviceLevels
export const selectServiceLevel = (state: RootState) => state.overview.serviceLevel
export const selectOverviewProviders = (state: RootState) => state.overview.providers
export const selectPinnedProviders = (state: RootState) => state.overview.pinned
export const selectOverviewLoading = (state: RootState) => state.overview.loading

export const { setGlobalError, setGlobalLoading } = globalSlice.actions

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
