import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModeKey, Source } from 'components/Map/types'
import { County, SelectedZip } from 'internal/types/map'
import { ServiceArea } from 'types'

import { MODES } from 'components/Map/modes'
import { getFeatureBounds, getFitBounds } from 'components/Map/utilities'
import { LngLatBoundsLike } from 'maplibre-gl'
import { loadCounties, loadCounts, loadServiceArea, loadZip, loadZips } from '../map/actions'

type MapState = {
  modeKey: ModeKey | null
  sources: Source[]
  loading: boolean
  selectedCounty: Partial<County> | null
  selectedZip: SelectedZip | null
  serviceArea: ServiceArea[]
  highlightedId: string | null
  bounds: LngLatBoundsLike | null
  zoom: number | null
}

const initialState: MapState = {
  modeKey: null,
  sources: [],
  loading: false,
  selectedCounty: null,
  selectedZip: null,
  serviceArea: [],
  highlightedId: null,
  bounds: null,
  zoom: null,
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<ModeKey>) {
      state.modeKey = action.payload
      state.sources = MODES[action.payload].map(m => m.source)
    },
    setSelectedCounty(state, action: PayloadAction<string | null>) {
      if (!action.payload) {
        state.selectedCounty = null
        state.modeKey = 'providerCounties'
        state.sources = MODES['providerCounties'].map(m => m.source)
        return
      }

      const countiesSource = state.sources.find(s => s.id === 'counties')
      const county = countiesSource?.config.data?.features.find(f => f.id === action.payload) || null
      if (!county) return

      state.selectedCounty = {
        geoid: county?.id,
        name: county?.properties?.name,
        state: county?.properties?.state,
        code: county?.properties?.code,
      }
    },
    setSelectedZip: (state, action: PayloadAction<string | null>) => {
      const { features } = state.sources[0].config?.data || {}
      if (!features) return
      const prevSelectedZip = features.find(f => f.id === state.selectedZip?.properties?.code)
      if (prevSelectedZip) prevSelectedZip.properties.selected = false

      if (!action.payload) {
        state.selectedZip = null
        return
      }

      const zip = features.find(f => f.id === action.payload)
      if (!zip) return

      zip.properties.selected = true
      state.selectedZip = zip
      const bounds = getFitBounds(zip.geometry) || null
      state.bounds = bounds
    },
    setServiceArea(state, action: PayloadAction<ServiceArea[]>) {
      state.serviceArea = action.payload
    },
    selectZip(state, action: PayloadAction<string>) {
      const { features } = state.sources[0].config?.data || {}
      if (!features) return

      const zip = features.find(f => f.id === action.payload)
      if (!zip) return

      const selected = zip.properties?.selected || false
      zip.properties.selected = !selected
    },
    selectAllZips(state, action: PayloadAction<boolean>) {
      const { features } = state.sources[0].config?.data || {}
      if (!features) return

      features.forEach(f => {
        f.properties.selected = action.payload
      })
    },
    setHighlightedId(state, action: PayloadAction<string | null>) {
      state.highlightedId = action.payload
    },
    setBounds(state, action: PayloadAction<LngLatBoundsLike | null>) {
      state.bounds = action.payload
    },
    setZoom(state, action: PayloadAction<number>) {
      state.zoom = action.payload
    },
    updateSource(
      state,
      action: PayloadAction<{
        id: string
        data: {
          type: string
          features: any[]
        }
      }>,
    ) {
      const source = state.sources.find(s => s.id === action.payload.id)
      if (!source) return

      source.config.data = action.payload.data
    },
    resetMap(state) {
      for (const key in initialState) {
        state[key] = initialState[key]
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadCounties.pending, state => {
        state.loading = true
      })
      .addCase(loadCounties.fulfilled, (state, action) => {
        state.loading = false

        if (action.payload instanceof Error) {
          return
        }

        const config = MODES[state.modeKey]?.find(m => m.id === 'counties')
        const countiesSource = state.sources.find(s => s.id === 'counties')
        if (countiesSource && config.featureData) {
          countiesSource.config.data = config.featureData({ counties: action.payload })
        }
      })
      .addCase(loadCounties.rejected, state => {
        state.loading = false
      })
      .addCase(loadZips.pending, state => {
        state.loading = true
      })
      .addCase(loadZips.fulfilled, (state, action) => {
        state.loading = false

        if (action.payload instanceof Error) {
          return
        }

        const config = MODES[state.modeKey]?.find(m => m.id === 'zipcodes')
        const zipCodesSource = state.sources.find(s => s.id === 'zipcodes')
        if (zipCodesSource && config.featureData) {
          const data = config.featureData({
            prevZips: zipCodesSource.config?.data?.features,
            zips: action.payload,
            serviceArea: state.serviceArea,
            selectedZipCode: state.selectedZip?.properties?.code,
          })

          zipCodesSource.config.data = data
        }
      })
      .addCase(loadZips.rejected, state => {
        state.loading = false
      })
      .addCase(loadZip.pending, state => {
        state.loading = true
      })
      .addCase(loadZip.fulfilled, (state, action) => {
        state.loading = true
        if (!action.payload) return

        const config = MODES[state.modeKey]?.find(m => m.id === 'zipcodes')
        const zipCodesSource = state.sources.find(s => s.id === 'zipcodes')

        if (zipCodesSource && config.featureData) {
          const data = config.featureData({
            zips: [action.payload],
            selectedZipCode: action.payload.postal_code as string,
          })

          zipCodesSource.config.data = data
        }
      })
      .addCase(loadZip.rejected, state => {
        state.loading = false
      })
      .addCase(loadServiceArea.fulfilled, (state, action) => {
        const config = MODES[state.modeKey]?.find(m => m.id === 'serviceArea')
        const serviceAreaSource = state.sources.find(s => s.id === 'serviceArea')
        if (serviceAreaSource && config.featureData) {
          serviceAreaSource.config.data = {
            type: 'FeatureCollection',
            features: action.payload,
          }

          if (action.payload?.length > 1) {
            state.bounds = getFeatureBounds(action.payload as any) as LngLatBoundsLike
          }
        }
      })
      .addCase(loadCounts.fulfilled, (state, action) => {
        const zipCodesSource = state.sources.find(s => s.id === 'zipcodes')
        const features = zipCodesSource?.config.data?.features
        if (features?.length > 0) {
          const zipsMap = {}
          action.payload.forEach(z => {
            zipsMap[z.zipCode] = z
          })

          zipCodesSource.config.data.features.forEach(f => {
            const z = zipsMap[f.properties?.code]

            f.properties = {
              ...f.properties,
              label: z ? `${z?.pinned > 0 ? `${z.pinned} / ` : ''}${z.count || ''}` : '',
              count: z?.count || 0,
              pinned: z?.pinned || 0,
            }
          })
        }
      })
  },
})

export const {
  setMode,
  setSelectedCounty,
  setSelectedZip,
  setServiceArea,
  selectZip,
  selectAllZips,
  setHighlightedId,
  setBounds,
  setZoom,
  updateSource,
  resetMap,
} = mapSlice.actions
