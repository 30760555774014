import classNames from 'classnames'
import { Box } from 'components/Box'
import { IconType } from 'react-icons'

type BannerProps = {
  align?: string
  icon?: IconType
  title?: string
  msg?: string | React.ReactNode
  shortMsg?: string
  truncate?: boolean
  action?: React.ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: 'warning' | 'error' | 'success' | 'info' | 'muted'
  onClose?: () => void
}

const iconSizes = {
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
}

const textSizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-base',
}

const padding = {
  xs: 'p-2',
  sm: 'p-3',
  md: 'p-4',
  lg: 'p-6',
}

const textVariants = {
  info: 'text-blue-800',
  warning: 'text-yellow-800',
  error: 'text-red-800',
  success: 'text-green-800',
  muted: 'text-gray-800',
}
const subtitleVariants = {
  info: 'text-blue-700',
  warning: 'text-yellow-700',
  error: 'text-red-700',
  success: 'text-green-700',
  muted: 'text-gray-700',
}

const variants = {
  info: 'bg-blue-50 border border-blue-300',
  warning: 'bg-yellow-50 border border-yellow-300',
  error: 'bg-red-50 border border-red-300',
  success: 'bg-green-50 border border-green-300',
  muted: 'bg-gray-50 border border-gray-300',
}

const rounding = {
  xs: 'rounded-sm',
  sm: 'rounded-md',
  md: 'rounded-md',
  lg: 'rounded-md',
}

const iconVariants = {
  info: 'text-blue-400',
  warning: 'text-yellow-400',
  error: 'text-red-400',
  success: 'text-green-400',
  muted: 'text-gray-400',
}

export function Banner(props: BannerProps) {
  const { icon: Icon = null, align, title, msg, action = null, size = 'md', variant = 'info' } = props

  return (
    <div className={classNames(padding[size], variants[variant], rounding[size])}>
      <div className={classNames('flex gap-3', align === 'center' && 'items-center')}>
        {Icon && (
          <div className="flex-shrink-0">
            <Icon className={classNames(iconSizes[size], iconVariants[variant])} aria-hidden="true" />
          </div>
        )}
        <div className="space-y-2">
          {title && <h3 className={classNames('font-medium', textSizes[size], textVariants[variant])}>{title}</h3>}
          {msg && (
            <div className={classNames('text-sm', textSizes[size], subtitleVariants[variant])}>
              <div>{msg}</div>
            </div>
          )}
        </div>
        {action && <Box className="flex flex-1 justify-end">{action}</Box>}
      </div>
    </div>
  )
}
