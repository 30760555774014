import { getDataUrl } from 'lib/image'
import { ReactNode, useEffect, useState } from 'react'
import { HiOutlineX } from 'react-icons/hi'

type FilePreviewProps = {
  file: File
  options?: any
  onCancel: () => void
  onError?: (error: any, file: File) => void
  onData?: (
    data: string | ImageData | { [key: string]: ImageData | string },
    ref?: any,
    width?: number,
    height?: number,
  ) => void
  children?: ReactNode | ReactNode[]
}

export const FilePreview = (props: FilePreviewProps) => {
  const { children, file, options, onData, onError } = props
  const [preview, setPreview] = useState(null)
  const isImage = file.type.startsWith('image')

  useEffect(() => {
    getDataUrl(file, options)
      .then(res => {
        setPreview(res.dataUrl)
        onData?.(
          options?.imageData && options?.dataUrl
            ? { dataUrl: res.dataUrl, imageData: res.data.imageData }
            : res.data?.imageData || res.dataUrl,
        )
      })
      .catch(e => {
        onError?.(e, file)
      })
    // eslint-disable-next-line
  }, [file])

  return (
    <div className="col-span-1 flex flex-col space-y-4 rounded-md border border-gray-200 bg-gray-50 shadow-sm">
      <div className="inline-flex">
        <div
          className={
            'flex w-16 flex-shrink-0 items-center justify-center rounded-b-none rounded-l-md text-sm font-medium text-white ' +
            (isImage ? 'bg-green-500' : 'bg-red-500')
          }
        >
          {file.name.split('.').pop().toUpperCase()}
        </div>
        <div className="flex flex-1 items-center justify-between truncate rounded-b-none rounded-r-md border-b bg-white">
          <div className="flex-1 truncate px-4 py-2 text-sm">
            <a href="#" className="font-medium text-gray-900 hover:text-gray-600">
              {file.name}
            </a>
            <p className="text-gray-500">
              {file.type} | {Math.floor(file.size / 1024)}KB
            </p>
          </div>
          <div className="flex-shrink-0 pr-2">
            <button
              type="button"
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              onClick={() => props.onCancel()}
            >
              <HiOutlineX className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      {preview && (
        <img
          id={`file_${file.name}`}
          src={preview}
          className="mx-auto h-screen max-h-64 object-contain text-center"
          alt="image preview"
        />
      )}
      {children}
    </div>
  )
}
