import { Title } from 'components/Title'
import { CheckResult } from 'types/Check'
import { ChecksFeed } from '../ChecksFeed'
import { Modal, ModalProps } from '../Modal'

type ChecksModalProps = ModalProps & {
  checks: CheckResult[]
}

export const ChecksModal = ({ checks, isOpen, onClose }: ChecksModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <section aria-labelledby="checks-title" className="w-screen max-w-lg lg:col-span-1 lg:col-start-3">
        <div className="dark:bg-dark-700 bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <Title size="base">Checks</Title>

          <ChecksFeed checks={checks} />
        </div>
      </section>
    </Modal>
  )
}
