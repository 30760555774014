import { EntityType } from 'components/Entities/types'
import { RequirementGroupRequirement } from 'types/RequirementGroup'
import { patch, post } from './req'

export type CreateRequirementGroupPayload = {
  name: string
  config: Record<string, RequirementGroupRequirement>
  allowedEntityType: string
  allowedEntityGroups?: Record<string, string[]>
  workspaceId: string
  marketId: string
  isDefault: boolean
}

export function createRequirementGroup(payload: CreateRequirementGroupPayload) {
  return post('/v1/workspaces/requirement-groups', payload)
}

export type UpdateRequirementGroupPayload = CreateRequirementGroupPayload & {
  id: string
}

export function updateRequirementGroup(payload: UpdateRequirementGroupPayload) {
  return patch('/v1/workspaces/requirement-groups', payload)
}

export type CreateRequirementPayload = {
  workspaceId: string
  name: string
  fulfillmentTypeIds: string[]
}

export function createRequirement(payload: CreateRequirementPayload) {
  return post('/v1/workspaces/requirements', payload)
}

export type UpdateRequirementPayload = CreateRequirementPayload & {
  id: string
}

export function updateRequirement(payload: CreateRequirementPayload) {
  return patch('/v1/workspaces/requirements', payload)
}

export type CreateFulfillmentTypePayload = {
  workspaceId: string
  name: string
}

export function createFulfillmentType(payload: CreateFulfillmentTypePayload) {
  return post('/v1/workspaces/fulfillment-types', payload)
}

export type UpdateFulfillmentTypePayload = {
  id: string
  name: string
}

export function updateFulfillmentType(payload: UpdateFulfillmentTypePayload) {
  return patch('/v1/workspaces/fulfillment-types', payload)
}

export type DeleteFulfillmentTypePayload = {
  id: string
}

export function deleteFulfillmentType(payload: DeleteFulfillmentTypePayload) {
  return post('/v1/workspaces/fulfillment-types/remove', payload)
}

export type LinkEntityPayload = {
  entityId: string
  workspaceId: string
  links: {
    id: string
    type: 'requirementGroup'
  }
}

export function linkEntity(payload: LinkEntityPayload) {
  return post('/v1/workspaces/entities/link', payload)
}

export type StartDocumentUpload = {
  mimeType: string
  name: string
  ownerId: string
  uploaderId: string
  workspaceId: string
  uploadLinkToken?: string
}

export function startDocumentUpload(payload: StartDocumentUpload) {
  return post('/v1/workspaces/documents/start', payload)
}

export type CompleteDocumentUpload = {
  uploadToken: string
}

export function completeDocumentUpload(payload: CompleteDocumentUpload) {
  return post('/v1/workspaces/documents/complete', payload)
}

export type SubmitDocument = {
  workspaceDocumentId: string
  submissions: Record<string, string>
}

export function submitDocument(payload: SubmitDocument) {
  return post('/v1/workspaces/documents/submit', payload)
}

export type ShareDocumentPayload = {
  workspaceDocumentId: string
  workspaceId: string
  requirementId: string
}

export function shareDocument(payload: ShareDocumentPayload) {
  return post('/v1/workspaces/documents/share', payload)
}

export type RequestDoumentsPayload = {
  workspaceId: string
  entityId: string
}

export function requestDocuments(payload: RequestDoumentsPayload) {
  return post('/v1/workspaces/uploadlink', payload)
}

type EntityPayload = {
  name: string
  phone: string
  links?: Record<string, any>
  type: EntityType
  attributes: Record<string, any>
}

export type CreateEntityPayload = EntityPayload & { workspaceId: string }

export function createEntity(payload: CreateEntityPayload) {
  return post('/v1/workspaces/entities', payload)
}

export type UpdateEntityPayload = EntityPayload & { id: string }

export function updateEntity(payload: UpdateEntityPayload) {
  return patch('/v1/workspaces/entities', payload)
}
