import { FilePreview } from 'components/File/FilePreview'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'components/Modal'
import { useShouldActivatePrompt } from 'hooks/useShouldActivatePrompt'
import { completeDocumentUpload, getDocumentUploadUrl, uploadFile } from 'lib/api'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { RequiredDriverDocument, RequiredProviderDocument, RequiredVehicleDocument } from 'types/Document'
import { Driver } from 'types/Driver'
import { Vehicle } from 'types/Vehicle'

import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Flex } from 'components/layout/Flex'
import { Text } from 'components/Text'
import { convertToAccept } from 'components/UploadSection/utils'
import { useFlagArrayValue } from 'hooks/useFlagsmith'
import { useVehicleHasTemplates } from 'hooks/useVehicleHasTemplates'
import { useRouter } from 'next/router'
import { HiOutlineBookOpen } from 'react-icons/hi2'
import { TransportationProvider } from 'types'
import { InputGroup } from '../InputGroup/InputGroup'
import { UploadSection } from '../UploadSection/UploadSection'

type NewDocumentModalProps = ModalProps & {
  driver?: Driver | null
  vehicle?: Vehicle | null
  provider?: TransportationProvider | null
  ownerId: string
  ownerName: string
  ownerType: 'driver' | 'vehicle' | 'provider'
  requiredDocument?: RequiredDriverDocument | RequiredVehicleDocument | RequiredProviderDocument | null
  onSetActive?(isActive: boolean): Promise<void>
  onBeforeSubmit?(): Promise<void>
  onAfterSubmit?(data: { documentType: string; documentId: string }): Promise<void>
  refresh(): void
}

export function NewDocumentModal(props: NewDocumentModalProps) {
  const {
    requiredDocument,
    driver,
    vehicle,
    provider,
    ownerName,
    ownerId,
    ownerType,
    isOpen,
    onClose,
    refresh,
    onSetActive,
    onBeforeSubmit,
    onAfterSubmit,
  } = props
  const [isUploading, setIsUploading] = useState(false)
  const [filesToUpload, setFilesToUpload] = useState<{ [key: string]: File }>({})
  const canUpload = !isUploading && Object.values(filesToUpload).some(v => v)
  const isInspectionMarket = useFlagArrayValue('vehicle_inspection_remote_markets', vehicle?.provider?.market?.name)
  const isInspectionType = useFlagArrayValue('vehicle_inspection_types', requiredDocument?.documentType)
  const hasTemplates = useVehicleHasTemplates(
    vehicle?.documentGroup.serviceType,
    !isInspectionType || !isInspectionMarket,
  )
  const shouldScheduleInspection = isInspectionMarket && isInspectionType && hasTemplates
  const { shouldActivate } = useShouldActivatePrompt(driver || vehicle)
  const router = useRouter()

  const handleError = (_error: any, file: File) => {
    toast.error(`${file.name} file is corrupt. Please check the file extension or retrieve another copy.`)
    setFilesToUpload({})
  }

  const handleCancel = () => {
    setFilesToUpload(files => ({ ...files, [requiredDocument.documentType]: undefined }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (onBeforeSubmit) {
      try {
        await onBeforeSubmit()
      } catch (e) {
        return
      }
    }

    if (!canUpload) return

    setIsUploading(true)

    for (const [documentType, file] of Object.entries(filesToUpload)) {
      const { uploadUrl, uploadToken, error } = await getDocumentUploadUrl({
        mime: file.type,
        name: file.name,
        checksum: Date.now().toString(),
        documentType,
        link: {
          [`${ownerType}Id`]: ownerId,
        },
      }).then(r => r.json())

      if (error) {
        toast.error(`Failed to upload: ${error}`)
        return
      }

      await uploadFile(uploadUrl, file)
      try {
        const { document } = await completeDocumentUpload({
          uploadToken,
        })

        toast.info(`Uploaded ${requiredDocument.name}`)

        if (onAfterSubmit) {
          await onAfterSubmit({
            documentId: document.id,
            documentType,
          }).catch(() => {})
        }
      } catch {
        toast.error(`Failed to upload ${requiredDocument.name}`)
        return
      }
    }

    onClose()

    if (!provider && onSetActive && (await shouldActivate())) {
      await onSetActive(true)
      refresh()
    }
  }

  const handleFile =
    documentType =>
    ([file]) =>
      setFilesToUpload(files => ({ ...files, [documentType]: file }))

  useEffect(() => {
    if (!isOpen) {
      setFilesToUpload({})
      setIsUploading(false)
    }
  }, [isOpen])

  const onInspectionGuide = () => {
    window.open('https://help.complicore.co/en/collections/9251746', '_blank')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form className="flex h-full w-screen max-w-3xl flex-col overflow-hidden" onSubmit={handleSubmit}>
        <ModalHeader title="Upload a Document" />
        <ModalBody>
          <div className="-mx-4 space-y-6 overflow-auto px-4 capitalize sm:px-6">
            <InputGroup id="ownerName" label={ownerType} value={ownerName} disabled readOnly />
            <div>
              {!shouldScheduleInspection && (
                <label htmlFor="documentType" className="block text-sm font-medium text-gray-700">
                  {requiredDocument?.name}
                </label>
              )}
              <div className="mt-1 flex flex-col gap-4">
                {shouldScheduleInspection && (
                  <Box className="gap-4 space-y-4 rounded-md bg-gray-50 p-6">
                    <Text>Video Inspection Required</Text>
                    <Text variant="light" weight="normal" size="sm" className="normal-case">
                      Please schedule an inspection using the button below.
                      <br />
                      Results will automatically be submitted to fulfill this requirement.
                    </Text>
                    <Flex className="gap-2">
                      <Button
                        variant="primary"
                        onClick={() => {
                          router.push(`/providers/inspections/schedule?vehicleId=${vehicle.id}`)
                          onClose()
                        }}
                      >
                        Schedule Inspection
                      </Button>
                      <Button variant="outline" icon={HiOutlineBookOpen} onClick={onInspectionGuide}>
                        Vehicle Inspection Guide
                      </Button>
                    </Flex>
                  </Box>
                )}
                {filesToUpload[requiredDocument?.documentType] ? (
                  <FilePreview
                    file={filesToUpload[requiredDocument.documentType]}
                    onError={handleError}
                    onCancel={handleCancel}
                  />
                ) : (
                  <UploadSection
                    id="file"
                    onFiles={handleFile(requiredDocument?.documentType)}
                    accept={convertToAccept(requiredDocument?.allowedMimeTypes)}
                    full
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        {(driver || vehicle || provider) && requiredDocument && (
          <ModalFooter isDisabled={!canUpload} isLoading={isUploading} onClose={onClose} />
        )}
      </form>
    </Modal>
  )
}
