import { ModalProps } from 'components/Modal'
import { ComponentType, useMemo, useRef, useState } from 'react'

export function useModalConfirmation(Component: ComponentType<ModalProps & { onSubmit: any }>) {
  const [isOpen, setIsOpen] = useState(false)
  const confirm = () =>
    new Promise(resolve => {
      setIsOpen(true)
      resolveRef.current = resolve
    })
  const resolveRef = useRef<any>(null)

  const onSubmit = (data: any) => {
    setIsOpen(false)
    resolveRef.current(data)
    resolveRef.current = null
  }

  const component = useMemo(
    () => <Component isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={onSubmit} />,
    [Component, isOpen],
  )

  return {
    component,
    confirm,
  }
}
