import { gql, useQuery } from '@apollo/client'
import { Market } from 'types'

type InspectionTemplate = {
  id: string
  isActive: boolean
  vehicleType: string
  name: string
  market: Market
  sections: string[]
  questions: InspectionTemplateQuestion[]
}

type InspectionTemplateQuestion = {
  id: string
  questionType: string
  question: string
  description: string
  order: number
}

const QueryInspectionTemplates = gql`
  query QueryInspectionTemplates($options: InspectionTemplatesInput) {
    inspectionTemplates(options: $options) {
      id
      vehicleType
      name
      sections
      isActive

      questions {
        id
        section
        order
      }
    }
  }
`

export function useInspectionTemplates(
  options?: { name?: string; vehicleType?: string; isActive?: boolean },
  config?: any,
) {
  const { data, loading, refetch } = useQuery(QueryInspectionTemplates, {
    variables: { options },
    fetchPolicy: 'no-cache',
    ...config,
  })
  const templates: InspectionTemplate[] = data?.inspectionTemplates || []

  return {
    templates,
    loading,
    refetch,
  }
}
