import { Box } from 'components/Box'
import { useAnalytics } from 'hooks/useAnalytics'
import { forwardRef, MouseEvent, MouseEventHandler, ReactNode } from 'react'
import { IconType } from 'react-icons'
import { twMerge } from 'tailwind-merge'
import { ColorType, Icon } from '../Icon'

type MenuItemProps = {
  icon?: IconType
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  title?: string
  color?: ColorType
  badge?: ReactNode
}

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  ({ onClick, color, title, icon, disabled, badge }: MenuItemProps, ref) => {
    const analytics = useAnalytics()
    const handleClick: MouseEventHandler<HTMLDivElement> = e => {
      analytics?.track('MenuItem - Click', {
        label: title,
      })

      if (onClick) {
        onClick(e)
      }
    }

    return (
      <div
        className={twMerge(
          'dark:hover:bg-dark-600 inline-flex w-full cursor-pointer items-center justify-between px-4 py-2 text-sm hover:bg-gray-100 focus:bg-gray-200 dark:focus:bg-gray-800',
          disabled && 'pointer-events-none opacity-50',
        )}
        role="menuitem"
        onClick={handleClick}
        ref={ref}
      >
        <Box className="flex items-center">
          {icon && <Icon className="mr-3 h-5 w-5" color={color} icon={icon} />}
          <span className="dark:text-gray-300">{title}</span>
        </Box>
        {badge && <Box>{badge}</Box>}
      </div>
    )
  },
)

MenuItem.displayName = 'MenuItem'
