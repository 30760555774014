/* eslint-disable camelcase */
import { Box } from 'components/Box'
import { Card } from 'components/Card'
import { HiCheckCircle, HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineOfficeBuilding } from 'react-icons/hi'
import { TransportationProvider } from 'types'
import { OnboardingStep } from './OnboardingStep'

import classNames from 'classnames'
import { Button } from 'components/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { useFlags } from 'hooks/useFlagsmith'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { Organization as Asset } from './assets/Organization'
import { parseJsonArray } from './utilities'

type Props = {
  provider: TransportationProvider
}

export function Organization({ provider }: Props) {
  const { addressStreet, city, state, zip, operatingHours, serviceArea, tripCapacity } = provider || {}
  const marketName = provider?.market?.name
  const flags = useFlags(['new_teams_page_markets', 'require_team_info_markets'])

  const newTeamsPageMarkets: string[] = parseJsonArray(flags['new_teams_page_markets']?.value as string)
  const shouldShowNewTeamsPage = flags['new_teams_page_markets']?.enabled && newTeamsPageMarkets?.includes(marketName)

  const teamInfoMarkets: string[] = parseJsonArray(flags['require_team_info_markets']?.value as string)
  const shouldRequireTeamInfo = flags['require_team_info_markets']?.enabled && teamInfoMarkets.includes(marketName)

  const steps = useMemo(() => {
    return [
      Boolean(addressStreet && city && state && zip),
      shouldShowNewTeamsPage ? Boolean(operatingHours) : null,
      shouldShowNewTeamsPage ? Boolean(serviceArea && serviceArea.length > 0) : null,
      shouldShowNewTeamsPage ? Boolean(tripCapacity && tripCapacity.length > 0) : null,
    ].filter(v => v !== null)
  }, [shouldShowNewTeamsPage, addressStreet, city, state, zip, operatingHours, serviceArea, tripCapacity])
  const activeStepIndex = useMemo(() => {
    const index = steps.findIndex(step => !step)
    return index !== -1 ? index : null
  }, [steps])

  const router = useRouter()
  const [expanded, setExpanded] = useState(true)
  const completedAllSteps = steps.every(Boolean)

  useEffect(() => {
    if (steps.every(Boolean)) {
      setExpanded(false)
    }
  }, [steps])

  return (
    <Card
      wrapActions={false}
      icon={HiOutlineOfficeBuilding}
      title="Organization"
      subtitle="Provide the most up-to-date information for your Organization below."
      actions={
        <IconButton
          variant="ghost"
          icon={completedAllSteps ? HiCheckCircle : expanded ? HiOutlineChevronUp : HiOutlineChevronDown}
          className={classNames('-mr-4', completedAllSteps && 'text-blue-600')}
          onClick={() => setExpanded(!expanded)}
          ariaLabel={expanded ? 'Collapse organization card' : 'Expand organization card'}
        />
      }
    >
      {expanded && (
        <Box className="flex flex-col-reverse p-4 sm:flex-row">
          <Box className="flex-1 space-y-2">
            <OnboardingStep
              label="Add your address"
              description="Tell us your Organization's current address."
              defaultOpen={activeStepIndex === 0}
              actions={
                <Button variant="primary" onClick={() => router.push('/account/team?tab=profile')}>
                  Add address
                </Button>
              }
              completed={steps[0]}
            />
            {shouldShowNewTeamsPage && (
              <OnboardingStep
                label="Operating Hours"
                description="Set your Hours of Operation to help Modivcare in determining when to send you trip requests."
                defaultOpen={activeStepIndex === 1}
                actions={
                  <Button variant="primary" onClick={() => router.push('/account/team?tab=hoursOfOperation')}>
                    Add operating hours
                  </Button>
                }
                completed={steps[1]}
              />
            )}
            {shouldShowNewTeamsPage && (
              <OnboardingStep
                label="Service Area"
                description="Specify your area of service to help Modivcare in determining where to send you trip requests."
                defaultOpen={activeStepIndex === 2}
                actions={
                  <Button variant="primary" onClick={() => router.push('/account/team?tab=serviceArea')}>
                    Add area of service
                  </Button>
                }
                missingRequirement={shouldRequireTeamInfo && !steps[2]}
                completed={steps[2]}
              />
            )}
            {shouldShowNewTeamsPage && (
              <OnboardingStep
                label="Trip Capacity"
                description="Inform Modivcare about your trip volume capacity by Level of Service. Please note that you can add trip volume capacity overrides for different days of the week and specific time frames."
                defaultOpen={activeStepIndex === 3}
                actions={
                  <Button variant="primary" onClick={() => router.push('/account/team?tab=tripCapacity')}>
                    Add trip capacity
                  </Button>
                }
                missingRequirement={shouldRequireTeamInfo && !steps[3]}
                completed={steps[3]}
              />
            )}
          </Box>
          <Box className="hidden h-60 opacity-70 sm:block">
            <Asset />
          </Box>
        </Box>
      )}
    </Card>
  )
}
