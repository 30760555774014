import { ProviderMetadata } from 'types/Provider'
import { useAuth } from './useAuth'

export function useProviderMetadata(): ProviderMetadata {
  const { account } = useAuth()

  return (
    account?.provider?.metadata || {
      createDefaultDriver: false,
      createDefaultVehicle: false,
      enableDrivers: true,
      enableInsurance: true,
      enableVehicles: true,
      enableTeams: true,
    }
  )
}
