import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Dot } from 'components/Dot'
import { Icon } from 'components/Icon'
import { useAuth } from 'hooks/useAuth'
import { useProviders } from 'hooks/useProviders'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { QueryProviders } from 'internal/graphql/query/provider'
import { switchProvider } from 'lib/api'
import { Fragment } from 'react'
import { HiChevronDown, HiOutlineOfficeBuilding } from 'react-icons/hi'
import { toast } from 'react-toastify'

export function ProviderSwitcher() {
  const { account } = useAuth()
  const { providers } = useProviders(QueryProviders, { page: 1 }, 'cache-and-network')
  const { isCollapsed } = useSidebarContext()
  const activeProvider = account.provider || providers.find(p => p.id === account.providerId)

  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center overflow-hidden rounded-md border border-gray-500 bg-gray-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-gray-900">
          <Icon
            color="light"
            icon={HiOutlineOfficeBuilding}
            className={classNames(['flex-shrink-0', !isCollapsed && 'mr-2'])}
          />
          {!isCollapsed && (
            <Fragment>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth: '125px' }}>
                {activeProvider?.name}
              </span>
              <HiChevronDown className="-mr-1 ml-2 h-5 w-5 flex-shrink-0" aria-hidden="true" />
            </Fragment>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="min-w-2xs absolute left-0 z-20 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {providers.map(p => (
              <Menu.Item key={p.id}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex w-full items-center gap-2 px-4 py-2 text-left text-xs hover:bg-gray-100',
                      activeProvider.id === p.id && 'font-medium',
                    )}
                    disabled={activeProvider.id === p.id}
                    onClick={() =>
                      switchProvider(p.id).then(r => {
                        if (!r.ok) {
                          toast.error('Failed to switch provider')
                          return
                        }

                        window.location.reload()
                      })
                    }
                  >
                    {activeProvider.id === p.id && <Dot />}
                    {p.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
