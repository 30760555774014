import { RequireSuperAdmin } from 'components/RequireRole/RequireSuperAdmin'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiOutlineAdjustments,
  HiOutlineArchive,
  HiOutlineClipboardCheck,
  HiOutlineClipboardList,
  HiOutlineClock,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlineFilter,
  HiOutlineLocationMarker,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
  HiOutlinePencil,
  HiOutlineUser,
  HiOutlineUsers,
  HiOutlineViewGrid,
} from 'react-icons/hi'
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2'
import { LuRefreshCw } from 'react-icons/lu'
import { RiTaxiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function SuperAdminNav() {
  const { stats } = useSidebarContext()
  const { t } = useTranslation()

  return (
    <Nav>
      <RequireSuperAdmin>
        <Fragment>
          <NavLink href="/admin/dashboard" icon={HiOutlineViewGrid}>
            {t('dashboard')}
          </NavLink>
          <NavGroup name={t('network')}>
            <NavLink href="/admin/accounts" icon={HiOutlineUsers} stat={stats?.accounts}>
              {t('accounts')}
            </NavLink>
            <NavLink href="/admin/accounts/invites" icon={HiOutlineMail}>
              {t('Invitations')}
            </NavLink>
            <NavLink href="/admin/providers" icon={HiOutlineOfficeBuilding} stat={stats?.providers}>
              {t('providers')}
            </NavLink>
            <NavLink href="/admin/drivers" icon={HiOutlineUser} stat={stats?.drivers}>
              {t('drivers')}
            </NavLink>
            <NavLink href="/admin/vehicles" icon={RiTaxiLine} stat={stats?.vehicles}>
              {t('vehicles')}
            </NavLink>
          </NavGroup>
        </Fragment>
      </RequireSuperAdmin>
      <NavGroup name="Data">
        <NavLink href="/admin/audit" icon={HiOutlineArchive}>
          {t('Audit')}
        </NavLink>
        <NavLink href="/admin/documents" icon={HiOutlineDocumentText} stat={stats?.documents}>
          {t('credentials')}
        </NavLink>
      </NavGroup>

      <RequireSuperAdmin>
        <NavGroup name="Configuration">
          <NavLink href="/admin/tasks" icon={HiOutlineClock}>
            Tasks
          </NavLink>
          <NavLink href="/admin/required-documents/documents" icon={HiOutlineDocumentDuplicate}>
            Required Documents
          </NavLink>
          <NavLink href="/admin/required-documents/markets" icon={HiOutlineLocationMarker}>
            Markets
          </NavLink>
          <NavLink href="/admin/required-documents/fields" icon={HiOutlineClipboardList}>
            Fields
          </NavLink>
          <NavLink href="/admin/provider-attribute-groups" icon={HiOutlineFilter}>
            Provider Attributes
          </NavLink>
          <NavLink href="/admin/required-documents/descriptions" icon={HiOutlinePencil}>
            Descriptions
          </NavLink>
          <NavLink href="/admin/inspections" icon={HiOutlineClipboardCheck}>
            Inspections
          </NavLink>
        </NavGroup>
        <NavGroup name="Operations">
          <NavLink href="/admin/operations/service-levels" icon={HiOutlineAdjustments}>
            Service Levels
          </NavLink>
        </NavGroup>
        <NavGroup name="Tools">
          <NavLink href="/admin/tools/bulk-exempt" icon={HiOutlineWrenchScrewdriver}>
            Bulk Exemptions
          </NavLink>
          <NavLink href="/admin/tools/cache" icon={LuRefreshCw}>
            Cache
          </NavLink>
        </NavGroup>
      </RequireSuperAdmin>
    </Nav>
  )
}
