import { Box } from 'components/Box'
import { Card } from 'components/Card'
import { useEntities } from 'hooks/useEntities'
import { TransportationProvider } from 'types'

import classNames from 'classnames'
import { Button } from 'components/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { HiCheckCircle, HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineClipboardList } from 'react-icons/hi'
import { OnboardingStep } from './OnboardingStep'
import { Entities as Asset } from './assets/Entities'

type Props = {
  provider: TransportationProvider
}

export function Entities({ provider }: Props) {
  const { drivers, vehicles, loading } = useEntities({ providerId: provider?.id })
  const steps = useMemo(() => {
    return [drivers > 0, vehicles > 0]
  }, [drivers, vehicles])
  const activeStepIndex = useMemo(() => {
    const index = steps.findIndex(step => !step)
    return index !== -1 ? index : null
  }, [steps])

  const router = useRouter()
  const [expanded, setExpanded] = useState(true)
  const completedAllSteps = steps.every(Boolean)
  const metadata = provider?.metadata
  const shouldHideCard = metadata && !metadata?.enableDrivers && !metadata?.enableVehicles

  useEffect(() => {
    if (steps.every(Boolean)) {
      setExpanded(false)
    }
  }, [steps])

  if (shouldHideCard || loading) {
    return null
  }

  return (
    <Card
      wrapActions={false}
      icon={HiOutlineClipboardList}
      title="Drivers and Vehicles"
      subtitle="To start the credentialing and billing authorization process for your drivers and vehicles, first add them to your Complicore roster."
      actions={
        <IconButton
          variant="ghost"
          icon={completedAllSteps ? HiCheckCircle : expanded ? HiOutlineChevronUp : HiOutlineChevronDown}
          className={classNames('-mr-4', completedAllSteps && 'text-blue-600')}
          onClick={() => setExpanded(!expanded)}
          ariaLabel={expanded ? 'Collapse card' : 'Collapse card'}
        />
      }
    >
      {expanded && (
        <Box className="flex flex-col-reverse p-4 sm:flex-row">
          <Box className="flex-1 space-y-2">
            {metadata?.enableDrivers !== false && (
              <OnboardingStep
                label="Add your first driver"
                description='To add drivers to your Complicore roster, click on "Go to Drivers Page" button and you will be redirected to the Drivers page.'
                defaultOpen={activeStepIndex === 0}
                actions={
                  <Button variant="primary" onClick={() => router.push('/providers/drivers')}>
                    Go to Drivers Page
                  </Button>
                }
                completed={steps[0]}
              />
            )}
            {metadata?.enableVehicles !== false && (
              <OnboardingStep
                label="Add your first vehicle"
                description='To add vehicles to your Complicore roster, click on "Go to Vehicles Page" button and you will be redirected to the Vehicles page.'
                defaultOpen={activeStepIndex === 1}
                actions={
                  <Button variant="primary" onClick={() => router.push('/providers/vehicles')}>
                    Go to Vehicles Page
                  </Button>
                }
                completed={steps[1]}
              />
            )}
          </Box>
          <Box className="hidden h-60 opacity-70 sm:block">
            <Asset />
          </Box>
        </Box>
      )}
    </Card>
  )
}
