import { LngLatLike } from 'maplibre-gl'
import { ServiceType } from 'types'

export const DEFAULT_MAP_URL = '34acb98d-0e37-41e2-afe0-ccafd2eccc0e'
export const DEFAULT_MAP_ZOOM = 4
export const MIN_COUNTY_ZOOM = 8
export const MIN_ZIPCODES_ZOOM = 10.5
export const USA_CENTER: LngLatLike = [-98.35, 39.5]
export const MAP_PADDING = {
  top: 50,
  bottom: 50,
  left: 350,
  right: 50,
}
export const SERVICE_TYPE_LABELS: Record<ServiceType, string> = {
  pickup: 'Pickup',
  dropoff: 'Dropoff',
  both: 'Both',
}
