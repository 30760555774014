import { Box } from 'components/Box'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import { ContentWrapper } from 'components/layout/ContentWrapper'
import { PageContent } from 'components/layout/PageContent'
import { useRequirementsProviderContext } from 'contexts/providers/RequirementsProvider'
import { MainLayout } from 'layouts/MainLayout'
import { TransportationProvider } from 'types'
import { MissingRequirementsBanner } from './MissingRequirementsBanner'
import { OnboardingSection } from './onboarding/OnboardingSection'

type Props = {
  provider: TransportationProvider
  loading: boolean
  refresh: () => void
}

export function OnboardingPage({ provider, loading, refresh }: Props) {
  const { refetch: refetchRequirements } = useRequirementsProviderContext()

  const refreshAll = () => {
    refresh()
    refetchRequirements()
  }

  return (
    <MainLayout>
      <ContentWrapper>
        <PageContent>
          <Box className="mx-auto mb-10 w-full max-w-4xl space-y-6 px-4">
            <Box className="container mx-auto pt-8">
              <Title weight="semibold">Welcome to Complicore</Title>
              <Text size="sm" weight="normal">
                Use the steps below to complete your onboarding process.
              </Text>
            </Box>
            <MissingRequirementsBanner />
            {loading ? (
              <Box className="p-10">
                <Loader />
              </Box>
            ) : (
              <OnboardingSection provider={provider} refresh={refreshAll} />
            )}
          </Box>
        </PageContent>
      </ContentWrapper>
    </MainLayout>
  )
}
