export function req(url, config) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${url}`, {
    credentials: 'include',
    ...config,
  })
}

export function get(url, config = {}) {
  return req(url, config)
}

export function post(url, data?, config = {}) {
  return req(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data && JSON.stringify(data),
    ...config,
  })
}
export function patch(url, data?, config = {}) {
  return req(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data && JSON.stringify(data),
    ...config,
  })
}

export function put(url, data, config = {}) {
  return req(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ...config,
  })
}

export function del(url, data, config = {}) {
  return req(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ...config,
  })
}
