import { useProviderMetadata } from 'hooks/useProviderMetadata'
import { Fragment, ReactNode } from 'react'
import { ProviderMetadata } from 'types/Provider'

export type MetadataSettingsProps = {
  setting: keyof ProviderMetadata | (keyof ProviderMetadata)[]
  strict?: boolean
  children: ReactNode | ReactNode[]
}

export const MetadataSettings = (props: MetadataSettingsProps) => {
  const metadata = useProviderMetadata()
  const { setting = '', children, strict = false } = props
  const settings = [].concat(setting)

  if (settings[strict ? 'some' : 'every'](s => metadata?.[s] === false)) {
    return null
  }

  return <Fragment>{children}</Fragment>
}
