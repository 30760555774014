import { gql } from '@apollo/client'

export const PROVIDER_FRAGMENTS = gql`
  fragment EntityProviderDetails on Provider {
    id
    marketId
    name
    market {
      id
    }

    customAttributes
  }
`
