import { DocumentExtraction } from 'types/Document'
import { del, get, patch, post } from './req'

export type CreateProviderPayload = {
  documentGroupId?: string
  marketId?: string
  npi?: string
  medicaidProviderId?: string
  tlcBaseNumber?: string
  name?: string
  addressStreet?: string
  addressStreet2?: string
  city?: string
  state?: string
  zip?: string
  primaryContactFirstName?: string
  primaryContactLastName?: string
  primaryContactEmail?: string
  primaryContactPhone?: string
  customAttributes?: any
}

export type UpdateProviderPayload = CreateProviderPayload & {
  id: string
}

export type UpdateProviderDocumentGroupPayload = {
  providerId: string
  documentGroupId: string
  driverUpdats?: Record<string, string>
  vehicleUpdates?: Record<string, string>
}

export type RefreshProviderPayload = {
  id: string
}

export type AddProviderNotePayload = {
  providerId: string
  text: string
}

export type AddDriverNotePayload = {
  driverId: string
  text: string
}

export type AddVehicleNotePayload = {
  vehicleId: string
  text: string
}

export type CreateAdminPayload = {
  email: string
}

export type CreateManagerPayload = {
  firstName: string
  lastName: string
  email: string
  roles: string[]
}

export type InviteAccountPayload = {
  firstName?: string
  lastName?: string
  driverId?: string
  phone?: string
  email?: string
  role: string
}

export type CreateProviderUploadLinkPayload = {
  providerId: string
}

export type AddRequiredDocumentPayload = {
  type: string
  documentType: string
  name: string
  description: string
  allowedMimeTypes: string[]
  canReplaceOnExpire: boolean
  canExempt?: boolean
  requireLevel: number
}

export type UpdateRequiredDocumentPayload = {
  type: string
  documentType: string
  description?: string
  requireLevel?: number
  allowedMimeTypes?: string[]
  canReplaceOnExpire?: boolean
  canExempt?: boolean
}

export type UpdateRequiredDocumentGroupPayload = {
  id: string
  name?: string
  documentTypes?: string[]
  typeDescriptions?: string[]
}

export type UpdateVehicleRequiredDocumentPayload = {
  documentType: string
  description?: string
  requireLevel?: number
}

export type AnalyzeDocumentPayload = {
  id: string
}

export type UpdateDocumentPayload = {
  id: string
  documentType?: string
  extractedData: DocumentExtraction
}

export type UpdateDocumentStatusPayload = {
  id: string
  status: string
  statusReason?: string
}

export type AddDocumentTagPayload = {
  documentId: string
  color?: string
  tag: string
}

export type RemoveDocumentTagPayload = {
  id: string
}

export type LinkVirtualPayload = {
  documentId: string
  documentType: string
  driverIds?: string[]
  vehicleIds?: string[]
}

export type ResendProviderInvitePayload = {
  id?: string
  email?: string
}

export function createProvider(payload: CreateProviderPayload) {
  return post('/v1/providers', payload)
}

export function updateProvider(payload: UpdateProviderPayload) {
  return patch('/v1/providers', payload)
}

export function updateProviderDocumentGroup(payload: UpdateProviderDocumentGroupPayload) {
  return patch('/v1/providers/document-group', payload)
}

export function refreshProvider(payload: RefreshProviderPayload) {
  return post('/v1/providers/refresh', payload)
}

export function addNote(payload: AddProviderNotePayload | AddDriverNotePayload | AddVehicleNotePayload) {
  return post('/v1/notes', payload)
}

export function createAdmin(payload: CreateAdminPayload) {
  return post('/admin.createAdmin', payload)
}

export function createManager(payload: CreateManagerPayload) {
  return post('/v1/accounts', payload)
}

export function inviteAccount(payload: InviteAccountPayload) {
  return post('/v1/accounts/invite', payload)
}

export function createProviderUploadLink(payload: CreateProviderUploadLinkPayload) {
  return post('/admin.createProviderUploadLink', payload)
}

export function addRequiredDocument(payload: AddRequiredDocumentPayload) {
  return post('/v1/required-documents', payload)
}

export function updateRequiredDocument(payload: UpdateRequiredDocumentPayload) {
  return patch('/v1/required-documents', payload)
}

export function addRequiredDocumentGroup(payload: UpdateRequiredDocumentGroupPayload) {
  return post('/v1/document-groups', payload)
}

export function updateRequiredDocumentGroup(payload: UpdateRequiredDocumentGroupPayload) {
  return patch('/v1/document-groups', payload)
}

type CreateDocumentTypeDescriptionPayload = {
  documentGroupId: string
  documentType: string
  description: string
}

export function createDocumentTypeDescription(payload: CreateDocumentTypeDescriptionPayload) {
  return post('/v1/documents/descriptions', payload)
}

type UpdateDocumentTypeDescriptionPayload = {
  id: string
  description: string
}

export function updateDocumentTypeDescription(payload: UpdateDocumentTypeDescriptionPayload) {
  return patch('/v1/documents/descriptions', payload)
}

export function analyzeDocument(id) {
  return get('/v1/documents/analyze?id=' + id)
}

export function updateDocument(payload: UpdateDocumentPayload) {
  return patch('/v1/documents', payload)
}

export function updateDocumentStatus(payload: UpdateDocumentStatusPayload) {
  return patch('/v1/documents/status', payload)
}

export function addDocumentTag(payload: AddDocumentTagPayload) {
  return post('/v1/documents/tags', payload)
}

export function removeDocumentTag(payload: RemoveDocumentTagPayload) {
  return del('/v1/documents/tags', payload)
}

export function createDocumentArchive(archived?: boolean, images?: boolean) {
  return post('/admin.createDocumentArchive?' + (archived ? 'archived=archived&' : '') + (images ? 'images=1&' : ''))
}

export function setRoles(accountId, roles) {
  return patch('/v1/accounts/roles', { accountIds: [accountId], roles })
}

export function getMedicaidProviderListing(id) {
  return get(`/v1/medicaid-providers/${id}`)
}
export function getNpi(id) {
  return get(`/v1/npi/${id}`)
}

export function linkVirtual(payload: LinkVirtualPayload) {
  return post(`/v1/documents/link-virtual`, payload)
}

export function setRequiredFields(payload) {
  return post(`/v1/required-fields`, payload)
}
export function setRequiredFieldOverrides(payload) {
  return post(`/v1/required-fields/overrides`, payload)
}

export type CreateMarketPayload = {
  name: string
}
export function createMarket(payload: CreateMarketPayload) {
  return post('/v1/markets', payload)
}

export type UpdateMarketPayload = {
  id: string
  name?: string
  enabled?: boolean
}

export function updateMarket(payload: UpdateMarketPayload) {
  return patch('/v1/markets', payload)
}

export function resendProviderInvite(payload: ResendProviderInvitePayload) {
  return post('/v1/providers/resend-invite', payload)
}

type AccountPermissionsPayload = {
  accountId: string
  documentTypes?: string[]
  marketIds?: string[]
}
export function updateAccountPermissions(payload: AccountPermissionsPayload) {
  return patch('/v1/accounts/permissions', payload)
}

export function resetDocument(id: string) {
  return post(`/v1/documents/reset?id=${id}`)
}

export function suspendAccount(id: string) {
  return post(`/v1/accounts/suspend`, { accountId: id })
}

type CreateServiceLevelPayload = {
  name: string
  description?: string
  code: number
  order: number
  marketIds?: string[]
}

export function createServiceLevel(payload: CreateServiceLevelPayload) {
  return post('/v1/service-levels', payload)
}

type UpdateServiceLevelPayload = {
  id: string
  name?: string
  description?: string
  code?: number
  order?: number
  marketIds?: string[]
}

export function updateServiceLevel(payload: UpdateServiceLevelPayload) {
  return patch('/v1/service-levels', payload)
}

type UpdateMarketServiceLevelPayload = {
  marketId: string
  groups: string[]
}

export function updateMarketServiceLevel(payload: UpdateMarketServiceLevelPayload) {
  return patch('/v1/markets/service-levels', payload)
}

type UpdateAttributesPayload = {
  driverId?: string
  vehicleId?: string
  providerId?: string
  attributes: Record<string, any>
}

export function updateAttributes(payload: UpdateAttributesPayload) {
  return patch('/v1/attributes', payload)
}
