import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { AuthMiddleware } from './middleware/auth'

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body)
  return fetch(`${uri}?op=${operationName}`, options)
}

export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  link: from([
    AuthMiddleware(),
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
      credentials: 'include',
      fetch: customFetch,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Document: {
        keyFields: ['id', 'status'],
      },
      DocumentExtraction: {
        keyFields: ['documentId'],
      },
      SearchResult: {
        keyFields: ['entity', 'id'],
      },
    },
  }),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' },
  },
})

export const inspectionsClient = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  link: from([
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_INSPECTIONS_GRAPHQL_URL,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' },
  },
})

export const messageServiceClient = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  link: from([
    AuthMiddleware(),
    new HttpLink({
      uri: 'http://127.0.0.1:30010/query',
    }),
  ]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' },
  },
})
