import { Text } from 'components/Text'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type LabelProps = {
  htmlFor?: string
  required?: boolean
  children: ReactNode
  className?: string
}

const baseClasses = 'block text-sm font-medium text-gray-700 dark:text-gray-300'

export function Label(props: LabelProps) {
  return (
    <label htmlFor={props.htmlFor} className={twMerge(baseClasses, props.className)}>
      <Text variant="light" size="xs" className="flex items-center">
        {props.children}
        {props.required && <span className="text-red-600">*</span>}
      </Text>
    </label>
  )
}
