import { gql, useQuery } from '@apollo/client'

const QueryNofications = gql`
  query {
    notifications {
      openedAt
      seenAt
      announcement {
        id
        author
        title
        content
        preview
        createdAt
      }
    }
  }
`

type Notification = {
  announcement: Announcement
  seenAt: string
  openedAt: string
}

type Announcement = {
  id: string
  author: string
  title: string
  content: string
  preview: string
  createdAt: string
}

export function useNotifications() {
  const { data, refetch } = useQuery(QueryNofications)
  const all: Notification[] = data?.notifications || []
  const announcements: Announcement[] = data?.notifications?.filter(n => !n.seenAt).map(n => n.announcement) || []

  return {
    all,
    announcements,
    refetch,
  }
}
