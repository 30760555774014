import classNames from 'classnames'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { Fragment, useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { MIME_TYPES_MAP } from './constants'

type UploadSectionProps = {
  accept?: Accept
  buttonOnly?: boolean
  buttonText?: string
  className?: string
  disabled?: boolean
  full?: boolean
  height?: string
  id?: string
  onFiles?: (files: any, e?: any) => void
  title?: any
}

export function UploadSection(props: UploadSectionProps) {
  const { accept = MIME_TYPES_MAP, onFiles, disabled = false, buttonText, buttonOnly = false } = props

  const onDrop = useCallback(
    (acceptedFiles, e) => {
      if (disabled) return
      onFiles?.(acceptedFiles, e)
    },
    [onFiles, disabled],
  )
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  if (buttonOnly) {
    return (
      <Button
        disabled={disabled}
        block
        {...getRootProps()}
        onClick={e => {
          e.stopPropagation()
          if (disabled) return
          open()
        }}
      >
        <input {...getInputProps()} />
        {buttonText || 'Upload a file'}
      </Button>
    )
  }

  return (
    <div
      aria-disabled={disabled}
      className={classNames([
        'dark:border-dark-400 dark:bg-dark-800 mx-auto flex w-full items-center justify-center rounded-md border bg-gray-50 px-6 pb-6 pt-5 focus:border-blue-600 focus:outline-none',
        props.full ? 'max-w-none' : 'max-w-lg',
        isDragActive && 'dark:bg-dark-700 border-blue-600 bg-blue-50',
        props.height ? props.height : 'min-h-60',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        props.className,
      ])}
      {...getRootProps()}
      onClick={e => {
        e.stopPropagation()
        if (disabled) return
        open()
      }}
    >
      <div className="space-y-1 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {props.title || (
          <div>
            <input {...getInputProps()} />
            {!isDragActive ? (
              <Fragment>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor={props.id}
                    className={classNames(
                      'relative rounded-md font-medium text-blue-600 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    )}
                  >
                    <span>{buttonText || 'Upload a file'}</span>
                  </label>
                  <Text className="pl-1" as="span" size="sm" weight="normal">
                    or drag and drop
                  </Text>
                </div>
                <Text size="xs" weight="normal" variant="light">
                  Files up to 10MB
                </Text>
              </Fragment>
            ) : (
              <div className="text-sm text-gray-600">
                <p>
                  <span className="font-medium text-blue-600 dark:text-blue-400">Release here</span>
                </p>
                <p className="text-xs text-gray-500">&nbsp;</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default UploadSection
