import { AnimatePresence, motion } from 'framer-motion'
import { usePortal } from 'hooks/usePortal'
import { removeDialog, selectDialogs } from 'internal/redux'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

export const DialogContainer = () => {
  const dispatch = useDispatch()
  const { dialogs } = useSelector(selectDialogs)
  const target = usePortal('dialog__portal')

  if (!target) {
    return null
  }

  const isOpen = dialogs.length > 0
  return createPortal(
    <AnimatePresence>
      <motion.div
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: isOpen ? 'auto' : 'none',
          zIndex: 60,
        }}
      >
        {isOpen && (
          <div
            className="fixed inset-0 z-61 flex cursor-pointer flex-col items-center justify-center space-y-6 py-10 transition-opacity"
            aria-hidden="true"
          >
            <motion.div
              className="absolute inset-0 bg-gray-500"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              onClick={() => {
                dialogs.forEach(d => dispatch(removeDialog(d.id)))
              }}
            />
            {dialogs.map(d => (
              <motion.div key={d.id} initial={{ y: 0 }} animate={{ y: 10 }} exit={{ y: 0 }}>
                {d.content}
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>
    </AnimatePresence>,
    target,
  )
}
