import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Account } from 'types/Account'

type AuthState = {
  account: Account | null
  isAuthenticated: boolean
  isLoading: boolean
  isLoaded: boolean
  context: string | null
}

const initialState: AuthState = {
  account: null,
  isAuthenticated: false,
  isLoading: true,
  isLoaded: false,
  context: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Account | null>) => {
      if (action.payload?.provider?.attributes) {
        action.payload.roles.push(...action.payload.provider.attributes.map(a => a.toLowerCase().replace(/ /g, '-')))
      }
      state.account = action.payload
      state.isLoaded = true
      state.isLoading = false

      if (state.account) {
        state.isAuthenticated = true
        state.context = state.account.roles.includes('superadmin')
          ? 'admin'
          : state.account.roles.includes('manager')
            ? 'manager'
            : state.account.roles.includes('operations')
              ? 'operations'
              : 'provider'
        ;(window as unknown as any).gtag?.call(null, 'config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
          // eslint-disable-next-line
          page_path: window.location.pathname,
          // eslint-disable-next-line
          user_id: state.account.id,
        })
      }
    },
    resetAccount: state => {
      state.account = null
      state.isLoaded = true
      state.isLoading = false
      state.isAuthenticated = false
    },
  },
})

export const { setAccount, resetAccount } = authSlice.actions
