import classNames from 'classnames'
import { Box } from 'components/Box'
import { ColorType, Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Label } from 'components/Label'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { ForwardRefExoticComponent, HTMLProps, forwardRef } from 'react'
import { IconType } from 'react-icons'
import { HiQuestionMarkCircle } from 'react-icons/hi'

type InputProps = HTMLProps<HTMLInputElement> & {
  containerClassName?: string
  label?: string
  error?: string
  component?: ForwardRefExoticComponent<any>
  variant?: 'stacked' | 'horizontal'
  helpText?: string
  icon?: IconType
  iconColor?: ColorType
  tooltip?: string
}

export const InputGroup = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      containerClassName,
      children,
      component: Component = Input,
      variant = 'stacked',
      helpText,
      icon = undefined,
      iconColor = undefined,
      tooltip,
      ...props
    }: InputProps,
    ref,
  ) => {
    return (
      <div
        className={classNames([
          'flex flex-1 gap-2',
          containerClassName,
          variant === 'stacked' ? 'flex-col gap-1' : 'flex-row items-center gap-4',
        ])}
      >
        <Box className="flex items-center gap-1">
          {label && (
            <Label htmlFor={props.id} required={props.required} className="whitespace-nowrap">
              {label}
            </Label>
          )}
          {tooltip && (
            <Tooltip label={tooltip}>
              <Icon size="xs" icon={HiQuestionMarkCircle} />
            </Tooltip>
          )}
        </Box>

        {children || (
          <Component
            {...props}
            icon={icon}
            iconColor={iconColor}
            className={classNames([props.className, error ? '' : ''])}
            ref={ref}
          />
        )}
        {helpText && (
          <Text size="xs" variant="light" weight="normal" className="mt-1">
            {helpText}
          </Text>
        )}
        {error && (
          <p className="text-sm text-red-600 dark:text-red-400" id="email-error">
            {error}
          </p>
        )}
      </div>
    )
  },
)

InputGroup.displayName = 'InputGroup'
