import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { DescriptionDetails } from 'components/DescriptionDetails'
import { DescriptionTerm } from 'components/DescriptionTerm'
import { Dot, DotColor } from 'components/Dot'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Label } from 'components/Label'
import { Modal, ModalFooter, ModalHeader } from 'components/Modal'
import { NewWorkspaceDocumentModal } from 'components/Modals/NewWorkspaceDocumentModal'
import { SubmissionStatusBadge } from 'components/Packets/SubmissionStatusBadge'
import { RequireRole } from 'components/RequireRole/RequireRole'
import { Text } from 'components/Text'
import { TextArea } from 'components/Textarea'
import { useWorkspaceDocument } from 'hooks/useWorkspaceDocument'
import { selectSubmission, selectWorkspaceDocumentId, setSubmission, setWorkspaceDocumentId } from 'internal/redux'
import { useAppDispatch } from 'internal/redux/hooks'
import { loadPacket } from 'internal/redux/slices/packet'
import { formatDate } from 'internal/util/dates'
import { UpdateSubmissionPayload, updateSubmission } from 'lib/api/submission'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { BiRotateRight } from 'react-icons/bi'
import { HiOutlineChevronDown, HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useKeyPress } from 'react-use'
import { SubmissionStatus } from 'types/Workspace'
import { DocumentViewer } from './DocumentViewer'

type DecisionOption = {
  label: string
  color?: keyof typeof DotColor
}
const decisionOptions: Record<SubmissionStatus, DecisionOption> = {
  pending: {
    label: 'Choose decision...',
  },
  accepted: {
    label: 'Accept document',
    color: 'green',
  },
  reviewed: {
    label: 'Request changes',
    color: 'red',
  },
  archived: {
    label: 'Archive',
    color: 'gray',
  },
}

export function WorkspaceDocumentModal() {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const documentId = useSelector(selectWorkspaceDocumentId)
  const submission = useSelector(selectSubmission)

  const { document } = useWorkspaceDocument(documentId)

  const [rotation, setRotation] = useState(0)
  const [view, setView] = useState<string>('details')
  const [decision, setDecision] = useState<SubmissionStatus | undefined>(undefined)
  const [reason, setReason] = useState<string>('')
  const [expirationDate, setExpirationDate] = useState<string>('')
  const [shouldClose] = useKeyPress('Escape')
  const [loading, setLoading] = useState(false)
  const [isReplacing, setIsReplacing] = useState(false)

  const isReviewing = submission && router.pathname.includes('manage/packets')

  const submitText = useMemo(() => {
    if (view !== 'decision') return 'Add decision'
    if (submission.status !== 'pending') return 'Update decision'

    return 'Confirm decision'
  }, [view, submission])
  const isDisabled = useMemo(() => {
    if (view !== 'decision') return false
    if (!decision) return true
    if (decision === 'accepted' && !expirationDate) return true
    if (decision === 'reviewed' && !reason) return true

    return false
  }, [view, decision, expirationDate, reason])

  const onReviewClose = useCallback(() => {
    if (!isReviewing) return

    setView('details')
    setDecision(undefined)
    setReason('')
    setExpirationDate('')
  }, [isReviewing])

  const onClose = useCallback(() => {
    dispatch(setWorkspaceDocumentId(null))
    dispatch(setSubmission(null))
    onReviewClose()
  }, [dispatch, onReviewClose])

  useEffect(() => {
    if (shouldClose || !documentId) {
      onClose()
      onReviewClose()
    }
  }, [shouldClose, onClose, documentId, onReviewClose])

  useEffect(() => {
    setRotation(0)
  }, [document])

  const onSubmit = async () => {
    if (view !== 'decision') {
      setView('decision')
      return
    }

    let payload: UpdateSubmissionPayload = {
      id: submission?.id,
      status: decision,
    }

    if (decision === 'reviewed') {
      payload.statusReason = reason
    } else if (decision === 'accepted') {
      payload.expiresAt = new Date(expirationDate).getTime()
    }

    setLoading(true)

    const res = await updateSubmission(payload)
    if (!res.ok) {
      toast.error('Something went wrong, please try again later')
    } else {
      toast.success('Successfully added decision')

      onClose()
      if (router.query.packetId) {
        dispatch(loadPacket(router.query.packetId as string))
      }
    }

    setLoading(false)
  }

  const onReplacingClose = () => setIsReplacing(false)

  useEffect(() => {
    if (document?.attributes?.expiresAt) {
      const date = formatDate(document?.attributes?.expiresAt, 'yyyy-MM-dd')
      setExpirationDate(date)
    }
  }, [document])

  useEffect(() => {
    if (submission) {
      setDecision(submission?.status)
      setReason(submission?.statusReason || '')

      const date = formatDate(submission?.expiresAt, 'yyyy-MM-dd')
      setExpirationDate(date)
    }
  }, [submission])

  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const decisionOption = decisionOptions[decision] || decisionOptions.pending

  return (
    <Fragment>
      <Modal full isOpen={Boolean(documentId)} onClose={onClose}>
        <ModalHeader
          icon={false}
          title={<Text className="pl-2">{submission?.requirement?.name || document?.name}</Text>}
        >
          <Box className="flex flex-1 justify-end gap-2">
            <Button
              icon={BiRotateRight}
              onClick={() => {
                setRotation((rotation - 90) % 360)
              }}
            >
              Rotate
            </Button>
            <RequireRole allow="provider">
              <Button icon={HiOutlineDocumentDuplicate} onClick={() => setIsReplacing(true)}>
                Replace
              </Button>
            </RequireRole>
          </Box>
        </ModalHeader>
        <Box className="dark:bg-dark-800 flex h-0 flex-1 flex-col overflow-auto border-t border-gray-200 bg-white dark:border-gray-700 sm:flex-row">
          <DocumentViewer rotation={rotation} isWorkspace documentId={documentId} mimeType={document?.mimeType} />
          <Box className="dark:bg-dark-800 w-screen max-w-md border-l bg-gray-50">
            <Accordion
              defaultValue="details"
              value={view}
              onValueChange={setView}
              type="single"
              collapsible
              className="flex h-full flex-col overflow-hidden"
            >
              <AccordionItem
                value="details"
                className="flex flex-col overflow-hidden border-b data-[state=open]:flex-1"
              >
                {!isReviewing ? null : (
                  <AccordionTrigger className="dark:bg-dark-600 border-b  bg-white px-4 py-3 dark:text-white">
                    Details
                  </AccordionTrigger>
                )}
                <AccordionContent className="h-full overflow-auto">
                  <Box className="flex h-full flex-col gap-4 overflow-auto p-4">
                    <Text>Details</Text>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <DescriptionTerm>Uploaded</DescriptionTerm>
                        <DescriptionDetails className="mt-1">{formatDate(document?.createdAt)}</DescriptionDetails>
                      </div>
                      <div className="sm:col-span-1">
                        <DescriptionTerm>Expires at</DescriptionTerm>
                        <DescriptionDetails className="mt-1">
                          {formatDate(submission?.expiresAt || document?.attributes?.expiresAt)}
                        </DescriptionDetails>
                      </div>
                      {submission?.status && (
                        <div className="sm:col-span-1">
                          <DescriptionTerm>Status</DescriptionTerm>
                          <DescriptionDetails className="mt-1">
                            <SubmissionStatusBadge submission={submission} />
                          </DescriptionDetails>
                        </div>
                      )}
                      {submission?.statusReason && (
                        <div className="sm:col-span-1">
                          <DescriptionTerm>Reason</DescriptionTerm>
                          <DescriptionDetails className="mt-1">{submission?.statusReason}</DescriptionDetails>
                        </div>
                      )}
                    </dl>
                  </Box>
                </AccordionContent>
              </AccordionItem>
              {isReviewing && (
                <AccordionItem value="decision" className="flex flex-col overflow-hidden data-[state=open]:flex-1">
                  <AccordionTrigger className="dark:bg-dark-600 border-b bg-white px-4 py-3 dark:text-white">
                    Decision
                  </AccordionTrigger>
                  <AccordionContent className="h-full overflow-auto">
                    <Box className="flex h-full flex-col gap-4 overflow-auto p-4">
                      <Text size="sm" weight="normal">
                        Add a decision for this submission. You must add a decision for all sumbissions.
                      </Text>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button>
                            <Box className="flex w-full items-center justify-between gap-4">
                              <Box className="flex items-center gap-2">
                                {decisionOption.color && <Dot color={decisionOption.color} />}
                                <Text size="sm" weight="normal">
                                  {decisionOption.label}
                                </Text>
                              </Box>
                              <Icon icon={HiOutlineChevronDown} />
                            </Box>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="flex w-full flex-col gap-1">
                          {Object.keys(decisionOptions)
                            .filter(d => d !== 'pending')
                            .map((d: SubmissionStatus) => {
                              const option = decisionOptions[d]

                              return (
                                <DropdownMenuItem
                                  key={`option-${d}`}
                                  className="flex cursor-pointer items-center gap-2"
                                  onClick={() => setDecision(d)}
                                >
                                  <Dot color={option.color} />
                                  <Text size="sm" weight="normal">
                                    {option.label}
                                  </Text>
                                </DropdownMenuItem>
                              )
                            })}
                        </DropdownMenuContent>
                      </DropdownMenu>
                      {decision === 'reviewed' && (
                        <Box className="flex flex-col gap-4">
                          <Box className="flex flex-col gap-1">
                            <Label required>Reason for requesting changes</Label>
                            <TextArea
                              required
                              value={reason}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
                            />
                          </Box>

                          <Text weight="normal" size="sm" variant="light">
                            By requesting changes for this submission, a notification will be sent to the provider to
                            make the necessary changes.
                          </Text>
                        </Box>
                      )}
                      {decision === 'accepted' && (
                        <Box className="flex flex-col gap-4">
                          <Box className="flex flex-col gap-1">
                            <Label required>Expiration date</Label>
                            <Input
                              type="date"
                              min={today}
                              required
                              value={expirationDate}
                              onChange={e => setExpirationDate(e.target.value)}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>
          </Box>
        </Box>
        <ModalFooter
          isOnlyClose={!isReviewing}
          onClose={onClose}
          onSubmit={onSubmit}
          submitText={submitText}
          isLoading={loading}
          isDisabled={isDisabled}
        />
      </Modal>
      {submission && submission.requirement && (
        <NewWorkspaceDocumentModal
          requirement={submission?.requirement}
          isOpen={isReplacing}
          onClose={onReplacingClose}
          afterUpload={onClose}
        />
      )}
    </Fragment>
  )
}
