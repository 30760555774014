import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { InputGroup } from 'components/InputGroup'
import { Modal, ModalBody, ModalProps } from 'components/Modal'
import { ModalForm } from 'components/Modals/ModalForm'
import { Text } from 'components/Text'
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { HiCheck, HiX } from 'react-icons/hi'
import { RequiredProviderDocument } from 'types'
import {
  Authorization,
  Confidentiality,
  Indemnification,
  InsuranceAccuracy,
  Introduction,
  LiabilityRelease,
} from './Terms'

type Props = ModalProps & {
  requiredDocuemnt: RequiredProviderDocument
  onSubmit: any
}

export function AttestationModal({ isOpen, onClose, onSubmit }: Props) {
  const { account } = useAuth()
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      signature: '',
    },
  })

  const { signature } = watch()

  const fullName = `${account?.firstName?.toUpperCase()} ${account?.lastName?.toUpperCase()}`
  const providerName = `${account?.provider?.name?.toUpperCase()}`

  useEffect(() => {
    reset()
  }, [isOpen, reset])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalForm className="mx-auto w-full max-w-xl rounded-md" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Box className="flex flex-1 flex-col gap-6">
            <Box className="flex items-start gap-4">
              <Text className="flex-1 pt-1">
                Insurance verification authorization and release of liability agreement
              </Text>
              <IconButton variant="ghost" size="sm" ariaLabel="Close" onClick={onClose} icon={HiX} />
            </Box>
            <Box className="h-full">
              <Box className="flex max-h-60 flex-col gap-4 overflow-auto rounded border bg-gray-50 p-2">
                <Introduction fullName={fullName} providerName={providerName} />
                <Authorization providerName={providerName} />
                <InsuranceAccuracy />
                <LiabilityRelease />
                <Confidentiality />
                <Indemnification />
              </Box>
            </Box>
            <Box className="flex flex-col gap-6">
              <Text as="span" size="sm" weight="normal">
                Please provide your e-signature below by typing your first and last name, as seen here:{' '}
                <Text as="span" size="sm" weight="semibold">
                  {fullName}
                </Text>
              </Text>
              <InputGroup
                label="E-Signature"
                className="font-serif italic"
                required
                {...register('signature')}
                value={signature?.toUpperCase()}
                error={errors.signature?.message}
                icon={signature?.toUpperCase() === fullName ? HiCheck : undefined}
                iconColor="success"
              />
              <Button
                block
                variant="primary"
                disabled={signature?.toUpperCase() != fullName?.toUpperCase()}
                type="submit"
              >
                Accept and sign
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalForm>
    </Modal>
  )
}
