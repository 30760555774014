import { Skeleton } from '@/components/ui/skeleton'
import { TableBody, TableCell, TableRow } from '@/components/ui/table'

export function LoadingState() {
  return (
    <TableBody>
      {Array.apply(null, Array(20)).map((_, idx) => (
        <TableRow key={idx}>
          <TableCell className="py-2">
            <Skeleton className="h-[20px] w-[20px] rounded" />
          </TableCell>
          <TableCell className="py-2">
            <Skeleton className="h-[14px] w-[160px] rounded-full" />
          </TableCell>
          <TableCell className="py-2">
            <Skeleton className="h-[14px] w-[80px] rounded-full" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
