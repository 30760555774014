import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { useAuth } from 'hooks/useAuth'
import { Document } from 'types/Document'

import { Icon } from 'components/Icon'
import { useRoles } from 'hooks/useRoles'
import { completeDocumentUpload } from 'lib/api'
import { Fragment, useState } from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { toast } from 'react-toastify'
import { RejectUploadedDocumentModal } from './RejectUploadedDocumentModal'

type Props = {
  document: Document
  baseUrl: string
  reload?: () => void
  onClose: () => void
}

export function HeldDocumentActions({ document, reload, onClose }: Props) {
  const [accepting, setAccepting] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const { account } = useAuth()
  const { isProvider } = useRoles()

  if (!account || !isProvider || !document?.uploadLink) return null

  const shouldShowProviderActions =
    document.status === 'held' && account.providerId === document.providerId && document.uploadLinkToken

  if (!shouldShowProviderActions) return null

  const onCompleteUpload = async () => {
    setAccepting(true)
    try {
      const { document: completedDocument } = await completeDocumentUpload({
        uploadToken: document.uploadToken,
      })

      if (!completedDocument) {
        toast.error('There was a problem accepting the document')
        return
      }

      onClose()
      toast.success('Document sent for review')
    } catch (e) {
      toast.error('There was a problem accepting the document')
    } finally {
      setAccepting(false)
    }
  }

  const driver = document?.uploadLink?.driver

  return (
    <Fragment>
      <Box className="mb-6 flex rounded-lg border border-blue-400 bg-blue-50 p-4 dark:border-blue-500 dark:bg-blue-900 dark:bg-opacity-30">
        <Icon icon={HiOutlineInformationCircle} color="info" className="mr-2 mt-0.5" />
        <Box className="flex flex-col">
          <Text as="span" className="mb-1">
            This document was submitted by{' '}
            <Text as="span" weight="semibold">
              {driver ? `${driver.firstName} ${driver.lastName}` : 'the driver'}
            </Text>
          </Text>
          <Box className="mb-4">
            <Text as="span" size="sm" weight="normal">
              {
                "If the document meets Modivcare's market requirements, accept it. If not, reject it with a reason so that "
              }
              <Text as="span" size="sm" weight="semibold">
                {driver ? `${driver.firstName} ${driver.lastName}` : 'the driver'}
              </Text>{' '}
              can resubmit after making necessary changes.
            </Text>
          </Box>
          <Box className="flex flex-1 items-center gap-2">
            <Button
              loading={accepting}
              disabled={rejecting}
              onClick={onCompleteUpload}
              className="flex-1 justify-center"
              variant="primary"
            >
              Accept
            </Button>
            <Button
              disabled={accepting || rejecting}
              onClick={() => setRejecting(true)}
              className="flex-1 justify-center"
            >
              Reject
            </Button>
          </Box>
        </Box>
      </Box>
      <RejectUploadedDocumentModal
        document={document}
        isOpen={rejecting}
        onClose={() => setRejecting(false)}
        onDocumentModalClose={onClose}
        reload={reload}
      />
    </Fragment>
  )
}
