import { useAuth } from 'hooks/useAuth'

export const useRoles = () => {
  const { account } = useAuth()

  return {
    isManager: account?.roles?.includes('manager') && !account?.roles?.includes('specialist'),
    isSpecialist: account?.roles?.includes('specialist'),
    isProvider: account?.roles?.includes('provider'),
    isSuperAdmin: account?.roles?.includes('superadmin'),
    isOperations: account?.roles?.includes('operations'),
    isVolunteerDriver: account?.roles?.includes('volunteer-driver'),
    isDmas: account?.roles?.includes('dmas'),
    isReadOnly: account?.roles?.includes('read-only'),
    isBroker: account?.roles?.includes('broker'),
    isRiskManager: account?.roles?.includes('risk-manager'),
    roles: account?.roles,
  }
}
