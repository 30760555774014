import { formatDate } from 'internal/util/dates'
import { DocumentExemption } from 'types/Document'
import { checkExpiration } from 'util/checks/checkExpiration'
import { checkFirstName, checkLastName, checkMiddleName } from 'util/checks/checkName'
import { checkVINOwner } from 'util/checks/checkVINOwner'
import { formatAge } from 'util/format'

export const SECTIONS = [
  {
    name: 'Expiration',
    fields: [
      { title: 'Issued', name: 'issuedAt', type: 'date' },
      { title: 'Expires', name: 'expiresAt', type: 'date', subtitle: checkExpiration },
    ],
  },
  {
    name: 'General',
    fields: [
      { title: 'Document ID', name: 'id', type: 'text' },
      { title: 'First Name', name: 'firstName', type: 'text', subtitle: checkFirstName },
      { title: 'Middle Name', name: 'middleName', type: 'text', subtitle: checkMiddleName },
      { title: 'Last Name', name: 'lastName', type: 'text', subtitle: checkLastName },
      { title: 'Suffix', name: 'suffix', type: 'text' },
      { title: 'Street', name: 'addressStreet', type: 'text' },
      { title: 'Street 2', name: 'addressStreet2', type: 'text' },
      { title: 'City', name: 'addressCity', type: 'text' },
      { title: 'State', name: 'addressState', type: 'text' },
      { title: 'ZIP Code', name: 'addressZIP', type: 'text' },
      { title: 'DOB', name: 'dob', type: 'date', subtitle: v => (v ? formatAge(v) : null) },
      { title: 'SSN', name: 'ssn', type: 'text' },
      { title: 'Screening SSN', name: 'screeningSsn', type: 'text' },
      { title: 'Restrictions', name: 'restrictions', type: 'text' },
      { title: 'Classification', name: 'classification', type: 'text' },
      { title: 'Issuing State', name: 'issuingState', type: 'text' },
    ],
  },
  {
    name: 'Vehicle',
    fields: [
      { title: 'VIN', name: 'vin', type: 'text', subtitle: checkVINOwner },
      { title: 'Plate', name: 'plate', type: 'text' },
      { title: 'Year', name: 'year', type: 'text' },
      { title: 'Make', name: 'make', type: 'text' },
      { title: 'Model', name: 'model', type: 'text' },
      { title: 'Color', name: 'color', type: 'text' },
      { title: 'Capacity', name: 'capacity', type: 'text' },
      { title: 'Odometer', name: 'odometer', type: 'text' },
    ],
  },
  {
    name: 'Insurance',
    fields: [
      { title: 'NAIC', name: 'naic', type: 'text' },
      { title: 'Producer', name: 'producer', type: 'text' },
      { title: 'Registered Party', name: 'registeredParty', type: 'text' },
      { title: 'Additional Insured', name: 'additionalInsured', type: 'text' },

      { title: 'Agent Name', name: 'agentName', type: 'text' },
      { title: 'Agent Email', name: 'agentEmail', type: 'text' },
      { title: 'Agent Phone', name: 'agentPhone', type: 'text' },

      { title: 'Certificate Number', name: 'certificateNumber', type: 'text' },
      { title: 'Certificate Holder', name: 'certificateHolder', type: 'text' },
      { title: 'Certificate Holder Street', name: 'certificateHolderAddressStreet', type: 'text' },
      { title: 'Certificate Holder Street 2', name: 'certificateHolderAddressStreet2', type: 'text' },
      { title: 'Certificate Holder City', name: 'certificateHolderAddressCity', type: 'text' },
      { title: 'Certificate Holder State', name: 'certificateHolderAddressState', type: 'text' },
      { title: 'Certificate Holder ZIP', name: 'certificateHolderAddressZIP', type: 'text' },

      { title: 'Policy Provider', name: 'policyProvider', type: 'text' },
      { title: 'Policy Number', name: 'policyNumber', type: 'text' },
      { title: 'Policy Limit - Per Occurrence', name: 'policyLimitPerOccurrence', type: 'text' },
      { title: 'Policy Limit Aggregate', name: 'policyLimitAggregate', type: 'text' },
      { title: 'Policy Limit Sexual Abuse & Molestation', name: 'policyLimitSexualAbuseAndMolestation', type: 'text' },
      {
        title: 'Policy Limit Sexual Abuse & Molestation Aggregate',
        name: 'policyLimitSexualAbuseAndMolestationAggregate',
        type: 'text',
      },
      { title: 'Policy Limit Professional Liability', name: 'policyLimitProfessionalLiability', type: 'text' },
      { title: 'Policy Limit Malpractice', name: 'policyLimitMalpractice', type: 'text' },
      { title: 'Policy Limit Combined Single Limit', name: 'policyLimitCombinedSingleLimit', type: 'text' },
      { title: 'Policy Limit Per Accident', name: 'policyLimitPerAccident', type: 'text' },
      { title: 'Policy Limit Per Disease', name: 'policyLimitPerDisease', type: 'text' },
      { title: 'Policy Limit Per Employee', name: 'policyLimitPerEmployee', type: 'text' },
      { title: 'Policy Limit - Bodily Injury - Per Person', name: 'policyLimitBodilyInjuryPerPerson', type: 'text' },
      {
        title: 'Policy Limit - Bodily Injury - Per Accident',
        name: 'policyLimitBodilyInjuryPerAccident',
        type: 'text',
      },
      {
        title: 'Policy Limit - Property Damage - Per Accident',
        name: 'policyLimitPropertyDamagePerAccident',
        type: 'text',
      },
      { title: 'Inspection Outcome', name: 'inspectionOutcome', type: 'text' },
      { title: 'Description Box', name: 'descriptionsBox', type: 'textarea' },
    ],
  },
  {
    name: 'Insurance Boxes',
    selectable: false,
    fields: [
      { title: 'Box - Additional Insured', name: 'boxAdditionalInsured', type: 'boolean' },
      { title: 'Box - Commercial General Liability', name: 'boxCommercialGeneralLiability', type: 'boolean' },
      { title: 'Box - General - Claims Made', name: 'boxGeneralClaimsMade', type: 'boolean' },
      { title: 'Box - General - Occurrence', name: 'boxGeneralOccurrence', type: 'boolean' },
      {
        title: 'Box - General - Aggregate Applies per Policy',
        name: 'boxGeneralAggregateAppliesPerPolicy',
        type: 'boolean',
      },
      {
        title: 'Box - General - Aggregate applies per Project',
        name: 'boxGeneralAggregateAppliesPerProject',
        type: 'boolean',
      },
      {
        title: 'Box - General - Aggregate applies per Location',
        name: 'boxGeneralAggregateAppliesPerLocation',
        type: 'boolean',
      },
      { title: 'Box - Auto - Any Auto', name: 'boxAutoAnyAuto', type: 'boolean' },
      { title: 'Box - Auto - All Owned Autos', name: 'boxAutoAllOwnedAutos', type: 'boolean' },
      { title: 'Box - Auto - Hired Autos', name: 'boxAutoHiredAutos', type: 'boolean' },
      { title: 'Box - Auto - Scheduled Autos', name: 'boxAutoScheduledAutos', type: 'boolean' },
      { title: 'Box - Auto - Non Owned Autos', name: 'boxAutoNonOwnedAutos', type: 'boolean' },
      { title: 'Box - Auto - Umbrella Liab', name: 'boxAutoUmbrellaLiab', type: 'boolean' },
      { title: 'Box - Auto - Excess', name: 'boxAutoExcess', type: 'boolean' },
      { title: 'Box - Auto - Occurrence', name: 'boxAutoOccurrence', type: 'boolean' },
      { title: 'Box - Auto - Claims Made', name: 'boxAutoClaimsMade', type: 'boolean' },
      { title: 'Box - Auto - Ded', name: 'boxAutoDed', type: 'boolean' },
      { title: 'Box - Auto - Retention', name: 'boxAutoRetention', type: 'boolean' },
      { title: 'Box - Subrogation Waived', name: 'boxSubrogationWaived', type: 'boolean' },
      {
        title: 'Box - Workers Compensation and Employers Liability Excluded',
        name: 'boxCompensationWorkersAndEmployersLiabilityExcluded',
        type: 'boolean',
      },
      { title: 'Box - Signature', name: 'boxSignature', type: 'boolean' },
    ],
  },
  {
    name: 'Other',
    fields: [
      { title: 'Vendor Name', name: 'vendorName', type: 'text' },
      { title: 'Inspector Name', name: 'inspectorName', type: 'text' },
      // { title: 'Status', name: 'status', type: 'text' },
    ],
  },
]

export const FIELDS = SECTIONS.reduce((fields, section) => {
  section.fields.forEach(field => fields.push(field))
  return fields
}, [])

export type StatusOption = {
  id: string
  value: string
  label: string
  classNames: {
    text: string
    indicator: string
    button: string
  }
}

export const getStatusExempt = (exemption: DocumentExemption): StatusOption => {
  return {
    id: 'exempt',
    value: 'exempt',
    label: exemption.expiresAt ? `Exempt until ${formatDate(exemption.expiresAt)}` : 'Exemption Active',
    classNames: {
      text: 'text-gray-400 dark:bg-gray-400',
      indicator: 'bg-gray-400 dark:bg-gray-400',
      button:
        'text-gray-800 bg-gray-100 border-gray-200 dark:text-gray-300 dark:border-gray-400 dark:bg-gray-900 dark:bg-opacity-20',
    },
  }
}

export const STATUS_OPTIONS: StatusOption[] = [
  {
    id: 'approved',
    value: 'approved',
    label: 'Approved',
    classNames: {
      text: 'text-green-500 dark:text-green-300',
      indicator: 'bg-green-400 dark:bg-green-300',
      button:
        'text-green-800 bg-green-100 border-green-200 dark:text-green-300 dark:border-green-400 dark:bg-green-900 dark:bg-opacity-30',
    },
  },
  {
    id: 'held',
    value: 'held',
    label: 'Driver Submitted',
    classNames: {
      text: 'text-yellow-500 dark:text-yellow-300',
      indicator: 'bg-yellow-400 dark:bg-yellow-300',
      button:
        'text-yellow-800 bg-yellow-100 border-yellow-200 dark:text-yellow-300 dark:border-yellow-400 dark:bg-yellow-900 dark:bg-opacity-30',
    },
  },
  {
    id: 'ready',
    value: 'ready',
    label: 'Ready to submit',
    classNames: {
      text: 'text-gray-500 dark:text-gray-300',
      indicator: 'bg-gray-400 dark:bg-gray-300',
      button:
        'text-gray-700 bg-gray-50 border border-gray-300 dark:text-gray-200 dark:border-gray-600 dark:bg-dark-800',
    },
  },
  {
    id: 'rejected',
    value: 'rejected',
    label: 'Rejected',
    classNames: {
      text: 'text-red-500 dark:text-red-300',
      indicator: 'bg-red-400 dark:bg-red-300',
      button:
        'text-red-800 bg-red-100 border-red-200 dark:text-red-300 dark:border-red-400 dark:bg-red-900 dark:bg-opacity-20',
    },
  },
  {
    id: 'failed',
    value: 'failed',
    label: 'Failed',
    classNames: {
      text: 'text-red-500 dark:text-red-300',
      indicator: 'bg-red-400 dark:bg-red-300',
      button:
        'text-red-800 bg-red-100 border-red-200 dark:text-red-300 dark:border-red-400 dark:bg-red-900 dark:bg-opacity-20',
    },
  },
  {
    id: 'created',
    value: 'created',
    label: 'Uploading',
    classNames: {
      text: 'text-gray-400 dark:bg-gray-400',
      indicator: 'bg-gray-400 dark:bg-gray-400',
      button: 'text-gray-800 bg-gray-100 border-gray-200 dark:text-gray-400 dark:border-gray-400 dark:bg-transparent',
    },
  },
  {
    id: 'uploaded',
    value: 'uploaded',
    label: 'Processing',
    classNames: {
      text: 'text-yellow-400 dark:bg-yellow-300',
      indicator: 'bg-yellow-400 dark:bg-yellow-300',
      button:
        'text-yellow-800 bg-yellow-100 border-yellow-200 dark:text-yellow-300 dark:border-yellow-400 dark:bg-yellow-900 dark:bg-opacity-20',
    },
  },
  {
    id: 'analyzed',
    value: 'analyzed',
    label: 'Waiting for Approval',
    classNames: {
      text: 'text-blue-400 dark:bg-blue-300',
      indicator: 'bg-blue-400 dark:bg-blue-300',
      button:
        'text-blue-800 bg-blue-100 border-blue-200 dark:text-blue-300 dark:border-blue-400 dark:bg-blue-900 dark:bg-opacity-30',
    },
  },
  {
    id: 'waiting',
    value: 'waiting',
    label: 'Waiting for Approval',
    classNames: {
      text: 'text-blue-400 dark:bg-blue-300',
      indicator: 'bg-blue-400 dark:bg-blue-300',
      button:
        'text-blue-800 bg-blue-100 border-blue-200 dark:text-blue-300 dark:border-blue-400 dark:bg-blue-900 dark:bg-opacity-30',
    },
  },
  {
    id: 'archived',
    value: 'archived',
    label: 'Archived',
    classNames: {
      text: 'text-gray-400 dark:bg-gray-400',
      indicator: 'bg-gray-400 dark:bg-gray-400',
      button: 'text-gray-800 bg-gray-100 border-gray-200 dark:text-gray-400 dark:border-gray-400 dark:bg-transparent',
    },
  },
]

export const STATUS_OPTIONS_FILTERABLE: StatusOption[] = [
  {
    id: 'all',
    value: 'all',
    label: 'All',
    classNames: {
      text: 'text-gray-400',
      indicator: 'bg-gray-400',
      button: 'text-gray-800 bg-gray-100',
    },
  },
].concat(STATUS_OPTIONS)

export const STATUS_OPTIONS_FILTERABLE_PROVIDER = [
  'all',
  'waiting',
  'approved',
  'rejected',
  'failed',
  'archived',
  'held',
].map(option => STATUS_OPTIONS_FILTERABLE.find(o => o.value === option))

export const STATUS_OPTIONS_FILTERABLE_MANAGER = ['all', 'waiting', 'approved', 'rejected', 'archived'].map(option =>
  STATUS_OPTIONS_FILTERABLE.find(o => o.value === option),
)

export const STATUS_OPTIONS_SETTABLE_MANAGER = ['approved', 'rejected'].map(option =>
  STATUS_OPTIONS_FILTERABLE.find(o => o.value === option),
)

export const REQUIRE_LEVEL = {
  REQUIRED: 0,
  WARNING: 1,
  OPTIONAL: 2,
  AUXILIARY_REQUIRED: 3,
  REQUESTABLE: 4,
  HIDDEN: 5,
}

export const REQUIRE_LEVEL_LOOKUP = {
  [REQUIRE_LEVEL.REQUIRED]: 'Required',
  [REQUIRE_LEVEL.WARNING]: 'Warning',
  [REQUIRE_LEVEL.OPTIONAL]: 'Optional',
  [REQUIRE_LEVEL.REQUESTABLE]: 'Requestable',
  [REQUIRE_LEVEL.HIDDEN]: 'Hidden',
}
