export function InsuranceDocuments() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="100%" height="100%" viewBox="0 0 1000 1000">
      <path fill="#e9e9e9" d="M888.7 388H237.9l-78.2 370.3h650.8L888.7 388Z" />
      <path fill="#ccc" d="m763.2 457.1-52.1 265.3 88.9 35.9h10.5L850 571l-86.8-113.9Z" />
      <path d="m763.2 457.1-52.1 265.3 88.9 35.9h10.5L834 646.9l-70.8-189.8Z" />
      <path fill="none" stroke="#000" strokeMiterlimit="10" d="M888.7 388H237.9l-78.2 370.3h650.8L888.7 388Z" />
      <path fill="#fff" d="M645.8 385.2h16.5v3.5h-16.5v-3.5Zm-65.5 0h47.9v3.5h-47.9v-3.5Z" />
      <path d="M637.8 355c-.5 4.2-.6 8.4-.1 12.7.1 2.8.4 5.6 1 8.3.5 2.7 2 5.1 4.1 6.9 2.2 1.7 5 2.3 7.8 1.8 3.3-.5 6.3-2.3 8.3-5.1.4-.5-.5-1-.9-.5-3 4.4-9.8 6.4-14.4 3.2s-4.6-9.2-4.9-14.1c-.5-4.3-.5-8.6 0-12.9.1-.7-.8-.9-.9-.3Zm14.3 11.6c0-.1.1-.1 0 0l-.1.1.1-.1h.5l.1.1.1.1v.3c0-.1 0-.2.1-.2l.1-.3v-.4c-.1-.1-.2-.2-.3-.2h-.4l-.1.1q-.1.1-.1.2l-.1.2c0 .1-.1.2-.1.2v.2c0 .1 0 .2.1.3.1.2.4.3.7.3.1 0 .2-.1.3-.2.1 0 .1-.1.1-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3v-.3c-.1-.3-.2-.5-.5-.6-.1-.1-.3-.1-.4 0-.1 0-.2.1-.3.2s-.1.3 0 .4c0 .1.1.2.2.3l-.3.1Z" />
      <path d="M642 367.5q0-.1 0 0s0 .1-.1.2l.1-.2h.5l.1.1.1.1v.3c0-.1 0-.2.1-.2l.1-.3c.1-.1.1-.3 0-.4s-.2-.2-.3-.2h-.4l-.1.1q-.1.1-.1.2v.3c-.1.1-.1.2-.1.3v.2c0 .1 0 .2.1.3.1.2.4.3.7.3.1 0 .2-.1.3-.2.1 0 .1-.1.1-.2.1-.1.1-.2.2-.3 0-.1.1-.2.1-.3v-.3c-.1-.3-.2-.5-.5-.6-.1-.1-.3-.1-.4 0-.1 0-.2.1-.3.2v.4c0 .1.1.2.2.3l-.4-.1Zm21 .6c2.1-1 3.6-3 4-5.4.2-1.2-.2-2.4-1.1-3.2-1-.7-2.3-.8-3.3-.2-1.2.6-2 1.8-2.2 3.1-.1.6.9.9 1 .3.1-1.2.9-2.2 2.1-2.6 1.1-.3 2.2.2 2.5 1.3v.2c.2 1.2-.1 2.5-.8 3.5s-1.6 1.8-2.6 2.3c-.6.1-.1 1 .4.7Z" />
      <path d="M664.5 361.7c-.3-.2-.6-.2-.9 0-.3.2-.6.5-.7.9-.3.8-.5 1.7-.5 2.5 0 .2.3.2.2 0 0-.7.1-1.5.3-2.2.1-.3.3-.6.5-.9.2-.2.6-.3.8-.1.3.1.4-.1.3-.2Z" />
      <path d="M663.7 363.2c-.1-.2-.4-.3-.6-.2s-.4.3-.5.5q0 .1.1.2h.1c0-.1.1-.2.1-.3l.2-.2h.3c.1.1.3 0 .2-.1l.1.1Zm-1.5 6.6c-.2 7-.8 14.1-.1 21.2.1.6 1.1.6 1 0-.7-7-.1-14.1.1-21.2 0-.3-.2-.5-.5-.5s-.5.2-.5.5Zm-17.4 14c.1 3.5.5 6.9.4 10.4 0 .7 1 .7 1 0 .1-3.5-.2-6.9-.4-10.4 0-.6-1-.6-1 0Zm1.6-17.7c-.2 1.8-.3 3.6-.2 5.4 0 .3.2.5.5.5s.5-.2.5-.5c-.1-1.8-.1-3.6.2-5.4 0-.3-.2-.5-.5-.5s-.5.2-.5.5Zm8.1-5c-.8-.4-1.7-.5-2.6-.4-.3.1-.4.3-.3.6.1.3.4.4.6.4h.9c.2 0 .3.1.5.1h.3l.1.1c.2.1.5.1.7-.2s0-.6-.2-.6Zm-10.7 1.4c-.8-.4-1.7-.5-2.6-.4-.3.1-.4.3-.3.6.1.3.3.4.6.4h1l.5.1h.3l.1.1c.2.1.5.1.7-.2s-.1-.6-.3-.6Z" />
      <path d="M657.6 380.6s-5.6 8.3-11.7 11.3l-.1-3.4-.2-4.3s-.2-.1 1.3.2c3.5.9 9.5-2.5 10.7-3.8Z" />
      <path fill="#fff" d="M477.8 385.2h17.4v3.5h-17.4v-3.5Z" />
      <path d="M469.8 355c-.5 4.2-.5 8.5-.1 12.7.1 2.8.4 5.6 1 8.3.5 2.7 2 5.1 4.1 6.9 2.2 1.7 5 2.3 7.8 1.8 3.3-.5 6.3-2.3 8.3-5.1.4-.5-.5-1-.9-.5-3 4.4-9.9 6.4-14.4 3.2s-4.6-9.2-4.9-14.1c-.4-4.3-.4-8.6.1-12.9.1-.6-.9-.9-1-.3Zm14.4 11.6c-.1-.1 0-.1 0 0l-.1.1.1-.1c0 .1-.1.1-.1.2l.1-.2h.4l.1.1.1.1v.3c0-.1 0-.2.1-.2l.1-.3v-.4c-.1-.1-.2-.2-.3-.2h-.4l-.1.1q-.1.1-.1.2l.1.3c-.2.1-.3.1-.3.2v.2c0 .1 0 .2.1.3.1.2.4.3.7.3.1 0 .2-.1.3-.2.1 0 .1-.1.1-.2.1-.1.1-.2.1-.3s.1-.2.1-.3v-.3c-.1-.3-.2-.5-.5-.6-.1-.1-.3-.1-.4 0-.1 0-.2.1-.3.2-.1.1-.1.3 0 .4 0 .1.1.2.2.3h-.1Z" />
      <path d="M474 367.5q0-.1 0 0l-.1.2.1-.2-.1.1.1-.1h.4l.1.1.1.1v.3c0-.1 0-.2.1-.2l.1-.3v-.4c-.1-.1-.2-.2-.3-.2h-.4l-.1.1q-.1.1-.1.2l-.1.2c0 .1-.1.2-.1.2v.2c0 .1 0 .2.1.3.1.2.4.3.6.3.1 0 .2-.1.3-.2.1 0 .1-.1.1-.2.1-.1.1-.2.1-.3s.1-.2.1-.3v-.3c-.1-.3-.2-.5-.5-.6-.1-.1-.3-.1-.4 0-.1 0-.2.1-.3.2-.1.1-.1.3 0 .4 0 .1.1.2.2.4Zm21 .6c2.1-1 3.6-3 4-5.4.2-1.2-.2-2.4-1.1-3.2-1-.7-2.3-.8-3.3-.2-1.2.6-2 1.8-2.2 3.1-.1.6.9.9 1 .3.1-1.2.9-2.2 2.1-2.6 1.1-.3 2.2.2 2.5 1.3v.2c.2 1.2-.1 2.5-.8 3.5-.7 1-1.6 1.8-2.6 2.3-.6.1-.1 1 .4.7Z" />
      <path d="M496.5 361.7c-.3-.2-.6-.2-.9 0s-.6.5-.7.9c-.3.8-.5 1.7-.5 2.5 0 .1.1.1.2.1 0 0 .1 0 .1-.1 0-.7.1-1.5.4-2.2.1-.3.3-.6.5-.9.2-.2.6-.3.9-.1 0 .1.1-.1 0-.2Z" />
      <path d="M495.7 363.2c-.1-.2-.4-.3-.6-.2-.2.1-.4.3-.5.5v.2h.2c0-.1.1-.2.1-.3l.2-.2h.3c.1.1.3 0 .2-.1l.1.1Zm-1.5 4.5c-.2 6.7.1 13.4.5 20.1.1 1.8.2 3.6.4 5.3.1.6 1.1.6 1 0-.7-6.8-.9-13.7-1-20.5v-5c.1-.6-.9-.6-.9.1Zm-17.3 16.1c.1 3.5.5 6.9.4 10.4 0 .3.2.5.5.5s.5-.2.5-.5c.1-3.5-.2-6.9-.4-10.4 0-.6-1-.6-1 0Zm1.5-17.7c-.2 1.8-.3 3.6-.2 5.4 0 .3.2.5.5.5s.5-.2.5-.5c-.1-1.8-.1-3.6.2-5.4 0-.3-.2-.5-.5-.5s-.5.2-.5.5Zm8.1-5c-.8-.4-1.7-.5-2.6-.4-.3.1-.4.4-.4.6.1.3.4.4.6.4 0 0 .2 0 0 0h.9c.2 0 .3.1.5.1h.3l.1.1c.2.1.5.1.7-.2.1-.2.1-.5-.1-.6Zm-10.7 1.4c-.8-.4-1.7-.5-2.6-.4-.3.1-.4.3-.4.6.1.3.3.4.6.4h1c.2 0 .4.1.5.1h.3l.1.1c.2.1.5.1.7-.2s0-.6-.2-.6Z" />
      <path d="M489.6 380.6s-5.6 8.3-11.7 11.3l-.1-3.4-.2-4.3s-.2-.1 1.3.2c3.5.9 9.5-2.5 10.7-3.8Zm-7.3-7.4c-.3.3-.6.5-1 .6l-.1.1h-.1l-.3.1c-.2.1-.4.1-.6.1-.3.1-.4.4-.4.6.1.3.4.4.6.4.9-.2 1.7-.5 2.4-1.1.1-.1.2-.2.2-.3v-.4c-.1-.1-.4-.2-.7-.1Zm-16.6-20.6c-.9 1.5-.9 3.4.1 4.8s3 2.5 7 1.5c3.8-.9 7.6-2.2 11.2-3.7 0 0-2.7 1.4-1.9 3.6s7.5-2.7 7.5-2.7 1.1 5 3.5 5.4c.4-1.3 1.7-2.1 3-2.1 2.4-.1 2.6 2.6 2.3 3.7s-1.4 3.3-2 3.6-1.7.3-1.7 2.9.1 8.3.1 8.3 1.4.6 2.4-3.2 3.7-10.8 3.3-17.2c0 0 1.2 1.6 2.7.7s.7-4.4-3-6.3c0 0 4.3 2.5 3.5-1.3s-7.8-5.5-9.9-3.9c0 0-7.4-8.1-9.4-6.2s-.7 3.7-.7 3.7-9.5-5.5-12.9-1.9.6 5.9.6 5.9-2.9-.5-5.7 4.4Z" />
      <path d="M467.5 357.1s-1.9 3.1-1.1 4.6c.4.7 1.2 1 2 .6.1 0 .2-.1.2-.2l1.5 5.2-.3-9.9" />
      <path
        stroke="#000"
        strokeMiterlimit="10"
        d="M648.5 373.8c-.1-.4.1-.8.5-.9 1-.4 3.1-.9 3.3.1.3 1.3-3.1 4.1-3.8.8Z"
      />
      <path d="M633.9 357.9s-1.2 4 2.9 3.4c10.8-1.6 14-6.6 17.4-11.1 0 0 4 10.2 7.1 10.9 0 0 3.3-2.5 4.2-1.2s1.2 3.7.2 5c-.6.8-1.3 1.5-2 2.2 0 0-1.4.4-1.5 1.7s0 8.5 0 8.5 9.7-8.4 6.9-19.2c-2.4-9-8.9-15.4-13.6-12.7 0 0-10.6-5.2-15.9.7-3 3.3-5 7.4-5.7 11.8Z" />
      <path d="M633.9 357.9s-2.9 9.7-6.6 15c-2.9 4.2-2.4 10.9-.7 13.6.7 1 .8 2.3.2 3.4-1.4 2.8-1.5 6.1-.2 9 .4 1.3 1.4 2.3 2.6 2.7 1.7.5 2.7 2.2 2.4 4-.6 3.2-.2 7.3 4.9 8 8.2 1.1 10.4-6.2 10.1-13.9-.3-6.5-.7-11.9-.9-14.2-.1-1-.5-1.8-1.3-2.4-.1-.1-.2-.2-.4-.2-.6-.2-5.8-4-5.7-13.2s-.5-14.2 1.7-14.6" />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="M662.6 390.9s27.6 3.3 35.2 21.6-19.8 44.6-19.8 44.6m-44.3-70.8s-29.8-9.2-33.5-11c5.3-9.3 9.5-19.3 12.6-29.5 4.3-15.4 9.8-15 10.2-19.8s-3.5-8.7-3.5-8.7"
      />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="m605.7 318.7 1.4 4.8c.4 1.5 0 3.1-1 4.3-5.4 6.3-21.9 25.8-26.9 38.3-6.1 15.1-2.8 29.6 35.8 43.1m2-87.5-20.6-5.5c-1.2-.3-2-1.6-1.7-2.8l9.2-44c.3-1.3 1.5-2.1 2.9-1.9h.1l23.4 6.2c.8.2 1.5.9 1.7 1.7l2.7 9.8c.1.4.1.8 0 1.1l-7.3 35.3M567 457.1l5.8-35.6c.2-.9.7-1.7 1.4-2.3l9.1-6.8c.8-.6 1.8-.8 2.8-.7l18 3.3c1.9.4 3.2 2.2 2.9 4.1l-2.7 16.5"
      />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="M594.1 434.3s6.8-1.5 11.9 2.6m2.5-6.5s4.7 2 4.1 10.1c5.2 3.5 10.7 6.6 16.5 9.1 9.8 4.4 17.7 7.5 17.7 7.5"
      />
      <path d="m595.1 439.8 1.6.2c.3 0 .5-.2.5-.5 0-.1 0-.3-.1-.3-.1-.1-.2-.1-.4-.2l-1.6-.2c-.1 0-.3 0-.3.1-.1.1-.1.2-.2.4 0 .1.1.3.2.3.1.1.2.2.3.2Zm.6 4.7c.2-.1.4-.1.7-.1h1.3c.2-.1.3-.4.2-.7-.1-.1-.2-.2-.3-.2-.7-.1-1.4-.1-2.1.1-.3.1-.4.4-.4.6s.3.5.6.3Zm.4 5.5c1.6-.5 3 .8 4.3 1.6 1.2.8 2.6 1.5 4.1 1.8.7.2 1.5.2 2.2.5.4.2.7.4 1 .8.8.7 1.4 1.6 1.8 2.5.2.6 1.2.3 1-.3-.5-1-1.1-2-2-2.8-.3-.4-.7-.7-1.1-1-.7-.3-1.5-.5-2.2-.6-1.7-.4-3.3-1.2-4.8-2.2-1.3-1-2.8-1.9-4.5-1.3-.7.2-.4 1.2.2 1Zm2-71.4c1.2-1.1 2.2-2.4 3.1-3.9.3-.5-.5-1-.9-.5-.8 1.3-1.8 2.6-2.9 3.6-.5.5.2 1.2.7.8Zm23.6 3.6c-3.9 9-6.3 18.6-7.2 28.4 0 .2.1.4.4.5l4.7 2-.3-.7c-1.8 3.8-2.9 7.8-3.3 12-.5 5.9 1.1 11.9 3.1 17.5.5 1.4 1 2.8 1.6 4.1.3.6 1.1.1.9-.5-1.5-3.4-2.7-7-3.6-10.7-1.7-5.8-1.5-12 .5-17.7.4-1.4 1-2.8 1.6-4.2.1-.2.1-.5-.2-.7h-.1l-4.7-2 .4.5c.8-9.6 3.2-19 7-27.9.3-.7-.6-1.2-.8-.6Zm36.6 50.3c8 2.9 15.6 6.7 22.7 11.2 1.8 1.2 3.5 2.5 5.1 4 .5.4 1.2-.3.7-.7-3.1-2.7-6.4-5-10-7-3.9-2.2-7.9-4.2-12-5.9-2.1-.9-4.2-1.8-6.3-2.6-.5-.2-.7.8-.2 1Z" />
      <path fill="#f4f4f4" d="m403 457.1-25.2-45.6H112.4l47.3 346.8h650.8l-47.3-301.2H403Z" />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="M490.5 457.1H403l-25.2-45.6H112.4l47.3 346.8h650.8l-47.3-301.2H503.7"
      />
      <path d="M647.1 457.2c5.9-7.1 11.4-14.6 16.4-22.4.3-.5-.5-1-.9-.5-5 7.7-10.5 15.1-16.3 22.2-.3.5.4 1.2.8.7Zm-6-51c4.5 4.1 10.5 6.1 16.6 5.5 5.9-.6 11.3-3.4 15.2-7.9 2.2-2.5 3.9-5.3 5-8.5.2-.6-.8-.9-1-.3-1.9 5.3-5.6 9.8-10.4 12.7-4.9 2.9-10.9 3.8-16.4 2.3-3.1-.8-5.9-2.4-8.3-4.6-.6-.4-1.3.3-.7.8Z" />
      <path
        stroke="#000"
        strokeMiterlimit="10"
        d="m687.8 445.7-2.5 11.1h-4.5s-3.1.7.2-2.9 6.8-8.2 6.8-8.2Zm-86.3 6-.7 5.1"
      />
      <path d="M629.2 275.5c-.6 1.2-1.2 2.4-1.7 3.7-.6 1.2-1 2.4-1.3 3.7-.2 1.2.5 1.8 1.5 2.3l3.9 2c.6.3 1.1-.6.5-.9l-3.2-1.7c-.5-.2-1-.5-1.5-.8-.5-.4-.3-.9-.1-1.4.8-2.2 1.7-4.4 2.8-6.5.3-.4-.6-.9-.9-.4Zm-53.2 147c1.4.5 2.9 1.1 4.3 1.5.7.2 1.5.5 2.2.7.6.2 1.4.6 1.9.1s.6-1.2.7-1.8c.2-.7.3-1.4.4-2.2.2-1.4.3-2.9.2-4.4 0-.6-1-.6-1 0 .1 1.3 0 2.6-.1 3.9-.1.6-.2 1.2-.3 1.9-.1.3-.2.7-.2 1 0 .1-.1.7-.2.8s-.7-.2-.8-.2l-.9-.3-1.9-.6c-1.3-.4-2.6-.9-3.8-1.4-.8-.2-1.1.8-.5 1Zm43 17.2c.8.2 15 8 27.1 5.2s16.5-9.4 16.3-9-10.2 14.3-11.1 15.5-2.9 6.5-6.3 5.1-20.5-9-20.5-9-3.3-1.8-4-3.6" />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="M477.6 392.9s-20.3-2.8-26.8 1.3-21 49.5-21 49.5-37.2-23-39.8-22.8c0 0-.5-8.3-4.4-10.8"
      />
      <path d="M379 407.4c1.6 2.1 3 4.2 4.5 6.4.3.5 1.2 0 .9-.5-1.4-2.2-2.9-4.3-4.5-6.4-.2-.2-.5-.3-.7-.1-.2.1-.3.4-.2.6Zm-4.1 2.1.2.2c.1.1.2.1.2.2l.2.2.1.1s-.1-.1 0 0v.1c.1.2.2.4.3.5l.1.3c0 .1 0-.1 0 0v.1l.1.2c.1.3.4.4.6.4s.4-.4.4-.6c-.3-1-1-1.9-1.8-2.5h-.4c-.1 0-.2.1-.3.2 0 .2 0 .5.3.6Zm-3.6.8c.2.1.3.2.5.3l.2.2.1.1c.3.2.5.5.8.7.2.2.5.2.7 0s.2-.5 0-.7c-.5-.6-1.1-1.1-1.8-1.5-.2-.1-.5-.1-.7.2-.1.2 0 .5.2.7Z" />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        d="M495.7 393.1c.6-.6 26.6-9.1 32 5s10.9 41.1 11.5 58.7m-29.3-9.6s-8.8-6.3-14.7 0c-3.5 3.5-5.2 8.4-4.7 13.2"
      />
      <path d="M494.5 460.9c-.3-1.7-.4-3.7.9-5.1.4-.5-.3-1.2-.7-.7-1.5 1.6-1.5 4-1.1 6 .1.3.4.4.6.3.2 0 .4-.3.3-.5Zm4-.8c-.4-1.2-.1-2.6.7-3.6.1-.2.1-.5-.2-.7-.2-.1-.5 0-.7.2-.9 1.3-1.2 2.9-.8 4.4.1.3.4.4.6.4s.5-.4.4-.7Zm4.5-.1c.1-.6.2-1.3.4-1.9l.3-.6c.2-.2.4-.3.7-.3 1.1-.3 2.1-1 2.7-2l-.5.1c-.1-.1-.2-.1-.1-.1l.1.2c.1.1.1.3.2.4.2.4.3.8.4 1.3.1.3.4.4.6.4s.4-.4.4-.6c-.2-.5-.4-1.1-.6-1.6-.1-.4-.4-.7-.7-.9-.2-.1-.4 0-.5.1-.4.5-.8.9-1.2 1.2-.2.2-.5.3-.7.4-.3.1-.6.1-.9.2-.6.3-1 .8-1.1 1.4-.2.7-.4 1.4-.4 2.1 0 .3.2.5.5.5s.4 0 .4-.3Zm-14.5-4.5 1.3-2.2c.1-.2.1-.5-.2-.7-.2-.1-.5-.1-.7.2l-1.3 2.2c-.1.2-.1.5.2.7.2.2.5.1.7-.2Zm-15.9-62.7c1.7 5 5.6 8.9 10.6 10.4 4.9 1.4 10.2.1 13.9-3.6 2.1-2 3.4-4.6 3.8-7.4.1-.6-.9-.9-1-.3-.8 4.7-4.2 8.6-8.7 10.1-4.6 1.4-9.6.3-13.2-2.9-2.1-1.8-3.6-4.1-4.5-6.7-.2-.4-1.1-.2-.9.4Zm-17 22.7c-1 10.8-1.9 21.6-3 32.4-.3 3-.8 6-1.4 8.9-.1.6.8.9 1 .3 1.1-5.2 1.8-10.4 2.1-15.7l1.5-16.5.9-9.3c0-.7-1-.7-1.1-.1Zm-26.5 28.4c1.5 1.2 2.7 2.7 3.5 4.4.1.2.4.3.7.2.2-.1.3-.4.2-.7-.9-1.8-2.1-3.4-3.7-4.6-.2-.2-.5-.2-.7 0s-.2.5 0 .7Z" />
      <path d="M435 428.5c.4.4.9.8 1.5 1 .8.3 1.5.6 2.3 1l4.8 2.3 9.7 4.8c.6.3 1.1-.6.5-.9l-9.5-4.7-4.7-2.3c-.7-.3-1.4-.8-2.2-1-.7-.2-1.3-.5-1.8-.9-.3-.4-1 .3-.6.7Zm76.5 7.9c4.2-.8 8.3-2 12.3-3.6 4-1.5 7.8-3.4 11.3-5.8.5-.4 0-1.2-.5-.9-3.5 2.3-7.3 4.3-11.3 5.8-3.9 1.5-8 2.7-12.1 3.5-.6.2-.3 1.1.3 1Z" />
      <path d="M509 447.3c2.8 1.8 5.4 3.9 7.7 6.2.2.2.5.2.7 0 .1-.1.2-.3.1-.5-1.8-5.6-3.2-11.4-4.3-17.2-.1-.6-1.1-.4-1 .3 1.1 5.8 2.6 11.5 4.3 17.2l.8-.5c-2.4-2.4-5.1-4.5-7.9-6.3-.5-.4-1 .4-.4.8Zm156.5-12.4s15.8 12.8 17.2 19.3l3.9-5s2.5-1.5-2.9-4.7-18.2-9.6-18.2-9.6Zm-150.9.7s15.9 1.4 21.7-1.7l-.6-5.7s.5-2.1-2.4-.5" />
      <path d="M515.9 452.5c.4.6.9 1.2 1.4 1.8l.7.7c.2.2.4.5.5.8.1.2.4.3.7.2.2-.1.3-.4.2-.7-.3-.6-.8-1.2-1.3-1.6-.5-.5-.9-1.1-1.3-1.7-.1-.3-.4-.4-.7-.2-.3.1-.4.4-.2.7-.1 0 0 0 0 0Zm-117.4-2.3c2.5 3 5.5 5.5 8.8 7.5.2.1.5.1.7-.2.1-.2.1-.5-.2-.7-3.3-1.9-6.2-4.4-8.6-7.3-.4-.5-1.1.2-.7.7Zm40.7-20.1s12.4 16.2 13.4 21.8l1.3-10.9s1.9-3.6-1.5-4.8m21.8 9.7c-5.5-4.2-8-11.3-6.3-18 .8-3.4 2.6-6.4 5.2-8.7 2.8-2.4 6.1-4.2 9.7-5.2 3.5-1.1 7.3-.6 10.5 1.2 3 1.9 5.2 4.6 6.5 7.9 2.6 6.3 1.4 13.5-3 18.7-2.4 2.8-5.8 4.8-9.4 5.5-4.3.8-8.7.1-12.9-.8-.2 0-.2.2-.1.2 3.9 1 7.9 1.4 11.9 1 3.4-.4 6.6-1.9 9.2-4.2 5.1-4.7 7.1-11.9 5.2-18.6-.9-3.4-2.8-6.4-5.5-8.7s-6.3-3.4-9.8-3c-3.8.5-7.4 2-10.5 4.3-2.9 2-5.2 4.8-6.5 8.1-2.6 6.6-1.1 14 3.9 19.1.6.6 1.2 1.1 1.8 1.5.1 0 .3-.3.1-.3Z" />
      <path d="M477.3 442.1c-3.9-3-5.6-7.9-4.4-12.7 1.3-5 5.7-8.5 10.5-9.8 2.5-.7 5.1-.4 7.4 1 2 1.3 3.6 3.2 4.4 5.5 1.8 4.4.9 9.5-2.2 13.1-1.7 2-4 3.3-6.6 3.8-3 .4-6.1.2-9-.6-.2 0-.2.2-.1.2 2.8.7 5.6 1 8.5.7 2.4-.3 4.6-1.4 6.4-3 3.6-3.3 5-8.4 3.6-13.1-1.3-4.8-5.5-8.7-10.6-8.2-2.7.4-5.3 1.4-7.5 3-5.7 3.9-7.1 11.6-3.3 17.3.8 1.1 1.7 2.1 2.7 2.9.2.1.3-.1.2-.1Z" />
      <path d="M480.2 437.5c-2.2-1.7-3.2-4.6-2.5-7.4.9-2.8 3.2-4.9 6-5.6 1.5-.5 3-.3 4.4.5 1.2.8 2.1 1.9 2.6 3.3 1 2.5.5 5.4-1.3 7.5-2.4 2.7-5.8 2.6-9 1.8-.2 0-.2.2-.1.2 3 .7 6.4.9 8.8-1.4 2.1-2 2.9-4.9 2-7.7-.8-2.8-3.3-5.1-6.4-4.8a9 9 0 0 0-6.9 5c-1.2 3.1-.3 6.6 2.3 8.6.2.3.3.1.1 0Z" />
      <path d="M482.9 433.7c-.8-.6-1.1-1.6-.9-2.6.3-1 1.1-1.7 2.1-1.9s2 .3 2.4 1.3c.3.9.2 1.9-.5 2.6-.8.8-2 1-3.1.6-.1 0-.2.2-.1.3 1.1.4 2.3.2 3.2-.5.8-.7 1.1-1.8.7-2.8-.3-1.1-1.2-1.8-2.3-1.7-1.1.1-2.1.8-2.6 1.9-.4 1.1-.1 2.4.9 3.2.2-.1.3-.3.2-.4Zm-88.2-148.5 12 44.9 12.1 44.9 6.7 25.1c0 .1.1.1.2 0l.1-.1-12-44.9-12.1-44.9-6.7-25.1c0-.1-.1-.1-.2 0 0 0 0 .1-.1.1Zm-6 90.7c3.9 7.4 7.4 15 10.5 22.7.1.2.3.1.2-.1-3.1-7.8-6.6-15.4-10.5-22.8 0-.1-.2 0-.2.2Zm163.8-43.8c-.7 9.8-1 19.6-1.1 29.5v8.3c0 .1.1.1.2.1 0 0 .1 0 .1-.1-.1-9.8.2-19.7.7-29.5.1-2.8.3-5.5.5-8.3-.1-.2-.4-.2-.4 0ZM564 381l-2.4 26.7c0 .2.2.2.2 0L564 381c.2-.2 0-.2 0 0Zm160.5-73-12.7 34.6-12.7 34.6-7.1 19.5c0 .2.2.2.2.1l12.7-34.6 12.7-34.6 7.2-19.5c0-.1-.2-.2-.3-.1Zm10.4 68.4c-4.5 8.8-9.4 17.5-14.6 26-1.5 2.4-2.9 4.8-4.5 7.2q0 .1.1.2h.1c5.3-8.4 10.4-17 15.1-25.7l3.9-7.5c.2-.2-.1-.3-.1-.2Zm-13.6 70.5 7.8-13c.1-.1-.1-.3-.2-.1l-7.8 13c-.1.1.1.2.2.1Zm24.1-84.1.7-.6c.1 0 .1-.1 0-.2s-.1-.1-.2 0l-.7.6c-.1 0-.1.1 0 .2s.1.1.2 0q0 .1 0 0Zm-425.3-22.3c7.6 13.9 15.1 27.9 22.4 41.9 2.1 3.9 4.1 7.9 6.2 11.9.1.1.3 0 .2-.1-7.3-14.1-14.8-28.1-22.2-42-2.1-3.9-4.2-7.8-6.4-11.8-.1-.2-.3 0-.2.1Z" />
      <path
        fill="#60a5fa"
        d="M485.3 241.7c12.5 0 22.6 10.1 22.6 22.6s-10.1 22.6-22.6 22.6-22.6-10.1-22.6-22.6c.1-12.5 10.2-22.6 22.6-22.6Zm308.6 154.4v-20c0-1.4-.8-2.8-2-3.5l-17.3-10c-1.3-.7-2.8-.7-4 0l-17.3 10c-1.3.7-2 2.1-2 3.5v20c0 1.4.8 2.8 2 3.5l17.3 10c1.3.7 2.8.7 4 0l17.3-10c1.2-.7 2-2.1 2-3.5Zm-432.1-98.6 24.2 19.3c1.4 1.1 1.6 3.1.5 4.5l-19.3 24.2c-1.1 1.4-3.1 1.6-4.5.5l-24.2-19.3c-1.4-1.1-1.6-3.1-.5-4.5l19.3-24.2c1.1-1.4 3.1-1.6 4.5-.5Zm304.6-30.6 27.9 29c.9.9 2.3 1 3.3.1.3-.3.5-.6.6-1l11.5-37.1c.4-1.2-.3-2.5-1.5-2.9-.4-.1-.8-.1-1.2-.1l-39.5 8.1c-1.2.2-2.1 1.4-1.8 2.7.2.5.4.9.7 1.2Z"
      />
      <path d="M607.8 287.6c.9-.1 1.8-.4 2.6-1 .7-.4 1.1-1.1 1.2-1.9 0-.5-.3-.9-.8-.9h-.2c-.8.1-1.3 1.1-1.6 1.7-.4.8-.3 1.7.2 2.4.4.6 1.2.8 1.8.6l.9-.6c.5-.5 1.3-.4 1.8.1.6.6 1.4 1.1 2.2 1.2 1.4 0 2.9 0 4.3-.1l4.7-.2c.1 0 .1-.1.1-.2 0 0 0-.1-.1-.1l-7.5.3c-1.2 0-2.3.1-3.3-.8-.5-.4-1-1-1.7-.9-.9.1-1.3 1.2-2.3 1-1.5-.4-1.4-2.1-.8-3.2.2-.5.9-1.5 1.6-1s.1 1.4-.3 1.8c-.8.8-1.9 1.4-3 1.5.1.2.1.4.2.3Zm.2 9.3c2.7 1 5.4 1.9 8.2 2.9.2.1.2-.2.1-.2-2.7-1-5.4-1.9-8.2-2.9-.1-.1-.2.2-.1.2Zm-26 134.3c1.3-.7 2.9-.5 4 .4.1.1.3-.1.2-.2-1.2-1-2.9-1.1-4.3-.4-.1 0-.1.1.1.2Zm10.5-1.5h1.6c.1 0 .1-.1.1-.2 0 0 0-.1-.1-.1h-1.6c-.1.1-.1.2 0 .3-.1 0 0 0 0 0Zm-13.1 13c.9.4 2.2 1 3.2.3.5-.4.7-1.2.4-1.8-.2-.4-.8-.6-1.2-.3-.1.1-.3.2-.3.3-.4.7-.2 1.6.5 2.1 0 0 .1 0 .1.1 1.2.7 2.7.3 3.9 1h.2v-.2c-.8-.4-1.7-.5-2.6-.6-.8-.1-1.8-.4-2-1.2-.1-.6.4-1.7 1.1-1.2.4.5.4 1.1 0 1.6-.7.9-2.2.2-3.1-.2-.2-.2-.3 0-.2.1Z" />
      <path fill="none" stroke="#000" strokeMiterlimit="10" d="M501.4 607.7H235.9m271.2 38.5H241.7m118.4 38.5H247.5" />
    </svg>
  )
}
