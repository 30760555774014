import classNames from 'classnames'
import { Input } from 'components/Input'
import { forwardRef } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

type SearchInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  append?: string
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInputComponent(props, ref) {
  return (
    <Input
      {...props}
      icon={HiOutlineSearch}
      append={props.append}
      className={classNames(
        props.className,
        props.className
          ?.split(' ')
          .find(p => (p.startsWith('p-') || p.startsWith('px') || p.startsWith('py') ? '' : 'py-2.5 pr-3 ')),
      )}
      type="search"
      autoComplete="off"
      ref={ref}
    />
  )
})
