import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Box } from 'components/Box'
import { Icon } from 'components/Icon'
import { Flex } from 'components/layout/Flex'
import { Modal, ModalFooter, ModalHeader } from 'components/Modal'
import { NewDocumentModal } from 'components/Modals/NewDocumentModal'
import { RequireRole } from 'components/RequireRole/RequireRole'
import { Tabs } from 'components/Tabs'
import { Text } from 'components/Text'
import { useDocument } from 'hooks/useDocument'
import { useFlags } from 'hooks/useFlagsmith'
import { useRequiredDocuments } from 'hooks/useRequiredDocuments'
import { useRoles } from 'hooks/useRoles'
import { useToggle } from 'hooks/useToggle'
import { selectGlobalActiveDocumentId, setGlobalActiveDocumentId } from 'internal/redux'
import { useAppDispatch } from 'internal/redux/hooks'
import { updateDocument } from 'lib/api/admin'
import { requestAttestation } from 'lib/api/document'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import Link from 'next/link'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { BiRotateRight } from 'react-icons/bi'
import { HiOutlineChevronDown } from 'react-icons/hi'
import {
  HiArrowPathRoundedSquare,
  HiOutlineCommandLine,
  HiOutlineDocument,
  HiOutlineDocumentCheck,
  HiOutlineEye,
  HiOutlineEyeSlash,
  HiOutlinePencilSquare,
  HiOutlineWrench,
} from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useKeyPress } from 'react-use'
import { formatName } from 'util/driver'
import { Button } from '../Button'
import { DecisionOverrideModal } from '../Modals/DecisionOverrideModal'
import { DocumentModalContent } from './DocumentModalContent'

type DocumentTab = 'Document' | 'Attestation'
const tabs: { key: DocumentTab; label: string; icon?: any }[] = [
  { key: 'Document', label: 'Document', icon: HiOutlineDocument },
  { key: 'Attestation', label: 'Attestation', icon: HiOutlinePencilSquare },
]

export function DocumentModal() {
  const dispatch = useAppDispatch()
  const documentId = useSelector(selectGlobalActiveDocumentId)
  const [isReplacing, setIsReplacing] = useState(false)

  const { isSpecialist, isProvider, isSuperAdmin, isReadOnly, isRiskManager } = useRoles()
  const formRef = useRef<HTMLFormElement>(null)
  const { document: doc, refetch } = useDocument(documentId)
  const { allRequiredDocuments } = useRequiredDocuments()
  const [rotation, setRotation] = useState(0)
  const [shouldClose] = useKeyPress('Escape')
  const [isOverriding, setIsOverriding] = useState(false)
  const [hideInfo, toggleHideInfo] = useToggle(false)
  const [tabIdx, setTabIdx] = useState(0)
  const flags = useFlags(['coi'])

  useEffect(() => {
    setRotation(0)
  }, [doc])

  const onClose = useCallback(() => dispatch(setGlobalActiveDocumentId(null)), [dispatch])

  useEffect(() => {
    if (shouldClose || !documentId) {
      setTabIdx(0)
      onClose()
    }

    return () => {
      setIsOverriding(false)
    }
  }, [shouldClose, documentId, onClose])

  useEffect(() => {
    setIsReplacing(false)
  }, [doc])

  const handleSubmit = e => {
    e.preventDefault()
    const updates: any = {
      status: doc.status,
      documentType: doc.documentType,
      extractedData: cloneDeep(doc.extractedData || {}),
    }

    const formFields = Array.from(formRef.current.elements).filter(e =>
      ['INPUT', 'TEXTAREA', 'SELECT'].includes(e.tagName),
    )
    formFields.forEach((e: any) => set(updates.extractedData, e.name || e.id, e.value))

    updates.id = doc.id

    updates.status = updates.extractedData.status
    delete updates.extractedData.status

    updates.documentType = updates.extractedData.documentType

    updates.statusReason = updates.extractedData.statusReason
    delete updates.extractedData.statusReason

    updateDocument(updates).finally(() => onClose())
  }
  const isCoiEnabled = flags?.coi?.enabled
  const hasAttestationRequest =
    doc?.owner?.type === 'provider' &&
    doc?.owner?.provider?.attestationRequests?.filter(a => a.documentId === doc?.id)?.length > 0
  const hasAttestation = doc?.attestation
  const showRequestAttestationButton = isCoiEnabled && !isProvider
  const canRequestAttestation =
    doc?.type?.canAttest && doc?.status === 'approved' && !hasAttestation && !hasAttestationRequest && !isProvider

  const requestAttestationOnClick = async () => {
    try {
      const res = await requestAttestation({
        documentId: doc.id,
        providerId: owner.provider.id,
      })
      if (!res.ok) {
        const body = await res.json()
        toast.error(body.error || 'Something went wrong. Please try again later')
        throw new Error(body.error || 'Something went wrong. Please try again later')
      }
      toast.success('Successfully requested attestation. A notification will be sent to the Transportation Provider.')
    } catch (e) {
      toast.error(e)
    }
    refetch()
  }

  const owner = doc?.owner
  const ownerType = owner?.type

  return (
    <Modal full isOpen={Boolean(documentId)} onClose={onClose}>
      <form className="flex flex-1 flex-col overflow-hidden" ref={formRef} onSubmit={handleSubmit}>
        <ModalHeader title={<Text className="pl-2">{doc?.type?.name}</Text>} icon={false}>
          <div className="flex flex-1 items-center justify-between gap-2 pl-4">
            <Flex className="relative flex-1 items-center justify-end gap-2">
              {doc?.attestation?.generatedAt && (
                <Flex className="-mb-3">
                  <Tabs itemClassName="py-2 pb-4 px-2" items={tabs} activeIdx={tabIdx} onChange={setTabIdx} />
                </Flex>
              )}
              <Box>
                <Button
                  icon={BiRotateRight}
                  onClick={() => {
                    setRotation((rotation - 90) % 360)
                  }}
                >
                  Rotate
                </Button>
              </Box>

              <RequireRole deny="insurance-producer">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button rightIcon={HiOutlineChevronDown}>Actions</Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="flex flex-col gap-1" align="end">
                    <DropdownMenuItem className="flex items-center gap-2" onClick={() => toggleHideInfo()}>
                      <Icon icon={hideInfo ? HiOutlineEye : HiOutlineEyeSlash} color="black" />
                      <Text size="sm" weight="normal">
                        {hideInfo ? 'Show' : 'Hide'} details
                      </Text>
                    </DropdownMenuItem>
                    {isSuperAdmin && (
                      <DropdownMenuItem asChild>
                        <Link href={`/admin/documents/${doc?.id}`} className="flex items-center gap-2">
                          <Icon icon={HiOutlineCommandLine} color="black" />
                          <Text size="sm" weight="normal">
                            Open document panel
                          </Text>
                        </Link>
                      </DropdownMenuItem>
                    )}
                    {['approved', 'rejected'].includes(doc?.status) &&
                      !isSpecialist &&
                      !isProvider &&
                      !isReadOnly &&
                      !isRiskManager && (
                        <DropdownMenuItem className="flex items-center gap-2" onClick={() => setIsOverriding(true)}>
                          <Icon icon={HiOutlineWrench} color="black" />
                          <Text size="sm" weight="normal">
                            Override decision
                          </Text>
                        </DropdownMenuItem>
                      )}
                    {showRequestAttestationButton && !isRiskManager && (
                      <DropdownMenuItem
                        className="flex items-center gap-2"
                        onClick={() => requestAttestationOnClick()}
                        disabled={!canRequestAttestation}
                      >
                        <Icon icon={HiOutlineDocumentCheck} color="black" />
                        <Text size="sm" weight="normal">
                          Request attestation
                        </Text>
                      </DropdownMenuItem>
                    )}
                    <RequireRole allow="provider">
                      <Button
                        variant="unstyled"
                        className="dark:hover:bg-dark-400 flex w-full cursor-pointer items-center gap-1 rounded px-3 py-2 hover:bg-gray-100"
                        icon={HiArrowPathRoundedSquare}
                        onClick={() => {
                          setIsReplacing(true)
                        }}
                      >
                        <Text size="sm" weight="normal">
                          Replace
                        </Text>
                      </Button>
                    </RequireRole>
                  </DropdownMenuContent>
                </DropdownMenu>
              </RequireRole>
            </Flex>
          </div>
        </ModalHeader>
        {doc ? (
          <DocumentModalContent
            onClose={onClose}
            document={doc}
            activeTab={tabs[tabIdx].key}
            rotation={rotation}
            hideInfo={hideInfo}
            reload={refetch}
            baseUrl={isProvider ? '/providers' : '/network'}
          />
        ) : (
          <div className="bg-white">
            <AiOutlineLoading className="mx-auto my-40 h-20 w-20 animate-spin text-blue-400" />
          </div>
        )}
        <ModalFooter isOnlyClose onClose={onClose} />
      </form>
      {!isProvider && !isSpecialist && (
        <DecisionOverrideModal
          document={doc}
          isOpen={!!isOverriding}
          onClose={async () => {
            await refetch()
            setIsOverriding(false)
          }}
        />
      )}
      {doc && (
        <NewDocumentModal
          ownerId={owner.driver?.id || owner.vehicle?.id || owner.provider?.id}
          ownerName={owner.driver ? formatName(owner.driver) : owner.vehicle?.vin || owner.provider?.name}
          ownerType={ownerType as any}
          driver={owner.driver}
          vehicle={owner.vehicle}
          provider={owner.provider}
          requiredDocument={allRequiredDocuments?.find(rd => rd.documentType === doc?.documentType)}
          isOpen={isReplacing}
          onClose={() => {
            onClose()
            setIsReplacing(false)
          }}
          refresh={refetch}
        />
      )}
    </Modal>
  )
}
