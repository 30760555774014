import { gql } from '@apollo/client'

export const QueryEntities = gql`
  query Entities($providerId: String!) {
    drivers(providerId: $providerId) {
      total
    }
    vehicles(providerId: $providerId) {
      total
    }
  }
`
