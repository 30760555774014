import cx from 'classnames'
import { Tooltip } from 'components/Tooltip'
import { forwardRef } from 'react'
import { Button, ButtonProps } from '../Button'

export type IconButtonProps = ButtonProps & {
  icon: any
  tooltip?: string
  ariaLabel: string
}

const iconSize = {
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
}

export const IconButton = forwardRef<any, IconButtonProps>(({ icon: Icon, tooltip, ariaLabel, ...props }, ref) => {
  const hasChildren = !!props.children

  return (
    <Tooltip label={tooltip}>
      <Button {...props} ref={ref} aria-label={ariaLabel}>
        <Icon className={cx('flex-shrink-0 text-inherit', hasChildren && '-ml-1 mr-2', iconSize[props.size || 'md'])} />
        {props.children}
      </Button>
    </Tooltip>
  )
})

IconButton.displayName = 'IconButton'
