import { cn } from '@/lib/utils'
import { Box } from './Box'

type DotProps = {
  color?: keyof typeof DotColor
  className?: string
}

export const DotColor = {
  blue: 'bg-blue-400',
  green: 'bg-green-400',
  red: 'bg-red-400',
  yellow: 'bg-yellow-400',
  gray: 'bg-gray-200 dark:bg-dark-700',
  transparent: 'bg-transparent',
}

export function Dot(props: DotProps) {
  const { color = 'blue' } = props
  return <Box className={cn('h-2 w-2 flex-shrink-0 rounded-full', DotColor[color], props.className)} />
}
