import classNames from 'classnames'
import Link from 'next/link'
import { TransportationProvider } from 'types/Provider'

type ProviderLinkProps = {
  size?: 'xs' | 'sm' | 'md'
  baseUrl: string
  provider?: TransportationProvider
}

const sizes = size => {
  switch (size) {
    case 'xs':
      return 'text-xs'
    case 'sm':
      return 'text-sm'
    default:
      return 'text-md'
  }
}

export function ProviderLink(props: ProviderLinkProps) {
  const { provider, baseUrl, size = 'md' } = props
  const sizeClasses = sizes(size)
  if (!provider) {
    return null
  }

  return (
    <Link
      href={`${baseUrl}/providers/${provider.id}`}
      className={classNames('font-medium text-gray-900 hover:underline dark:text-gray-100', sizeClasses)}
    >
      {provider.name}
    </Link>
  )
}
