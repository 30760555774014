import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export const Select = forwardRef<HTMLSelectElement, SelectProps>((props: SelectProps, ref) => {
  return (
    <select
      {...props}
      ref={ref}
      className={twMerge([
        'dark:border-dark-500 dark:bg-dark-800 relative block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:text-gray-200',
        props.className,
      ])}
    >
      {props.children}
    </select>
  )
})

Select.displayName = 'Select'
