import { gql, useLazyQuery } from '@apollo/client'

const Query = gql`
  query Provider($id: String!) {
    provider(id: $id) {
      id
      traits
    }
  }
`

type Variables = {
  id: string
}

type Traits = {
  scheduledAutoVins?: string[]
}

export function useLazyProviderTraits(variables: Variables) {
  const [load, { data, loading, refetch }] = useLazyQuery(Query, {
    variables,
  })

  const traits: Traits = data?.provider?.traits || {}

  return {
    load,
    traits,
    loading,
    refetch,
  }
}
