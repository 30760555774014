import { gql } from '@apollo/client'

export const DRIVER_FRAGMENTS = gql`
  fragment DriverDetails on Driver {
    id
    providerId
    documentGroupId
    driverNumber
    firstName
    middleName
    lastName
    suffix
    dob
    ssn
    phone
    email
    streetAddress
    streetAddress2
    city
    state
    zip
    hackNumber
    hackExpires
    driversLicenseNumber
    driversLicenseExpires
    isActive
    isAuthorized
    isCompliant
    compliantUntil
    hasAccount
    createdAt
    updatedAt
  }
`
