import { useFlags } from 'hooks/useFlagsmith'
import { Fragment, ReactNode } from 'react'

type Props = {
  flag: string
  children: ReactNode | ReactNode[]
  value?: string
}

export function RequireFlag({ flag, children, value }: Props) {
  const flags = useFlags([flag])

  if (flags?.[flag]?.enabled === true) {
    if (value && !tryFlag(flags?.[flag]?.value, value)) {
      return null
    }
    return <Fragment>{children}</Fragment>
  }

  return null
}

function tryFlag(flagValue?: string, value?: string) {
  if (flagValue === undefined || value === undefined) {
    return false
  }

  try {
    const flagValueParsed = JSON.parse(flagValue)

    if (Array.isArray(flagValueParsed)) {
      return flagValueParsed.includes(value)
    }
  } catch {}

  return flagValue === value
}
