import mixpanel, { Mixpanel, OverridedMixpanel } from 'mixpanel-browser'
import React, { useEffect } from 'react'

export const AnalyticsContext = React.createContext<OverridedMixpanel | Partial<Mixpanel>>(null)

type Props = {
  children: React.ReactNode
  isTest?: boolean
}

export const mockAnalytics: Partial<Mixpanel> = {
  register: async () => {},
  track: async () => {},
}

export const AnalyticsProvider = ({ children, isTest = false }: Props) => {
  const analytics = isTest || !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ? mockAnalytics : mixpanel
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
        debug: process.env.NEXT_PUBLIC_APP_ENV === 'development' || process.env.NEXT_PUBLIC_APP_ENV === 'local',
      })
      mixpanel.register({ Environment: process.env.NEXT_PUBLIC_APP_ENV })
    }
  }, [])

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
}
