import { gql } from '@apollo/client'

export const AUTHORIZATION_FRAGMENTS = gql`
  fragment EntityAuthorizationDetails on Authorization {
    id
    expiresAt
    completedAt
    createdAt
    updatedAt
    decider {
      firstName
      lastName
    }
  }
`
