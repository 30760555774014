import { Driver } from 'types/Driver'
import { Vehicle } from 'types/Vehicle'
import { useShowDialog } from './useDialog'

export const useShouldActivatePrompt = (entity: Driver | Vehicle) => {
  const ownerType = (entity as Vehicle)?.vin ? 'vehicle' : 'driver'
  const prompt = useShowDialog({
    title: `Current ${ownerType} is inactive`,
    msg: `The ${ownerType} you just uploaded documents for is currently inactive, which means it is not eligible for billing authorization. Do you wish to activate this ${ownerType}?`,
    btnText: 'Activate',
    variant: 'primary',
  })

  const shouldActivate = entity?.isActive ? async () => false : prompt.show

  return { shouldActivate }
}
