import classNames from 'classnames'
import { Badge } from 'components/Badge'
import { useSidebarContext } from 'hooks/useSidebarContext'
import Link from 'next/link'

type SidebarLogoProps = {
  color?: 'light' | 'dark'
}

export function SidebarLogo(props: SidebarLogoProps) {
  const { isCollapsed } = useSidebarContext()
  const { color = 'light' } = props

  return (
    <Link href="/">
      <div className={classNames('flex flex-shrink-0 items-center', isCollapsed && 'flex-col justify-center gap-4')}>
        {color === 'light' && <img className="h-6 w-auto" src="/img/SVG/logo.svg" alt="Complicore" />}
        {color === 'dark' && <img className="h-6 w-auto" src="/img/logo-dark.svg" alt="Complicore" />}
        {!isCollapsed && (
          <span className={classNames('ml-4 font-medium', color === 'dark' ? 'text-black' : 'text-white')}>
            Complicore
          </span>
        )}
        {process.env.NEXT_PUBLIC_APP_ENV === 'local' && (
          <Badge color="blue" padding="sm" rounded="full" size="xs" className={classNames(!isCollapsed && 'ml-3')}>
            Local
          </Badge>
        )}
      </div>
    </Link>
  )
}
