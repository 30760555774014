import { gql } from '@apollo/client'

export const QueryWorkspaceLinks = gql`
  query WorkspaceLinks {
    workspaceLinks {
      total
      results {
        id
        workspaceId
        brokerWorkspaceId
        marketId
        createdAt
        updatedAt

        market {
          id
          name
        }

        broker {
          id
          name
          type
          attributes
        }

        workspace {
          id
          name
          type
        }
      }
    }
  }
`
