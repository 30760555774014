import type { Meta, StoryObj } from '@storybook/react'
import { ChecksList } from './ChecksList'
import { ExampleChecks } from './constants'

const meta: Meta<typeof ChecksList> = {
  title: 'Checks Feed/ ChecksList',
  component: ChecksList,
}
type Story = StoryObj<typeof ChecksList>

export default meta
export const Default: Story = {
  args: {
    checks: ExampleChecks as any,
  },
}
