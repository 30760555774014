import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { QueryProviderDrivers } from 'internal/graphql/query/driver'
import { QueryProviderVehicles } from 'internal/graphql/query/vehicle'
import { useEffect } from 'react'
import { Driver } from 'types/Driver'
import { Vehicle } from 'types/Vehicle'

type RosterOptions = { id: string }

const queryOptions: LazyQueryHookOptions<any, RosterOptions> = {
  fetchPolicy: 'no-cache',
}

export const useProviderRoster = (id: string) => {
  const [fetchDrivers, { data: driverData, refetch: refetchDrivers, called: fetchedDrivers }] = useLazyQuery<
    any,
    RosterOptions
  >(QueryProviderDrivers, queryOptions)
  const [fetchVehicles, { data: vehicleData, refetch: refetchVehicles, called: fetchedVehicles }] = useLazyQuery<
    any,
    RosterOptions
  >(QueryProviderVehicles, queryOptions)

  const drivers = driverData?.providerDrivers?.results || []
  const vehicles = vehicleData?.providerVehicles?.results || []

  useEffect(() => {
    if (!id) {
      return
    }

    if (!fetchedDrivers) {
      fetchDrivers({ variables: { id } })
    } else {
      refetchDrivers({ id })
    }

    if (!fetchedVehicles) {
      fetchVehicles({ variables: { id } })
    } else {
      refetchVehicles({ id })
    }
  }, [id, fetchedDrivers, fetchedVehicles, fetchDrivers, refetchDrivers, fetchVehicles, refetchVehicles])

  return {
    drivers,
    vehicles,
  } as {
    drivers: Driver[]
    vehicles: Vehicle[]
  }
}
