import { gql } from '@apollo/client'
import { useRequiredDocuments } from 'hooks/useRequiredDocuments'
import { client } from 'internal/graphql/client'
import * as pdfjs from 'pdfjs-dist'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Document, FieldMetadata, TextractBlock } from 'types/Document'
import { DocumentPage } from './DocumentPage'

const QueryDocumentUrls = gql`
  query DocumentUrls($id: String!) {
    document(id: $id) {
      id
      urls
    }
  }
`

const QueryDocumentUrl = gql`
  query DocumentUrl($id: String!) {
    document(id: $id) {
      id
      url
    }
  }
`

export type DocumentPagesProps = {
  blocks: TextractBlock[]
  document: Document
  fieldMetadata: FieldMetadata[]
  onChange: (field: string, value: string, blocks: TextractBlock[]) => void
  onFirstPageLoad?: (dataUrl: string) => void
  onData?: (data: any) => void
}

export const DocumentPages = ({
  document: doc,
  fieldMetadata,
  blocks = [],
  onChange,
  onData,
  onFirstPageLoad,
}: DocumentPagesProps) => {
  const size = useWindowSize()
  const containerRef = useRef<HTMLDivElement>(null)
  const [pdf, setPdf] = useState(null)
  const { requiredFields = {} } = useRequiredDocuments()
  const fields = useMemo(() => {
    if (!requiredFields || !doc?.documentType || !requiredFields[doc.documentType]) {
      return []
    }
    return Object.keys(requiredFields[doc.documentType])
  }, [doc, requiredFields])
  const [[width, height], setSize] = useState<number[]>([0, 0])
  const [urls, setUrls] = useState([])

  useEffect(() => {
    const rect = containerRef.current?.getBoundingClientRect()
    setSize([rect.width - 48, rect.height])
    return () => {
      setSize([0, 0])
    }
  }, [size])

  useEffect(() => {
    if (!doc) return

    if (doc.mimeType === 'application/pdf') {
      client
        .query({
          query: QueryDocumentUrl,
          variables: { id: doc.id },
        })
        .then(r => {
          if (!r.data?.document?.url) {
            return []
          }

          pdfjs.getDocument(r.data.document.url).promise.then(async pdf => {
            setPdf(pdf)
          })
        })
    } else {
      client
        .query({
          query: QueryDocumentUrls,
          variables: { id: doc.id },
        })
        .then(r => {
          if (!r.data?.document?.urls) {
            return []
          }

          return r.data.document.urls
        })
        .then(setUrls)
    }

    return () => {
      setUrls([])
    }
  }, [doc])

  useEffect(() => {
    if (!pdf) {
      setUrls([])
      return
    }

    Promise.all(
      Array.from({ length: pdf.numPages }, (_, i) => {
        return pdf.getPage(i + 1).then(page => {
          const scale = width / page.getViewport({ scale: 1 }).width
          const viewport = page.getViewport({ scale })
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width
          const renderContext = {
            canvasContext: context,
            viewport,
          }
          return page.render(renderContext).promise.then(() => {
            return canvas.toDataURL('image/jpeg', 0.7)
          })
        })
      }),
    ).then(setUrls)
  }, [pdf, width])

  return (
    <div className="relative mx-auto h-full max-w-7xl flex-1 space-y-4 px-6" ref={containerRef}>
      {urls.map((url, i) => (
        <DocumentPage
          key={i}
          document={doc}
          page={i}
          pages={urls.length}
          fields={fields}
          fieldMetadata={fieldMetadata}
          blocks={urls.length > 1 ? blocks.filter(b => b.Page === i + 1) : blocks}
          url={url}
          rotation={doc.rotation}
          width={width}
          height={height}
          onLoad={i === 0 ? onFirstPageLoad : undefined}
          onData={i === 0 ? onData : undefined}
          onChange={onChange}
        />
      ))}
    </div>
  )
}
