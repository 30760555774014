import { ApolloLink } from '@apollo/client'
import { store } from 'internal/redux'

export const AuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const auth = store.getState().auth

    if (!auth.isAuthenticated) {
      return forward(operation)
    }

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-Auth-Context': auth.account?.roles[0],
      },
    }))

    return forward(operation)
  })
}
