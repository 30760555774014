import { useAuth } from 'hooks/useAuth'
import { MissingRequirements, useHasMissingRequirements } from 'hooks/useHasMissingRequirements'
import { createContext, useContext } from 'react'

type Requirements = MissingRequirements & {
  hasMissingRequirements: boolean
}

export const RequirementsProviderContext = createContext<Requirements>({
  loading: false,
  hasMissingRequirements: false,
  missingRequirements: {
    serviceArea: false,
    tripCapacity: false,
    insuranceDocuments: false,
  },
  refetch: () => {},
})

export const useRequirementsProviderContext = () => useContext(RequirementsProviderContext)

export function RequirementsProvider({ children }) {
  const { account } = useAuth()
  const { loading, missingRequirements, refetch } = useHasMissingRequirements(account?.providerId)
  const hasMissingRequirements = Object.values(missingRequirements).some(value => value === true)

  return (
    <RequirementsProviderContext.Provider value={{ hasMissingRequirements, loading, missingRequirements, refetch }}>
      {children}
    </RequirementsProviderContext.Provider>
  )
}
