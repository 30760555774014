import { HiCheckCircle, HiClock, HiEye } from 'react-icons/hi2'
import { PacketStatus, SubmissionStatus } from 'types/Workspace'
import { PacketStatusBadge, SubmissionStatusBadge } from './types'

export const SUBMISSION_STATUS: Record<SubmissionStatus, SubmissionStatusBadge> = {
  archived: {
    id: 'archived',
    label: 'Archived',
    color: 'white',
  },
  pending: {
    id: 'pending',
    label: 'Pending',
    color: 'yellow',
  },
  reviewed: {
    id: 'reviewed',
    label: 'Requested changes',
    color: 'red',
  },
  accepted: {
    id: 'accepted',
    label: 'Accepted',
    color: 'green',
  },
}

export const PACKET_STATUS: Record<PacketStatus, PacketStatusBadge> = {
  pending: {
    id: 'pending',
    label: 'Waiting for review',
    color: 'yellow',
    icon: HiClock,
  },
  reviewed: {
    id: 'reviewed',
    label: 'Reviewed',
    color: 'white',
    icon: HiEye,
  },
  accepted: {
    id: 'accepted',
    label: 'Accepted',
    color: 'green',
    icon: HiCheckCircle,
  },
}
