import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export type CheckBoxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>((props, ref) => (
  <input
    {...props}
    ref={ref}
    type="checkbox"
    className={twMerge(
      'dark:bg-dark-400 dark:ring-offset-dark-500 h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-600 dark:text-blue-500 dark:focus:ring-blue-400',
      props.className,
    )}
  />
))

CheckBox.displayName = 'CheckBox'
