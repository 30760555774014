import { HiViewGrid } from 'react-icons/hi'
import { HiBuildingOffice2, HiClipboardDocumentList } from 'react-icons/hi2'
import { Nav } from './Nav'
import { NavLink } from './NavLink'

export function DiversityNav() {
  return (
    <Nav>
      <NavLink href="/network/diversity" icon={HiViewGrid}>
        Dashboard
      </NavLink>
      <NavLink href="/network/diversity/providers" icon={HiBuildingOffice2}>
        Providers
      </NavLink>
      <NavLink href="/network/diversity/submissions" icon={HiClipboardDocumentList}>
        Submissions
      </NavLink>
    </Nav>
  )
}
