import { Button } from 'components/Button/Button'
import { Fragment, ReactNode } from 'react'

type PanelActionsProps = {
  actions?: ReactNode
  isLoading?: boolean
  isSubmittable?: boolean
  onSubmit?: any
}

export const PanelActions = (props: PanelActionsProps) => {
  const { actions, isLoading, isSubmittable = true, onSubmit } = props
  return (
    <Fragment>
      <div className="dark:border-dark-500 dark:bg-dark-700 inline-flex w-full justify-end border-b border-t border-gray-200 px-6 py-4">
        <div className="flex flex-1 space-x-2">
          {actions}
          {isSubmittable && (
            <Button type="submit" variant="primary" loading={isLoading} disabled={isLoading} onClick={onSubmit}>
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </Fragment>
  )
}
