import { BrowserPDF417Reader } from '@zxing/browser'
import { DATA_ELEMENTS } from 'constants/pdf417'

declare var cv

const parseDate = (str: string) => {
  if (!str || str.length != 8) return ''
  const year = parseInt(str.slice(0, 4), 10)
  const month = parseInt(str.slice(4, 6), 10).toString().padStart(2, '0')
  const day = parseInt(str.slice(6, 8), 10).toString().padStart(2, '0')

  return `${month}/${day}/${year}`
}
export function draw(dst, rect) {
  const canvas = document.createElement('canvas')
  canvas.width = rect.width
  canvas.height = rect.height
  cv.imshow(canvas, dst)

  return canvas.toDataURL('image/png')
}
export async function decode(dataUrl: string) {
  const reader = new BrowserPDF417Reader()
  try {
    const result = await reader.decodeFromImageUrl(dataUrl)
    const entries = Object.fromEntries(
      result
        ?.getText()
        .split('\r')
        .map(l => l.split('\n').map(t => t.trim()))
        .flat()
        .filter(l => l.length > 3)
        .map(l => [l.slice(0, 3), l.slice(3)])
        .map(([code, val]) => [DATA_ELEMENTS[code]?.field || code, val]),
    )
    const names = entries['RBC']?.split(',') || []
    const firstName = names[1]
    const lastName = names[0]
    const middleName = names.slice(2).join(' ')

    const data = {
      addressStreet: entries['RBN'],
      addressCity: entries['RBP'],
      addressState: entries['RBQ'],
      addressZIP: entries['RBR'],
      vin: entries['VAD'],
      plate: entries['RAM'],
      make: entries['VAK'],
      model: entries['ZVC'],
      year: entries['VAL'],
      registeredParty: [entries['ZBC'], entries['ZBD']].filter(Boolean).join(' ').replace(/\@/g, ';'),
      policyNumber: entries['ZZB'],
      issuedAt: parseDate(entries['FAC']),
      expiresAt: parseDate(entries['FAD'] || entries['RAG']),
      firstName,
      lastName,
      middleName,
    }

    return data
  } catch (e) {
    return null
  }
}
