import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { Box } from 'components/Box'
import { CheckBox } from 'components/CheckBox'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'
import { ReactNode } from 'react'
import { HiExclamationCircle, HiMinus, HiPlus } from 'react-icons/hi'

type Props = {
  label: string | ReactNode
  description?: string
  defaultOpen?: boolean
  expandable?: boolean
  actions?: ReactNode
  completed: boolean
  missingRequirement?: boolean
  severity?: 'warning' | 'danger'
}

export function OnboardingStep({
  actions,
  completed,
  description,
  label,
  expandable = true,
  defaultOpen = false,
  missingRequirement = false,
  severity = 'warning',
}: Props) {
  const key = Math.random().toString(36).substring(7)

  return (
    <Disclosure defaultOpen={defaultOpen} key={key}>
      {({ open }) => (
        <Box className="space-y-2">
          <Disclosure.Button
            disabled={completed || !expandable}
            className={classNames(
              'dark:border-dark-500 dark:bg-dark-600 flex w-full items-center justify-between gap-4 rounded-md bg-gray-50 p-2 dark:border',
              !completed && expandable && 'hover:cursor-pointer hover:bg-gray-100',
              missingRequirement ? (severity === 'danger' ? 'border border-red-500' : 'border border-yellow-500') : '',
            )}
          >
            <Box className="flex flex-1 items-center gap-4">
              {missingRequirement ? (
                <Icon icon={HiExclamationCircle} color={severity} />
              ) : (
                <CheckBox readOnly checked={completed} className="rounded-full" />
              )}
              {typeof label === 'string' ? (
                <Text size="sm" weight={completed ? 'normal' : 'medium'} variant={completed ? 'light' : 'base'}>
                  {label}
                </Text>
              ) : (
                label
              )}
            </Box>
            {!completed && expandable && <Icon icon={open ? HiMinus : HiPlus} size="sm" />}
          </Disclosure.Button>
          <Disclosure.Panel>
            {!completed && expandable && (
              <Box className="space-y-4 pb-4 pl-11 pr-4">
                <Text size="sm" weight="normal">
                  {description}
                </Text>
                {actions && <Box>{actions}</Box>}
              </Box>
            )}
          </Disclosure.Panel>
        </Box>
      )}
    </Disclosure>
  )
}
