import classNames from 'classnames'
import { fontSize, fontWeight, textVariant } from 'components/constants'

import { FontSize, FontWeight, TextVariant } from 'components/constants/types'
import { forwardRef } from 'react'
import { IconType } from 'react-icons'

type TitleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface TitleProps {
  id?: string
  type?: TitleType
  weight?: FontWeight
  size?: FontSize
  children: any
  center?: boolean
  className?: string
  icon?: IconType
  ariaLabel?: string
  variant?: TextVariant
}

const baseClasses = 'inline-flex items-center'

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ ariaLabel, id, center, children, className, size, type, weight, icon: Icon, variant }, ref) => {
    const Heading: TitleType = type || 'h1'
    const sizeClass = size ? fontSize[size] : fontSize.xl
    const weightClass = weight ? fontWeight[weight] : fontWeight.medium
    const variantClasses = variant ? textVariant[variant] : textVariant.extradark

    return (
      <Heading
        id={id}
        ref={ref}
        aria-label={ariaLabel || children}
        className={classNames(baseClasses, weightClass, sizeClass, center && 'text-center', variantClasses, className)}
      >
        {Icon && <Icon className="mr-2 h-6 w-6" />}
        {children}
      </Heading>
    )
  },
)

Title.displayName = 'Title'
