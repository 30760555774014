import { graphqlFetcher } from 'internal/graphql/fetcher'
import { QueryAccountWorkspace } from 'internal/graphql/query/account'
import { Account } from 'types/Account'
import { getProvider } from './providers'
import { get, post } from './req'

export const getProfile = async (config?: RequestInit) => {
  const r = await get('/v1/auth/profile', config)
  if (!r.ok) {
    return r
  }

  return {
    status: 200,
    ok: true,
    json: async () => {
      const acc: Account = await r.json()
      if (acc.providerId) {
        acc.provider = await getProvider(acc.providerId)
          .then(r => {
            return r.provider
          })
          .catch(() => null)
      }

      if (acc.workspaceId) {
        acc.workspace = await graphqlFetcher(QueryAccountWorkspace, { id: acc.workspaceId })
          .then(r => {
            return r.workspace
          })
          .catch(() => null)
      }

      return acc
    },
  }
}

export const refreshProfile = async (config?: RequestInit) => {
  const r = await get('/v1/auth/refresh', config)
  if (!r.ok) {
    return r
  }

  return {
    status: 200,
    ok: true,
    json: async () => {
      const acc: Account = await r.json()
      if (acc.providerId) {
        acc.provider = await getProvider(acc.providerId)
          .then(r => {
            return r.provider
          })
          .catch(() => null)
      }

      if (acc.workspaceId) {
        acc.workspace = await graphqlFetcher(QueryAccountWorkspace, { id: acc.workspaceId })
          .then(r => {
            return r.workspace
          })
          .catch(() => null)
      }

      return acc
    },
  }
}

export const checkDeviceLogin = (token: string) => {
  return post('/v1/auth/check-device-login', { token }, { credentials: 'include' })
}
