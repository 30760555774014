import { gql } from '@apollo/client'

import { FRAGMENTS } from '../fragments'
import { AUTHORIZATION_FRAGMENTS } from '../fragments/authorization'
import { DOCUMENT_FRAGMENTS } from '../fragments/document'
import { DRIVER_FRAGMENTS } from '../fragments/driver'
import { PROVIDER_FRAGMENTS } from '../fragments/provider'
import { VEHICLE_FRAGMENTS } from '../fragments/vehicle'

export const QueryEntityDetails = gql`
  ${DRIVER_FRAGMENTS}
  ${VEHICLE_FRAGMENTS}
  ${PROVIDER_FRAGMENTS}
  ${DOCUMENT_FRAGMENTS}
  ${AUTHORIZATION_FRAGMENTS}
  ${FRAGMENTS}
  query EntityDetails($id: ID!, $packetsInput: PacketsInput, $submissionsInput: SubmissionsInput) {
    workspaceEntity(id: $id) {
      id
      workspaceId
      name
      phone
      attributes
      createdAt
      updatedAt

      links {
        legacyId
        requirementGroupId
        brokerWorkspaceId

        legacyDriver {
          ...DriverDetails

          customAttributes {
            providerCode
            attributes
          }

          authorization {
            ...EntityAuthorizationDetails
          }

          provider {
            ...EntityProviderDetails
          }

          checks(latest: true) {
            ...CheckDetails
          }

          documentExemptions {
            ...DocumentExemptionDetails
          }

          requiredCredentials {
            name
            type

            requiredDocuments {
              ...RequiredDocumentDetails
            }
          }

          requiredDocuments {
            ...RequiredDocumentDetails

            requiredAuxiliaryDocuments {
              ...RequiredDocumentDetails
            }
          }
        }

        legacyVehicle {
          ...VehicleDetails

          customAttributes {
            providerCode
            attributes
          }

          authorization {
            ...EntityAuthorizationDetails
          }

          provider {
            ...EntityProviderDetails
          }

          checks(latest: true) {
            ...CheckDetails
          }

          documentExemptions {
            ...DocumentExemptionDetails
          }

          requiredCredentials {
            name
            type

            requiredDocuments {
              ...RequiredDocumentDetails
            }
          }

          requiredDocuments {
            ...RequiredDocumentDetails

            requiredAuxiliaryDocuments {
              ...RequiredDocumentDetails
            }
          }
        }
      }
      brokers {
        id
        name
      }
    }

    entityRequirements(id: $id) {
      id
      requirements {
        broker {
          id
          name
        }
        requiredCredentials {
          name
          requiredDocuments {
            documentType
            requireLevel
            name
            allowedMimeTypes
            dependencies {
              documentType
              name
            }
            primary {
              id
              status
              expiresAt
            }
            pending {
              id
              status
            }
          }
        }
        requirementGroup {
          id
          name
          config
          requirements {
            id
            name
            workspaceId
            fulfillmentTypes {
              id
              name
            }
          }
        }
      }
    }

    submissions(options: $submissionsInput) {
      total
      results {
        id
        requirementId
        workspaceId
        workspaceDocumentId
        documentType
        createdAt
        expiresAt
        status

        requirement {
          id
          name
        }
      }
    }

    packets(options: $packetsInput) {
      total
      results {
        id
        ownerId
        workspaceId
        status
        isPrimary
        requirementGroupId
        marketId
        validAt
        expiresAt
        createdAt
        submittedAt

        submissions {
          id
          requirementId
          workspaceId
          workspaceDocumentId
          documentType
          createdAt
          expiresAt
          status
          requirement {
            id
            name
          }
        }

        requirementGroup {
          requirements {
            id
            name
            workspaceId
            fulfillmentTypes {
              id
              name
            }
          }
        }
      }
    }
  }
`
