import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QueryWorkspaceEntities } from 'hooks/useWorkspaceEntities'
import { client } from 'internal/graphql/client'
import { QueryWorkspaces } from 'internal/graphql/query/workspace'
import { QueryWorkspaceLinks } from 'internal/graphql/query/workspaceLink'
import { QueryResponse } from 'types'
import { Workspace, WorkspaceEntity, WorkspaceLink } from 'types/Workspace'

type WorkspaceState = {
  brokers: WorkspaceLink[]
  workspaces: Workspace[]
  activeWorkspace?: Workspace
  providerEntity: WorkspaceEntity
}

const initialState: WorkspaceState = {
  brokers: [],
  workspaces: [],
  activeWorkspace: null,
  providerEntity: null,
}

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload
    },
    setBrokers: (state, action: PayloadAction<WorkspaceLink[]>) => {
      state.brokers = action.payload
    },
    setActiveWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.activeWorkspace = action.payload
    },
    resetWorkspace: state => {
      state.brokers = initialState.brokers
      state.workspaces = initialState.workspaces
      state.activeWorkspace = initialState.activeWorkspace
    },
  },

  extraReducers(builder) {
    builder
      .addCase(loadWorkspaces.fulfilled, (state, action: PayloadAction<Workspace[]>) => {
        state.workspaces = action.payload
      })
      .addCase(loadBrokers.fulfilled, (state, action: PayloadAction<WorkspaceLink[]>) => {
        state.brokers = action.payload
      })
      .addCase(loadProviderEntity.fulfilled, (state, action: PayloadAction<WorkspaceEntity>) => {
        state.providerEntity = action.payload
      })
  },
})

export const loadWorkspaces = createAsyncThunk('workspaces/loadWorkspaces', async () => {
  const res = await client.query({ query: QueryWorkspaces, fetchPolicy: 'no-cache' })
  const workspaces = res.data?.workspaces?.results?.filter(w => w.type === 'broker') || []

  return workspaces
})

export const loadBrokers = createAsyncThunk('workspaces/loadBrokers', async () => {
  const res = await client.query({ query: QueryWorkspaceLinks, fetchPolicy: 'no-cache' })
  const brokers = res.data?.workspaceLinks?.results || []

  return brokers
})

export const loadProviderEntity = createAsyncThunk('workspaces/loadProviderLinks', async (workspaceId: string) => {
  const res = await client.query<QueryResponse<'workspaceEntities', WorkspaceEntity>>({
    query: QueryWorkspaceEntities,
    fetchPolicy: 'no-cache',
    variables: {
      options: {
        workspaceId,
        type: 'provider',
      },
    },
  })

  const entity = res.data?.workspaceEntities?.results?.[0]

  return entity || null
})

export const { resetWorkspace, setWorkspaces, setActiveWorkspace } = workspaceSlice.actions
